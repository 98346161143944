import { TypographyOptions } from '@material-ui/core/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: [
    'Trebuchet MS',
    "'Open Sans'",
    'system-ui',
    '-apple-system',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    '"Noto Sans"',
    '"Liberation Sans"',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"Noto Color Emoji"',
  ].join(','),
  h1: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: 28,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h4: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h5: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  h6: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  // T2
  subtitle1: {
    fontSize: 20,
    lineHeight: 1.5,
  },
  // T3
  subtitle2: {
    fontSize: 18,
    lineHeight: 1.5,
  },
  // T4
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  // T5
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.5,
  },
  button: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1.5,
    textTransform: 'none',
  },
  button1: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: 1,
    textTransform: 'none',
  },
  button2: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1,
    textTransform: 'none',
  },
};
