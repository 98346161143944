import { Box, Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { get } from 'lodash';
import { ComponentType, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserPersonalData } from '../';
import { State } from '../../../common/store';
import { getRefreshData } from '../../../thunks/profile-thunks';
import useAuth from '../../auth/useAuth';
import { LayoutContext } from '../../layout/LayoutProvider';
import { VaccinationBadge } from '../../vaccination';
import useStyles from './styles';

interface UserProfileProps {
  className?: string;
}

export const UserProfile: ComponentType<UserProfileProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { authorized } = useAuth();
  const { sidebar } = useContext(LayoutContext);
  const profile = useSelector((state: State) => state.profile);
  const photo = get(profile, 'photo.data', false);

  const _updateUserData = () => {
    dispatch(getRefreshData());
  };
  const isLoading = get(profile, 'isLoading', false);

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container columnGap={3}>
        {photo && (
          <Grid item xs={12} sm="auto" pb={4.5}>
            <picture className={classes.media}>
              <source
                srcSet={`data:image/jpeg;base64,${photo}`}
                type="image/webp"
              />
              <source
                srcSet={`data:image/jpeg;base64,${photo} 1x,data:image/jpeg;base64,${photo} 2x,data:image/jpeg;base64,${photo} 3x`}
                type="image/jpeg"
              />
              <img
                className={classes.img}
                src={`data:image/jpeg;base64,${photo}`}
                srcSet={`data:image/jpeg;base64,${photo} 1x,data:image/jpeg;base64,${photo} 2x,data:image/jpeg;base64,${photo} 3x`}
                height="224"
                width="175"
                alt="user avatar"
              />
            </picture>
            {get(profile, ['vaccinated']) && <VaccinationBadge />}
          </Grid>
        )}
        <Grid item xs={12} sm={Boolean(photo) || 12}>
          <UserPersonalData
            profile={profile}
            variant={authorized ? 'edit' : 'view'}
            // column={2}
            column={Boolean(photo) || sidebar.open ? 2 : 3}
          />
        </Grid>
      </Grid>
      <Box className={classes.actions} mt={1.5}>
        <Grid container columnGap={1.5}>
          <Grid item xs="auto">
            <LoadingButton
              variant="outlined"
              size="small"
              onClick={_updateUserData}
              loading={isLoading}
            >
              {t('action.updateData', 'Обновить все данные')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
