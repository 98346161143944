import { api } from '../common/utils/fetch-utils';
import { vaccinationActions } from '../slices/vaccination-slice';

export const checkVaccination = () => async (dispatch: any) => {
  dispatch(vaccinationActions.setVaccinationRequest());
  try {
    const { data, status } = await api.get(`profile/vaccineStatus`);
    if (status.code === 0) {
      dispatch(
        vaccinationActions.setVaccinationSuccess({
          ...status,
          data: data.vaccineData,
        })
      );
    } else {
      dispatch(vaccinationActions.setVaccinationFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(
      vaccinationActions.setVaccinationFailure({ code: -1, message: 'error' })
    );
  }
};

export const clearVaccination = () => async (dispatch: any) => {
  dispatch(vaccinationActions.setVaccinationDefault());
};
