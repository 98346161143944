import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Socials = () => {
  const { i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsMobile(true);
    }
  };

  const sendData = async (action: string, label: string = 'CUSTOM') => {
    let _data = JSON.stringify({
      ACTION: action,
      LABEL: label,
      DEVICE: isMobile ? 'MOBILE' : 'DESKTOP',
    });
    await fetch('https://id.egov.uz/api/v1/metrics/pushData', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data: _data,
      }),
    });
  };

  const openLink = async (link: string, label: string) => {
    await sendData('OPEN', label);
    window.open(link, '_blank');
  };
  const closeBanner = async () => {
    await sendData('REJECT', 'TELECOM');
    setIsVisible(false);
  };

  useEffect(() => {
    checkDevice();
  }, []);

  return (
    <>
      {isVisible ? (
        <Box
          className="banner-telekom"
          sx={{
            margin: '24px 24px 170px 24px',
            width: 'calc(100% - 48px)',
            position: 'relative',
            zIndex: 1,
          }}
          onMouseLeave={() => {
            !isSent && sendData('HOVER', 'TELECOM');
            setIsSent(true);
          }}
        >
          <img
            onClick={() =>
              openLink(
                'https://redirect.appmetrica.yandex.com/serve/821558678900536553',
                'TELECOM'
              )
            }
            className="banner-image"
            src="/assets/img/socials/telekom.png"
            alt="telecom"
            style={{ width: '100%', borderRadius: 12, background: 'inherit' }}
          />
          <button
            className="cross-telekom xs-show"
            title="closeBanner"
            onClick={closeBanner}
            style={{
              position: 'absolute',
              right: '10px',
              zIndex: 3,
              top: '10px',
              background: 'transparent',
              border: 0,
              outline: 0,
              cursor: 'pointer',
              padding: '4px 4px 1px 4px',
              borderRadius: '5px',
            }}
          >
            <img src="/assets/img/socials/cross.svg" alt="" />
          </button>
        </Box>
      ) : null}
    </>
  );
};

export default Socials;
