import { alpha, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'help-widget',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '&$small': {
        '& $link': {
          color: 'inherit',
        },
        '& $question': {
          borderColor: alpha(theme.palette.primary.contrastText, 0.2),
        },
      },
      '&$large': {
        '& $question': {
          borderColor: alpha(theme.palette.divider, 0.2),
        },
      },
    },
    small: {},
    large: {},
    question: {
      border: `1px solid`,
      borderRadius: 4,
      padding: theme.spacing(2),
    },
    body: {
      padding: theme.spacing(2),
      '&:last-child': {
        paddingBottom: theme.spacing(0),
      },
    },
    title: {
      marginBottom: theme.spacing(1),
      opacity: '.6',
    },
    text: {
      opacity: '0.6',
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    item: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    icon: {
      opacity: '0.6',
      marginRight: theme.spacing(1),
      flex: '0 0 24px',
      height: 24,
      width: 24,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    old: {},
    phone: {},
    mail: {},
  }),
  options
);

export default useStyles;
