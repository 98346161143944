import { Box, BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType, ReactElement } from 'react';
import { ESignItem } from '../index';
import useStyles from './styles';

interface ESignListProps {
  className?: string;
  actionsItem?: ReactElement[] | ((item: any) => ReactElement[]);
  onClickItem?: (item: any) => void;
  data?: any;
}

export const ESignList: ComponentType<ESignListProps & BoxProps> = ({
  className,
  onClickItem,
  actionsItem,
  data,
  ...props
}) => {
  const classes = useStyles();

  if (!data) {
    return null;
  }

  return (
    <Box className={clsx(classes.root, className)}>
      {data.length &&
        (data || []).map((item: any, index: any) => {
          return (
            <ESignItem
              key={`eSignItem_${index}_${item?.variant}`}
              variant={item?.variant}
              status={item?.status}
              className={classes.item}
              data={item}
              onClick={() => onClickItem && onClickItem(item)}
              actions={actionsItem}
              gap={props.gap}
              rowGap={props.rowGap}
              columnGap={props.columnGap}
            />
          );
        })}
    </Box>
  );
};
