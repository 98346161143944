import { alpha } from '@material-ui/core/styles';

export const lightPalette = {
  type: 'light',
  primary: {
    light: '#6D51CE',
    main: '#4825C2',
    dark: '#4024A2',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#4024A2',
    main: '#5275D0',
    dark: '#7591D9',
    contrastText: '#FFFFFF',
  },
  info: {
    light: '#4024A2',
    main: '#5275D0',
    dark: '#7591D9',
    contrastText: '#FFFFFF',
  },
  success: {
    light: '#65CD88',
    main: '#3EC06A',
    dark: '#38A05C',
    contrastText: '#FFFFFF',
  },
  error: {
    light: '#F15959',
    main: '#EE3030',
    dark: '#C52D2D',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FCCC52',
    main: '#FBBF27',
    dark: '#D0A026',
    contrastText: '#FFFFFF',
  },
  grey: {
    100: alpha('#FFFFFF', 0.4),
    200: alpha('#FFFFFF', 0.2),
    300: alpha('#FFFFFF', 0.05),
    400: alpha('#222222', 0.05),
    500: alpha('#222222', 0.2),
    600: alpha('#222222', 0.4),
    700: alpha('#222222', 0.6),
    800: alpha('#222222', 0.8),
    900: '#222222',
  },
  text: {
    primary: alpha('#222222', 1),
    secondary: alpha('#222222', 0.8),
    tertiary: alpha('#222222', 0.6),
    disabled: alpha('#222222', 0.2),
    hint: alpha('#222222', 0.4),
  },
  action: {
    disabled: alpha('#222222', 0.4),
    disabledBackground: alpha('#222222', 0.4),
    disabledOpacity: 0.4,
  },
  background: {
    paper: '#FFFFFF',
    default: '#EAEAEA',
  },
};
