import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorized } from '../authorized';
import { ConnectedResources } from '../connected-resources';
import { Header } from '../header';
import { Layout } from '../layout';

interface PageConnectedResourcesProps {}

export const PageConnectedResources: ComponentType<PageConnectedResourcesProps> = Authorized()(
  () => {
    const { t } = useTranslation();

    return (
      <Layout variant="default" bgcolor="background.paper">
        <Header
          title={t('connectedResources', 'Подключенные ресурсы')}
          component={'h1'}
        />
        <Box component={'main'} role="main">
          <Container>
            <ConnectedResources />
          </Container>
        </Box>
      </Layout>
    );
  }
);
