import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import {
  ArrowIcon,
  CaseIcon,
  CheckCircleOutlineIcon,
  CheckListIcon,
  ChildIcon,
  DocumentIcon,
  ExitIcon,
  HelpIcon,
  HomeIcon,
  InfoOutlineIcon,
  LaunchIcon,
  TimeIcon,
  UserIcon,
} from '../../common/icons';
import MainRoute from '../../common/routes';
import { userLogOut } from '../../thunks/auth-thunks';
import useAuth from '../auth/useAuth';
import { localeParseRegex } from '../lang-switcher';
import { useLayout } from '../layout';
import { LayoutContext } from '../layout/LayoutProvider';
import { VariantSidebar } from '../layout/layout-sidebar/layout-sidebar';
import { useResponsive } from '../theme/useResponsive';
import useStyles from './styles';

type navigationIconsProps = {
  [key: string]: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
};

const navigationIcons: navigationIconsProps = {
  HomeIcon: HomeIcon,
  UserIcon: UserIcon,
  CheckCircleOutlineIcon: CheckCircleOutlineIcon,
  CaseIcon: CaseIcon,
  TimeIcon: TimeIcon,
  ChildIcon: ChildIcon,
  CheckListIcon: CheckListIcon,
  DocumentIcon: DocumentIcon,
  LaunchIcon: LaunchIcon,
  HelpIcon: HelpIcon,
  InfoIcon: InfoOutlineIcon,
  ExitIcon: ExitIcon,
  // ArrowLeftIcon: ArrowLeftIcon,
};

interface NavigationProps {
  className?: any;
  variant?: VariantSidebar;
}

export const Navigation: ComponentType<NavigationProps> = ({
  className,
  variant = 'default',
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const r = useResponsive();
  const { authorized } = useAuth();
  const dispatch = useDispatch();
  const { sidebar } = useContext(LayoutContext);
  const { pathname } = useLocation();
  const [, , path = ''] = pathname.match(localeParseRegex) || [];
  const isActive = (pathname: any) => {
    const active =
      pathname === path ||
      (pathname && pathname.length && path.includes(pathname));
    return active;
  };

  const handleLogOut = () => {
    dispatch(userLogOut());
  };

  const { closeSidebar } = useLayout();

  const renderListItem = (item: any, index: number) => {
    return (
      <Tooltip
        title={
          r({
            xl: !sidebar.open && t(item.i18n, item.title),
          }) || ''
        }
        arrow
        placement="right"
        key={`nav_${index}`}
      >
        <ListItemButton
          className={classes.item}
          to={`/${i18n.language}${item.pathname}`}
          key={`nav_${index}`}
          selected={isActive(item.pathname)}
          component={item.pathname === null ? 'span' : Link}
          // onClick={handleLogOut}
          onClick={r({
            xs: () =>
              authorized && item.pathname === null
                ? handleLogOut()
                : closeSidebar(),
            xl: () =>
              authorized && item.pathname === null ? handleLogOut() : null,
          })}
        >
          <ListItemIcon className={classes.iconWrap}>
            <SvgIcon
              className={classes.icon}
              component={navigationIcons[item.icon as string]}
            />
          </ListItemIcon>
          {r({ xs: true, lg: sidebar.open }) && (
            <>
              <ListItemText
                className={classes.body}
                primary={
                  <>
                    <Typography
                      className={classes.text}
                      variant="h6"
                      component="div"
                      noWrap={true}
                    >
                      {t(item.i18n as string, item.title as string)}
                    </Typography>
                    {isActive(item.pathname) && (
                      <SvgIcon component={ArrowIcon} className={classes.icon} />
                    )}
                  </>
                }
              />
            </>
          )}
          {!sidebar.open && isActive(item.pathname) && (
            <div className={classes.dot} />
          )}
        </ListItemButton>
      </Tooltip>
    );
  };

  return (
    <List
      className={clsx(classes.root, className, classes[variant])}
      component="nav"
      aria-label={t('nav.title', 'Навигация приложения')}
    >
      {MainRoute &&
        MainRoute.map((item, index) => {
          if (!authorized && item.authorized) {
            return null;
          }

          return renderListItem(item, index);
        })}

      {(!isActive('') || authorized) && (
        <>
          {sidebar.open && <Divider className={classes.divider} />}

          {renderListItem(
            {
              pathname: authorized ? null : '',
              icon: authorized ? 'ExitIcon' : 'UserIcon',
              title: authorized ? 'Выйти' : 'Войти',
              i18n: `action.${authorized ? 'exit' : 'signIn'}`,
            },
            MainRoute.length + 1
          )}
        </>
      )}
    </List>
  );
};
