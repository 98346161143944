import { Box, Divider, Typography } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../../../common/locales';
import dataSource from '../../../../data-sources/sign-up-for-foreigners.json';
import { IconList } from '../../../list/Icon-list';

export const SignUpHelpForForeigners: ComponentType<BoxProps> = ({
  className,
  ...props
}) => {
  const { i18n } = useTranslation();

  const content = dataSource[i18n.language as Locale]['content'];

  const body = (content || []).map((item: Record<string, any>, idx: number) => {
    const { view, list } = item;
    switch (view) {
      case 'list':
        return <IconList data={list} key={idx} />;
      case 'divider':
        return (
          <Divider
            sx={{
              my: 4,
            }}
          />
        );
      default:
        return <Typography {...item} key={idx} />;
    }
  });
  return (
    <Box className={clsx(className)} {...props}>
      {body}
    </Box>
  );
};
