import { combineReducers } from '@reduxjs/toolkit';
import { authReducer as auth } from '../slices/auth-slice';
import { currentLocaleReducer as currentLocale } from '../slices/currentLocaleSlice';
import { eSignReducer as eSign } from '../slices/e-sign-slice';
import { errorReducer as error } from '../slices/error-slice';
import { legalEntityReducer as legalEntity } from '../slices/legal-entity-slice';
import { profileReducer as profile } from '../slices/profile-slice';
import { ssoReducer as sso } from '../slices/sso-slice';
import { toasterReducer as toaster } from '../slices/toaster-slice';
import { vaccinationReducer as vaccination } from '../slices/vaccination-slice';

export const rootReducer = combineReducers({
  currentLocale,
  auth,
  profile,
  vaccination,
  sso,
  legalEntity,
  eSign,
  toaster,
  error,
});
