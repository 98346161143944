import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'session',
};

const useStyles = makeStyles(() => ({
  root: {},
  header: {},
  content: {},
  container: {},
  options,
}));

export default useStyles;
