import { SvgIcon, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MITC } from './assets/mitc.svg';
import useStyles from './styles';

interface DeveloperProps {
  className?: string;
}

const Developer: ComponentType<DeveloperProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={clsx(classes.root, className)}>
      <Tooltip title={t('action.go.MITC', 'Перейти на сайт MITC') || ''} arrow>
        <a
          href="https://mitc.uz"
          className={clsx(classes.item, classes.mitc)}
          target="_blank"
          rel="nofollow noopener noreferrer"
          // title={t(
          //   'MITC',
          //   'Министерство по развитию информационных технологий и коммуникаций Республики Узбекистан'
          // )}
        >
          <SvgIcon
            className={classes.img}
            component={MITC}
            titleAccess="MITC"
            viewBox="0 0 56 56"
          />
          <Typography
            variant="caption"
            component="div"
            className={classes.text}
          >
            {t(
              'MITC',
              'Министерство Цифровых Технологий Республики Узбекистан'
            )}
          </Typography>
        </a>
      </Tooltip>
    </div>
  );
};

export { Developer };
