import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
} from '@material-ui/core';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CloseIcon } from '../../../common/icons';
import {
  clearRecoverPasswordSmsStates,
  clearRecoverPasswordStates,
} from '../../../thunks/profile-thunks';
import { useResponsive } from '../../theme/useResponsive';
import { PasswordRecovery } from './password-recovery';

interface PasswordRecoveryDialogProps {
  open?: boolean;
  onClose?: () => void;
}

export const PasswordRecoveryDialog: ComponentType<PasswordRecoveryDialogProps> = ({
  open = false,
  onClose,
}) => {
  const dispatch = useDispatch();
  const r = useResponsive();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      dispatch(clearRecoverPasswordStates());
      dispatch(clearRecoverPasswordSmsStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (onClose) onClose();
    setTimeout(() => {
      dispatch(clearRecoverPasswordStates());
      dispatch(clearRecoverPasswordSmsStates());
    }, 300);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={r({ xs: true, lg: false })}
      scroll={'body'}
      maxWidth={'sm'}
      aria-labelledby="agreement-title"
      aria-describedby="agreement-description"
    >
      <DialogContent id="agreement-description">
        <Box
          sx={{
            position: r({ xs: 'static', lg: 'absolute' }),
            top: 4,
            right: 4,
            display: 'flex',
            justifyContent: 'flex-end',
            pt: r({ xs: 0, lg: 0 }),
            m: r({ xs: '-12px -12px 0 0', lg: '0' }),
          }}
        >
          <IconButton
            onClick={handleClose}
            color="primary"
            aria-label={t('action.dialog.close', 'Закрыть диалоговое окно')}
          >
            <SvgIcon component={CloseIcon} />
          </IconButton>
        </Box>
        <PasswordRecovery onClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};
