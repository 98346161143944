import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'file-list',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& a': {
      display: 'inline-flex',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '& svg': {
        margin: theme.spacing(0.5, 1, 0, 0),
        fontSize: 16,
      },
    },
  },
  item: {
    paddingLeft: theme.spacing(0.5),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
  },
  options,
}));

export default useStyles;
