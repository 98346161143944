import { Theme } from '@material-ui/core/styles/createTheme';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'data-table',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      overflowX: 'auto',
    },
    table: {},
    head: {
      '& $row': {
        '& th': {
          color: theme.palette.text.primary,
        },
      },
    },
    body: {
      '& $row': {
        '& td': {},
      },
    },
    row: {},
    placeholder: {
      padding: theme.spacing(3, 0),
      textAlign: 'center',
      background: theme.palette.common.white,
    },
  }),
  options
);

export default useStyles;
