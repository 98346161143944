import { Tooltip } from '@material-ui/core';
import { FormikValues, useFormik } from 'formik';
import { forEach, get, isEmpty } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../common/store';
import { rplSpace } from '../../../../common/utils/format-utils';
import {
  clearSignInPhoneStates,
  confirmSignInPhone,
  signInPhone as signInPhoneHandler,
} from '../../../../thunks/auth-thunks';
import {
  FIELD_PHONE,
  SignInValidationSchema,
} from './sign-in-phone-form-schema';

export const useSignInPhone = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { signInPhone, signInSms } = useSelector((state: State) => state.auth);
  const [oneTimePass, setOneTimePass] = useState<String>();

  const handleSendSms = (values: FormikValues) => {
    const { phone } = values;
    dispatch(clearSignInPhoneStates());
    dispatch(confirmSignInPhone({ userPhone: rplSpace(phone) }));
  };
  const handleResendSms = () => {
    handleSendSms(formik.values());
  };

  const handleFieldChange = (field: string, e: any) => {
    formik.setFieldValue(field, e.target.value);
    formik.setFieldError(field, null);
    dispatch(clearSignInPhoneStates());
  };

  const handleSmsChange = (code: string) => {
    setOneTimePass(code);
  };

  const handleSubmit = () => {
    formik
      .validateForm(formik.values)
      .then((res: any) => {
        if (isEmpty(res) && !!oneTimePass) {
          const phone = rplSpace(formik.values[FIELD_PHONE]);
          dispatch(
            signInPhoneHandler({
              userPhone: phone,
              smsCode: oneTimePass,
              controlCode: signInPhone.controlCode,
              actionId: signInPhone.actionId,
            })
          );
        } else {
          forEach(res, (value, field) => {
            formik.setFieldError(field, value);
          });
        }
      })
      .catch((e: any) => console.log(e, '2'));
    if (!formik.isValid) return null;
  };

  const handleClear = () => {
    dispatch(clearSignInPhoneStates());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_PHONE]: '',
    },
    validationSchema: SignInValidationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSendSms,
  });

  return {
    signInPhone,
    signInSms,
    formik,
    oneTimePass,
    controlCode: signInPhone.controlCode,
    handleFieldChange,
    handleSubmit,
    handleSendSms,
    handleResendSms,
    handleSmsChange,
    handleClear,
    getErrorMessage,
    hasError,
  };
};
