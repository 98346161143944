import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialStateProps {
  detail: {
    isLoading: boolean;
    data: Record<string, any> | null;
    code: number | null;
    message: string | null;
  };
}
const initialState: initialStateProps = {
  detail: {
    isLoading: false,
    data: null,
    code: null,
    message: null,
  },
};

export const {
  actions: vaccinationActions,
  reducer: vaccinationReducer,
} = createSlice({
  name: 'vaccination',
  initialState,
  reducers: {
    setVaccinationRequest: (state) => {
      state.detail = {
        isLoading: true,
        data: null,
        code: null,
        message: null,
      };
    },
    setVaccinationSuccess: (state, { payload }: PayloadAction<any>) => {
      state.detail = {
        isLoading: false,
        data: payload.data,
        code: payload.code,
        message: payload.message,
      };
    },
    setVaccinationFailure: (state, { payload }: PayloadAction<any>) => {
      state.detail = {
        isLoading: false,
        data: null,
        code: payload.code,
        message: payload.message,
      };
    },
    setVaccinationDefault: (state) => {
      state.detail = {
        isLoading: false,
        data: null,
        code: null,
        message: null,
      };
    },
  },
});
