import { Alert, Box, Collapse, Divider } from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { Address } from '../../address';
import { Email } from '../../email';
import { Phone } from '../../phone';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

interface UserContactsProps {
  className?: string;
}

export const UserContacts: ComponentType<UserContactsProps> = ({
  className,
}) => {
  const classes = useStyles();
  const [alert, setAlert] = useState(true);
  const profile = useSelector((state: State) => state.profile);
  const { t } = useTranslation();
  const r = useResponsive();

  return (
    <div className={clsx(classes.root, className)}>
      <Address
        addressData={profile.addressData}
        variant="auto"
        className={clsx(classes.addressAuto, classes.item)}
      />

      <Collapse in={alert}>
        <Alert
          severity="warning"
          iconMapping={{
            warning: <ErrorOutlineIcon fontSize="inherit" />,
          }}
          sx={{
            mt: 4.5,
          }}
          onClose={() => {
            setAlert(false);
          }}
        >
          {t(
            'alert.address',
            'Согласно 365 ПКМ ответственным органом за прописку является МВД. В случае не правильной прописки, нужно обращаться с заявлением в МВД.'
          )}
        </Alert>
      </Collapse>
      {/* <Divider className={classes.divider} />
      <Address
        variant="custom"
        className={clsx(classes.addressCustom, classes.item)}
      /> */}
      <Divider className={classes.divider} />
      <Box mb={r({ xs: '25px', lg: '0' })}>
        <Email variant="edit" className={clsx(classes.phone, classes.item)} />
      </Box>

      <Phone variant="edit" className={clsx(classes.phone, classes.item)} />
    </div>
  );
};
