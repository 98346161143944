import { Box, Button, Grid, Hidden, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignIn, SignUp } from '.';
import { useResponsive } from '../theme/useResponsive';
import useStyles from './styles';
interface AuthProps {
  className?: any;
  variant?: 'full' | 'short';
}

export const Auth: ComponentType<AuthProps> = ({
  className,
  variant = 'full',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const [open, setOpen] = useState({
    intro: true,
    signIn: false,
    signUp: false,
  });

  return (
    <div className={clsx(classes.root, classes[variant], className)}>
      <div className={classes.content}>
        <div className={classes.wrap}>
          {/* {open.intro && (
            <div className={classes.media}>
              <img
                className={classes.img}
                src="/assets/img/id.svg"
                height="408"
                width="360"
                alt={t('app.title', 'Единая система идентификации')}
              />
            </div>
          )} */}

          {r({ xs: open.intro, lg: false }) && (
            <div className={classes.body}>
              <Typography className={classes.title} component="h1" variant="h4">
                {t('app.title', 'Единая система идентификации')}
              </Typography>
              <Typography
                className={classes.text}
                component="div"
                variant="body2"
              >
                {t(
                  'app.text',
                  'Получите доступ ко всем системам электронного правительства через одну учетную запись'
                )}
              </Typography>
            </div>
          )}

          {r({ xs: open.signIn, lg: true }) && (
            <SignIn
              className={classes.signIn}
              classes={{
                root: classes.cardRoot,
                paper: classes.cardPaper,
                body: classes.cardBody,
              }}
            />
          )}
        </div>

        {r({ xs: open.signUp, lg: true }) && (
          <SignUp
            className={classes.signUp}
            classes={{
              root: classes.cardRoot,
              paper: classes.cardPaper,
              body: classes.cardBody,
            }}
          />
        )}

        {r({ xs: true, lg: false }) && (
          <Box className={classes.action}>
            <Grid container rowSpacing={2}>
              <Hidden xsUp={open.signIn}>
                <Grid item xs={12} pt={0}>
                  <Button
                    onClick={() =>
                      setOpen({
                        intro: false,
                        signIn: true,
                        signUp: false,
                      })
                    }
                    className={classes.btn}
                    variant={open.intro ? 'contained' : 'outlined'}
                    fullWidth
                  >
                    {t('action.signIn', 'Войти')}
                  </Button>
                </Grid>
              </Hidden>
              <Hidden xsUp={open.signUp}>
                <Grid item xs={12}>
                  <Button
                    onClick={() =>
                      setOpen({
                        intro: false,
                        signIn: false,
                        signUp: true,
                      })
                    }
                    className={classes.btn}
                    variant="outlined"
                    fullWidth
                  >
                    {t('action.signUp', 'Зарегистрироваться')}
                  </Button>
                </Grid>
              </Hidden>
            </Grid>
          </Box>
        )}
      </div>
    </div>
  );
};
