import { ComponentType, ReactNode } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export interface DataTableColumnsProps {
  label: string;
  labelKey: string;
  dataKey: string;
  width?: string | number;
  sortable?: boolean;
  renderItem?: (item: any) => ReactNode | string | number;
}

interface DataTableProps {
  className?: string;
  data: any[];
  columns?: DataTableColumnsProps[];
  loading?: boolean;
}

export const DataTable: ComponentType<DataTableProps> = ({
  className,
  data,
  columns,
  loading = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const tablePlaceholder = (status: string) => {
    let colSpan = columns?.length ? columns?.length : 1;

    return (
      <TableBody classes={{ root: classes.body }}>
        <TableRow>
          <TableCell colSpan={colSpan} className={clsx(classes.placeholder)}>
            {status === 'loading' && (
              <Typography variant="subtitle1" component="div">
                {t('loading', 'Загрузка')}
              </Typography>
            )}
            {status === 'empty' && (
              <Typography variant="subtitle1" component="div">
                {t('noResults', 'По вашему запросу ничего не найдено')}
              </Typography>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const tableHead = () => {
    if (!columns || !columns.length) return null;

    let cols: any[] = [];
    cols = [
      ...cols,
      ...(columns || []).map(({ label, labelKey, width }, idx) => (
        <TableCell width={width} key={idx}>
          <Typography color="textPrimary">{t(labelKey, label)}</Typography>
        </TableCell>
      )),
    ];

    return (
      <TableHead classes={{ root: classes.head }}>
        <TableRow classes={{ root: classes.row }}>{cols}</TableRow>
      </TableHead>
    );
  };

  const tableBody = () => {
    if (!columns || !columns.length) return null;

    const rows = (data || []).map((item, idx) => {
      let cols: any[] = [];

      const items = (columns || []).map(({ dataKey, renderItem }, colx) => {
        if (renderItem) {
          return <TableCell key={colx}>{renderItem(item)}</TableCell>;
        }
        return <TableCell key={colx}>{item[dataKey]}</TableCell>;
      });

      cols = [...cols, ...items];

      return (
        <TableRow classes={{ root: classes.row }} key={idx}>
          {cols}
        </TableRow>
      );
    });

    return <TableBody classes={{ root: classes.body }}>{rows}</TableBody>;
  };

  if (loading) {
    return (
      <Table classes={{ root: classes.root }} className={clsx(className)}>
        {tableHead()}
        {tablePlaceholder('loading')}
      </Table>
    );
  }

  if (!data || !data.length) {
    return (
      <Table classes={{ root: classes.root }} className={clsx(className)}>
        {tableHead()}
        {tablePlaceholder('empty')}
      </Table>
    );
  }

  return (
    <div className={clsx(classes.root)}>
      <Table classes={{ root: classes.table }} className={clsx(className)}>
        {tableHead()}
        {tableBody()}
      </Table>
    </div>
  );
};
