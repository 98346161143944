import { FormikValues, useFormik } from 'formik';
import { State } from '../../../common/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { forEach, get, isInteger } from 'lodash';
import { changeLogin, clearLoginStates } from '../../../thunks/profile-thunks';
import { useTranslation } from 'react-i18next';
import {
  FIELD_NEW_LOGIN,
  FIELD_PASSWORD,
  LoginFormValidationForm,
} from './passport-serial-number-schema';
import { Tooltip } from '@material-ui/core';

export const useLoginForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { login, loginForm } = useSelector((state: State) => state.profile);

  const handleSubmit = (values: FormikValues) => {
    let hasError = [];

    forEach(values, (value, field) => {
      if (value.includes('_')) {
        formik.setFieldError(field, 'error.required');
        hasError.push(field);
      }
    });

    if (hasError.length) return;

    dispatch(changeLogin(values));
  };

  const handleFieldChange = (field: string, e: any) => {
    const value = e.target.value;
    if (field === FIELD_NEW_LOGIN) {
      setIsTouched(value !== login);
    }
    formik.setFieldValue(field, value);
    formik.setFieldError(field, null);
    dispatch(clearLoginStates());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_NEW_LOGIN]: login || '',
      [FIELD_PASSWORD]: '',
    },
    validationSchema: LoginFormValidationForm,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isInteger(loginForm.code) && Number(loginForm.code) === 0) {
      setIsTouched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginForm.code]);

  useEffect(() => {
    return () => {
      dispatch(clearLoginStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...loginForm,
    isTouched,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  };
};
