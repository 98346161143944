import clsx from 'clsx';
import { ComponentType } from 'react';
import { ConnectedResourcesItem } from '../connected-resources-item/connected-resources-item';
import useStyles from './styles';

interface ConnectedResourcesListProps {
  className?: any;
  data?: any;
}

export const ConnectedResourcesList: ComponentType<ConnectedResourcesListProps> = ({
  className,
  data,
}) => {
  const classes = useStyles();

  if (!data) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {data.length &&
        data.map((item: any, index: number) => {
          return (
            <ConnectedResourcesItem
              key={`ConnectedResourcesItem_${index}`}
              className={classes.item}
              data={item}
            />
          );
        })}
    </div>
  );
};
