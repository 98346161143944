import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'radio',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    icon: {
      fill: theme.palette.background.default,
      stroke: theme.palette.grey[500],
      '.Mui-checked &': {
        fill: theme.palette.background.paper,
        stroke: 'currentColor',
      },
    },
    checked: {},
    disabled: {},
    unChecked: {},
  }),
  options
);

export default useStyles;
