import { CircularProgress, SvgIcon, Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserIcon } from '../../../common/icons';
import useStyles from './styles';
import { REACT_APP_API_STATISTICS_URL } from '../../../common/constants';

interface UserStatisticsProps {
  className?: string;
}

export const UserStatistics: ComponentType<
  UserStatisticsProps & TypographyProps
> = ({ className }) => {
  const classes = useStyles();
  const [userCount, setUserCount] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFetch = () => {
    setIsLoading(true);
    fetch(`${REACT_APP_API_STATISTICS_URL}`)
      .then((res) => res.json())
      .then(
        ({ data }) => setUserCount(data),
        (error) => {
          console.log(error);
        }
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography className={classes.count} variant="body1" component="div">
        {!isLoading && userCount ? (
          <>
            <div className={classes.iconWrap}>
              <SvgIcon className={classes.icon} component={UserIcon} />
            </div>
            {userCount}
          </>
        ) : (
          <>
            <div className={classes.iconWrap}>
              <CircularProgress
                className={classes.icon}
                size={20}
                color="inherit"
              />
            </div>
            {t('user.stat.update', 'Данные обновляются')}
          </>
        )}
      </Typography>
      <Typography className={classes.text} variant="caption" component="div">
        {t('user.stat', 'Количество пользователей по состоянию на 5:00')}
      </Typography>
    </div>
  );
};
