import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../../common/locales';
import dataSource from '../../../data-sources/user-agreement.json';
import useStyles from './styles';
import { UserAgreementModal } from './user-agreement-modal';
import { UserAgreementPage } from './user-agreement-page';

interface UserAgreementProps {
  className?: any;
  variant?: 'modal' | 'page';
  onSuccess?: () => any;
}

export const UserAgreement: ComponentType<UserAgreementProps & BoxProps> = ({
  className,
  variant = 'page',
  ...props
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const curentLang = i18n.language as Locale;
  const content = dataSource[curentLang].content || dataSource.uz.content; // #TODO #WARNING

  return (
    <div className={clsx(classes.root, classes[variant])}>
      {variant === 'page' && content && (
        <UserAgreementPage className={classes.page} data={content} {...props} />
      )}
      {variant === 'modal' && content && (
        <UserAgreementModal className={classes.page} data={content} />
      )}
    </div>
  );
};
