import { Box, BoxProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import camelCase from 'lodash/camelCase';
import React, { ComponentType, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export interface ESignItemTextProps {
  title?: string;
  text?: string;
  error?: string;
  actions?: ReactNode[];
}

export const ESignItemText: ComponentType<ESignItemTextProps & BoxProps> = ({
  color,
  title,
  text,
  error,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const transformValue = (title: string = '', value: string | boolean = '') => {
    let result: any = '';

    switch (title) {
      case 'typeOwnership':
        result =
          value === 'le'
            ? t('eSign.type.legalEntity')
            : t('eSign.type.individual');
        break;
      default:
        result = !!value ? value : t('eSign.error.noAvailable');
        break;
    }
    return result;
  };

  return (
    <Box className={clsx(classes.root, props.className)} {...props}>
      {title && (
        <Typography
          className={classes.title}
          variant="body2"
          color={(error && 'error') || 'textSecondary'}
          component="div"
        >
          {t(`eSign.${camelCase(title)}`) + ':'}
        </Typography>
      )}
      {(title || text) && (
        <Typography
          className={classes.text}
          color={(error && 'error') || (color && color) || 'inherit'}
          variant="h6"
          component="div"
          sx={{
            textTransform: (!title && 'uppercase') || 'none',
          }}
        >
          {transformValue(title, text) ||
            (error &&
              t(`eSign.error.${error}`, `Нет перевода: eSign.error.${error}`))}
        </Typography>
      )}
    </Box>
  );
};
