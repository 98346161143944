import { LinearProgress } from '@material-ui/core';
import { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import { App } from './app';
import { history } from './common/history';
import './common/i18next';
import { store } from './common/store';
import { SnackbarProvider } from './components/snackbar';
import { ThemeProvider } from './components/theme';

const splashScreen = document.querySelector('.splash-screen');
const hideSplashScreen = () => {
  splashScreen?.classList.remove('splash-screen--show');
  setTimeout(() => {
    splashScreen?.classList.remove('splash-screen--in');
  }, 350);
};

const showSplashScreen = () => {
  splashScreen?.classList.add('splash-screen--in');
  splashScreen?.classList.add('splash-screen--show');
};

render(
  <StoreProvider store={store}>
    <Router history={history}>
      <Suspense
        fallback={
          <LinearProgress variant={'query'} style={{ height: '30px' }} />
        }
      >
        <BrowserRouter>
          <ThemeProvider>
            <SnackbarProvider>
              <App
                hideSplashScreen={hideSplashScreen}
                showSplashScreen={showSplashScreen}
              />
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Suspense>
    </Router>
  </StoreProvider>,
  document.getElementById('root')
);
