import { addHours, getUnixTime } from 'date-fns';
import { filter, isInteger } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  create_pkcs7,
  formattedListCertificates,
  listTokenReaders,
  loadKey,
  verifyPassword,
} from '../../common/sign-utils';
import { State, store } from '../../common/store';
import { eSignActions } from '../../slices/e-sign-slice';
import {
  getUUID,
  signInESP,
  signInESPChildren,
} from '../../thunks/auth-thunks';
import { addByESP } from '../../thunks/legal-entity-thunks';
import { useSnackbar } from '../snackbar';

type SingCertProps = {
  cert: {
    certificate: {
      alias: string;
      disk: string;
      name: string;
      path: string;
    };
  };
  formatedCert: Record<string, any>;
};

type SingProps = SingCertProps | string;

export const useESign = (
  PINFL_KEY: string | null = null,
  LE: boolean = false
) => {
  const dispatch = useDispatch();
  const { snackbarOpen } = useSnackbar();
  const { t } = useTranslation();
  const setKeys = (key: Record<string, any>) =>
    dispatch(eSignActions.setKeys(key));
  const { signIn } = useSelector((state: State) => state.auth);

  const setKeyId = (keyId: string) => {
    dispatch(eSignActions.setKeyId(keyId));
  };

  const sign = async (value: SingProps) => {
    await dispatch(getUUID());
    const _uuid = store.getState().auth.uuid;
    try {
      if (typeof value === 'string' && value === 'idcard') {
        listTokenReaders().then((res) => {
          if (res.success && res.readers.length > 0) {
            create_pkcs7(_uuid, 'idcard').then(({ pkcs7_64, success }) => {
              if (success) {
                dispatch(signInESP(pkcs7_64));
              }
            });
          } else {
            snackbarOpen(
              t(
                'error.NoTokebFound',
                'Браузер не имеет доступа ни к одному ключу USB c ЭЦП'
              ),
              { severity: 'error' }
            );
          }
        });
      } else if (typeof value == 'object') {
        const { disk, name, path, alias } = value.cert.certificate;
        const { passportID } = value.formatedCert;
        const CERT_PNFL = passportID.value;

        loadKey(disk, path, name, alias).then((res) => {
          if (res.success) {
            let tmpId = res.keyId;
            const EXPIRE =
              CERT_PNFL && localStorage.getItem(CERT_PNFL)?.split('-');
            const CERT_KEY = EXPIRE ? EXPIRE[0] : '';
            const CERT_EXPIRE = EXPIRE ? Number(EXPIRE[1]) : 0;
            const CURRENT_TIME = getUnixTime(new Date());

            setKeyId(tmpId);

            if (EXPIRE && !!CERT_KEY && CERT_EXPIRE >= CURRENT_TIME) {
              tmpId = CERT_KEY;
              create_pkcs7(_uuid, tmpId).then(({ pkcs7_64, success }) => {
                if (success) {
                  dispatch(signInESP(pkcs7_64));
                }
              });
            } else {
              verifyPassword(tmpId).then(({ success }) => {
                if (success) {
                  create_pkcs7(_uuid, tmpId).then(({ pkcs7_64, success }) => {
                    if (success) {
                      const EXPIRE_TIME = getUnixTime(addHours(new Date(), 6));
                      localStorage.setItem(
                        CERT_PNFL,
                        `${tmpId}-${EXPIRE_TIME}`
                      );
                      dispatch(signInESP(pkcs7_64));
                    }
                  });
                } else {
                  localStorage.removeItem(CERT_PNFL);
                  snackbarOpen(t('error.api.2', 'Wrong certificate key'), {
                    severity: 'error',
                  });
                }
              });
            }
          }
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const signChildren = async (value: SingProps) => {
    // await dispatch(getUUID());
    // const _uuid = store.getState().auth.uuid;
    const _uuid = {
      pin: '1123',
      certSeries: 'I-TV',
      certNumber: '123',
      guardianType: 'FATHER',
    };
    try {
      if (typeof value === 'string' && value === 'idcard') {
        listTokenReaders().then((res) => {
          if (res.success && res.readers.length > 0) {
            create_pkcs7(_uuid, 'idcard').then(({ pkcs7_64, success }) => {
              if (success) {
                dispatch(signInESPChildren(pkcs7_64));
              }
            });
          } else {
            snackbarOpen(
              t(
                'error.NoTokebFound',
                'Браузер не имеет доступа ни к одному ключу USB c ЭЦП'
              ),
              { severity: 'error' }
            );
          }
        });
      } else if (typeof value == 'object') {
        const { disk, name, path, alias } = value.cert.certificate;
        const { passportID } = value.formatedCert;
        const CERT_PNFL = passportID.value;

        loadKey(disk, path, name, alias).then((res) => {
          if (res.success) {
            let tmpId = res.keyId;
            const EXPIRE =
              CERT_PNFL && localStorage.getItem(CERT_PNFL)?.split('-');
            const CERT_KEY = EXPIRE ? EXPIRE[0] : '';
            const CERT_EXPIRE = EXPIRE ? Number(EXPIRE[1]) : 0;
            const CURRENT_TIME = getUnixTime(new Date());

            setKeyId(tmpId);

            if (EXPIRE && !!CERT_KEY && CERT_EXPIRE >= CURRENT_TIME) {
              tmpId = CERT_KEY;
              create_pkcs7(_uuid, tmpId).then(({ pkcs7_64, success }) => {
                if (success) {
                  dispatch(signInESPChildren(pkcs7_64));
                }
              });
            } else {
              verifyPassword(tmpId).then(({ success }) => {
                if (success) {
                  create_pkcs7(_uuid, tmpId).then(({ pkcs7_64, success }) => {
                    if (success) {
                      const EXPIRE_TIME = getUnixTime(addHours(new Date(), 6));
                      localStorage.setItem(
                        CERT_PNFL,
                        `${tmpId}-${EXPIRE_TIME}`
                      );
                      dispatch(signInESPChildren(pkcs7_64));
                    }
                  });
                } else {
                  localStorage.removeItem(CERT_PNFL);
                  snackbarOpen(t('error.api.2', 'Wrong certificate key'), {
                    severity: 'error',
                  });
                }
              });
            }
          }
        });
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const addLE = async (value: {
    cert: {
      certificate: {
        alias: string;
        disk: string;
        name: string;
        path: string;
      };
    };
  }) => {
    await dispatch(getUUID());
    const _uuid = store.getState().auth.uuid;
    // dispatch(getUUID());
    const { disk, name, path, alias } = value.cert.certificate;
    loadKey(disk, path, name, alias).then((res) => {
      if (res.success) {
        setKeyId(res.keyId);
        verifyPassword(res.keyId).then(({ success }) => {
          if (success) {
            create_pkcs7(_uuid, res.keyId).then(({ pkcs7_64, success }) => {
              if (success) {
                dispatch(addByESP(pkcs7_64));
              }
            });
          }
        });
      }
    });
  };

  const keys = useSelector((state: State) => state.eSign.keys);
  const allCerts = useSelector((state: State) => state.eSign.certs);
  const allTokens = useSelector((state: State) => state.eSign.tokens);
  const keyId = useSelector((state: State) => state.eSign.keyId);
  const uuid = useSelector((state: State) => state.auth.uuid);
  let certs: any = [];

  // only self legal entity certs
  if (PINFL_KEY && PINFL_KEY.length === 14 && LE) {
    certs = filter(
      allCerts,
      (o: any) =>
        o.cert.parsedCert.hasOwnProperty('businesscategory') &&
        o.cert.parsedCert['1.2.860.3.16.1.2'] === PINFL_KEY
    );
    // only self certs
  } else if (PINFL_KEY && PINFL_KEY.length === 14) {
    certs = filter(
      allCerts,
      (o: any) => o.cert.parsedCert['1.2.860.3.16.1.2'] === PINFL_KEY
    );
    // all accessible certs
  } else {
    certs = filter(
      allCerts,
      (o: any) =>
        o.cert.parsedCert['1.2.860.3.16.1.2'] || o.cert.parsedCert['uid']
    );
  }

  const refreshKeys = async () => {
    try {
      dispatch(eSignActions.setCerts(await formattedListCertificates()));
    } catch (e: any) {
      snackbarOpen(
        t(
          'error.NoTokebFound',
          'Браузер не имеет доступа ни к одному ключу USB c ЭЦП'
        ),
        { severity: 'error' }
      );
    }
  };

  const refreshIDCardToken = async () => {
    try {
      dispatch(eSignActions.setTokenReader(await listTokenReaders()));
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isInteger(signIn.code) && Number(signIn.code) !== 0) {
      const message = signIn.message || `Ошибка с кодом ${signIn.code}`;
      snackbarOpen(t(`error.api.${signIn.code}.full}`, message), {
        severity: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signIn.code]);

  return {
    setKeys,
    keys,
    allCerts,
    allTokens,
    certs,
    sign,
    signChildren,
    refreshKeys,
    refreshIDCardToken,
    setKeyId,
    keyId,
    uuid,
    addLE,
  };
};
