import { SvgIcon, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowIcon } from '../../../../common/icons';
import { ESign } from '../../../e-sign';
import { useESign } from '../../../e-sign/useESign';
import useStyles from './styles';
interface SignInESignProps {
  className?: any;
}

export const SignInESign: ComponentType<SignInESignProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { certs, sign, refreshKeys } = useESign();

  const handleSignIn = (value: any) => {
    sign(value);
  };

  return (
    <div className={clsx(classes.root, className)}>
      {certs.length > 0 && (
        <Typography
          className={classes.title}
          mb={2}
          variant="body1"
          component="div"
        >
          {t('eSign.action.esign', 'Выберите ЭЦП')}
        </Typography>
      )}
      <ESign
        exists={true}
        actionsItem={(item: any) => [
          <LoadingButton
            onClick={() => handleSignIn(item)}
            size="small"
            variant="contained"
            className={classes.btn}
            key={'refresh_btn'}
          >
            <SvgIcon className={classes.icon} component={ArrowIcon} />
          </LoadingButton>,
        ]}
        onClickRefresh={() => {
          refreshKeys();
        }}
        onClickSignItem={(item) => handleSignIn(item)}
      />
    </div>
  );
};
