import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
  report: {
    isLoading: boolean;
    code: string | number | null;
    message: string | null;
  };
}

const initialState: initialStateProps = {
  report: {
    isLoading: false,
    code: null,
    message: null,
  },
};

export const { actions: errorActions, reducer: errorReducer } = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setReportRequest: (state) => {
      state.report = {
        isLoading: true,
        code: null,
        message: null,
      };
    },
    setReportSuccess: (state, { payload }) => {
      state.report = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setReportFailure: (state, { payload }) => {
      state.report = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setReportDefault: (state) => {
      state.report = {
        isLoading: false,
        code: null,
        message: null,
      };
    },
  },
});
