import * as yup from 'yup';
import { MASK_PHONE, MASK_PINFL } from '../../../constants/masks-constants';

export const FIELD_PHONE = 'phone';
export const FIELD_PINFL = 'pinfl';
export const FIELD_PASSPORT_SN = 'document';
export const FIELD_SMS_CODE = 'code';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_PINFL]: {
    label: 'ПИНФЛ',
    labelKey: 'passport.pin',
    arialLabel: 'Your passport pin',
  },
  [FIELD_PASSPORT_SN]: {
    label: 'Серия и Номер паспорта',
    labelKey: 'passport.serialNumber',
    arialLabel: 'Your passport serial number',
  },
  [FIELD_SMS_CODE]: {
    label: 'SMS',
    labelKey: 'sms',
    arialLabel: 'SMS',
  },
  [FIELD_PHONE]: {
    label: 'Телефон',
    labelKey: 'signUp.phone',
    ariaLabel: 'Your phone number',
  },
};

export const FIELD_MASKS: Record<string, any> = {
  [FIELD_PINFL]: MASK_PINFL,
  [FIELD_PHONE]: MASK_PHONE,
};

export const PasswordRecoveryValidationForm = yup.object().shape({
  [FIELD_PINFL]: yup
    .string()
    .length(14, 'error.passport.pin.length')
    .required('error.required')
    .trim(),
  [FIELD_PHONE]: yup
    .string()
    .transform((value) => value.replace(/\s/g, ''))
    .min(13, 'error.phone.notValid')
    .max(13, 'error.phone.notValid')
    // .phone('UZ', false, `error.phone.notValid`) 33 55 не работают
    .required('error.required')
    .trim(),
});
