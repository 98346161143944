import { ComponentType } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

interface DocketListProps {
  className?: string;
  data: string[];
}

export const BulletList: ComponentType<DocketListProps> = ({
  className,
  data,
}) => {
  const classes = useStyles();

  return (
    <ul className={clsx(classes.root, className)}>
      {(data || []).map((item: string, idx: number) => (
        <li className={clsx(classes.item)} key={idx}>
          <Typography component="div" variant="body1" color="textSecondary">
            {item}
          </Typography>
        </li>
      ))}
    </ul>
  );
};
