import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter } from 'lodash';
interface InitialStateProps {
  isLoading: boolean;
  items: any[];
  roles: any[] | null;
  entity: Record<string, any>;
  code: number | null;
  message: string;
  persons: Record<string, any>;
}
const initialState: InitialStateProps = {
  isLoading: false,
  items: [],
  roles: null,
  entity: {},
  code: null,
  message: '',
  persons: [] as Record<string, any>,
};

export const {
  actions: legalEntityActions,
  reducer: legalEntityReducer,
} = createSlice({
  name: 'legalEntity',
  initialState,
  reducers: {
    getLegalEntitiesRequest: (state) => {
      state.isLoading = true;
      state.code = null;
      state.message = '';
    },
    getLegalEntitiesSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.items = payload.items;
      state.code = payload.code;
      state.message = payload.message;
    },
    getLegalEntitiesFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.code = payload.code;
      state.message = payload.message;
    },

    addLegalEntityRequest: (state) => {
      state.isLoading = true;
      state.code = null;
      state.message = '';
    },
    addLegalEntitySuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.items = [...state.items, payload.entity];
    },
    addLegalEntityFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.code = payload.code;
      state.message = payload.message;
    },

    deleteLegalEntityRequest: (state) => {
      state.isLoading = true;
      state.code = null;
      state.message = '';
    },
    deleteLegalEntitySuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.items = filter(state.items, (item) => item.tin !== payload.tin);
      state.code = payload.code;
      state.message = payload.message;
    },
    deleteLegalEntityFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.code = payload.code;
      state.message = payload.message;
    },

    setActiveLegalEntity: (state, { payload }: PayloadAction<any>) => {
      state.entity = payload;
    },

    addLegalEntityByESPRequest: (state) => {
      state.isLoading = true;
      state.code = null;
      state.message = '';
    },
    addLegalEntityByESPSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.items = [...state.items, payload.entity];
    },
    addLegalEntityByESPFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.code = payload.code;
      state.message = payload.message;
    },

    getLEPersonsRequest: (state) => {
      state.isLoading = true;
    },
    getLEPersonsSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.persons = payload.persons;
    },
    getLEPersonsFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.message = payload.message;
    },

    addLAPersonRequest: (state) => {
      state.isLoading = true;
      state.code = null;
    },
    addLAPersonSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.code = payload;
    },
    addLAPersonFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.message = payload.message;
    },

    deleteLAPersonRequest: (state) => {
      state.isLoading = true;
    },
    deleteLAPersonSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
    },
    deleteLAPersonFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.message = payload.message;
    },

    updateLADataRequest: (state) => {
      state.isLoading = true;
    },
    updateLADataSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
    },
    updateLADataFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.message = payload.message;
    },

    // Roles
    getLERolesRequest: (state) => {
      state.isLoading = true;
      state.code = null;
      state.message = '';
    },
    getLERolesSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.roles = payload.roles;
      state.code = payload.code;
      state.message = payload.message;
    },
    getLERolesFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.code = payload.code;
      state.message = payload.message;
    },

    updateLERolesRequest: (state) => {
      state.isLoading = true;
    },
    updateLERolesSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
    },
    updateLERolesFailure: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = false;
      state.message = payload.message;
    },
  },
});
