import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getISOLocale } from '../../../common/locales';
import { HEADERS, api } from '../../../common/utils/fetch-utils';
import { authActions } from '../../../slices/auth-slice';
import { restoreAuthToken, userLogOut } from '../../../thunks/auth-thunks';
import { DataTable } from '../../data-table';
import { HISTORY_AUTHORIZATION_COLUMNS_RESOURCE } from './history-authorization-columns-resource';

interface HistoryAuthorizationProps {
  className?: string;
}

export const HistoryAuthorization: ComponentType<HistoryAuthorizationProps> = ({
  className,
}) => {
  const [hits, setHits] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const handleFetch = () => {
    const page = 1;
    const size = 10;
    setIsLoading(true);
    api.addHeader(HEADERS.AUTHORIZATION, `Bearer ${restoreAuthToken()}`);
    api.addHeader(HEADERS.ACCEPT_LANGUAGE, getISOLocale());
    api
      .get('/profile/getHistoryIO', { page, size })
      .then(({ data }) => setHits(data.userActions))
      .catch((error: any) => {
        if (error.code === 401) {
          dispatch(authActions.getInfoFailure());
          userLogOut()(dispatch);
        }
        dispatch(authActions.getInfoFailure());
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <DataTable
      className={className}
      data={hits}
      columns={HISTORY_AUTHORIZATION_COLUMNS_RESOURCE}
      loading={isLoading}
    />
  );
};
