import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Locale, locales, localesMap } from '../../common/locales';
import { currentLocaleActions } from '../../slices/currentLocaleSlice';

export const localeParseRegex = String.raw`^\/(${locales.join('|')})(\/.*)`;

export const useLangSwitcher = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [, , path = ''] = pathname.match(localeParseRegex) || [];

  let history = useHistory();

  const changeStoreLang = (locale: Locale) => {
    dispatch(currentLocaleActions.setCurrentLocale(locale as Locale));
  };

  const changeLang = (locale: Locale) => {
    changeStoreLang(locale);
    i18n.changeLanguage(locale).then(() => {
      history.replace(`/${locale}${path}${window.location.search}`);
    });
  };

  const curentLang = (locale: Locale) => {
    return i18n.language === locale && locale;
  };

  const curentLangName = localesMap[i18n.language as Locale].shortName;

  return { changeLang, curentLang, curentLangName };
};
