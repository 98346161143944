import { BASE_LOCALE } from './constants';

export const localesMap = {
  uz: {
    shortName: 'Ўзб',
    name: 'Ўзбекча',
    iso: 'uz-Cyrl-UZ,uz-Latn-UZ;q=0.9,ru;q=0.8,en;q=0.7',
  },
  oz: {
    shortName: `O'zb`,
    name: `O'zbekcha`,
    iso: 'uz-Latn-UZ,uz-Cyrl-UZ;q=0.9,ru;q=0.8,en;q=0.7',
  },
  ru: {
    shortName: 'Рус',
    name: 'Русский',
    iso: 'ru,en;q=0.9,uz-Cyrl-UZ;q=0.8,uz-Latn-UZ;q=0.7',
  },
  en: {
    shortName: 'Eng',
    name: 'English',
    iso: 'en,uz-Latn-UZ;q=0.9,uz-Cyrl-UZ;q=0.8,ru;q=0.7',
  },
};

export type Locale = keyof typeof localesMap;

export const locales = Object.keys(localesMap) as Locale[];

export const DEFAULT_LOCALE: Locale =
  (localStorage.getItem(BASE_LOCALE) as Locale) || 'oz';

export const getISOLocale = (): string => {
  const locale = (localStorage.getItem(BASE_LOCALE) as Locale) || 'oz';
  return localesMap[locale].iso;
};
