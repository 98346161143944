import { Divider, Paper, Stack, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Box, BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSelectRole } from '../user-select-role/user-select-role';
import { useUser } from '../useUser';
import useStyles from './styles';

interface UserSSOProps {
  className?: string;
}

export const UserSSO: ComponentType<UserSSOProps & BoxProps> = ({
  className,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { role, isLoading, handleSubmit, handleSelect } = useUser();

  return (
    <Box
      className={clsx(classes.root, className)}
      component={Paper}
      p={3.75}
      {...props}
    >
      <Typography variant="h5" component="div" mb={1}>
        {t('user.role.select', 'Выберите роль')}
      </Typography>
      {/*
      <Typography variant="body1" component="p" color="textSecondary">
        Единый портал интерактивных государственных услуг является единой точкой
        доступа к электронным государственным услугам, предоставляемым
        государственными органами, в том числе на платной основе.
      </Typography> */}

      <Divider
        sx={{
          mt: 3,
          mb: 3,
        }}
      />
      <UserSelectRole onChange={handleSelect} role={role} />
      <Box
        component={Stack}
        direction="row"
        spacing={2}
        justifyContent="space-between"
        mt={4}
      >
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          loading={isLoading}
          disabled={!role}
          sx={{
            width: { md: 'auto', xs: '100%' },
            ml: { md: 'auto' },
          }}
        >
          {t('action.next', 'Далее')}
        </LoadingButton>

        {/*

        <Button variant="contained">{t('action.transfer', 'Передать')}</Button>
        <FormControlLabel
          control={<UiCheckbox />}
          label={t('action.alwaysTrust', 'Всегда доверять')}
        />
        <Button variant="contained">
          {t('action.notTransfer', 'Не передавать')}
        </Button> */}
      </Box>
    </Box>
  );
};
