import { Alert, Box, IconButton, SvgIcon } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { isFunction, map } from 'lodash';
import camelCase from 'lodash/camelCase';
import React, { ComponentType, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '../../theme/useResponsive';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ESignItemText } from './e-sign-item-text';
import useStyles from './styles';

export interface ESignItemProps {
  className?: string;
  variant?: 'select' | 'confirm' | 'manage';
  status?: 'success' | 'error';
  actions?: ReactElement[] | ((item: any) => ReactElement[]);
  data: any;
  onClick?: () => void;
}

export const ESignItem: ComponentType<ESignItemProps & BoxProps> = ({
  className,
  variant = 'select',
  status,
  actions,
  data,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const error = status === 'error';
  const [alert, setAlert] = useState(error);
  const expiredText = t('eSign.expired', 'Подпись не дейтвительна');
  const r = useResponsive();

  if (!data) {
    return null;
  }

  return (
    <Box
      className={clsx(
        classes.root,
        className,
        classes[variant],
        status && classes[status]
      )}
      {...props}
    >
      <div className={classes.content}>
        <Box
          display="grid"
          gridTemplateColumns={r({
            xs: `repeat(6, 1fr)`,
            sm: `repeat(12, 1fr)`,
          })}
          rowGap={r({
            xs: 1,
            sm: props.rowGap || 2,
          })}
          columnGap={r({
            xs: 2,
            sm: props.columnGap || 4,
          })}
          className={classes.body}
        >
          {(data?.values || []).map((item: any, index: any) => {
            const sizeXs = item.size > 7 ? 6 : 3;
            const sizeSm = item.size > 4 ? 8 : 4;
            return (
              <ESignItemText
                className={clsx(classes.item, camelCase(item.key))}
                gridColumn={r({
                  xs: `span ${!item.size || item.size !== 12 ? sizeXs : 6}`,
                  sm: `span ${!item.size || item.size !== 12 ? sizeSm : 12}`,
                  md: `span ${item.size || 12}`,
                })}
                title={item?.title}
                text={item?.text}
                color={(!item?.title && 'primary') || 'inherit'}
                error={item?.error}
                key={`ESignItemText_${index}`}
              />
            );
          })}

          <Box
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              inset: 0,
            }}
            onClick={() => {
              error ? setAlert(true) : onClick && onClick();
            }}
          />
        </Box>

        {!error && actions && (
          <Box
            display="flex"
            // flexDirection="column-reverse"
            flexDirection={r({
              xs: `row-reverse`,
              md: `column-reverse`,
            })}
            justifyContent="space-between"
            className={classes.actions}
          >
            {isFunction(actions)
              ? actions(data)
              : map(actions, (item: any, index: any) => (
                  <React.Fragment key={`action_${index}_${variant}`}>
                    {item}
                  </React.Fragment>
                ))}
          </Box>
        )}
      </div>

      {alert && (
        <Alert
          className={classes.alert}
          color="error"
          icon={false}
          action={
            <IconButton
              className={clsx(classes.btn, classes.btnClose)}
              color="inherit"
              onClick={() => {
                setAlert(false);
              }}
            >
              <SvgIcon className={classes.icon} component={CloseIcon} />
            </IconButton>
          }
        >
          {expiredText}
        </Alert>
      )}
    </Box>
  );
};
