import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { themeBreakpoints } from '../../theme/breakpoints';
import { typography } from '../../theme/typography';

const options = {
  name: 'sign-in',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    fullWidth: {},
    shifted: {
      '& $body': {
        maxWidth: theme.spacing(60),
      },
    },
    content: {
      padding: theme.spacing(2.5, 3.75, 3.75),
      borderRadius: theme.spacing(0, 0, 0.5, 0.5),

      [themeBreakpoints.up('lg')]: {
        borderRadius: theme.spacing(0, 1, 1, 1),
      },
    },
    title: {
      margin: theme.spacing(0, 0, 2, 0),
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 0, 3, 3.75),
      },
    },
    body: {},
    list: {
      alignItems: 'flex-end',
      '& .MuiTabs-scroller': {},
      '& .MuiTabs-flexContainer': {
        alignItems: 'flex-end',
      },
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },
    tab: {
      position: 'relative',
      flexDirection: 'row',
      borderRadius: theme.spacing(0.5, 0.5, 0, 0),
      marginLeft: '1px',
      padding: theme.spacing(0, 3.75),
      color: theme.palette.primary.contrastText,
      ...typography.h5,
      whiteSpace: 'nowrap',
      minHeight: 54,
      background: theme.palette.primary.main,
      '&$selected': {
        color: theme.palette.text.primary,
      },
      '&:first-child': {
        marginLeft: 0,
      },

      [themeBreakpoints.up('lg')]: {
        borderRadius: theme.spacing(1, 1, 0, 0),
      },
    },
    tabText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      fontSize: 'inherit',
    },
    tabTextFake: {
      position: 'relative',
      ...typography.h4,
      opacity: 0,
    },
    // next: {
    //   borderRadius: theme.spacing(0, 0.5, 0, 0),
    // },
    // prev: {
    //   borderRadius: theme.spacing(0.5, 0, 0, 0),
    // },
    disabled: {
      display: 'none',
    },
    selected: {
      paddingTop: '10px',
      color: theme.palette.text.primary,
      ...typography.h4,
      minHeight: 64,
      background: theme.palette.background.paper,
      overflow: 'initial',
      zIndex: 100,
      '& $tabText': {
        top: 'calc(50% + 5px)',
        transform: `translate(-50%, calc(-50% - 2px))`,
      },
      '&::before, &::after': {
        content: '""',
        PointerEvent: 'none',
        position: 'absolute',
        bottom: 0,
        height: 8,
        width: 16,
        backgroundColor: 'transparent',
      },
      '&::before': {
        left: '-16px',
        borderBottomRightRadius: '8px',
        boxShadow: '8px 0 0 0 #fff',
      },
      '&::after': {
        right: '-16px',
        borderBottomLeftRadius: '8px',
        boxShadow: '-8px 0 0 0 #fff',
      },
      [theme.breakpoints.down('lg')]: {
        '&:first-child': {
          '&::before': {
            content: 'none',
          },
        },
        '&:last-child': {
          '&::after': {
            content: 'none',
          },
        },
      },
    },
    panel: {
      padding: 0,
    },
    login: {},
    phone: {},
    eSign: {
      [theme.breakpoints.down('xl')]: {
        display: 'none',
      },
    },
    idCard: {},
  }),
  options
);

export default useStyles;
