import clsx from 'clsx';
import { ComponentType, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18n from '../../../../common/i18next';
import { useSSO } from '../../../../hooks/useSSO';
import { signIn } from '../../../../thunks/auth-thunks';
import useAuth from '../../useAuth';
import { SignInIdCardForm } from '../index';
import useStyles from './styles';

interface SignInIdCardProps {
  className?: any;
}

export const SignInIdCard: ComponentType<SignInIdCardProps> = ({
  className,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token_id } = useSSO();
  const history = useHistory();
  const { authorized } = useAuth();

  useEffect(() => {
    if (authorized && !token_id) {
      history.push(`/${i18n.language}/personal-data`);
    }
  }, [authorized, history, token_id]);

  const handleSubmit = async (credentials: {
    passportId: string;
    password: string;
  }) => {
    const { passportId, password } = credentials;
    dispatch(signIn({ login: passportId, password }));
  };
  return (
    <div className={clsx(classes.root, className)}>
      <SignInIdCardForm handleSubmit={handleSubmit} className={classes.form} />
    </div>
  );
};
