import { SvgIcon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultTheme } from '../theme/ThemeProvider';
import { useToggleTheme } from '../theme/useToggleTheme';
import { ReactComponent as A11yIcon } from './assets/a11y.svg';
import useStyles from './styles';

interface A11yProps {
  className?: any;
}
export { A11yIcon };

export const A11y: ComponentType<A11yProps> = ({ className, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { theme, handleTheme } = useToggleTheme();

  const toggleA11y = () => {
    handleTheme(theme === 'a11y' ? defaultTheme : 'a11y');
  };

  return (
    <div className={clsx(classes.root, className)} onClick={toggleA11y}>
      <SvgIcon component={A11yIcon} className={classes.icon} />
      <Typography variant="body2" component="div" className={classes.text}>
        {t(theme === 'a11y' ? 'a11y.text.default' : 'a11y.text')}
      </Typography>
    </div>
  );
};
