import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { themeBreakpoints } from '../../theme/breakpoints';

const options = {
  name: 'phone-sms',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    row: {
      margin: theme.spacing(-0.5),
      width: `calc(100% - ${theme.spacing(1)})`,
    },
    col: {
      padding: theme.spacing(0.5),
    },
    colCode: {},
    colText: {
      paddingTop: theme.spacing(3),
      flex: `0 0 calc(100% - ${theme.spacing(13)})`,
      width: `calc(100% - ${theme.spacing(13)})`,
      [themeBreakpoints.up('sm')]: {
        flex: `0 0 calc(160px + ${theme.spacing(1)})`,
        width: `calc(160px + ${theme.spacing(1)})`,
      },
    },
    colActions: {
      [themeBreakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
      },
    },
    code: {
      maxWidth: theme.spacing(12),
      width: '100%',
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: '18px',
      minHeight: 32,
    },
    btn: {},
  }),
  options
);

export default useStyles;
