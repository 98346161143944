import { Collapse, Grid, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty, isInteger } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { MASK_PHONE } from '../../../../constants/masks-constants';
import { PhoneConfirmNew } from '../../../phone';
import { useSnackbar } from '../../../snackbar';
import { useResponsive } from '../../../theme/useResponsive';
import { FIELD_LABELS, FIELD_PHONE } from './sign-in-phone-form-schema';
import useStyles from './styles';
import { useSignInPhone } from './useSignInPhone';

interface SignInPhoneFormProps {
  className?: any;
}

const PREFIX = 'sign-in';

export const SignInPhoneForm: ComponentType<SignInPhoneFormProps> = ({
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const { snackbarOpen } = useSnackbar();
  const [isSmsEnabled, setIsSmsEnabled] = useState<Boolean | any>(false);

  const {
    signInPhone,
    signInSms,
    formik,
    oneTimePass,
    controlCode,
    handleFieldChange,
    handleSmsChange,
    handleResendSms,
    handleSubmit,
    getErrorMessage,
    hasError,
  } = useSignInPhone();

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);
  const smsErrors =
    isInteger(signInSms.code) && Number(signInSms.code) !== 0
      ? t(`error.api.${signInSms?.code}`, `Ошибка с кодом ${signInSms?.code}`)
      : null;

  const isLoading = signInPhone.isLoading || signInSms.isLoading;
  // const isSmsEnabled =
  //   isInteger(signInPhone?.code) && Number(signInPhone.code) === 0;

  useEffect(() => {
    if (isInteger(signInPhone?.code) && Number(signInPhone.code) !== 0) {
      snackbarOpen(
        t(
          `error.api.${signInPhone?.code}`,
          `Ошибка с кодом ${signInPhone?.code}`
        ),
        {
          severity: 'error',
        }
      );
    }
    if (isInteger(signInPhone?.code) && Number(signInPhone.code) === 0) {
      setIsSmsEnabled(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInPhone?.code]);

  return (
    <div className={clsx(classes.root, className)}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={r({ lg: 2 })}>
          <Grid item xs={12} lg={6}>
            <InputMask
              mask={MASK_PHONE}
              maskPlaceholder={null}
              {...formik.getFieldProps(FIELD_PHONE)}
              onChange={(e: any) => handleFieldChange(FIELD_PHONE, e)}
              disabled={
                isLoading
                // ||
                // (isInteger(code) &&
                //   Number(code) === 0 &&
                //   !isInteger(profileSms?.code))
              }
            >
              <TextField
                label={t(
                  FIELD_LABELS[FIELD_PHONE]['labelKey'],
                  FIELD_LABELS[FIELD_PHONE]['label']
                )}
                id={`${PREFIX}-${FIELD_PHONE}`}
                error={hasError(FIELD_PHONE)}
                helperText={getErrorMessage(FIELD_PHONE)}
                required
                InputProps={{
                  'aria-label': FIELD_LABELS[FIELD_PHONE]['ariaLabel'],
                }}
              />
            </InputMask>
          </Grid>
          <Grid item xs={12} lg={6} pt={r({ lg: 3 })} pb={3.25}>
            <LoadingButton
              className={classes.btn}
              loading={isLoading}
              disabled={!noErrors || isSmsEnabled}
              fullWidth={r({ xs: true, lg: false })}
              variant="outlined"
              type="submit"
            >
              {t('action.sendSms', 'Отправить SMS')}
            </LoadingButton>
          </Grid>
        </Grid>
        <Collapse in={isSmsEnabled && Boolean(controlCode)}>
          <Typography
            component="div"
            variant="body2"
            fontStyle="italic"
            color="textSecondary"
            mb={0.5}
          >
            {`${t(
              'signIn.controlCode',
              'Техническая информация: контрольный код подтверждения услуги'
            )} - ${controlCode}`}
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color="textSecondary"
            mb={0.5}
          >
            {t(
              'phone.sms.title',
              'На указанный вами телефона мы отправили SMS с кодом'
            )}
          </Typography>
          <PhoneConfirmNew
            isLoading={signInSms.isLoading}
            error={smsErrors}
            onChange={handleSmsChange}
            onRetry={handleResendSms}
          />
        </Collapse>

        <Grid container columnSpacing={r({ lg: 2 })} mt={0.5}>
          <Grid
            item
            xs={12}
            lg={6}
            alignSelf="center"
            order={r({ xs: 1, lg: 2 })}
          />
          <Grid item xs={12} lg={6} order={r({ xs: 2, lg: 1 })}>
            <LoadingButton
              loading={isLoading}
              disabled={!noErrors || !oneTimePass}
              variant="contained"
              className={classes.btn}
              fullWidth={r({ xs: true, lg: false })}
              onClick={handleSubmit}
            >
              {t('action.signIn', 'Войти')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
