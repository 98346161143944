import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'hint',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    fullScreen: {},
    paper: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: 348,
      },
      '$fullScreen &': {
        color: '#ff0000',
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%',
        outline: 0,
        overflowY: 'auto',
        overflowX: 'hidden',
      },
    },
    content: {
      display: 'flex',
      '$fullScreen &': {
        flexDirection: 'column',
        minHeight: '100%',
      },
    },
    body: {
      padding: theme.spacing(2, 0, 2, 2),
      '$fullScreen &': {
        padding: theme.spacing(0, 2, 2),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 2,
        order: 2,
      },
    },
    action: {
      '$fullScreen &': {
        order: 1,
        padding: theme.spacing(2),
        flex: '0 0 auto',
      },
    },
    btn: {
      '$fullScreen &': {
        marginTop: theme.spacing(-1.5),
        '&:first-child': {
          marginLeft: theme.spacing(-1.75),
        },
      },
    },
    btnIcon: {
      padding: theme.spacing(1.5),
      height: 48,
      width: 48,
    },

    icon: {},
    iconLeft: {
      transform: 'rotate(180deg)',
    },
  }),
  options
);

export default useStyles;
