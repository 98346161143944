import { FormControlLabel, RadioGroup, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { map } from 'lodash';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { UiRadio } from '../../ui';
import { useUser } from '../useUser';
import useStyles from './styles';

interface UserSelectRoleProps {
  className?: string;
  role: any;
  onChange: (e: any) => void;
}

export const UserSelectRole: ComponentType<UserSelectRoleProps> = ({
  className,
  role,
  onChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { listRole, isLoading } = useUser();

  return (
    <div className={clsx(classes.root, className)}>
      {listRole?.length && (
        <RadioGroup
          aria-label={'select role for authorization'}
          name="role"
          value={role}
          onChange={(e) => onChange(e)}
        >
          {map(listRole, (item: any, index: number) => {
            return (
              <React.Fragment key={'role_' + index}>
                <FormControlLabel
                  value={String(item.le_tin)}
                  control={<UiRadio />}
                  label={
                    <Typography variant="body1" component="div">
                      {item.le_tin === 0
                        ? `${t(
                            'user.transfer.individual',
                            'Физическое лицо'
                          )} (${item.le_name})`
                        : item.le_name}
                    </Typography>
                  }
                  disabled={isLoading}
                />
                {/* <Collapse in={role === item?.id}>
                  <UserTransferData
                    data={item.transferData}
                    pl={4}
                    sx={
                      {
                        // height: 200,
                      }
                    }
                  />
                </Collapse> */}
              </React.Fragment>
            );
          })}
        </RadioGroup>
      )}
    </div>
  );
};
