import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressForm } from '../address-form/address-form';
import useStyles from './styles';

interface AddressCustomProps {
  className?: string;
  addressData?: any;
}

export const AddressCustom: ComponentType<AddressCustomProps> = ({
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography
        variant="h4"
        component="h2"
        className={classes.title}
        mb={2.25}
      >
        {t('user.address.actual', 'Адрес фактического проживания')}{' '}
        <Typography color="textSecondary" component="span">
          ({t('notNecessary', 'необязательно')})
        </Typography>
      </Typography>
      <Typography
        variant="body1"
        component="div"
        color="textSecondary"
        className={classes.text}
        mb={2}
      >
        {t(
          'user.address.text',
          'Некоторые системы могут отправлять информацию на указанный адрес.'
        )}
      </Typography>
      <AddressForm className={classes.form} />
    </div>
  );
};
