import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'legal-entity-esign',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      margin: theme.spacing(2, 0, 0),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(1, 0, 0, 4),
      },
    },
    header: {},
    container: {},
    content: {},
  }),
  options
);

export default useStyles;
