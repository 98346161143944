import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'sign-in-login-form',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    text: {},
    link: {
      color: theme.palette.primary.main,
      '&:hover, &:focus:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.dark,
      },
    },
    btn: {},
  }),
  options
);

export default useStyles;
