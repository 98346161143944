import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { themeBreakpoints } from '../../../theme/breakpoints';
import { typography } from '../../../theme/typography';

const options = {
  name: 'sign-up-form',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    tabList: {
      minHeight: '1%',
      '& .MuiTabs-indicator': {
        display: 'none',
      },
    },

    tab: {
      padding: 0,
      margin: theme.spacing(0, 3.75, 0, 0),
      color: theme.palette.primary.main,
      flexDirection: 'row',
      alignItems: 'center',
      minHeight: '1%',
      minWidth: '1%',
      '&.Mui-selected': {
        cursor: 'auto',
        PointerEvent: 'none',
      },
      '&.Mui-disabled': {
        PointerEvent: 'none',
        color: theme.palette.action.disabled,
        '& $tabIcon': {
          background: theme.palette.action.disabled,
        },
      },
      '&.MuiTab-labelIcon': {},
    },

    tabIcon: {
      margin: `${theme.spacing(0, 1, 0, 0)} !important`,
      borderRadius: '100%',
      color: theme.palette.primary.contrastText,
      ...typography.h5,
      lineHeight: '1.5em',
      height: '1.5em',
      width: '1.5em',
      background: theme.palette.primary.main,
    },

    tabPanel: {
      padding: 0,
    },

    row: {},
    rowTabs: {
      marginBottom: theme.spacing(3),
    },
    rowActions: {},
    col: {
      maxWidth: '100%',
    },
    colForm: {
      flex: '0 0 100%',
      width: '100%',
      [themeBreakpoints.up('md')]: {
        flex: '0 0 284px',
        maxWidth: '0 0 284px',
      },
    },
    colMedia: {
      flex: '0 0 100%',
      width: '100%',
      [themeBreakpoints.up('md')]: {
        flex: `0 0 calc(100% - 284px)`,
        maxWidth: `calc(100% - 284px)`,
      },
    },
    actions: {
      // margin: theme.spacing(3, 0, 2),
    },
    media: {
      display: 'block',
      maxWidth: '100%',
    },
    img: {
      display: 'block',
      maxWidth: '100%',
    },
    divider: {
      margin: theme.spacing(2, 0, 5),
    },
    text: {
      marginBottom: theme.spacing(2),
    },
    sms: {},
    link: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      transition: '.25s',
      '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.25),
      },
    },
    btn: {},
    help: {
      color: theme.palette.text.secondary,
      ...typography.body2,
      '& h2': {
        marginBottom: theme.spacing(2.25),
      },
      '& p': {
        margin: theme.spacing(0, 0, 2),
      },
      '& a': {
        display: 'inline-flex',
        alignItems: 'center',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        transition: '.25s',
        '&:hover': {
          color: theme.palette.primary.dark,
          textDecoration: 'underline',
        },
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.25),
        },
      },
      '& ul': {
        position: 'relative',
        listStyle: 'none',
        padding: theme.spacing(0, 0, 0, 4),
        margin: 0,
      },
      '& li': {
        ...typography.body1,
        marginBottom: theme.spacing(1),
        lineHeight: '24px',
        '&:last-of-type': {
          marginBottom: 0,
        },
        '& .MuiSvgIcon-root': {
          position: 'absolute',
          left: 0,
        },
      },
      '& .MuiDivider-root, & hr': {
        margin: theme.spacing(4, 0),
      },
    },
  }),
  options
);

export default useStyles;
