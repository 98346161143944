import { ComponentType } from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface DataTableTypographyProps {
  label: string;
  labelKey?: string;
}

export const DataTableTypography: ComponentType<
  DataTableTypographyProps & TypographyProps
> = ({ label, labelKey, ...props }) => {
  const { t } = useTranslation();
  const text = labelKey ? t(labelKey, label) : label;

  return <Typography {...props}>{text}</Typography>;
};
