import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState = {
  error: null as string | null,
  passportData: null as Record<string, any> | null,
  innData: null as Record<string, any> | null,
  addressData: null as Record<string, any> | null,
  refreshData: null as Record<string, any> | null,
  pinfl: null as string | null,
  email: null as string | null,
  login: null as string | null,
  phoneNumber: null as string | null,
  phoneNumberOwner: null as string | null,
  authenticESP: null as boolean | null,
  vaccinated: null as boolean | null,
  vaccineIssueDate: null as any,
  isLoading: false,

  authenticationPhoneForm: {
    isLoading: false,
    code: null as string | null,
    message: null as string | null,
  },
  photo: {
    isLoading: false,
    data: null as string | null,
  },
  passwordForm: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
  passwordRecovery: {
    isLoading: false,
    code: null as string | null,
    message: null as string | null,
    data: null,
  },
  passwordRecoverySMS: {
    isLoading: false,
    isValid: false,
    code: null as number | null,
    message: null as string | null,
  },
  loginForm: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
  passportSerialNumber: {
    isLoading: false,
    code: null as string | null,
    message: null as string | null,
  },
  removeForm: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
  emailForm: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
  phoneForm: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
  profileSms: {
    isLoading: false,
    isValid: false,
    code: null as number | null,
    message: null as string | null,
  },
  vaccination: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
};

export const { actions: profileActions, reducer: profileReducer } = createSlice(
  {
    name: 'auth',
    initialState,
    reducers: {
      //Authentication Phone
      setAutificationPhoneRequest: (state) => {
        state.authenticationPhoneForm = {
          isLoading: true,
          code: null,
          message: null,
        };
      },
      setAutificationPhoneSuccess: (state, { payload }: PayloadAction<any>) => {
        state.phoneNumberOwner = payload.data;
        state.authenticationPhoneForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setAutificationPhoneFailure: (state, { payload }: PayloadAction<any>) => {
        state.authenticationPhoneForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setAutificationPhoneDefault: (state) => {
        state.authenticationPhoneForm = {
          isLoading: false,
          code: null,
          message: null,
        };
      },

      //User profile phone
      setPhoneRequest: (state) => {
        state.phoneForm = {
          isLoading: true,
          code: null,
          message: null,
        };
      },
      setPhoneSuccess: (state, { payload }: PayloadAction<any>) => {
        state.phoneNumber = payload.data;
        state.phoneForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setPhoneFailure: (state, { payload }: PayloadAction<any>) => {
        state.phoneForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setPhoneDefault: (state) => {
        state.phoneForm = {
          isLoading: false,
          code: null,
          message: null,
        };
      },

      //User profile email
      setEmailRequest: (state) => {
        state.emailForm = {
          isLoading: true,
          code: null,
          message: null,
        };
      },
      setEmailSuccess: (state, { payload }: PayloadAction<any>) => {
        state.email = payload.email;
        state.emailForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setEmailFailure: (state, { payload }: PayloadAction<any>) => {
        state.emailForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setEmailDefault: (state) => {
        state.emailForm = {
          isLoading: false,
          code: null,
          message: null,
        };
      },

      //User profile password
      setPasswordRequest: (state) => {
        state.passwordForm = {
          isLoading: true,
          code: null,
          message: null,
        };
      },
      setPasswordSuccess: (state, { payload }: PayloadAction<any>) => {
        state.passwordForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setPasswordFailure: (state, { payload }: PayloadAction<any>) => {
        state.passwordForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setPasswordDefault: (state) => {
        state.passwordForm = {
          isLoading: false,
          code: null,
          message: null,
        };
      },

      //User profile recover password
      setRecoverPasswordRequest: (state) => {
        state.passwordRecovery = {
          isLoading: true,
          code: null,
          message: null,
          data: null,
        };
      },
      setRecoverPasswordSuccess: (state, { payload }: PayloadAction<any>) => {
        state.passwordRecovery = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
          data: payload.data,
        };
      },
      setRecoverPasswordFailure: (state, { payload }: PayloadAction<any>) => {
        state.passwordRecovery = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
          data: payload.data,
        };
      },
      setRecoverPasswordDefault: (state) => {
        state.passwordRecovery = {
          isLoading: false,
          code: null,
          message: null,
          data: null,
        };
      },

      setRecoverPasswordSmsRequest: (state) => {
        state.passwordRecoverySMS = {
          isLoading: true,
          isValid: false,
          code: null,
          message: null,
        };
      },
      setRecoverPasswordSmsSuccess: (
        state,
        { payload }: PayloadAction<any>
      ) => {
        state.passwordRecoverySMS = {
          isLoading: false,
          isValid: true,
          code: payload.code,
          message: payload.message,
        };
      },
      setRecoverPasswordSmsFailure: (
        state,
        { payload }: PayloadAction<any>
      ) => {
        state.passwordRecoverySMS = {
          isLoading: false,
          isValid: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setRecoverPasswordSmsDefault: (state) => {
        state.passwordRecoverySMS = {
          isLoading: false,
          isValid: false,
          code: null,
          message: null,
        };
      },

      //User profile login
      setLoginRequest: (state) => {
        state.loginForm = {
          isLoading: true,
          code: null,
          message: null,
        };
      },
      setLoginSuccess: (state, { payload }) => {
        state.loginForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setLoginFailure: (state, { payload }) => {
        state.loginForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setLoginDefault: (state) => {
        state.loginForm = {
          isLoading: false,
          code: null,
          message: null,
        };
      },

      //User passport serial number
      setPassportSerialNumberRequest: (state) => {
        state.passportSerialNumber = {
          isLoading: true,
          code: null,
          message: null,
        };
      },
      setPassportSerialNumberSuccess: (state, { payload }) => {
        state.passportSerialNumber = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setPassportSerialNumberFailure: (state, { payload }) => {
        state.passportSerialNumber = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setPassportSerialNumberDefault: (state) => {
        state.passportSerialNumber = {
          isLoading: false,
          code: null,
          message: null,
        };
      },

      //Get user profile photo
      setPhotoRequest: (state) => {
        state.photo = {
          isLoading: true,
          data: null,
        };
      },
      setPhotoSuccess: (state, { payload }: PayloadAction<any>) => {
        state.photo = {
          isLoading: false,
          data: payload,
        };
      },
      setPhotoFailure: (state) => {
        state.photo = {
          isLoading: false,
          data: null,
        };
      },
      setPhotoDefault: (state) => {
        state.photo = {
          isLoading: false,
          data: null,
        };
      },

      //Update user profile info
      updateProfileInfo: (state, { payload }: PayloadAction<any>) => ({
        ...state,
        ...payload,
        isLoading: false,
      }),

      //Refresh user data
      getRefreshDataRequest: (state) => ({
        ...state,
        isLoading: true,
        refreshData: null,
      }),
      getRefreshDataSuccess: (state, { payload }: PayloadAction<any>) => ({
        ...state,
        isLoading: false,
        refreshData: payload.data,
        addressData: payload.addressData,
        passportData: payload.passportData,
        pinfl: payload.pinfl,
        status: payload.status,
      }),
      getRefreshDataFailure: (state, { payload }: PayloadAction<any>) => ({
        ...state,
        isLoading: false,
        error: payload.error,
      }),

      //User profile login
      setProfileRemoveRequest: (state) => {
        state.removeForm = {
          isLoading: true,
          code: null,
          message: null,
        };
      },
      setProfileRemoveSuccess: (state, { payload }) => {
        state.removeForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setProfileRemoveFailure: (state, { payload }) => {
        state.removeForm = {
          isLoading: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setProfileRemoveDefault: (state) => {
        state.removeForm = {
          isLoading: false,
          code: null,
          message: null,
        };
      },

      //User profile confirmation via sms
      setProfileSmsRequest: (state) => {
        state.profileSms = {
          isLoading: true,
          isValid: false,
          code: null,
          message: null,
        };
      },
      setProfileSmsSuccess: (state, { payload }: PayloadAction<any>) => {
        state.profileSms = {
          isLoading: false,
          isValid: true,
          code: payload.code,
          message: payload.message,
        };
      },
      setProfileSmsFailure: (state, { payload }: PayloadAction<any>) => {
        state.profileSms = {
          isLoading: false,
          isValid: false,
          code: payload.code,
          message: payload.message,
        };
      },
      setProfileSmsDefault: (state) => {
        state.profileSms = {
          isLoading: false,
          isValid: false,
          code: null,
          message: null,
        };
      },

      //User profile reset
      setProfileDefault: (state) => {
        state.error = null;
        state.passportData = null;
        state.innData = null;
        state.addressData = null;
        state.pinfl = null;
        state.email = null;
        state.login = null;
        state.phoneNumber = null;
        state.phoneNumberOwner = null;
        state.authenticESP = null;
        state.vaccinated = null;
        state.vaccineIssueDate = null;
      },
    },
  }
);
