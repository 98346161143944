import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'user-profile-remove',
};
const useStyles = makeStyles(
  () => ({
    root: {},
    title: {},
    text: {},
    form: {},
    actions: {},
    btn: {},
  }),
  options
);

export default useStyles;
