import {
  Button,
  IconButton,
  Popover,
  PopoverOrigin,
  SvgIcon,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowIcon, CloseIcon } from '../../common/icons';
import useStyles from './styles';

interface HintPros {
  className?: string;
  content?: ReactElement;
  children: any;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  fullScreen?: boolean;
  event?: 'click' | 'hover';
}

export const Hint: ComponentType<HintPros> = ({
  className,
  content,
  fullScreen,
  event,
  ...props
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'hint-popover' : undefined;
  const { t } = useTranslation();
  // const r = useResponsive();
  // const typeEvent = event || r({ xs: 'click', xl: 'hover' });
  return (
    <>
      {props.children &&
        React.cloneElement(props.children, {
          onClick: (e) => handleClick(e),
          // onMouseEnter: (e) => (typeEvent === 'hover' ? handleClick(e) : null),
        })}
      <Popover
        className={clsx(classes.root, className, {
          [classes.fullScreen]: fullScreen,
        })}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        id={id}
        open={open}
        anchorEl={!fullScreen ? anchorEl : undefined}
        onClose={handleClose}
        anchorOrigin={props.anchorOrigin}
        transformOrigin={props.transformOrigin}
        marginThreshold={fullScreen ? 0 : 16}
        elevation={2}
        {...props}
      >
        <div className={classes.content}>
          <div className={classes.body}>{content}</div>
          <div className={classes.action}>
            {!fullScreen && (
              <IconButton
                color="primary"
                className={clsx(classes.btn, classes.btnIcon)}
                onClick={handleClose}
              >
                <SvgIcon className={classes.icon} component={CloseIcon} />
              </IconButton>
            )}
            {fullScreen && (
              <Button
                className={classes.btn}
                onClick={handleClose}
                startIcon={
                  <ArrowIcon
                    color="inherit"
                    className={clsx(classes.icon, classes.iconLeft)}
                  />
                }
              >
                {t('actions.back', 'Назад')}
              </Button>
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};
