import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'sign-in-esign',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    form: {},
    title: {},
    btn: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      padding: 8,
      height: 32,
      width: 32,
      minWidth: 32,
      minHeight: 32,
      // opacity: 0,
      // transform: 'translate(-10%, 0)',
      // transition: theme.transitions.create(['opacity', 'transform'], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
    },
    icon: {
      height: 16,
      width: 16,
    },
  }),
  options
);

export default useStyles;
