import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

interface UserAgreementModalProps {
  className?: any;
  data: any;
}

export const UserAgreementModal: ComponentType<UserAgreementModalProps> = ({
  className,
  data,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography variant="h1" component="div" className={classes.title}>
        {t('user.agreement', 'Пользователькое соглашение')}
      </Typography>
      <div
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </div>
  );
};
