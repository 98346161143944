import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'connected-resources',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    content: {},
    container: {},
    header: {
      paddingBottom: theme.spacing(2.5),
      marginBottom: theme.spacing(1),
    },
    main: {},
    title: {},
    text: {},
    list: {},
    actions: {
      paddingTop: theme.spacing(1.5),
      [theme.breakpoints.up('lg')]: {
        paddingTop: theme.spacing(2),
      },
    },
    btn: {},
  }),
  options
);

export default useStyles;
