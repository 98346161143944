import { Button, Typography } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { dateParse } from '../../../common/utils/format-utils';
import { usePasswordRecovery } from './usePasswordRecovery';

interface PasswordRecoveryErrorProps {
  code: number;
  expiredDate?: Date;
  onClose?: () => void;
}

export const PasswordRecoveryError: ComponentType<
  PasswordRecoveryErrorProps
> = ({ code, expiredDate, onClose }) => {
  const { t } = useTranslation();
  const { handleRetry } = usePasswordRecovery();

  return (
    <>
      {code === 3000 && (
        <>
          <Typography component="div" variant="h4" mb={2.5}>
            {t('password.phone.notSpecified', 'Телефон не указан')}
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color="textSecondary"
            mb={0.5}
          >
            {t(
              'password.phone.notSpecified.full',
              'У вас в кабинете не указан номер телефона. Для того чтобы восстановить пароль, обратитесь в службу поддержки.'
            )}
            &nbsp;
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color="textSecondary"
            mb={0.5}
          >
            {t('password.phone', 'Телефон')}&nbsp;
            <Typography
              component="a"
              href="tel: 1242"
              color="inherit"
              fontWeight="bold"
              sx={{
                textDecoration: 'none !important',
              }}
            >
              1242
            </Typography>
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color="textSecondary"
            mb={5}
          >
            {t('password.telegram', 'Телеграм')}&nbsp;
            <Typography
              component="a"
              href="https://t.me/iduzsupport"
              color="inherit"
              fontWeight="bold"
              sx={{
                textDecoration: 'none !important',
              }}
            >
              @iduzsupport
            </Typography>
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRetry}>
            {t('action.toRetry', 'Попробовать еще раз')}
          </Button>
        </>
      )}

      {code && (
        <>
          <Typography component="div" variant="h4" mb={2.5}>
            {t('error', 'Ошибка')}
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color="textSecondary"
            mb={5}
          >
            {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`, {
              expiredDate:
                expiredDate && dateParse(expiredDate, `dd.MM.yyyy HH:mm`),
            })}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleRetry}>
            {t('action.toRetry', 'Попробовать еще раз')}
          </Button>
        </>
      )}
    </>
  );
};
