import useEventListener from '@use-it/event-listener';
import { ComponentType, createContext, useEffect, useState } from 'react';
import { breakpoints } from '../theme/breakpoints';
import { LayoutSidebarProps } from './layout-sidebar/layout-sidebar';

const drawerStorage = () => localStorage.getItem('drawer') === 'true';

export interface LayoutContextInterface {
  sidebar: LayoutSidebarProps;
  toggleSidebar: any;
  closeSidebar: any;
}

export const LayoutContext = createContext<LayoutContextInterface>({
  sidebar: {
    open: drawerStorage(),
    variant: 'default',
    className: '',
  },
  toggleSidebar: () => {},
  closeSidebar: () => {},
});

export const LayoutProvider: ComponentType = (props) => {
  const [sidebar, setSidebar] = useState<LayoutSidebarProps>({
    open: drawerStorage(),
    variant: 'default',
    className: '',
  });

  const closeSidebar = () => {
    localStorage.setItem('drawer', `false`);
    setSidebar({
      open: false,
      variant: sidebar.variant,
      className: sidebar.className,
    });
  };

  const toggleSidebar = () => {
    localStorage.setItem('drawer', `${!sidebar.open}`);
    setSidebar({
      open: !sidebar.open,
      variant: sidebar.variant,
      className: sidebar.className,
    });
  };

  const openSidebarDefault = () => {
    if (!sidebar.open && window.innerWidth >= breakpoints.values.xxl) {
      localStorage.setItem('drawer', 'true');
      setSidebar({
        open: true,
        variant: sidebar.variant,
        className: sidebar.className,
      });
    }
    if (sidebar.open && window.innerWidth < breakpoints.values.xl) {
      localStorage.setItem('drawer', 'false');
      setSidebar({
        open: false,
        variant: sidebar.variant,
        className: sidebar.className,
      });
    }
  };

  useEventListener('resize', openSidebarDefault, window);
  // useEventListener('load', openSidebarDefault, window);
  useEffect(() => {
    openSidebarDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        sidebar,
        toggleSidebar,
        closeSidebar,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};
