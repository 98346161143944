import { Alert, Box, Collapse, Grid, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isInteger } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearPasswordSerialNumberStates } from '../../../thunks/profile-thunks';
import { FIELD_DOCUMENT, FIELD_LABELS } from './passport-serial-number-schema';
import useStyles from './styles';
import { usePassportSerialNumber } from './usePassportSerialNumber';

interface PassportSerialNumberFormProps {
  className?: string;
  onSuccess?: (data: any) => void;
  onError?: (data: any) => void;
}

export const PassportSerialNumberForm: ComponentType<PassportSerialNumberFormProps> = ({
  className,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isLoading,
    code,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  } = usePassportSerialNumber();

  useEffect(() => {
    return () => {
      dispatch(clearPasswordSerialNumberStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={clsx(classes.root, className)}
    >
      <Grid container spacing={2.25} mb={isInteger(code) ? 1 : 0}>
        <Grid item flex={1}>
          <TextField
            {...formik.getFieldProps(FIELD_DOCUMENT)}
            onChange={(e: any) => handleFieldChange(FIELD_DOCUMENT, e)}
            id={FIELD_DOCUMENT}
            label={t(
              FIELD_LABELS[FIELD_DOCUMENT]['labelKey'],
              FIELD_LABELS[FIELD_DOCUMENT]['label']
            )}
            error={hasError(FIELD_DOCUMENT)}
            helperText={getErrorMessage(FIELD_DOCUMENT) || ' '}
            InputProps={{
              'aria-label': FIELD_LABELS[FIELD_DOCUMENT]['arialLabel'],
              autoComplete: 'off',
              disabled: isLoading,
            }}
          />
        </Grid>
        <Grid item>
          <Box className={classes.actions} pt={3}>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isLoading}
            >
              {t('action.replace', 'Заменить')}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>

      <Collapse in={isInteger(code) && Number(code) !== 0}>
        <Alert severity="error">
          {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`)}
        </Alert>
      </Collapse>

      <Collapse in={isInteger(code) && Number(code) === 0}>
        <Alert severity="success">
          {t('success.updated', 'Данные успешно обновлены')}
        </Alert>
      </Collapse>
    </form>
  );
};
