import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'bullet-list',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  item: {
    position: 'relative',
    padding: theme.spacing(0, 0, 0, 4),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1.5),
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 8,
      left: 8,
      width: 8,
      height: 8,
      borderRadius: '50%',
      background: theme.palette.primary.main,
    },
  },
  options,
}));

export default useStyles;
