import { Box, Typography } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { MasonryList } from '../list';
import useStyles from './styles';

interface AboutProps {
  className?: string;
  data?: any;
}

export const About: ComponentType<AboutProps & BoxProps> = ({
  className,
  data,
  ...props
}) => {
  const classes = useStyles();

  const body = (data || []).map((item: Record<string, any>, idx: number) => {
    const { view, list } = item;
    switch (view) {
      case 'image':
        return (
          <figure className={clsx(classes.figure)} key={idx}>
            <img
              className={classes.img}
              src={item.src}
              alt={item.alt}
              title={item.title}
              width={item.width}
              height={item.height}
            />
          </figure>
        );
      case 'masonry':
        return <MasonryList data={list} key={idx} />;
      default:
        return <Typography {...item} key={idx} />;
    }
  });

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      {body}
    </Box>
  );
};
