import { ComponentType, useState } from 'react';
import { DataTable } from '../../data-table';
import { HISTORY_TRANSFERRED_COLUMNS_RESOURCE } from './history-transferred-columns-resource';

interface HistoryTransferredPersonalDataProps {
  className?: string;
}

export const HistoryTransferredPersonalData: ComponentType<HistoryTransferredPersonalDataProps> = ({
  className,
}) => {
  const [hits] = useState<any[]>([]);
  const [isLoading] = useState<boolean>(false);

  return (
    <DataTable
      className={className}
      data={hits}
      columns={HISTORY_TRANSFERRED_COLUMNS_RESOURCE}
      loading={isLoading}
    />
  );
};
