import { Grid, Typography } from '@material-ui/core';
import { Box } from '@material-ui/system';
import { map } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../common/i18next';
import { useResponsive } from '../../theme/useResponsive';

export interface LegalEntityDatagridProps {
  data: Record<string, any>;
  general?: boolean;
  head?: boolean;
  founders?: boolean;
  contacts?: boolean;
}

export const LegalEntityDatagrid: ComponentType<LegalEntityDatagridProps> = ({
  data,
  general,
  head,
  founders,
  contacts,
}) => {
  const { t } = useTranslation();
  const r = useResponsive();
  const locale = i18n.language;

  const foundersArray: Record<string, any>[] = [];
  for (var i = 1; i < 6; i++) {
    const FOUNDER_KEY = 'founder_NM' + i;
    if (data[FOUNDER_KEY])
      foundersArray.push({
        name: data[FOUNDER_KEY],
        country: data['founder_COUNTRY' + i],
      });
  }

  return (
    <Box>
      {/* general info */}
      {general && (
        <Grid container spacing={3.625} mb={r({ xs: 5, md: 9 })}>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.shortName', 'Сокращенное наименование')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.acron_UZ}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.fullName', 'Полное наименование')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.le_NM_UZ}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.tin', 'ИНН')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.tin}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.regDate', 'Дата гос. регистрации')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.reg_DATE}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.regNumber', 'Номер регистрации в реестре')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.reg_NO}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.regAuthority', 'Регистрирующий орган')}
            </Typography>
            <Typography variant="h6" component="h6">
              {null}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t(
                'legalEntity.legalForm',
                'Организационно-правовая форма (ОПФ)'
              )}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.kopf_CD} - {data[`kopf_DESC_${locale.toUpperCase()}`]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.ownership', 'Форма собственности (ФС)')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.kfs_CD} - {data[`kfs_DESC_${locale.toUpperCase()}`]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t(
                'legalEntity.activityType',
                'Код ОКЭД (Вид(ы) осуществляемой деятельности)'
              )}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.oked_CD} - {data[`oked_DESC_${locale.toUpperCase()}`]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.sooguCode', 'Код СООГУ')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.soogu_CD} - {data[`soogu_DESC_${locale.toUpperCase()}`]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.smallBiz', 'Принадлежность к субъектам малого')}
            </Typography>
            <Typography variant="h6" component="h6">
              {t(data.small_BIZ === 'Y' ? 'yes' : 'no')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.status', 'Состояние деятельности предприятия')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.valid_FLAG === 'Y'
                ? t('legalEntity.status.active', 'Действующий')
                : t('legalEntity.status.inactive', 'Не действующий')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.fund', 'Уставный фонд')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.auth_CAPITAL}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Head info */}
      {head && (
        <Grid container spacing={3.625} mb={r({ xs: 5, md: 9 })}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              {t('legalEntity.headInfo', 'Информация о руководителе')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.headName', 'Имя руководителя')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.head_NM}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.headTin', 'ИНН руководителя')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.head_TIN}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* Founders info */}
      {founders && (
        <Grid container spacing={3.625} mb={r({ xs: 5, md: 9 })}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              {t(
                'legalEntity.foundersInfo',
                'Информация об учредителях и их доле в уставном фонде'
              )}
            </Typography>
          </Grid>
          {map(foundersArray, (fndr: any) => (
            <>
              <Grid item xs={12} md={7}>
                <Typography variant="body2" color="grey.700">
                  {t('legalEntity.founderName', 'Имя учредителя')}
                </Typography>
                <Typography variant="h6" component="h6">
                  {fndr.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="body2" color="grey.700">
                  {t('legalEntity.founderPart', 'Доля')}
                </Typography>
                <Typography variant="h6" component="h6"></Typography>
              </Grid>
            </>
          ))}
        </Grid>
      )}

      {/* Contacts */}
      {contacts && (
        <Grid container spacing={3.625} mb={r({ xs: 5, md: 9 })}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h5">
              {t('legalEntity.contacts', 'Контактные данные')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.contacts.phone', 'Телефон')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.contacts.email', 'Адрес электронной почты')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.contacts.soato', 'СОАТО')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.soato_CD}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="grey.700">
              {t('legalEntity.contacts.location', 'Местонахождение')}
            </Typography>
            <Typography variant="h6" component="h6">
              {data.addr}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
