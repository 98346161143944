import { BoxProps } from '@material-ui/system';
import { ComponentType } from 'react';
import { LangSwitcherInline, LangSwitcherSelect } from './index';

interface LangSwitcherProps {
  variant?: 'inline' | 'select';
}

export const LangSwitcher: ComponentType<LangSwitcherProps & BoxProps> = ({
  variant = 'select',
  ...props
}) => {
  return (
    <>
      {variant === 'inline' && <LangSwitcherInline {...props} />}
      {variant === 'select' && <LangSwitcherSelect {...props} />}
    </>
  );
};
