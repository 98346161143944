import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MainRouteProps } from '../../common/routes';
import { Authorized } from '../authorized';
import { Header } from '../header';
import { HistoryAuthorization } from '../history/history-authorization';
import { HistoryTransferredPersonalData } from '../history/history-transferred-personal-data';
import { Layout } from '../layout';

const nav: MainRouteProps[] = [
  // {
  //   pathname: '/history',
  //   title: 'Авторизация',
  //   i18n: 'nav.history.authorization',
  //   authorized: true,
  // },
  // {
  //   pathname: '/history/transferred-personal-data',
  //   title: 'Переданные персональные данные',
  //   i18n: 'nav.help.transferredPersonalData',
  //   authorized: false,
  // },
];

interface PageHistoryProps {}

export const PageHistory: ComponentType<PageHistoryProps> = Authorized()(() => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <Layout variant="default" bgcolor="background.paper">
      <Header title={t('history', 'История')} actions={nav} />
      <Box component={'main'} role="main">
        <Container>
          <Switch>
            <Route
              path={`${url}/transferred-personal-data`}
              component={HistoryTransferredPersonalData}
              exact
            />
            <Route component={HistoryAuthorization} exact />
          </Switch>
        </Container>
      </Box>
    </Layout>
  );
});
