import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Authentication } from '../authentication';
import { Authorized } from '../authorized';
import { Header } from '../header';
import { Layout } from '../layout';

interface PageAuthenticationProps {}

export const PageAuthentication: ComponentType<PageAuthenticationProps> = Authorized()(
  () => {
    const { t } = useTranslation();

    return (
      <Layout variant="default" bgcolor="background.paper">
        <Header title={t('authentication', 'Подлинность')} component={'h1'} />
        <Box component={'main'} role="main">
          <Container>
            <Authentication />
          </Container>
        </Box>
      </Layout>
    );
  }
);
