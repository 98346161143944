import { Alert, Box, Collapse, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty, isInteger } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendReport } from '..';
import { useSnackbar } from '../../snackbar';
import useStyles from '../styles';
import { FIELD_LABELS, FIELD_TEXT } from './error-validation-scheme';

interface ErrorFormProps {
  className?: string;
  onSuccess?: () => void;
}

export const ErrorForm: ComponentType<ErrorFormProps> = ({
  className,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { snackbarOpen } = useSnackbar();
  const {
    isLoading,
    code,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  } = useSendReport();

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);

  useEffect(() => {
    if (isInteger(code) && Number(code) === 0) {
      snackbarOpen(t('success.sent', 'Сообщение отправленно'), {
        severity: 'success',
      });
      if (onSuccess) onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={clsx(classes.root, className)}
    >
      <TextField
        {...formik.getFieldProps(FIELD_TEXT)}
        onChange={(e: any) => handleFieldChange(FIELD_TEXT, e)}
        label={t(
          FIELD_LABELS[FIELD_TEXT]['labelKey'],
          FIELD_LABELS[FIELD_TEXT]['label']
        )}
        id={FIELD_TEXT}
        error={hasError(FIELD_TEXT)}
        helperText={getErrorMessage(FIELD_TEXT)}
        disabled={isLoading}
        InputProps={{
          'aria-label': FIELD_LABELS[FIELD_TEXT]['arialLabel'],
        }}
        multiline
        rows={4}
        required={true}
      />

      <Box display="flex" justifyContent="flex-end" pt={2}>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          loading={isLoading}
          disabled={!noErrors}
        >
          {t('actions.send', 'Отправить')}
        </LoadingButton>
      </Box>

      <Collapse in={isInteger(code) && Number(code) !== 0}>
        <Alert
          severity="error"
          sx={{
            mt: 3.75,
          }}
        >
          {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`)}
        </Alert>
      </Collapse>
    </form>
  );
};
