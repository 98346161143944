import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../common/locales';
import dataSource from '../../data-sources/about-resourses.json';
import { About } from '../about';
import { Header } from '../header';
import { Layout } from '../layout';

interface PageAboutProps {}

export const PageAbout: ComponentType<PageAboutProps> = () => {
  const { i18n } = useTranslation();

  const source = dataSource[i18n.language as Locale];
  const title = source['title'];
  const content = source['content'];

  return (
    <Layout variant="default" bgcolor="background.paper">
      <Header title={title} component={'h1'} />
      <Box component={'main'} role="main">
        <Container>
          <About data={content} />
        </Container>
      </Box>
    </Layout>
  );
};
