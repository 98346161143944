import { DataTableColumnsProps } from '../data-table';
import { Typography } from '@material-ui/core';
import { parseUserAgent } from '../../common/utils/user-agent-utils';

const defaultTypography = (text: string) => (
  <Typography component="div" variant="body1" color="#777777">
    {text}
  </Typography>
);

export const SESSION_COLUMNS_RESOURCE: DataTableColumnsProps[] = [
  {
    label: 'Вход с IP',
    labelKey: 'history.signViaIP',
    dataKey: 'remoteIp',
    renderItem: ({ remoteIp }) => defaultTypography(remoteIp),
  },
  {
    label: 'User Agent',
    labelKey: 'userAgent',
    dataKey: 'userAgent',
    renderItem: ({ userAgent }: any) => {
      const { browserName, osName } = parseUserAgent(userAgent);
      return defaultTypography(`${browserName}, ${osName}`);
    },
  },
  {
    label: 'Дата и время',
    labelKey: 'history.dateTime',
    dataKey: 'loginTime',
    renderItem: ({ loginTime }: any) =>
      defaultTypography(
        `${new Date(loginTime).toLocaleDateString()} ${new Date(
          loginTime
        ).toLocaleTimeString()}`
      ),
  },
];
