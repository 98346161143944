import * as dateFunctions from 'date-fns';
import { get } from 'lodash';

export const dateParse = (
  originalDate: any,
  format = `dd.MM.yyyy`,
  split?: string
) => {
  if (split && split.length === originalDate.length) {
    const dirtyDate = dateFunctions.parse(originalDate, split, new Date());
    return `${dateFunctions.format(dirtyDate, format)}`;
  }

  return `${dateFunctions.format(new Date(originalDate), format)}`;
};

export const numberWithSpaces = (x: number): string => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const expiredDate = (date: Date): boolean =>
  dateFunctions.isAfter(date, new Date());

export const getUserName = (
  first: string,
  middle: string,
  last: string,
  format = `Last F. L.`
) => {
  let result = '';
  const F = first && first.charAt(0).toUpperCase();
  const M = middle && middle.charAt(0).toUpperCase();
  // const L = last && last.charAt(0).toUpperCase();

  switch (format) {
    default:
      result = `${last && last} ${F && F}. ${M && M}.`;
  }
  return result;
};

export const encodePinfl = (pinfl: string) =>
  window.btoa(pinfl + pinfl.slice(0, 4) + pinfl.slice(pinfl.length - 4));

export const displayTime = (seconds: any) => {
  const format = (val: any) => `0${Math.floor(val)}`.slice(-2);
  const minutes = (seconds % 3600) / 60;
  return [minutes, seconds % 60].map(format).join(':');
};

export const rplSpace = (string: string) => (string || '').replace(/\s/g, '');

// Return the formik error text
export const getFormikError = (formik: any, key: string) => {
  const error = get(formik.errors, key);
  if (typeof error === 'string') {
    const message = error;
    return get(formik.touched, key) && error && message;
  }
  return '';
};
