import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MainRouteProps } from '../../common/routes';
import { Authorized } from '../authorized';
import { Header } from '../header';
import { Layout } from '../layout';
import { LegalEntityCabinet } from '../legal-entity/legal-entity-cabinet';
import { LegalEntityDirector } from '../legal-entity/legal-entity-director';

export const PageLegalEntityCabinet: ComponentType = Authorized()(() => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const nav: MainRouteProps[] = [
    {
      pathname: '/legal-entity/cabinet',
      title: 'Общая информация',
      i18n: 'nav.entityLegal.info',
    },
    {
      pathname: '/legal-entity/cabinet/director',
      title: 'Директор',
      i18n: 'nav.entityLegal.director',
    },
  ];

  return (
    <Layout variant="default" bgcolor="background.paper">
      <Header
        title={t('legalEntity.cabinet.title', 'Кабинет Юридического лица')}
        elevation={1}
        actions={nav}
      />
      <Box component={'main'} role="main">
        <Container>
          <Switch>
            <Route
              path={`${url}/director`}
              exact
              component={LegalEntityDirector}
            />
            <Route component={LegalEntityCabinet} />
          </Switch>
        </Container>
      </Box>
    </Layout>
  );
});
