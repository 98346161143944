import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../header';
import { Layout } from '../layout';
import { UserAgreement } from '../user';

interface PageUserAgreementProps {}

export const PageUserAgreement: ComponentType<PageUserAgreementProps> = () => {
  const { t } = useTranslation();
  return (
    <Layout variant="default" bgcolor="background.paper">
      <Header title={t('user.agreement', 'Пользователькое соглашение')} />
      <Box component={'main'} role="main">
        <Container>
          <UserAgreement />
        </Container>
      </Box>
    </Layout>
  );
};
