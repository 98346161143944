import * as yup from 'yup';

export const FIELD_PASSPORT_ID = 'passportId';
export const FIELD_PASSWORD = 'password';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_PASSPORT_ID]: {
    label: 'ПИНФЛ',
    labelKey: 'signIn.passportId',
    ariaLabel: 'Your passport id',
  },
  [FIELD_PASSWORD]: {
    label: 'Пароль',
    labelKey: 'signIn.password',
    ariaLabel: 'Your password',
  },
};

export const SignInValidationSchema = yup.object().shape({
  [FIELD_PASSPORT_ID]: yup
    .string()
    .transform((value) => value.replace(/\s/g, ''))
    .length(14, 'error.passport.pin.length')
    .required('error.required')
    .trim(),
  [FIELD_PASSWORD]: yup
    .string()
    .min(3, 'error.minLength')
    .required('error.required')
    .trim(),
});
