import { get } from 'lodash';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../common/store';
import { getUserInfo } from '../../thunks/auth-thunks';
import { getLERoles, updateLERoles } from '../../thunks/legal-entity-thunks';

export interface RolesProps {
  le_tin: number;
  le_name: string;
  is_main?: boolean;
}

type RoleType = string | number | null;

export const useUser = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const legalEntity = useSelector((state: State) => state.legalEntity);
  const { passportData: user } = useSelector((state: State) => state.profile);
  const [listRole, setListRole] = useState<any[]>([]);
  const [role, setRole] = useState<string | number | null>(null);

  // full name for individual role
  const le_name = [
    get(user, 'surname_latin') || get(user, 'surname_engl'),
    get(user, 'name_latin') || get(user, 'name_engl'),
    get(user, 'patronym_latin' || get(user, 'patronym_engl')),
  ]
    .filter(Boolean)
    .join(' ');
  // create individual role
  const individualRole: RolesProps[] = [
    {
      le_tin: 0,
      le_name,
      is_main: false,
    },
  ];

  // demo
  const isLoading = false;

  // radio select role
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole((event.target as HTMLInputElement).value);
  };

  // send update role
  const handleUpdate = (role: RoleType) => {
    dispatch(updateLERoles(role as string));
  };

  // manual send role
  const handleSubmit = () => {
    handleUpdate(role as string);
  };

  // add individual role to list role
  useLayoutEffect(() => {
    dispatch(getUserInfo());
    dispatch(getLERoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, i18n.language]);

  // set individual role
  useEffect(() => {
    // setListRole([...individualRole]);
    // setListRole((prev) => [...prev, ...individualRole]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // update list roles width legak entity roles

  useEffect(() => {
    if (legalEntity.roles !== null) {
      setListRole([...individualRole, ...legalEntity.roles]);
    } else {
      setListRole([...individualRole]);
      // handleUpdate(individualRole[0]['le_tin']);
    }
    if (legalEntity.roles?.length === 0) {
      handleUpdate(individualRole[0]['le_tin']);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legalEntity.roles, individualRole[0]['le_name']]);

  return {
    isLoading,
    listRole,
    role,
    handleSelect,
    handleUpdate,
    handleSubmit,
  };
};
