import { ReactComponent as A11yIcon } from './assets/icons/a11y.svg';
import { ReactComponent as AriaIcon } from './assets/icons/aria.svg';
import { ReactComponent as ArrowBottomIcon } from './assets/icons/arrow-bottom.svg';
import { ReactComponent as ArrowLeftIcon } from './assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from './assets/icons/arrow-right.svg';
import { ReactComponent as ArrowIcon } from './assets/icons/arrow.svg';
import { ReactComponent as CaseIcon } from './assets/icons/case.svg';
import { ReactComponent as CheckCircleOutlineIcon } from './assets/icons/check-circle-outline.svg';
import { ReactComponent as CheckListIcon } from './assets/icons/check-list.svg';
import { ReactComponent as CheckIcon } from './assets/icons/check.svg';
import { ReactComponent as CheckboxCheckedIcon } from './assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckboxIcon } from './assets/icons/checkbox.svg';
import { ReactComponent as ChevronLeftIcon } from './assets/icons/chevron-left.svg';
import { ReactComponent as ChildIcon } from './assets/icons/child.svg';
import { ReactComponent as CloseIcon } from './assets/icons/close.svg';
import { ReactComponent as DocumentIcon } from './assets/icons/document.svg';
import { ReactComponent as DownloadFileIcon } from './assets/icons/download-file.svg';
import { ReactComponent as DropdownIcon } from './assets/icons/dropdown.svg';
import { ReactComponent as EditIcon } from './assets/icons/edit.svg';
import { ReactComponent as ExitIcon } from './assets/icons/exit.svg';
import { ReactComponent as EyeHideIcon } from './assets/icons/eye-hide.svg';
import { ReactComponent as EyeIcon } from './assets/icons/eye.svg';
import { ReactComponent as FileIcon } from './assets/icons/file.svg';
import { ReactComponent as HelpOutlineIcon } from './assets/icons/help-circle-outline.svg';
import { ReactComponent as HelpIcon } from './assets/icons/help.svg';
import { ReactComponent as HomeIcon } from './assets/icons/home.svg';
import { ReactComponent as InfoOutlineIcon } from './assets/icons/info-circle-outline.svg';
import { ReactComponent as LaunchIcon } from './assets/icons/launch.svg';
import { ReactComponent as MailIcon } from './assets/icons/mail.svg';
import { ReactComponent as OpenInNewIcon } from './assets/icons/open-in-new.svg';
import { ReactComponent as PhoneIcon } from './assets/icons/phone.svg';
import { ReactComponent as PhotoIcon } from './assets/icons/photo.svg';
import { ReactComponent as PlayIcon } from './assets/icons/play.svg';
import { ReactComponent as PlusIcon } from './assets/icons/plus.svg';
import { ReactComponent as RadioButtonCheckedIcon } from './assets/icons/radio-button-checked.svg';
import { ReactComponent as RadioButtonUncheckedIcon } from './assets/icons/radio-button-unchecked.svg';
import { ReactComponent as RefreshIcon } from './assets/icons/refresh.svg';
import { ReactComponent as SearchIcon } from './assets/icons/search.svg';
import { ReactComponent as TextIcon } from './assets/icons/text.svg';
import { ReactComponent as TimeIcon } from './assets/icons/time.svg';
import { ReactComponent as UserIcon } from './assets/icons/user.svg';
import { ReactComponent as VaccinatedIcon } from './assets/icons/vaccinated.svg';
import { ReactComponent as VideoIcon } from './assets/icons/video.svg';
import { ReactComponent as WarningIcon } from './assets/icons/warning.svg';
import { ReactComponent as CalendarIcon } from './assets/icons/сalendar.svg';

export {
  A11yIcon,
  AriaIcon,
  ArrowBottomIcon,
  ArrowIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  CaseIcon,
  CheckCircleOutlineIcon,
  CheckIcon,
  CheckListIcon,
  CheckboxCheckedIcon,
  CheckboxIcon,
  ChevronLeftIcon,
  ChildIcon,
  CloseIcon,
  DocumentIcon,
  DownloadFileIcon,
  DropdownIcon,
  EditIcon,
  ExitIcon,
  EyeHideIcon,
  EyeIcon,
  FileIcon,
  HelpIcon,
  HelpOutlineIcon,
  HomeIcon,
  InfoOutlineIcon,
  LaunchIcon,
  MailIcon,
  OpenInNewIcon,
  PhoneIcon,
  PhotoIcon,
  PlayIcon,
  PlusIcon,
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
  RefreshIcon,
  SearchIcon,
  TextIcon,
  TimeIcon,
  UserIcon,
  VaccinatedIcon,
  VideoIcon,
  WarningIcon,
};

export const IconLabels: Record<
  string,
  React.FC<React.SVGProps<SVGSVGElement>>
> = {
  Check: CheckIcon,
  Aria: AriaIcon,
  File: FileIcon,
  Text: TextIcon,
  Vaccinated: VaccinatedIcon,
  Calendar: CalendarIcon,
  A11y: A11yIcon,
  ArrowBottom: ArrowBottomIcon,
  Arrow: ArrowIcon,
  ArrowLeft: ArrowLeftIcon,
  ArrowRight: ArrowRightIcon,
  Case: CaseIcon,
  CheckboxChecked: CheckboxCheckedIcon,
  Checkbox: CheckboxIcon,
  CheckCircleOutline: CheckCircleOutlineIcon,
  CheckList: CheckListIcon,
  ChevronLeft: ChevronLeftIcon,
  Close: CloseIcon,
  Document: DocumentIcon,
  DownloadFile: DownloadFileIcon,
  Dropdown: DropdownIcon,
  Edit: EditIcon,
  Exit: ExitIcon,
  EyeHide: EyeHideIcon,
  Eye: EyeIcon,
  Help: HelpIcon,
  HelpOutline: HelpOutlineIcon,
  Home: HomeIcon,
  InfoOutline: InfoOutlineIcon,
  Launch: LaunchIcon,
  Mail: MailIcon,
  OpenInNew: OpenInNewIcon,
  Phone: PhoneIcon,
  Photo: PhotoIcon,
  Plus: PlusIcon,
  Play: PlayIcon,
  RadioButtonChecked: RadioButtonCheckedIcon,
  RadioButtonUnchecked: RadioButtonUncheckedIcon,
  Refresh: RefreshIcon,
  Search: SearchIcon,
  Time: TimeIcon,
  User: UserIcon,
  Video: VideoIcon,
  Warning: WarningIcon,
};
