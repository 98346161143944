import { alpha, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'developer',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    img: {
      display: 'block',
      flex: '0 0 76px',
      height: 76,
      width: 76,
    },
    text: {
      paddingLeft: theme.spacing(2),
      flex: '0 0 calc(100%  - 76px)',
      width: 'calc(100%  - 76px)',
    },
    item: {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: theme.spacing(4),
      color: 'inherit',
      // color: alpha(theme.palette.primary.contrastText, 0.4),
      textDecoration: 'none',
      height: 'auto',
      transition: theme.transitions.create(['color']),
      '&:first-child': {
        marginLeft: '0',
      },
      '&:hover, &:focus:hover': {
        color: alpha(theme.palette.primary.contrastText, 1),
      },
    },
    mitc: {},
  }),
  options
);

export default useStyles;
