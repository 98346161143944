import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'e-sign',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    body: {},
    text: {
      '& > p': {
        marginBottom: theme.spacing(1),
        '&:last-child, &$last': {
          marginBottom: 0,
        },
      },
    },
    browse: {},
    list: {},
    token: {
      marginBottom: theme.spacing(3),
    },
    notFound: {},
    actions: {
      paddingTop: theme.spacing(3),
    },
    btn: {},
    last: {},
  }),
  options
);

export default useStyles;
