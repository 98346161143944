import { Button, Typography } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

interface PasswordRecoverySuccessProps {
  onClose?: () => void;
}

export const PasswordRecoverySuccess: ComponentType<PasswordRecoverySuccessProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="div" variant="h4" mb={2.5}>
        {t('password.sent', 'Пароль отправлен')}
      </Typography>
      <Typography component="div" variant="body2" color="textSecondary" mb={5}>
        {t('password.sent.full', 'Новый пароль отправлен на ваш телефон')}
      </Typography>
      <Button variant="contained" color="primary" onClick={onClose && onClose}>
        {t('action.close', 'Закрыть')}
      </Button>
    </>
  );
};
