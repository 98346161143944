import * as yup from 'yup';
import 'yup-phone';

export const FIELD_PHONE = 'phone';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_PHONE]: {
    label: 'Телефон',
    labelKey: 'signUp.phone',
    ariaLabel: 'Your phone number',
  },
};

export const SignInValidationSchema = yup.object().shape({
  [FIELD_PHONE]: yup
    .string()
    .transform((value) => value.replace(/\s/g, ''))
    .min(13, 'error.phone.notValid')
    .max(13, 'error.phone.notValid')
    // .phone('UZ', false, `error.phone.notValid`) 33 55 не работают
    .required('error.required')
    .trim(),
});
