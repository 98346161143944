import { SvgIcon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MailIcon } from '../assets/mail.svg';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg';
import useStyles from './styles';

interface HelpWidgetProps {
  className?: string;
  variant?: 'small' | 'large';
}

const HelpWidget: ComponentType<HelpWidgetProps> = ({
  className,
  variant = 'small',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Typography
      className={clsx(classes.root, classes[variant], className)}
      variant={variant === 'small' ? 'body1' : 'body2'}
      component="div"
    >
      <div className={classes.question}>
        <div className={classes.title}>
          {t('help.widget.title', 'При возникновении вопросов')}
        </div>
        {/* <div className={classes.item}>
          <SvgIcon component={MailIcon} className={classes.icon} />
          <a
            className={clsx(classes.link)}
            href="mailto:iduzsupport@egov.uz"
            title={t('help.widget.mail.title', 'Написать письмо')}
          >
            iduzsupport@egov.uz
          </a>
        </div> */}
        <div className={classes.item}>
          <SvgIcon component={PhoneIcon} className={classes.icon} />
          <a
            className={clsx(classes.link)}
            href="tel:555013646"
            title={t('help.widget.phone.title', 'Позвонить')}
          >
            1242
          </a>
        </div>
      </div>
      {variant === 'large' && (
        <div className={classes.body}>
          <div className={classes.text}>
            {t(
              'help.widget.error',
              'Нажмите "Ctrl + Enter" чтобы сообщить об ошибке'
            )}
          </div>
          {/* <a
            className={clsx(classes.link)}
            href="https://id.gov.uz/"
            title={t('help.widget.old', 'Старая версия системы')}
          >
            {t('help.widget.old', 'Старая версия системы')}
          </a> */}
        </div>
      )}
    </Typography>
  );
};

export { HelpWidget, MailIcon, PhoneIcon };
