import { Grid, TextField, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { differenceInMilliseconds } from 'date-fns';
import { get, isEmpty, isInteger } from 'lodash';
import { ComponentType } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { PhoneConfirmNew } from '../../phone';
import {
  FIELD_LABELS,
  FIELD_MASKS,
  FIELD_PHONE,
  FIELD_PINFL,
} from './password-recovery-schema';

import { MASK_PHONE } from '../../../constants/masks-constants';
import useStyles from './styles';
import { usePasswordRecovery } from './usePasswordRecovery';

interface PasswordRecoveryFormProps {
  className?: string;
}

export const PasswordRecoveryForm: ComponentType<PasswordRecoveryFormProps> = ({
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    codeSms,
    sendSms,
    checkSms,
    formik,

    handleSendSms,
    handleSmsChange,
    handleFieldChange,
    hasError,
    getErrorMessage,
  } = usePasswordRecovery();

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);
  const smsErrors =
    isInteger(checkSms.code) && Number(checkSms.code) !== 0
      ? t(`error.api.${checkSms?.code}`, `Ошибка с кодом ${checkSms?.code}`)
      : null;

  const phone = get(sendSms, ['data', 'phone']);
  const expiredDate = get(sendSms, ['data', 'expiredDate']);
  const delay = expiredDate
    ? differenceInMilliseconds(new Date(expiredDate), new Date()) / 1000
    : 0;

  const isLoading = sendSms.isLoading || checkSms.isLoading;

  // const renderField = (field: string) => (
  //   <InputMask
  //     mask={FIELD_MASKS[field]}
  //     {...formik.getFieldProps(field)}
  //     onChange={(e: any) => handleFieldChange(field, e)}
  //     maskPlaceholder={null}
  //     disabled={isLoading}
  //   >
  //     <TextField
  //       label={t(FIELD_LABELS[field]['labelKey'], FIELD_LABELS[field]['label'])}
  //       id={field}
  //       error={hasError(field)}
  //       helperText={getErrorMessage(field)}
  //       InputProps={{
  //         'aria-label': FIELD_LABELS[field]['arialLabel'],
  //         autoComplete: 'off',
  //         disabled: isLoading,
  //       }}
  //     />
  //   </InputMask>
  // );

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={clsx(classes.root, className)}
      noValidate
    >
      {sendSms.code === null && (
        <>
          <Typography component="div" variant="h4" mb={2.5}>
            {t('password.recovery', 'Восстановление логина и пароля')}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            color="textSecondary"
            mb={1}
          >
            {t(
              'signUp.phone.textpasswordnew',
              '* Внимание! Для восстановления логина и пароля используйте номер телефона, зарегистрированный на Ваше имя! После успешной проверки на указанный телефон будет выслано СМС с новым логином и паролем.'
            )}
          </Typography>
          <Grid container direction="column" mb={2}>
            <Grid item>
              <InputMask
                mask={FIELD_MASKS[FIELD_PINFL]}
                {...formik.getFieldProps(FIELD_PINFL)}
                onChange={(e: any) => handleFieldChange(FIELD_PINFL, e)}
                maskPlaceholder={null}
                disabled={isLoading}
              >
                <TextField
                  label={t(
                    FIELD_LABELS[FIELD_PINFL]['labelKey'],
                    FIELD_LABELS[FIELD_PINFL]['label']
                  )}
                  id={FIELD_PINFL}
                  error={hasError(FIELD_PINFL)}
                  helperText={getErrorMessage(FIELD_PINFL)}
                  InputProps={{
                    'aria-label': FIELD_LABELS[FIELD_PINFL]['arialLabel'],
                    autoComplete: 'off',
                    disabled: isLoading,
                  }}
                />
              </InputMask>
            </Grid>
            <Grid item>
              {/* <TextField
                {...formik.getFieldProps(FIELD_PHONE)}
                onChange={(e: any) => handleFieldChange(FIELD_PHONE, e)}
                disabled={isLoading}
                label={t(
                  FIELD_LABELS[FIELD_PHONE]['labelKey'],
                  FIELD_LABELS[FIELD_PHONE]['label']
                )}
                id={FIELD_PHONE}
                error={hasError(FIELD_PHONE)}
                helperText={getErrorMessage(FIELD_PHONE)}
                InputProps={{
                  'aria-label': FIELD_LABELS[FIELD_PHONE]['arialLabel'],
                  autoComplete: 'off',
                  disabled: isLoading,
                }}
              /> */}
              <InputMask
                mask={MASK_PHONE}
                maskPlaceholder={null}
                {...formik.getFieldProps(FIELD_PHONE)}
                onChange={(e: any) => {
                  handleFieldChange(FIELD_PHONE, e);
                }}
              >
                <TextField
                  label={t(
                    FIELD_LABELS[FIELD_PHONE]['labelKey'],
                    FIELD_LABELS[FIELD_PHONE]['label']
                  )}
                  id={`${FIELD_PHONE}`}
                  error={hasError(FIELD_PHONE)}
                  helperText={getErrorMessage(FIELD_PHONE)}
                  required
                  InputProps={{
                    'aria-label': FIELD_LABELS[FIELD_PHONE]['ariaLabel'],
                  }}
                />
              </InputMask>
            </Grid>
          </Grid>

          <LoadingButton
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={!noErrors}
            type="submit"
          >
            {t('action.next', 'Далее')}
          </LoadingButton>
        </>
      )}
      {isInteger(sendSms.code) &&
        (Number(sendSms.code) === 0 || Number(sendSms.code) === 58) && (
          <>
            <Typography component="div" variant="h4" mb={2.5}>
              {t('password.phone.smsCode', 'SMS код')}
            </Typography>
            <Typography
              component="div"
              variant="body2"
              color="textSecondary"
              mb={2}
            >
              <Trans
                i18nKey="password.phone.sms"
                defaults="На указаный в личном кабинете телефон <strong>{{phone}}</strong> отправлен код
            для продолжения восстановления"
                values={{ phone: phone }}
              />
            </Typography>

            <PhoneConfirmNew
              isLoading={isLoading}
              error={smsErrors}
              delay={delay}
              onChange={handleSmsChange}
              onRetry={handleSendSms}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              loading={isLoading}
              disabled={!noErrors || !codeSms}
              type="submit"
            >
              {t('action.recover', 'Восстановить')}
            </LoadingButton>
          </>
        )}
    </form>
  );
};
