import { Button } from '@material-ui/core';
import { Box, BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Locale } from '../../common/locales';
import dataSource from '../../data-sources/connected-resources.json';
import { useResponsive } from '../theme/useResponsive';
import { ConnectedResourcesList } from './connected-resources-list/connected-resources-list';
import useStyles from './styles';

interface ConnectedResourcesProps {
  className?: any;
  variant?: 'default' | 'block';
}

export const ConnectedResources: ComponentType<
  ConnectedResourcesProps & BoxProps
> = ({ className, variant = 'default', ...props }) => {
  const classes = useStyles();
  const r = useResponsive();
  const { t, i18n } = useTranslation();
  const curentLang = i18n.language as Locale;
  const content: any = dataSource[curentLang] || dataSource.uz;

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <ConnectedResourcesList className={classes.list} data={content} />
      {variant === 'block' && (
        <Box className={classes.actions}>
          <Button
            variant="outlined"
            className={classes.btn}
            fullWidth={r({ xs: true, md: false })}
            size={r({ xs: 'small', md: 'medium' })}
            component={Link}
            to={`/${i18n.language}/connected-resources`}
          >
            {t(
              `${r({
                xs: 'connectedResources.all',
                md: 'connectedResources.more',
              })}`,
              'Посмостреть все подключеные ресурсы'
            )}
          </Button>
        </Box>
      )}
    </Box>
  );
};
