import { Typography } from '@material-ui/core';
import { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

export const AskRedirectToSignIn: ComponentType = () => {
  const to = `/${i18n.language}}/login`;
  return (
    <div>
      <Typography>This component requires a user to be signed in.</Typography>
      <Link to={to}>Sign in</Link>
    </div>
  );
};
