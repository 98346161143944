import * as yup from 'yup';

const SignInLoginValidationSchema = yup.object().shape({
  login: yup
    .string()
    .min(3, 'error.minLength3')
    .matches(/^[a-zA-Z0-9_.@#-]+$/, 'error.login.rules')
    .required('error.required')
    .trim(),
  password: yup
    .string()
    .min(3, 'error.minLength')
    .required('error.required')
    .trim(),
});

export default SignInLoginValidationSchema;
