import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'user-personal-data',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    row: {
      margin: theme.spacing(0, -1),
      width: `calc(100% + ${theme.spacing(2)})`,
    },
    col: {
      padding: theme.spacing(0, 1, 3),
    },
    title: {
      lineHeight: 1.2,
    },
    text: {
      display: 'flex',
      lineHeight: 1.5,
    },
    btn: {},
    btnLink: {
      padding: theme.spacing(0, 1),
      marginLeft: theme.spacing(0.5),
      height: 24,
    },
    btnIcon: {
      userSelect: 'none',
      width: '1em',
      height: '1em',
      display: 'inline-block',
      fill: 'currentColor',
      flexShrink: 0,
      fontSize: '1.5rem',
      transition: theme.transitions.create('fill', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
      }),
    },
    view: {
      $col: {},
    },
    edit: {
      $col: {},
    },
  }),
  options
);

export default useStyles;
