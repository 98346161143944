import { Divider, Link, SvgIcon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { OpenInNewIcon } from '../../../common/icons';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

interface ConnectedResourcesItemProps {
  className?: any;
  data: any;
}

export const ConnectedResourcesItem: ComponentType<ConnectedResourcesItemProps> = ({
  className,
  data,
}) => {
  const classes = useStyles();
  const img = data?.img ? data?.img : '/assets/img/emlemOfUzbekistan.svg';
  const r = useResponsive();

  return (
    <>
      <Link
        className={clsx(classes.root, className)}
        rel="noopener noreferrer"
        target="_blank"
        href={data?.link}
      >
        {r({
          xs: false,
          md: true,
        }) && (
          <div className={classes.media}>
            <img className={classes.img} src={img} alt={data?.title} />
          </div>
        )}
        <div className={classes.body}>
          <Typography
            variant="body1"
            component="span"
            className={classes.title}
          >
            {data?.title}
          </Typography>
          <SvgIcon component={OpenInNewIcon} className={classes.icon} />
        </div>
      </Link>
      <Divider className={classes.divider} />
    </>
  );
};
