import { get } from 'lodash';
import { Dispatch } from 'redux';
import { getISOLocale } from '../common/locales';
import { store } from '../common/store';
import { api, HEADERS } from '../common/utils/fetch-utils';
import { dateParse, encodePinfl, rplSpace } from '../common/utils/format-utils';
import { authActions } from '../slices/auth-slice';
import { profileActions } from '../slices/profile-slice';
import { clearProfile } from './profile-thunks';
import { oAuthGenerated } from './sso-thunks';
import { clearVaccination } from './vaccination-thunks';

export const AUTH_TOKEN_KEY = 'AUTH_TOKEN';
export const PINFL_KEY = 'PINFL';
export const CERT_KEY = 'CERT';

const persistAuthToken = (authToken: Record<string, any>) =>
  window.localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(authToken));
const removeAuthToken = () => window.localStorage.removeItem(AUTH_TOKEN_KEY);

const _getSmsAskToken = () => store.getState().auth.askSms.token;
const _getSmsCheckToken = () => store.getState().auth.sms.token;

export const restoreAuthToken = () => {
  const authToken = window.localStorage.getItem(AUTH_TOKEN_KEY);
  if (!authToken) {
    return null;
  }
  try {
    return JSON.parse(authToken);
  } catch (error) {
    return null;
  }
};

const persistPinfl = (pinfl: string) =>
  window.localStorage.setItem(PINFL_KEY, pinfl);
const removePinfl = () => window.localStorage.removeItem(PINFL_KEY);

const restorePinfl = () => {
  const authToken = window.localStorage.getItem(PINFL_KEY);
  if (!authToken) {
    return null;
  }
  try {
    return JSON.parse(authToken);
  } catch (error) {
    return null;
  }
};

export const refreshToken = () => async (dispatch: Dispatch) => {
  console.log(localStorage.getItem(PINFL_KEY), dispatch);
};

export const signIn = (data: any) => async (dispatch: Dispatch) => {
  dispatch(authActions.setSignInRequest());
  try {
    api.removeHeader(HEADERS.AUTHORIZATION); // #TODO найти где добавляется токен при авторизации
    const { status, data: responseData } = await api.post(
      'secure/signIn',
      data
    );
    if (status.code === 0) {
      dispatch(afterAuth({ ...status, ...responseData }) as any);
    } else {
      dispatch(authActions.setSignInFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setSignInFailure({ code: -1, message: 'error' }));
  }
};

export const clearSignInStates = () => async (dispatch: any) => {
  dispatch(authActions.setSignInDefault());
};

export const signUp = (data: any) => async (dispatch: any) => {
  dispatch(authActions.setSignUpRequest());
  try {
    const { status, data: responseData } = await api.post('secure/signUp', {
      ...data,
      token: _getSmsCheckToken(),
    });
    if (status.code === 0) {
      dispatch(authActions.setSignUpSuccess(status));
      dispatch(afterAuth({ ...status, ...responseData }) as any);
    } else {
      dispatch(authActions.setSignUpFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setSignUpFailure({ code: -1, message: 'error' }));
  }
};

export const clearSignUpStates = () => async (dispatch: any) => {
  dispatch(authActions.setSignUpDefault());
};

export const afterAuth = (data: any) => async (dispatch: Dispatch) => {
  api.addHeader(HEADERS.AUTHORIZATION, `${data.tokenType}${data.accessToken}`);
  api.addHeader(HEADERS.ACCEPT_LANGUAGE, getISOLocale());
  removeAuthToken();
  removePinfl();
  persistAuthToken(data.accessToken);
  persistPinfl(data.pinfl);
  await getUserInfo()(dispatch);
  dispatch(authActions.setSignInSuccess(data));
  const ssoScope = store.getState().sso.scope;
  const ssoUuid = store.getState().sso.uuid;
  const ssoRole = store.getState().sso.role;

  // if (ssoScope && ssoUuid && role !== null) {
  if (ssoScope && ssoUuid && ssoRole) {
    await oAuthGenerated({ uuid: ssoUuid, scope: ssoScope })(dispatch);
  }
};

export const getUserInfo = () => async (dispatch: Dispatch) => {
  dispatch(authActions.setRequiresPreviousSignInCheck(false));
  dispatch(authActions.getInfoRequest());
  try {
    const userData = restoreAuthToken();
    if (userData) {
      const encodedPinfl = encodePinfl(String(restorePinfl()));
      api.addHeader(HEADERS.AUTHORIZATION, `Bearer ${restoreAuthToken()}`);
      api.addHeader(HEADERS.ACCEPT_LANGUAGE, getISOLocale());
      const { status, data } = await api.post('secure/getUser', {
        pinfl: encodedPinfl,
      });
      if (status.code === 0) {
        dispatch(profileActions.updateProfileInfo(data));
        dispatch(authActions.getInfoSuccess({ data, status }));

        if (get(data, 'pinfl', false))
          dispatch(authActions.setPinflSuccess({ code: null, message: null }));
        if (get(data, 'phone', false))
          dispatch(authActions.setPhoneSuccess({ code: null, message: null }));
      }
    } else {
      dispatch(authActions.getInfoFailure());
      await userLogOut()(dispatch); // TODO remove on commit
    }
  } catch (error: any) {
    console.log(error);
    if (error.code === 401) {
      dispatch(authActions.getInfoFailure());
      await userLogOut()(dispatch);
    }
    dispatch(authActions.getInfoFailure());
  }
};

export const userLogOut = () => async (dispatch: Dispatch) => {
  api.removeHeader(HEADERS.AUTHORIZATION); // #TODO найти где добавляется токен при авторизации 2
  try {
    dispatch(clearProfile() as any);
    dispatch(clearVaccination() as any);
    dispatch(authActions.logOut());
    removeAuthToken();
    removePinfl();
  } catch (error) {
    console.error(error);
  }
};

export const requestSms =
  (data: {
    email: string;
    passport: string;
    phone: string;
    pinfl: string;
    birthDate: Date | null;
  }) =>
  async (dispatch: any) => {
    dispatch(authActions.setAskSmsRequest());

    try {
      const { status, data: responseData } = await api.post('secure/sms/ask', {
        pinfl: rplSpace(data.pinfl),
        birthDate: data.birthDate
          ? dateParse(data.birthDate, 'yyyy-MM-dd')
          : null,
        phoneNumber: rplSpace(data.phone),
        email: data.email,
        passSeriaNumber: rplSpace(data.passport),
      });

      if (status.code === 0) {
        dispatch(authActions.setAskSmsSuccess({ ...status, ...responseData }));
      } else {
        dispatch(authActions.setAskSmsFailure(status));
      }
    } catch (error) {
      console.error(error);
      dispatch(authActions.setAskSmsFailure({ code: -1, message: 'error' }));
    }
  };
export const clearAskSmsSates = () => async (dispatch: any) => {
  dispatch(authActions.setAskSmsDefault());
};

export const checkPinfl = (pinfl: string) => async (dispatch: any) => {
  dispatch(authActions.setPinflRequest());
  try {
    if (pinfl.length === 14 && !pinfl.includes(' ')) {
      const { status } = await api.post('secure/check/pinfl', { pinfl });
      if (status.code === 101 || status.code === 206) {
        dispatch(
          authActions.setPinflSuccess({ code: 0, message: status.message })
        );
      } else {
        dispatch(authActions.setPinflFailure(status));
      }
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setPinflFailure({ code: -1, message: 'error' }));
  }
};
export const clearPinflStates = () => async (dispatch: any) => {
  dispatch(authActions.setPinflDefault());
};

export const checkPhone = (phone: string) => async (dispatch: any) => {
  dispatch(authActions.setPhoneRequest());

  try {
    if (rplSpace(phone).length === 13 && !rplSpace(phone).includes(' ')) {
      const { status } = await api.post('secure/check/phone', {
        phone: rplSpace(phone),
      });
      if (status.code === 0) {
        dispatch(authActions.setPhoneSuccess(status));
      } else {
        dispatch(authActions.setPhoneFailure(status));
      }
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setPhoneFailure({ code: -1, message: 'error' }));
  }
};
export const clearPhoneStates = () => async (dispatch: any) => {
  dispatch(authActions.setPhoneDefault());
};

export const checkSms = (smsCode: number) => async (dispatch: any) => {
  dispatch(authActions.setSmsRequest());
  try {
    const { status, data: responseData } = await api.post('secure/sms/check', {
      smsCode,
      token: _getSmsAskToken(),
    });
    if (status.code === 0) {
      dispatch(authActions.setSmsSuccess({ ...status, ...responseData }));
    } else {
      dispatch(authActions.setSmsFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setSmsFailure({ code: -1, message: 'error' }));
  }
};

export const clearSmsStates = () => async (dispatch: any) => {
  dispatch(authActions.setSmsDefault());
};

export const getUUID = () => async (dispatch: Dispatch) => {
  dispatch(authActions.getUuidRequest());
  try {
    const result = await api.get(`secure/getUUID`);
    dispatch(authActions.getUuidSuccess(result.data));
  } catch (error) {
    console.error(error);
    dispatch(authActions.getUuidFailure(error));
  }
};

export const signInESP = (data: string) => async (dispatch: Dispatch) => {
  try {
    const { data: responseData, status } = await api.post('secure/signIn/esp', {
      data,
    });
    if (status.code === 0) {
      dispatch(afterAuth({ ...responseData, message: status.message }) as any);
    } else {
      dispatch(authActions.setSignInFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setSignInFailure({ code: -1, message: 'error' }));
  }
};

export const signInESPChildren =
  (data: string) => async (dispatch: Dispatch) => {
    try {
      const _data = await fetch('https://id.egov.uz/frontend/timestamp/pkcs7', {
        method: 'POST',
        headers: {
          Host: 'id.egov.uz',
          'X-Real-IP': '1.2.3.4',
          'X-Real-Host': '1.2.3.4',
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify(data),
      });
      const result = _data.json();
      console.log(result);
      // const { data: responseData, status } = await api.post(
      //   'http://172.16.30.243:59091/frontend/timestamp/pkcs7',
      //   {
      //     data,
      //   }
      // );
      // if (status.code === 0) {
      //   dispatch(
      //     afterAuth({ ...responseData, message: status.message }) as any
      //   );
      // } else {
      //   dispatch(authActions.setSignInFailure(status));
      // }
    } catch (error) {
      console.error(error);
      dispatch(authActions.setSignInFailure({ code: -1, message: 'error' }));
    }
  };

//Sign In Phone
export const confirmSignInPhone = (data: any) => async (dispatch: any) => {
  dispatch(authActions.setSignInPhoneRequest());
  try {
    // api.addHeader(HEADERS.AUTHORIZATION, `Bearer ${restoreAuthToken()}`);
    const { status, data: responseData } = await api.post(
      'secure/generateCodeForSigInFromMobileId',
      data
    );
    if (status.code === 0) {
      dispatch(authActions.setSignInPhoneSuccess({ status, ...responseData }));
    } else {
      dispatch(authActions.setSignInPhoneFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setSignInPhoneFailure({ code: -1, message: 'error' }));
  }
};
export const clearSignInSmsStates = () => async (dispatch: any) => {
  dispatch(authActions.setSignInPhoneDefault());
};

export const signInPhone = (data: any) => async (dispatch: Dispatch) => {
  dispatch(authActions.setSignInPhoneRequest());
  try {
    const { status, data: responseData } = await api.post(
      'secure/signInUseMobileId',
      data
    );
    if (status.code === 0) {
      dispatch(afterAuth({ ...status, ...responseData }) as any);
      dispatch(authActions.setSignInPhoneDefault());
    } else {
      dispatch(authActions.setSignInPhoneFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(authActions.setSignInPhoneFailure({ code: -1, message: 'error' }));
  }
};

export const clearSignInPhoneStates = () => async (dispatch: any) => {
  dispatch(authActions.setSignInPhoneDefault());
};
