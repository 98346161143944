import { createTheme } from '@material-ui/core/styles';
import { Components } from '@material-ui/core/styles/components';
import { themeBreakpoints } from './breakpoints';
import { typography } from './typography';

export const themeComponents = (pallete: any): Components => {
  const theme = createTheme();
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '::-webkit-scrollbar': {
          width: 4,
          height: 4,
        },
        '::-webkit-scrollbar-thumb': {
          background: pallete.grey[400],
          borderRadius: 4,
        },
        html: {
          minHeight: '100vh',
          height: '100%',
        },
        body: {
          minHeight: '100vh',
          height: '100%',
        },
        '#root': {
          display: 'flex',
          minHeight: '100vh',
          '& img, & picture': pallete?.type === 'a11y' && {
            filter: 'grayscale(1)',
          },
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
      styleOverrides: {
        root: {
          boxSizing: 'border-box',
          maxWidth: '100%',
          margin: 0,
          [themeBreakpoints.up('sm')]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
          [themeBreakpoints.up('lg')]: {
            paddingLeft: 50,
            paddingRight: 50,
            margin: 0,
          },
          [themeBreakpoints.up('xl')]: {
            maxWidth: 784,
          },
          [themeBreakpoints.up('xxl')]: {
            paddingLeft: 100,
            paddingRight: 100,
            margin: 0,
            maxWidth: 1100,
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {},
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application!
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
          '&~.MuiFormHelperText-root': {
            padding: 0,
            margin: '1px 0 4px',
            textAlign: 'right',
            ...typography.body2,
            minHeight: '21px',
          },
        },
        input: {
          display: 'block',
          padding: '12px 16px',
          ...typography.body1,
          minHeight: 48,
          boxSizing: 'border-box',
          '&.MuiInputBase-multiline': {
            height: 'auto',
          },
          '&:hover': {
            borderColor: pallete.grey[400],
          },
          '&:disabled': {
            color: pallete.grey[500],
            background: pallete.grey[400],
          },
        },
        inputSizeSmall: {
          padding: '6px 22px',
          ...typography.body2,
          minHeight: 32,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          position: 'static',
          transform: 'translate(0, 0) scale(1)',
          width: '100%',
          maxWidth: '100%',
          ...typography.body1,
        },
        sizeSmall: {
          ...typography.body2,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          padding: 0,
          paddingRight: 0,
          paddingLeft: 0,
          '&:not(.Mui-error, .Mui-focused):hover [class*="notchedOutline"]': {
            borderColor: pallete.grey[600],
          },
        },
        input: {
          padding: '12px 16px',
        },
        inputSizeSmall: {},
        inputMultiline: {},
        notchedOutline: {
          top: 0,
          transition: theme.transitions.create(
            ['border-width', 'border-color'],
            {
              duration: theme.transitions.duration.short,
            }
          ),
          '& > *': {
            display: 'none !important',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        helperText: ' ',
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            position: 'static',
            transform: 'translate(0, 0) scale(1)',
            width: '100%',
            maxWidth: '100%',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: '0 !important',
        },
        input: {
          padding: '12px 16px !important',
        },
        endAdornment: {
          position: 'static',
        },
        popupIndicator: {
          padding: 12,
          height: 48,
          width: 48,
        },
        clearIndicator: {
          padding: 12,
          height: 48,
          width: 48,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: pallete.grey[500],
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application!
      },

      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderWidth: 2,
            '&:hover, &:hover:focus, &.Mui-disabled': {
              borderWidth: 2,
            },
          },
        },
        {
          props: { variant: 'outlined', size: 'small' },
          style: {
            borderWidth: 1,
            fontWeight: 400,
            '&:hover, &:hover:focus, &.Mui-disabled': {
              borderWidth: 1,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            borderColor: pallete.primary.main,
            '&:hover, &:focus:hover': {
              borderColor: pallete.primary.main,
              color: pallete.primary.contrastText,
              backgroundColor: pallete.primary.main,
            },
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            '&.Mui-disabled': {
              color: pallete.primary.contrastText,
              backgroundColor: pallete.action.disabledBackground,
            },
          },
        },
      ],

      styleOverrides: {
        sizeSmall: {
          padding: '4px 22px',
          ...typography.body2,
          fontWeight: 700,
          lineHeight: '24px',
          height: 32,
          borderWidth: 1,
        },
        sizeMedium: {
          padding: '12px 22px',
          ...typography.body1,
          fontWeight: 700,
          lineHeight: '24px',
          height: 48,
        },
        startIcon: {
          '&>*:nth-of-type(1)': {
            fontSize: 24,
          },
        },
        endIcon: {
          '&>*:nth-of-type(1)': {
            fontSize: 24,
          },
        },
        iconSizeSmall: {
          '&.MuiButton-startIcon': {
            marginLeft: '-6px',
            marginRight: '6px',
          },
          '&.MuiButton-endIcon': {
            marginLeft: '6px',
            marginRight: '-6px',
          },
        },
        iconSizeMedium: {
          '&.MuiButton-startIcon': {
            marginLeft: '-10px',
            marginRight: '10px',
          },
          '&.MuiButton-endIcon': {
            marginLeft: '10px',
            marginRight: '-10px',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        sizeSmall: {
          padding: '4px',
          height: 32,
        },
        sizeMedium: {
          padding: '12px',
          height: 48,
        },
        sizeLarge: {},
      },
    },
    MuiDialog: {
      defaultProps: {},
      styleOverrides: {
        paper: {
          width: '100%',
        },
        paperWidthFalse: {},
        paperWidthXs: {},
        paperWidthSm: {
          maxWidth: 536,
        },
        paperWidthMd: {
          maxWidth: 770,
        },
        paperWidthLg: {},
        paperWidthXl: {},
        paperFullWidth: {},
        paperFullScreen: {},
        container: {
          '&>.MuiPaper-root': {
            boxShadow: '0 0 50px rgba(0, 0, 0, 0.2)',
          },
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: 16,
          [themeBreakpoints.up('lg')]: {
            padding: '30px 50px 30px 30px',
            '.MuiDialog-paperWidthMd &, .MuiDialog-paperWidthLg &, .MuiDialog-paperWidthXl &, .MuiDialog-paperFullScreen &':
              {
                padding: 50,
              },
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {},
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {},
      styleOverrides: {
        content: {
          margin: '13px 0',
          '&.Mui-expanded': {
            margin: '13px 0',
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
      variants: [
        {
          props: { variant: 'filled', color: 'primary' },
          style: {
            background: pallete.primary.light,
          },
        },
        {
          props: { severity: 'warning' },
          style: {
            color: pallete.text.primary,
            background: pallete.warning.light,
          },
        },
        {
          props: { severity: 'info' },
          style: {
            background: pallete.info.light,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 4,
          padding: 12,
          alignItems: 'center',
          '& a': {
            color: 'inherit',
            textDecoration: 'none',
          },
        },
        message: {
          padding: 0,
          ...typography.body2,
        },
        icon: {
          marginRight: 12,
          padding: 0,
          fontSize: 32,
          opacity: 1,
        },
        action: {
          alignSelf: 'flex-start',
          padding: '0 0 0 12px',
          margin: '-6px -6px 0 auto',
          marginRight: 0,
          marginTop: 0,
          '& > .MuiButtonBase-root': {
            height: 32,
          },
          '& > [class*="MuiIconButton"]': {
            padding: 6,
            fontSize: 16,
            width: 32,
          },
          '& [class*="MuiSvgIcon"]': {
            fontSize: 'inherit',
          },
        },
      },
    },
  };
};
