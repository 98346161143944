import { ComponentType } from 'react';
import { EmailEdit } from './email-edit/email-edit';

interface EmailProps {
  className?: any;
  variant?: 'edit';
}

export const Email: ComponentType<EmailProps> = ({
  className,
  variant = 'edit',
}) => {
  return <>{variant === 'edit' && <EmailEdit />}</>;
};
