import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'main',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    content: {},
    container: {},
    body: {
      '& [class*="subtitle2"]': {
        marginBottom: theme.spacing(2),
      },
      '& [class*="body2"]': {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down('md')]: {
        '& [class*="subtitle2"]': {
          marginBottom: theme.spacing(2.5),
        },
        '& [class*="body2"]': {
          marginBottom: theme.spacing(2),
        },
      },
    },
    actions: {
      paddingTop: theme.spacing(3.25),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(4.5),
      },
    },
    link: {},
    btn: {},
    title: {},
    text: {},
  }),
  options
);

export default useStyles;
