import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { typography } from '../../theme/typography';

const options = {
  name: 'language-select',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
    },
    btn: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1, 0, 1.5),
      ...typography.body2,
      minHegith: '36px',
      minWidth: '64px',
      height: 36,
      '& $text': {
        margin: '0 auto',
      },
    },
    divider: {
      margin: '0 !important',
      '&:last-of-type': {
        display: 'none',
      },
    },
    text: {},
    icon: {
      marginLeft: 'auto',
      height: 16,
      width: 16,
    },
    nav: {
      '& .MuiMenu-paper': {
        borderRadius: theme.spacing(2),
      },
      '& .MuiList-root': {
        padding: 0,
      },
    },
    item: {
      padding: theme.spacing(1.5, 2),
      color: theme.palette.text.secondary,
      justifyContent: 'center',
      ...typography.body2,
      lineHeight: '24px',
      minWidth: '116px',
    },
  }),
  options
);

export default useStyles;
