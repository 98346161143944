import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'vaccination-badge',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      position: 'relative',
      margin: theme.spacing(-3, 0, 0, -1),
      zIndex: 10,
    },
    icon: {
      height: 40,
      width: 110,
    },
  }),
  options
);

export default useStyles;
