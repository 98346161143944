import { Divider } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { Login } from '../../login';
import { Password } from '../../password';
//import { UserProfileRemove } from '../index';
import useStyles from './styles';

interface UserSettingsProps {
  className?: string;
}

export const UserSettings: ComponentType<UserSettingsProps> = ({
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Login variant="edit" className={clsx(classes.login, classes.item)} />
      <Divider className={classes.divider} />
      <Password
        variant="edit"
        className={clsx(classes.password, classes.item)}
      />
      {/*<Divider className={classes.divider} />*/}
      {/*<UserProfileRemove className={clsx(classes.remove, classes.item)} />*/}
    </div>
  );
};
