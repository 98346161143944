import { Alert, Box, Collapse, Grid, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty, isInteger } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsive } from '../../theme/useResponsive';
import { FIELD_EMAIL, FIELD_LABELS } from './change-email-schema';
import useStyles from './styles';
import { useChangeEmail } from './useChangeEmail';

interface EmailFormProps {
  className?: string;
}

export const EmailForm: ComponentType<EmailFormProps> = ({ className }) => {
  const classes = useStyles();
  const r = useResponsive();
  const { t } = useTranslation();
  const {
    isTouched,
    isLoading,
    code,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  } = useChangeEmail();

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);

  return (
    <Box className={clsx(classes.root, className)}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={r({ lg: 4.5 })}>
          <Grid item xs={12} lg={6}>
            <TextField
              {...formik.getFieldProps(FIELD_EMAIL)}
              onChange={(e: any) => handleFieldChange(FIELD_EMAIL, e)}
              label={t(
                FIELD_LABELS[FIELD_EMAIL]['labelKey'],
                FIELD_LABELS[FIELD_EMAIL]['label']
              )}
              id={FIELD_EMAIL}
              error={hasError(FIELD_EMAIL)}
              helperText={getErrorMessage(FIELD_EMAIL)}
              disabled={isLoading}
              InputProps={{
                'aria-label': FIELD_LABELS[FIELD_EMAIL]['arialLabel'],
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className={classes.actions} pt={r({ lg: 3 })}>
              <LoadingButton
                className={classes.btn}
                loading={isLoading}
                disabled={!noErrors || !isTouched}
                fullWidth={r({ xs: true, lg: false })}
                variant="outlined"
                type="submit"
              >
                {t('action.edit', 'Изменить')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>

        <Collapse in={isInteger(code) && Number(code) !== 0}>
          <Alert severity="error">
            {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`)}
          </Alert>
        </Collapse>

        <Collapse in={isInteger(code) && Number(code) === 0}>
          <Alert severity="success">
            {t('success.updated', 'Данные успешно обновлены')}
          </Alert>
        </Collapse>
      </form>
    </Box>
  );
};
