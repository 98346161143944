import { REACT_APP_API_OAUTH_URL } from '../common/constants';
import { ssoActions } from '../slices/sso-slice';
import { restoreAuthToken } from './auth-thunks';

export const ssoCheckRedirect = (data: any) => async (dispatch: any) => {
  dispatch(ssoActions.ssoCheckRequest());
  // try {
  //   const url = `${REACT_APP_API_OAUTH_URL}/check`;
  //   try {
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       body: JSON.stringify(data),
  //       headers: { 'Content-Type': 'application/json' },
  //     });

  //     if (response.status === 200) {
  //       const ssoResult = await response.json();
  //       const {
  //         client_id,
  //         redirect_uri,
  //         response_type,
  //         scope,
  //         scopeType,
  //         state,
  //         xoauth_displayname,
  //       } = ssoResult;
  //       const oAuthData = {
  //         uuid: data.uuid,
  //         client_id,
  //         redirect_uri,
  //         response_type,
  //         scope,
  //         scopeType,
  //         state,
  //         xoauth_displayname,
  //       };
  //       dispatch(ssoActions.ssoCheckSuccess(oAuthData));
  //     }
  //   } catch (error) {
  //     console.error('Ошибка ssoCheckRedirect:', error);
  //   }
  // } catch (error) {
  //   dispatch(ssoActions.ssoCheckFailure(error));
  // }
};

export const oAuthGenerated =
  (data: { uuid: string; scope: string }) => async (dispatch: any) => {
    console.log('ssoGenerateRequest');
    dispatch(ssoActions.ssoGenerateRequest());
    try {
      const url = `${REACT_APP_API_OAUTH_URL}/generate`;
      try {
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${restoreAuthToken()}`,
          },
        });

        if (response.status === 200) {
          const json = await response.json();
          const { callbackUrl, code, state } = json;
          if (callbackUrl && code && state) {
            dispatch(ssoActions.ssoGenerateSuccess(true));

            window.location.replace(
              `${callbackUrl}?code=${code}&state=${state}`
            );
          } else {
            alert('Ошибка');
          }
        }
      } catch (e) {
        console.error(e);
      }
    } catch (error) {
      dispatch(ssoActions.ssoGenerateFailure(error));
    }
  };
