import { Tooltip } from '@material-ui/core';
import { Box, BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { localeParseRegex } from '../lang-switcher';
import { useLayout } from '../layout';
import { useResponsive } from '../theme/useResponsive';
import { ReactComponent as LogoImg } from './assets/logo_beta.svg';
import useStyles from './styles';

interface LogoProps {
  className?: any;
  variant?: 'small' | 'large' | 'xLarge' | 'beta';
}

export const Logo: ComponentType<LogoProps & BoxProps> = ({
  className,
  variant = 'large',
  sx,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const { sidebar } = useLayout();

  const { pathname } = useLocation();
  const [, , path = ''] = pathname.match(localeParseRegex) || [];

  const isMain = (pathname: any = '') => {
    return pathname === path;
  };

  return (
    <Tooltip
      title={
        r({
          xs: '',
          lg: !isMain()
            ? t('action.goHome', 'Перейти на главную страницу')
            : '',
        }) || ''
      }
      arrow
      placement={!sidebar.open ? 'right' : 'bottom'}
    >
      <Box
        to="/"
        className={clsx(classes.root, className, classes[variant])}
        // title={isMain() ? t('app.title') : false}
        component={isMain() ? 'div' : Link}
        sx={{
          ...sx,
        }}
        {...props}
      >
        <LogoImg className={classes.img} />
      </Box>
    </Tooltip>
  );
};
