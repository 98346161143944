import { Box, Grid, TextField, Tooltip } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { get } from 'lodash';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

interface LegalEntityTinProps {
  className?: any;
  onTinSubmit: (tin: string) => void;
}

export const LegalEntityTin: ComponentType<LegalEntityTinProps> = ({
  className,
  onTinSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();

  const handleSubmit = () => {
    // TODO: submit
    setTimeout(() => {
      onTinSubmit(formik.values.tin);
      formik.setSubmitting(false);
      formik.resetForm();
    }, 1000);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { tin: '' },
    validationSchema: yup.object({
      tin: yup
        .string()
        .matches(/^\d+$/, 'error.tinOnlyNumbers')
        .length(9, 'error.length')
        .required('error.required'),
    }),
    onSubmit: handleSubmit,
  });

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  return (
    <form
      className={clsx(classes.root, className)}
      onSubmit={formik.handleSubmit}
    >
      <Grid container columnSpacing={r({ md: 4.5 })}>
        <Grid item xs={12} md={5}>
          <TextField
            {...formik.getFieldProps('tin')}
            label={t('legalEntityTIN', 'ИНН юридического лица')}
            id="tin"
            {...formik.getFieldProps('tin')}
            inputProps={{
              'aria-label': t('legalEntityTIN', 'ИНН юридического лица'),
              maxLength: 9,
            }}
            error={hasError('tinn')}
            helperText={getErrorMessage('tin')}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <Box pt={r({ xs: 0, md: 3 })}>
            <LoadingButton
              loading={formik.isSubmitting}
              disabled={!formik.isValid}
              variant="contained"
              fullWidth={r({ xs: true, md: false })}
              type="submit"
            >
              {t('action.add', 'Добавить')}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};
