import * as yup from 'yup';

export const FIELD_PASSWORD = 'password';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_PASSWORD]: {
    label: 'Текущий пароль',
    labelKey: 'password.current',
    arialLabel: 'Your passport serial number',
  },
};

export const UserRemoveValidationForm = yup.object().shape({
  [FIELD_PASSWORD]: yup.string().required('error.required').trim(),
});
