import { DataTableColumnsProps, DataTableTypography } from '../../data-table';
import { parseUserAgent } from '../../../common/utils/user-agent-utils';
import { Typography } from '@material-ui/core';

const LOGIN_METHODS: Record<string, any> = {
  LOGINPASSMETHOD: {
    label: 'Логин и пароль',
    labelKey: 'loginAndPassword',
  },
};

const getLoginMethods = (method: string) => {
  if (LOGIN_METHODS[method]) return LOGIN_METHODS[method];
  return {
    label: method,
    labelKey: method,
  };
};

const defaultTypography = (text: string) => (
  <Typography component="div" variant="body1" color="#777777">
    {text}
  </Typography>
);

export const HISTORY_AUTHORIZATION_COLUMNS_RESOURCE: DataTableColumnsProps[] = [
  {
    label: 'Ресурс',
    labelKey: 'history.resource',
    dataKey: 'resource',
    renderItem: ({ resource }) => defaultTypography(resource),
  },
  {
    label: 'Вход с IP',
    labelKey: 'history.signViaIP',
    dataKey: 'remoteIp',
    renderItem: ({ remoteIp }) => defaultTypography(remoteIp),
  },
  {
    label: 'User Agent',
    labelKey: 'userAgent',
    dataKey: 'userAgent',
    renderItem: ({ userAgent }: any) => {
      const { browserName, osName } = parseUserAgent(userAgent);
      return (
        <Typography
          component="div"
          variant="body1"
          color="#777777"
          sx={{ maxWidth: 100 }}
        >
          {browserName},
          <br />
          {osName}
        </Typography>
      );
    },
  },
  {
    label: 'Способ входа',
    labelKey: 'history.signOption',
    dataKey: 'loginMethods',
    renderItem: ({ loginMethods }: any) => {
      const { label, labelKey } = getLoginMethods(loginMethods);
      return (
        <DataTableTypography
          label={label}
          labelKey={labelKey}
          variant="body1"
          color="#777777"
          sx={{ whiteSpace: 'nowrap' }}
        />
      );
    },
  },
  {
    label: 'Дата и время',
    labelKey: 'history.dateTime',
    dataKey: 'loginTime',
    renderItem: ({ loginTime }: any) =>
      defaultTypography(
        `${new Date(loginTime).toLocaleDateString()} ${new Date(
          loginTime
        ).toLocaleTimeString()}`
      ),
  },
];
