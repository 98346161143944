import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'legal-entity-cabinet',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(4.625, 0),
    },
    header: {},
    container: {},
    content: {},
    divider: {
      margin: theme.spacing(4.5, 0),
    },
    add: {},
    list: {},
  }),
  options
);

export default useStyles;
