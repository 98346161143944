import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../../common/locales';
import dataSource from '../../../data-sources/help-contacts-resources.json';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

interface HelpContactsProps {
  className?: string;
}

interface ContactsProps {
  label: string;
  labelKey: string;
  value: string;
  valueKey: string;
}

export const CONTACTS: ContactsProps[] = [
  {
    label: 'Электронная почта',
    labelKey: 'email',
    value: 'iduzsupport@egov.uz',
    valueKey: 'iduzsupport@egov.uz',
  },
  {
    label: 'Телефон',
    labelKey: 'phone',
    value: '1242',
    valueKey: '1242',
  },
  {
    label: 'Режим работы',
    labelKey: 'help.contacts.openingHours',
    value: '9:00-18:00 (пн.-пт.)',
    valueKey: 'contacts.openingHours',
  },
  {
    label: 'Адрес',
    labelKey: 'contacts.address.label',
    value: 'Тошкент ш. Махтумкули кўчаси, 1а',
    valueKey: 'contacts.address',
  },
];

export const HelpContacts: ComponentType<HelpContactsProps> = ({
  className,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const r = useResponsive();

  const content = dataSource[i18n.language as Locale]['content'];

  const renderContactItem = (
    { label, labelKey, value, valueKey }: ContactsProps,
    idx: any,
    breakpoints: Record<string, number>
  ) => (
    <Grid
      item
      container
      direction="column"
      spacing={0.75}
      key={idx}
      {...breakpoints}
    >
      <Grid item>
        <Typography variant="h6">{t(labelKey, label)}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" color="textSecondary">
          {labelKey === 'email' ? (
            <a href={`mailto:${value}`}>{value}</a>
          ) : (
            t(valueKey, value)
          )}
        </Typography>
      </Grid>
    </Grid>
  );

  const contacts = CONTACTS.map((contact, idx) => {
    if (contact.labelKey === 'address') {
      return renderContactItem(contact, 'address', {
        xs: 12,
        md: 12,
      });
    }
    return renderContactItem(contact, idx, { xs: 12, md: 4 });
  });

  return (
    <div className={clsx(classes.root, className)}>
      {(content || []).map((item: Record<string, any>, idx: number) => (
        <Typography {...item} key={idx} />
      ))}

      <Grid
        container
        columnSpacing={6}
        rowSpacing={r({ xs: 3.25, md: 4 })}
        mb={r({ xs: 4.5, md: 4 })}
      >
        {contacts}
      </Grid>

      <div className={clsx(classes.map)}>
        {/* <iframe
          title={t('address', 'Адрес')}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d662.3194074499389!2d69.31569719643757!3d41.30280775323111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef52099f9f365%3A0xfbd5b98a8fa2c648!2sIT%20Park%20Tashkent!5e0!3m2!1sru!2sus!4v1645085009556!5m2!1sru!2sus"
          width="100%"
          height={350}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        /> */}
        <iframe
          title={t('address', 'Адрес')}
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae980ec0f787a296ae1802c795dd26e0a1bfa1e0792edff8cc2149533e5390f56&amp;source=constructor"
          width="100%"
          height="400"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};
