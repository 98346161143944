import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { typography } from '../theme/typography';

const options = {
  name: 'header',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginBottom: theme.spacing(3.25),
      // marginBottom: theme.spacing(1),
      zIndex: 100,
      [theme.breakpoints.up('xl')]: {
        paddingTop: theme.spacing(6.25),
        marginBottom: theme.spacing(4.5),
        // paddingTop: theme.spacing(1),
        // marginBottom: theme.spacing(1),
      },
    },
    // variants
    static: {
      paddingTop: theme.spacing(8.5),
      // paddingTop: theme.spacing(2),
      '& $toolbar': {
        position: 'absolute',
        top: '0px',
        left: 0,
        right: 0,
        width: '100%',
      },
      // [theme.breakpoints.up('sm')]: {
      //   '& $toolbar': {
      //     position: 'absolute',
      //     top: '0px',
      //   },
      // },
      // [theme.breakpoints.up('md')]: {
      //   '& $toolbar': {
      //     position: 'absolute',
      //     top: '0',
      //   },
      // },
      // [theme.breakpoints.up('lg')]: {
      //   '& $toolbar': {
      //     position: 'absolute',
      //     top: '0',
      //   },
      // },
      [theme.breakpoints.up('xl')]: {
        '& $toolbar': {
          position: 'fixed',
          top: '0',
          left: 'auto',
        },
        '& $toolbarFixed': {
          position: 'fixed',
          top: 0,
          right: 0,
        },
      },
    },
    sticky: {
      position: 'sticky',
      top: 0,
      left: 0,
      paddingBottom: theme.spacing(2.5),
      width: '100%',
      boxShadow: theme.shadows[2],
      [theme.breakpoints.up('xl')]: {
        '& $toolbar': {
          position: 'absolute',
          top: 0,
          right: 0,
          left: 'auto',
        },
      },
    },
    contained: {
      paddingBottom: theme.spacing(2.5),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('xl')]: {
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(4.5),
      },
    },

    container: {},
    content: {},
    toolbarFixed: {},
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      zIndex: 101,
      // [theme.breakpoints.up('lg')]: {
      //   padding: theme.spacing(4.5, 6.25, 0),
      // },
      [theme.breakpoints.up('xl')]: {
        left: 'auto',
        padding: theme.spacing(2.5),
        flexDirection: 'column',
        alignItems: 'flex-end',
        maxWidth: 400,
      },
    },
    title: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
      [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        marginBottom: theme.spacing(3.5),
      },
    },
    body: {},
    actions: {},
    tabs: {
      minHeight: 36,
      lineHeight: '20px',
    },
    tab: {
      flexDirection: 'row',
      padding: theme.spacing(1, 0),
      marginRight: theme.spacing(4),
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      ...typography.body1,
      minHeight: 36,
      minWidth: '1%',
      '&.Mui-selected': {
        color: theme.palette.text.primary,
        ...typography.h6,
        letterSpacing: 0,
        '& $tabText': {
          letterSpacing: 0,
        },
      },
    },
    tabText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(-50%, -50%)`,
      fontSize: 'inherit',
      letterSpacing: '0.03em',
    },
    tabTextFake: {
      ...typography.h6,
      opacity: 0,
    },
    user: {
      margin: theme.spacing(-0.5, 0),
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 0, 2.5),
      },
    },
    help: {},
    lang: {},
    toggle: {
      fontSize: 32,
      height: '1em',
      width: '1em',
      minHeight: '1em',
      minWidth: '1em',
      [theme.breakpoints.up('lg')]: {
        fontSize: 38,
      },
    },
    icon: {
      height: 24,
      width: 24,
    },
  }),
  options
);

export default useStyles;
