import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './styles';

interface ESignBrowseProps {
  className?: string;
}

export const ESignBrowse: ComponentType<ESignBrowseProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.text}>
        <Typography variant="body1" color="textSecondary" component="p">
          <Trans i18nKey="eSign.install">
            Для корректной работы системы необходимо установить&nbsp;
            <a
              href="https://e-imzo.uz/main/downloads"
              rel="noopener noreferrer"
              target="_blank"
              title={t('eSign.action.module', 'Скачать Модуль E-IMZO')}
            >
              Модуль E-IMZO
            </a>
            &nbsp;или&nbsp;
            <a
              href="https://e-imzo.uz/main/downloads"
              rel="noopener noreferrer"
              target="_blank"
              title={t('eSign.action.browser', 'Скачать Браузер E-IMZO')}
            >
              Браузер E-IMZO
            </a>
          </Trans>
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          <Trans i18nKey="eSign.instructions">
            Ознакомиться с&nbsp;инструкцией по&nbsp;установке Модуля Е-IMZO
            можно&nbsp;
            <a
              href="https://e-imzo.uz/#instructions"
              rel="noopener noreferrer"
              target="_blank"
              title={t(
                'eSign.action.instructions',
                'Инструкция по установке Модуля Е-IMZO'
              )}
            >
              здесь
            </a>
          </Trans>
        </Typography>
      </div>
    </div>
  );
};
