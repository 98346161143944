// export const REACT_APP_API_BASE_URL = 'https://id2.egov.uz/api/v1';
// export const REACT_APP_API_OAUTH_URL = 'https://id2.egov.uz/api/oauth';
//
// // export const REACT_APP_API_BASE_URL = 'http://172.16.30.37:8998/api/v1';
// // export const REACT_APP_API_OAUTH_URL = 'http://172.16.30.37:8998/api/oauth';

export const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_OAUTH_URL,
  REACT_APP_API_STATISTICS_URL,
} = process.env;

export const BASE_LOCALE = 'i18nextLng';
