import {
  Box,
  BoxProps,
  Container,
  Fab,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType, ReactHTML, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ArrowRightIcon } from '../../common/icons';
import { MainRouteProps } from '../../common/routes';
import useAuth from '../auth/useAuth';
// import { HelpHeader } from '../help/help-header/help-header';
import { LangSwitcher } from '../lang-switcher';
import { useLayout } from '../layout';
import { useResponsive } from '../theme/useResponsive';
import { UserHeader } from '../user';
import useStyles from './styles';

interface HeaderProps {
  className?: any;
  variant?: 'static' | 'sticky';

  title?: any;
  titleTag?: ReactHTML;
  actions?: MainRouteProps[];
  elevation?: number;
  hasBackButton?: boolean;
}

export const Header: ComponentType<HeaderProps & BoxProps> = ({
  className,
  variant = 'static',
  bgcolor,
  title,
  component = 'h1',
  actions = [],
  hasBackButton = false,
  ...props
}) => {
  const classes = useStyles();
  const { authorized } = useAuth();
  const { t, i18n } = useTranslation();
  const r = useResponsive();
  const location = useLocation();

  const [active, setActive] = useState(0);
  //const [fixed, setFixed] = useState<Boolean>(false);
  const { toggleSidebar } = useLayout();

  const handleChange = (event: any, value: any): any => {
    setActive(value);
  };

  const { children } = props;

  useEffect(() => {
    const asPath = location.pathname.replace(/\/(en|ru|uz|oz)/g, '');
    const idx = actions.findIndex((x) => {
      return x.pathname === asPath;
    });
    idx > 0 ? setActive(idx) : setActive(0);
  }, [actions, location.pathname]);

  return (
    <Box
      className={clsx(
        classes.root,
        classes[variant],
        {
          [classes.contained]: Boolean(bgcolor),
        },
        className
      )}
      component={'header'}
      bgcolor={bgcolor}
      {...props}
    >
      <Box
        className={clsx(classes.toolbar, {
          [classes.toolbarFixed]: false,
        })}
      >
        {r({ xs: true, xl: false }) && (
          <Fab
            color="primary"
            className={classes.toggle}
            aria-label="toggle sidebar"
            onClick={toggleSidebar}
          >
            <SvgIcon
              component={ArrowRightIcon}
              className={clsx(classes.icon)}
            />
          </Fab>
        )}

        {!authorized && r({ xs: true, xl: false }) && (
          <LangSwitcher className={clsx(classes.lang)} variant="select" />
        )}

        {authorized && <UserHeader className={classes.user} />}

        {/* {r({ xl: true }) && <HelpHeader className={classes.help} />} */}
      </Box>
      <Container className={classes.container}>
        <div className={classes.content}>
          {title && (
            <Typography
              variant={r({ xs: 'h2', md: 'h1' })}
              component={component}
              className={classes.title}
            >
              {title}
            </Typography>
          )}
          {children && <div className={classes.body}>{children}</div>}
          {actions.length > 0 && (
            <Tabs
              className={classes.tabs}
              value={active}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              component="nav"
              aria-label="Навигация" // #TODO a11y
            >
              {actions.map((item, index) => {
                if (!authorized && item?.authorized) {
                  return null;
                }
                return (
                  <Tab
                    className={classes.tab}
                    label={
                      <>
                        <span className={classes.tabTextFake}>
                          {t(item.i18n, item.title || 'Нет перевода')}
                        </span>
                        <span className={classes.tabText}>
                          {t(item.i18n, item.title || 'Нет перевода')}
                        </span>
                      </>
                    }
                    component={Link}
                    to={`/${i18n.language}${item.pathname}`}
                    aria-label={t(item.i18n, item.title || 'Нет перевода')}
                    key={`header-nav-${index}`}
                  />
                );
              })}
            </Tabs>
          )}
        </div>
      </Container>
    </Box>
  );
};
