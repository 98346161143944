import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  Paper,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { ChangeEvent, ComponentType, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  CheckboxCheckedIcon,
  CheckboxIcon,
  CloseIcon,
  DocumentIcon,
  VideoIcon,
} from '../../../common/icons';
import { signUp } from '../../../thunks/auth-thunks';
import { useResponsive } from '../../theme/useResponsive';
import { UserAgreement } from '../../user';
import { SignUpForm } from './sign-up-form';
import useStyles from './styles';

interface SignUpProps {
  className?: any;
  classes?: {
    root: string;
    paper: string;
    body: string;
  };
  variant?: 'fullWidth' | 'shifted';
}

export const SignUp: ComponentType<SignUpProps> = ({
  className,
  variant = 'fullWidth',
  classes: extClasses = {
    root: '',
    paper: '',
    body: '',
  },
}) => {
  // const theme: any = useTheme();

  // const styles = theme => ({
  //   disabledButton: {
  //     backgroundColor: theme.palette.primary || 'red'
  //   }
  // });

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const r = useResponsive();
  const dispatch = useDispatch();
  const [alerts, setAlerts] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const handleAgreement = (event: ChangeEvent<HTMLInputElement>) => {
    setAgreement(event.target.checked);
    setAlerts(false);
  };

  const [agreementDialog, setAgreementDialog] = useState(false);

  const handleAgreementDialog = (value = !Boolean(agreementDialog)) =>
    setAgreementDialog(value);

  const [singUpDialog, setSingUpDialog] = useState(false);

  const handleSingUpDialog = (value = !Boolean(singUpDialog)) => {
    setSingUpDialog(value);
  };

  const handleSubmit = (data: {
    login: string;
    password: string;
    password_repeat: string;
  }) => {
    const { login, password, password_repeat } = data;
    if (password !== password_repeat) {
      alert('Пароли не совпадают');
      return false;
    }
    dispatch(signUp({ login, password }));
  };

  const checkButton = () => {
    if (agreement) {
      setSingUpDialog(true);
    } else setAlerts(true);
  };

  return (
    <Paper
      className={clsx(
        classes.root,
        className,
        classes[variant],
        extClasses.root,
        extClasses.paper
      )}
    >
      <div className={classes.content}>
        <div className={clsx(classes.body, extClasses.body)}>
          <Typography
            className={classes.title}
            variant={r({ xs: 'h2', md: 'h1' })}
            component="div"
          >
            {t('signUp.title', 'Регистрация')}
          </Typography>

          <Hidden mdDown>
            <Typography
              className={classes.subtitle}
              variant="h5"
              component="div"
            >
              {t('signUp.subtitle', 'Удобство и безопасность')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="body1"
              color="textSecondary"
            >
              {t(
                'signUp.text01',
                'Единая система идентификации (OneID) предназначена для простого и безопасного доступа пользователей к различным информационным системам электронного правительства и государственных органов.'
              )}
            </Typography>
          </Hidden>

          <Typography
            className={classes.text}
            variant="body1"
            color="textSecondary"
          >
            {t(
              'signUp.text02',
              'Для регистрации вам потребуются ваши Паспортные данные.'
            )}
          </Typography>

          <div className={classes.agreement}>
            <Checkbox
              checked={agreement}
              onChange={handleAgreement}
              className={clsx(classes.control, classes.checkbox)}
              aria-label={t('signUp.agreement')}
              color="primary"
              icon={
                <SvgIcon
                  component={CheckboxIcon}
                  className={classes.controlIcon}
                />
              }
              checkedIcon={
                <SvgIcon
                  component={CheckboxCheckedIcon}
                  className={classes.controlIcon}
                />
              }
            />
            <Typography
              className={classes.label}
              variant="body1"
              color="textSecondary"
            >
              <Trans i18nKey="signUp.agreement">
                Я ознакомился(ась) с&nbsp;
                <span
                  id="agreement-dialog-title"
                  onClick={() => setAgreementDialog(true)}
                  className={classes.link}
                  title={t('user.agreement', 'Пользовательское соглашение')}
                >
                  пользовательским соглашением
                </span>
              </Trans>
            </Typography>
          </div>
        </div>

        <div className={classes.actions}>
          <Collapse in={alerts}>
            <Alert
              severity="warning"
              iconMapping={{
                warning: <ErrorOutlineIcon fontSize="inherit" />,
              }}
              sx={{
                mb: 4.5,
              }}
              onClose={() => {
                setAlerts(false);
              }}
            >
              {t(
                'alert.agreement',
                i18n.language === 'ru'
                  ? 'Пожалуйста нажмите "Я ознакомился с пользовательским соглашением.'
                  : i18n.language === 'en'
                  ? 'Please click "I got acquainted with users agreement"'
                  : i18n.language === 'uz'
                  ? 'Илтимос "Фойдаланувчи келишуви билан танишдим" тугмасини босинг '
                  : 'Iltimos "Foydalanuvchi kelishuvi bilan tanishdim" tugmasini bosing'
              )}
            </Alert>
          </Collapse>
          <Grid className={classes.row} container spacing={2}>
            <Grid className={classes.col} item xs={12} lg={6}>
              <Button
                onClick={checkButton}
                variant="contained"
                // variant={agreement ? 'contained' : 'transparent'}
                type="submit"
                className={agreement ? classes.btn : classes.disabled}
                fullWidth={r({ xs: true, lg: false })}
              >
                <Trans i18nKey="action.signUp">Зарегистрироваться</Trans>
              </Button>
            </Grid>
            <Hidden lgDown>
              <Grid className={classes.col} item xs={12} lg={6}>
                <div className={classes.instruction}>
                  <Typography
                    target="_blank"
                    href="https://my.gov.uz/uz/pages/instruction-ind?new=1"
                    title={t('signup.instruction')}
                    className={clsx(classes.link, classes.linkWidthIcon)}
                    component="a"
                    variant="body2"
                  >
                    <Trans i18nKey="signup.instruction">
                      Инструкция по регистрации
                    </Trans>
                    <SvgIcon
                      component={DocumentIcon}
                      className={classes.linkIcon}
                    />
                  </Typography>
                  <Typography
                    target="_blank"
                    href="https://www.youtube.com/watch?v=NnIOnwodeIs"
                    title={t('signup.howSignup')}
                    className={clsx(classes.link, classes.linkWidthIcon)}
                    component="a"
                    variant="body2"
                  >
                    <Trans i18nKey="signup.howSignup">
                      Как зарегистрироваться
                    </Trans>
                    <SvgIcon
                      component={VideoIcon}
                      className={classes.linkIcon}
                    />
                  </Typography>
                </div>
              </Grid>
            </Hidden>
          </Grid>
        </div>
      </div>

      {/* UserAgreementDialog */}
      <Dialog
        open={agreementDialog}
        onClose={() => handleAgreementDialog()}
        fullScreen={r({ xs: true, lg: false })}
        scroll={'body'}
        maxWidth={'md'}
        aria-labelledby="agreement-title"
        aria-describedby="agreement-description"
      >
        <DialogContent id="agreement-description">
          <Box pt={r({ xs: 3, lg: 0 })}>
            <IconButton
              onClick={() => handleAgreementDialog()}
              color="primary"
              aria-label={t('action.dialog.close', 'Закрыть диалоговое окно')}
              sx={{
                position: 'absolute',
                right: 4,
                top: 4,
              }}
            >
              <SvgIcon component={CloseIcon} />
            </IconButton>
          </Box>
          <UserAgreement
            variant="modal"
            onSuccess={() => handleAgreementDialog()}
          />
        </DialogContent>
      </Dialog>

      {/* SingUp Dialog */}
      <Dialog
        open={singUpDialog}
        onClose={(e: any) => e.preventDefault()}
        fullScreen={r({ xs: true, lg: false })}
        scroll={'body'}
        maxWidth={'md'}
        aria-labelledby="sign-up-title"
        aria-describedby="sign-up-description"
      >
        <DialogContent id="sign-up-description">
          <Box
            sx={{
              position: r({ xs: 'static', lg: 'absolute' }),
              top: 4,
              right: 4,
              display: 'flex',
              justifyContent: 'flex-end',
              pt: r({ xs: 0, lg: 0 }),
              m: r({ xs: '-12px -12px 0 0', lg: '0' }),
            }}
          >
            <IconButton
              onClick={() => handleSingUpDialog()}
              color="primary"
              aria-label={t('action.dialog.close', 'Закрыть диалоговое окно')}
            >
              <SvgIcon component={CloseIcon} />
            </IconButton>
          </Box>
          <SignUpForm
            handleSubmit={handleSubmit}
            onSuccess={() => handleSingUpDialog()}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};
