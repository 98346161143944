import { ComponentType, useEffect } from 'react';
import { withRouter } from 'react-router';

export const ScrollToTop: ComponentType = withRouter(
  ({ history, children }) => {
    useEffect(() => {
      const unlisten = history.listen(() => {
        window.scrollTo(0, 0);
      });
      return () => {
        unlisten();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
  }
);
