import { Box } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ESignToken } from './e-sign-token/e-sign-token';
import { ESignBrowse, ESignList, ESignNotFound } from './index';
import useStyles from './styles';
import { useESign } from './useESign';

import { isWindows } from 'react-device-detect';

export interface ESignProps {
  className?: string;
  pinfl?: string | null;
  le?: boolean;
  exists?: boolean;
  actionsItem?: ReactElement[] | ((item: any) => ReactElement[]);
  onClickRefresh?: () => void;
  onClickSignItem?: (item: any) => void;
}

export const ESign: ComponentType<ESignProps & BoxProps> = ({
  className,
  pinfl = null,
  le = false,
  exists,
  actionsItem,
  onClickRefresh,
  onClickSignItem,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { certs, allCerts } = useESign(pinfl, le);

  useEffect(() => {
    if (onClickRefresh) {
      onClickRefresh();
      let intervalRefreshKeys = setInterval(() => {
        onClickRefresh();
      }, 10000);
      return () => {
        clearInterval(intervalRefreshKeys);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <div className={classes.body}>
        {!exists && <ESignBrowse className={classes.browse} />}
        {isWindows ? <ESignToken className={classes.token} /> : null}

        {!certs.length && (
          <ESignNotFound
            className={classes.notFound}
            variant={
              (allCerts.length &&
                ((le && 'noLegalEntity') ||
                  (!le && pinfl && 'notIndividual'))) ||
              'notFound'
            }
          />
        )}

        {exists && certs.length > 0 && (
          <ESignList
            className={classes.list}
            data={certs}
            actionsItem={actionsItem}
            onClickItem={onClickSignItem}
            gap={props.gap}
            rowGap={props.rowGap}
            columnGap={props.columnGap}
          />
        )}
      </div>
      {onClickRefresh && (
        <div className={classes.actions}>
          <LoadingButton
            loading={false}
            className={classes.btn}
            variant="outlined"
            onClick={onClickRefresh}
          >
            {t('esign.action.update', 'Обновить')}
          </LoadingButton>
        </div>
      )}
    </Box>
  );
};
