import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState = {
  error: null as string | null,
  isLoading: false,
  uuid: null as string | null,
  scope: null as string | null,
  role: null as string | null,
};

export const { actions: ssoActions, reducer: ssoReducer } = createSlice({
  name: 'sso',
  initialState,
  reducers: {
    ssoCheckRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    ssoCheckSuccess: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      isLoading: false,
      ...payload,
    }),
    ssoCheckFailure: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }),

    ssoGenerateRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    ssoGenerateSuccess: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      isLoading: false,
      ...payload,
    }),
    ssoGenerateFailure: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      isLoading: false,
      error: payload.error,
    }),
    setRole: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      role: payload,
    }),
  },
});
