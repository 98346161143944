import { FormikValues, useFormik } from 'formik';
import { get, isInteger } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../common/store';
import { clearSignInStates } from '../../../../thunks/auth-thunks';
import {
  FIELD_PASSPORT_ID,
  FIELD_PASSWORD,
  SignInValidationSchema,
} from './sign-in-id-card-schema';
import { Tooltip } from '@material-ui/core';

interface useSignInProps {
  handleSubmit: (values: FormikValues) => void;
}

export const useSignIn = ({ handleSubmit }: useSignInProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authStore = useSelector((state: State) => state.auth);

  const signIn = get(authStore, 'signIn', {
    isLoading: false,
    code: null,
    message: null,
  });

  const formik: any = useFormik({
    initialValues: {
      [FIELD_PASSPORT_ID]: '',
      [FIELD_PASSWORD]: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: SignInValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleFieldChange = (field: string, e: any) => {
    formik.setFieldValue(field, e.target.value);
    formik.setFieldError(field, null);
    dispatch(clearSignInStates());
  };

  const hasError = (field: string) => {
    if (field === FIELD_PASSWORD && !!signIn.code) {
      return !!signIn.code;
    }
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (field === FIELD_PASSWORD && !!signIn.code) {
      fullMessage = t(
        `error.api.${signIn?.code}.full`,
        `Ошибка с кодом ${signIn?.code}`
      );
      shortMessage = t(
        `error.api.${signIn?.code}`,
        `Ошибка с кодом ${signIn?.code}`
      );
    }

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (isInteger(signIn.code) && Number(signIn.code) !== 0) {
      formik.setFieldError(FIELD_PASSWORD, `error.${signIn.code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signIn.code]);

  useEffect(() => {
    return () => {
      dispatch(clearSignInStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...signIn, formik, handleFieldChange, hasError, getErrorMessage };
};
