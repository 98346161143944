import { ComponentType } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import clsx from 'clsx';

interface MasonryListItemProps {
  title: string;
  icon?: string;
  text?: string;
}

interface MasonryListProps {
  className?: string;
  data: MasonryListItemProps[];
}

export const MasonryList: ComponentType<MasonryListProps> = ({
  className,
  data,
}) => {
  const classes = useStyles();

  return (
    <ul className={clsx(classes.root, className)}>
      {(data || []).map(({ icon, title, text }: any, idx: number) => (
        <li className={clsx(classes.item)} key={idx}>
          {icon && (
            <figure className={clsx(classes.figure)}>
              <img src={icon} alt={title} width={70} height={70} />
            </figure>
          )}
          <Typography
            component="h3"
            variant="h5"
            color="textSecondary"
            mb={2.5}
          >
            <span dangerouslySetInnerHTML={{ __html: title }} />
          </Typography>
          {text && (
            <Typography component="p" variant="body2" color="textSecondary">
              {text}
            </Typography>
          )}
        </li>
      ))}
    </ul>
  );
};
