import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { Auth } from '../auth';
import { Header } from '../header';
import { Layout } from '../layout';
import Socials from '../socials';

interface PageAuthProps {}

export const PageAuth: ComponentType<PageAuthProps> = () => {
  return (
    <Layout variant="default" bgcolor="background.default">
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Socials />
        {/* <LastBanner /> */}
      </Box>
      <Header variant="static" />
      <Box
        component={'main'}
        role="main"
        sx={{
          overflowX: 'hidden',
        }}
      >
        <Container
          sx={{
            mx: 'auto !important',
          }}
        >
          <Auth />
        </Container>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Socials />
          {/* <LastBanner /> */}
        </Box>
      </Box>
    </Layout>
  );
};
