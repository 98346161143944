import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { ESign } from '../../e-sign';
import { useESign } from '../../e-sign/useESign';
import useStyles from './styles';

interface LegalEntityESignProps {
  className?: any;
}

export const LegalEntityESign: ComponentType<LegalEntityESignProps> = ({
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pinfl } = useSelector((state: State) => state.profile);

  const { refreshKeys, addLE } = useESign();

  const handleLEAdd = (value: any) => {
    addLE(value);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <ESign
        pinfl={pinfl}
        le={true}
        exists={true}
        actionsItem={(item: any) => [
          <LoadingButton
            key="add_entity_btn"
            onClick={() => handleLEAdd(item)}
            size="small"
            variant="contained"
            className={classes.btn}
          >
            {t('actions.add', 'Добавить')}
          </LoadingButton>,
        ]}
        onClickRefresh={() => {
          refreshKeys();
        }}
        onClickSignItem={(item) => handleLEAdd(item)}
      />
    </div>
  );
};
