import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorized } from '../authorized';
import { Children } from '../children';
import { Header } from '../header';
import { Layout } from '../layout';

interface PageChildrenProps {}

export const PageChildren: ComponentType<PageChildrenProps> = Authorized()(
  () => {
    const { t } = useTranslation();

    return (
      <Layout variant="default" bgcolor="background.paper">
        <Header
          title={t('register-children', 'Регистрация детей')}
          component={'h1'}
        />
        <Box component={'main'} role="main">
          <Container>
            <Children />
          </Container>
        </Box>
      </Layout>
    );
  }
);
