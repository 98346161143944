import { FormikValues } from 'formik';
import { api } from '../common/utils/fetch-utils';
import { errorActions } from '../slices/error-slice';

const {
  setReportRequest,
  setReportSuccess,
  setReportFailure,
  setReportDefault,
} = errorActions;

export const sendReport = (values: FormikValues) => async (dispatch: any) => {
  dispatch(setReportRequest());
  try {
    const res = await api.post('do/errorReporting', values);
    if (res.code === 0) {
      dispatch(setReportSuccess(res));
    } else {
      dispatch(setReportFailure(res));
    }
  } catch (error) {
    console.error(error);
    dispatch(setReportFailure({ code: -1, message: 'error' }));
  }
};

export const clearSendReport = () => async (dispatch: any) => {
  dispatch(setReportDefault());
};
