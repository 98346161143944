import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'authentication',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    title: {},
    isConfirmed: {
      '& .MuiInputBase-root': {
        color: `${theme.palette.success.contrastText} !important`,
        backgroundColor: `${theme.palette.success.main} !important`,
      },
      '& .MuiInputBase-input': {
        color: `${theme.palette.success.contrastText} !important`,
      },
      '& .MuiSvgIcon-root': {
        marginRight: 10,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.success.main} !important`,
      },
    },
    icon: {},
    btn: {},
    alert: {},
  }),
  options
);

export default useStyles;
