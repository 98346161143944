import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  RadioGroup,
  SvgIcon,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DatePicker,
  LoadingButton,
  LocalizationProvider,
  TabContext,
  TabList,
  TabPanel,
} from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import clsx from 'clsx';
import { get, isEmpty, isInteger } from 'lodash';
import React, { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import {
  CalendarIcon,
  EyeHideIcon,
  EyeIcon,
  HelpIcon,
} from '../../../../common/icons';
import {
  MASK_PASSPORT_SERIAL_NUMBER,
  MASK_PHONE,
  MASK_PINFL,
} from '../../../../constants/masks-constants';
import {
  checkPhone,
  checkPinfl,
  clearAskSmsSates,
} from '../../../../thunks/auth-thunks';
import { Hint } from '../../../hint';
import { PhoneSms } from '../../../phone';
import { useResponsive } from '../../../theme/useResponsive';
import { UiRadio } from '../../../ui';
import { SignUpHelpForForeigners } from './sign-up-form-foreigners';
import { SignUpMedia } from './sign-up-form-media';
import {
  FIELD_BIRTH_DATE,
  FIELD_CITIZENSHIP,
  FIELD_EMAIL,
  FIELD_LABELS,
  FIELD_LOGIN,
  FIELD_PASSPORT,
  FIELD_PASSWORD,
  FIELD_PASSWORD_REPEAT,
  FIELD_PHONE,
  FIELD_PINFL,
} from './sign-up-schema';
import useStyles from './styles';
import { useSignUp } from './useSignUp';

interface SignUpFormProps {
  className?: any;
  onSuccess?: () => any;
  handleSubmit: (data: any) => void;
}

const PREFIX = 'sign-up';

export const SignUpForm: ComponentType<SignUpFormProps> = ({
  className,
  // onSuccess,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const r = useResponsive();
  const { t } = useTranslation();

  const [typePassword, setTypePassword] = useState(true);
  const [typePasswordRepeat, setTypePasswordRepeat] = useState(true);

  const {
    isLoading,
    code,
    authStore,
    formik,
    step,
    handleFieldChange,
    hasError,
    getErrorMessage,
    handleStep,
    nextStep,
    prevStep,
    generatePassword,
  } = useSignUp({ handleSubmit });

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);

  const pinflValue = get(formik, ['values', FIELD_PINFL], null);
  const birthDateValue = get(formik, ['values', FIELD_BIRTH_DATE], null);
  const passportValue = get(formik, ['values', FIELD_PASSPORT], null);
  const phoneValue = get(formik, ['values', FIELD_PHONE], null);

  const isSmsSenderEnabled =
    (!!pinflValue || !!birthDateValue) &&
    !!passportValue &&
    !!phoneValue &&
    !hasError(FIELD_EMAIL);

  const [citizenship, setСitizenship] = useState(1);

  const [openCalendar, setOpenCalendar] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setСitizenship(Number((event.target as HTMLInputElement).value));
  };

  useEffect(() => {
    dispatch(checkPhone(formik.values.phone));
  }, [formik.values.phone, dispatch]);

  useEffect(() => {
    dispatch(checkPinfl(formik.values.pinfl));
  }, [formik.values.pinfl, dispatch]);

  return (
    <form
      className={clsx(classes.root, className)}
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Typography variant="h1" component="div" mb={3}>
        {t('signUp.title', 'Регистрация')}
      </Typography>

      <TabContext value={step.toString()}>
        <Grid
          container
          rowSpacing={r({ xs: 2, md: 0 })}
          className={clsx(classes.row, classes.rowTabs)}
          alignItems="center"
        >
          <Grid item xs="auto" className={clsx(classes.col)}>
            <TabList
              onChange={(e: any, value) => handleStep(Number(value))}
              aria-label={t('signUp.title', 'Регистрация')}
              className={classes.tabList}
              variant="scrollable"
            >
              <Tab
                label={
                  <Typography variant="body1" component="span">
                    {t('signUp.identification', 'Идентификация')}
                  </Typography>
                }
                icon={<span className={classes.tabIcon}>1</span>}
                value="1"
                className={classes.tab}
                disableRipple
              />
              <Tab
                label={
                  <Typography variant="body1" component="span">
                    {t('signUp.confirmation', 'Подтверждение')}
                  </Typography>
                }
                icon={<span className={classes.tabIcon}>2</span>}
                value="2"
                className={classes.tab}
                disabled={!Boolean(get(authStore, 'sms.isValid'))}
                disableRipple
              />
            </TabList>
          </Grid>
          <Grid item md className={clsx(classes.col)}>
            <Typography
              variant="caption"
              color="textSecondary"
              textAlign={r({ xs: 'left', md: 'right' })}
              component="div"
            >
              <sup>* </sup>
              {t(
                'signUp.requiredFields',
                'Звездочкой отмечены обязательные для заполнения поля'
              )}
            </Typography>
          </Grid>
        </Grid>

        <TabPanel value="1" className={classes.tabPanel}>
          <RadioGroup
            id={`${PREFIX}-${FIELD_CITIZENSHIP}`}
            aria-label={t(
              FIELD_LABELS[FIELD_CITIZENSHIP]['labelKey'],
              FIELD_LABELS[FIELD_CITIZENSHIP]['label']
            )}
            defaultValue={1}
            name="citizenship"
            value={citizenship}
            onChange={handleRadioChange}
            row={r({ xs: false, lg: true })}
            sx={{
              mb: 4.5,
            }}
          >
            <FormControlLabel
              value={1}
              control={<UiRadio />}
              label={
                <Typography variant="body1" component="div">
                  {t('signUp.citizen', 'Я гражданин Республики Узбекистан')}
                </Typography>
              }
              sx={{
                mr: 4.5,
              }}
            />
            <FormControlLabel
              value={0}
              control={<UiRadio />}
              label={
                <Typography variant="body1" component="div">
                  {t('signUp.foreigner', 'Я иностранец')}
                </Typography>
              }
            />
          </RadioGroup>
          <Grid container columnSpacing={r({ xs: 0, md: 3 })} wrap="nowrap">
            <Grid item className={clsx(classes.col, classes.colForm)}>
              {citizenship ? (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={t(
                      FIELD_LABELS[FIELD_BIRTH_DATE]['labelKey'],
                      FIELD_LABELS[FIELD_BIRTH_DATE]['label']
                    )}
                    // mask={FIELD_LABELS[FIELD_BIRTH_DATE]['mask']}
                    mask={'__.__.____'}
                    inputFormat="dd.MM.yyyy"
                    {...formik.getFieldProps(FIELD_BIRTH_DATE)}
                    open={openCalendar}
                    onClose={() => setOpenCalendar(false)}
                    onChange={(e: any) =>
                      handleFieldChange(FIELD_BIRTH_DATE, e)
                    }
                    renderInput={(params) => {
                      return (
                        <TextField
                          id={`${PREFIX}-${FIELD_BIRTH_DATE}`}
                          error={hasError(FIELD_BIRTH_DATE)}
                          helperText={getErrorMessage(FIELD_BIRTH_DATE)}
                          required
                          {...params}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label={t(
                                    'singUp.calendar',
                                    'Укажите дату'
                                  )}
                                  color="primary"
                                  size="medium"
                                  onClick={() => setOpenCalendar(true)}
                                >
                                  <SvgIcon
                                    className=""
                                    component={CalendarIcon}
                                    viewBox="0 0 24 24"
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                            'aria-label':
                              FIELD_LABELS[FIELD_BIRTH_DATE]['ariaLabel'],
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              ) : (
                <InputMask
                  mask={MASK_PINFL}
                  maskPlaceholder={null}
                  {...formik.getFieldProps(FIELD_PINFL)}
                >
                  <TextField
                    label={t(
                      FIELD_LABELS[FIELD_PINFL]['labelKey'],
                      FIELD_LABELS[FIELD_PINFL]['label']
                    )}
                    id={`${PREFIX}-${FIELD_PINFL}`}
                    error={hasError(FIELD_PINFL)}
                    helperText={getErrorMessage(FIELD_PINFL)}
                    required
                    InputProps={{
                      endAdornment: r({ xs: true, md: false }) && (
                        <InputAdornment position="end">
                          <Hidden mdUp>
                            <Hint
                              fullScreen
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                              }}
                              content={
                                citizenship ? (
                                  <SignUpMedia my={'auto'} />
                                ) : (
                                  <SignUpHelpForForeigners
                                    className={classes.help}
                                  />
                                )
                              }
                            >
                              <IconButton
                                aria-label={t('singUp.help', 'Помощь')}
                                color="primary"
                                size="medium"
                              >
                                <SvgIcon
                                  className=""
                                  component={HelpIcon}
                                  viewBox="0 0 24 24"
                                />
                              </IconButton>
                            </Hint>
                          </Hidden>
                        </InputAdornment>
                      ),
                      'aria-label': FIELD_LABELS[FIELD_PINFL]['ariaLabel'],
                    }}
                  />
                </InputMask>
              )}

              <InputMask
                mask={Boolean(citizenship) ? MASK_PASSPORT_SERIAL_NUMBER : ''}
                maskPlaceholder={null}
                {...formik.getFieldProps(FIELD_PASSPORT)}
                onChange={(e: any) => handleFieldChange(FIELD_PASSPORT, e)}
              >
                <TextField
                  label={t(
                    FIELD_LABELS[FIELD_PASSPORT]['labelKey'],
                    FIELD_LABELS[FIELD_PASSPORT]['label']
                  )}
                  id={`${PREFIX}-${FIELD_PASSPORT}`}
                  error={hasError(FIELD_PASSPORT)}
                  helperText={getErrorMessage(FIELD_PASSPORT)}
                  required
                  InputProps={{
                    'aria-label': FIELD_LABELS[FIELD_PASSPORT]['ariaLabel'],
                  }}
                />
              </InputMask>

              <TextField
                {...formik.getFieldProps(FIELD_EMAIL)}
                label={t(
                  FIELD_LABELS[FIELD_EMAIL]['labelKey'],
                  FIELD_LABELS[FIELD_EMAIL]['label']
                )}
                onChange={(e: any) => handleFieldChange(FIELD_EMAIL, e)}
                type="email"
                id={`${PREFIX}-${FIELD_EMAIL}`}
                error={hasError(FIELD_EMAIL)}
                helperText={getErrorMessage(FIELD_EMAIL)}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Hint
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        content={
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                          >
                            {t(
                              'signUp.help.hint',
                              'Электронная почта будет использоваться для отправки уведомлений и восстановления доступа. Если у вас нет электроной почты, она будет создана автоматически'
                            )}
                          </Typography>
                        }
                      >
                        <IconButton
                          aria-label={t('singUp.help', 'Помощь')}
                          color="primary"
                          size="medium"
                        >
                          <SvgIcon
                            className=""
                            component={HelpIcon}
                            viewBox="0 0 24 24"
                          />
                        </IconButton>
                      </Hint>
                    </InputAdornment>
                  ),
                  'aria-label': FIELD_LABELS[FIELD_EMAIL]['ariaLabel'],
                }}
              />

              <Typography
                variant="body2"
                component="div"
                color="textSecondary"
                mb={1}
              >
                {t(
                  'signUp.phone.text',
                  '* Внимание! Для регистрации введите номер телефона, зарегистрированный на Ваше имя! После успешной проверки на указанный телефон будет выслано СМС с кодом подтверждения.'
                )}
              </Typography>

              <InputMask
                mask={MASK_PHONE}
                maskPlaceholder={null}
                {...formik.getFieldProps(FIELD_PHONE)}
                onChange={(e: any) => {
                  handleFieldChange(FIELD_PHONE, e);
                  dispatch(clearAskSmsSates());
                }}
              >
                <TextField
                  label={t(
                    FIELD_LABELS[FIELD_PHONE]['labelKey'],
                    FIELD_LABELS[FIELD_PHONE]['label']
                  )}
                  id={`${PREFIX}-${FIELD_PHONE}`}
                  error={hasError(FIELD_PHONE)}
                  helperText={getErrorMessage(FIELD_PHONE)}
                  required
                  InputProps={{
                    'aria-label': FIELD_LABELS[FIELD_PHONE]['ariaLabel'],
                  }}
                />
              </InputMask>
            </Grid>
            {r({ xs: false, md: true }) && (
              <Grid item className={clsx(classes.col, classes.colMedia)}>
                {citizenship ? (
                  <SignUpMedia />
                ) : (
                  <SignUpHelpForForeigners className={classes.help} />
                )}
              </Grid>
            )}
          </Grid>

          <PhoneSms
            className={classes.sms}
            phone={formik.values.phone}
            data={formik.values}
            disabled={!isSmsSenderEnabled}
          />

          <Box className={classes.actions} pt={4} pb={r({ xs: 2, md: 0 })}>
            <Grid
              container
              rowSpacing={r({ xs: 3, md: 0 })}
              columnSpacing={r({ xs: 0, md: 4 })}
              alignItems="center"
              className={clsx(classes.row, classes.rowActions)}
            >
              <Grid
                item
                xs={12}
                md
                order={r({ xs: 0, md: 2 })}
                className={clsx(classes.col)}
              >
                <Typography
                  className={clsx(classes.text)}
                  variant="body2"
                  color="textSecondary"
                >
                  {t(
                    'signUp.helpText',
                    'После нажатия на кнопку «Далее» - Ваши персональные данные будут автоматически загружены. В случае обнаружения неточности, Вы можете обратиться в  ближайший Центр Государственных Услуг.'
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={'auto'}
                order={r({ xs: 0, md: 1 })}
                className={clsx(classes.col)}
              >
                <Button
                  disabled={!Boolean(get(authStore, 'sms.isValid'))}
                  onClick={nextStep}
                  variant="outlined"
                  className={classes.btn}
                  fullWidth={r({ xs: true, sm: false })}
                >
                  {t('action.next', 'Далее')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>

        <TabPanel value="2" className={classes.tabPanel}>
          {/* <Typography variant="h5" component="div" mb={3}>
            {t('signUp.personalData', 'Персональные данные')}
            <Typography
              variant="inherit"
              component="span"
              color="textSecondary"
              fontWeight="fontWeightRegular"
            >
              ({t('signUp.personalData.auto', 'получены автоматически')})
            </Typography>
          </Typography>
          <UserPersonalData profile={authStore} />

          <Divider light className={classes.divider} /> */}

          <Typography variant="h5" component="div" mb={3}>
            {t('signUp.newAccountDetails', 'Данные новой учетной записи')}
          </Typography>

          <Grid
            container
            columnSpacing={r({ xs: 0, md: 3 })}
            className={clsx(classes.row)}
          >
            <Grid item className={clsx(classes.col, classes.colForm)}>
              <TextField
                {...formik.getFieldProps(FIELD_LOGIN)}
                label={t(
                  FIELD_LABELS[FIELD_LOGIN]['labelKey'],
                  FIELD_LABELS[FIELD_LOGIN]['label']
                )}
                onChange={(e: any) => handleFieldChange(FIELD_LOGIN, e)}
                id={`${PREFIX}-${FIELD_LOGIN}`}
                error={hasError(FIELD_LOGIN)}
                helperText={getErrorMessage(FIELD_LOGIN)}
                disabled={isLoading}
                required
                InputProps={{
                  'aria-label': FIELD_LABELS[FIELD_LOGIN]['ariaLabel'],
                }}
              />
            </Grid>
            <Grid item xs className={clsx(classes.col, classes.colMedia)} />
            <Grid item className={clsx(classes.col, classes.colForm)}>
              <TextField
                {...formik.getFieldProps(FIELD_PASSWORD)}
                label={t(
                  FIELD_LABELS[FIELD_PASSWORD]['labelKey'],
                  FIELD_LABELS[FIELD_PASSWORD]['label']
                )}
                onChange={(e: any) => handleFieldChange(FIELD_PASSWORD, e)}
                id={`${PREFIX}-${FIELD_PASSWORD}`}
                error={hasError(FIELD_PASSWORD)}
                helperText={getErrorMessage(FIELD_PASSWORD)}
                disabled={isLoading}
                type={typePassword ? 'password' : 'text'}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setTypePassword(!typePassword)}
                        aria-label={t('signUp.password', 'Пароль')}
                        color="primary"
                        size="medium"
                      >
                        <SvgIcon
                          component={typePassword ? EyeIcon : EyeHideIcon}
                          viewBox="0 0 24 24"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  'aria-label': FIELD_LABELS[FIELD_PASSWORD]['ariaLabel'],
                  autoComplete: 'off',
                }}
              />
            </Grid>
            <Grid
              item
              pt={r({ xs: 0, md: 3 })}
              pb={r({ xs: 3, md: 0 })}
              className={clsx(classes.col, classes.colMedia)}
            >
              <Button
                variant="outlined"
                size={r({ xs: 'small', md: 'medium' })}
                onClick={generatePassword}
              >
                {t('signUp.password.generate', 'Создать надежный пароль')}
              </Button>
            </Grid>
            <Grid item className={clsx(classes.col, classes.colForm)}>
              <TextField
                {...formik.getFieldProps(FIELD_PASSWORD_REPEAT)}
                label={t(
                  FIELD_LABELS[FIELD_PASSWORD_REPEAT]['labelKey'],
                  FIELD_LABELS[FIELD_PASSWORD_REPEAT]['label']
                )}
                onChange={(e: any) =>
                  handleFieldChange(FIELD_PASSWORD_REPEAT, e)
                }
                id={`${PREFIX}-${FIELD_PASSWORD_REPEAT}`}
                error={hasError(FIELD_PASSWORD_REPEAT)}
                helperText={getErrorMessage(FIELD_PASSWORD_REPEAT)}
                disabled={isLoading}
                type={typePasswordRepeat ? 'password' : 'text'}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          t(
                            typePasswordRepeat
                              ? `action.password.hide`
                              : `action.password.show`
                          ) || ''
                        }
                        arrow
                      >
                        <IconButton
                          onClick={() =>
                            setTypePasswordRepeat(!typePasswordRepeat)
                          }
                          aria-label={t(
                            'signUp.password.repeat',
                            'Повторите пароль'
                          )}
                          color="primary"
                          size="medium"
                        >
                          <SvgIcon
                            component={
                              typePasswordRepeat ? EyeIcon : EyeHideIcon
                            }
                            viewBox="0 0 24 24"
                          />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  'aria-label':
                    FIELD_LABELS[FIELD_PASSWORD_REPEAT]['ariaLabel'],
                  autoComplete: 'off',
                }}
              />
            </Grid>
          </Grid>

          <Collapse in={isInteger(code) && Number(code) !== 0}>
            <>
              <Alert severity="error">
                {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`)}
              </Alert>
              <Divider light className={classes.divider} />
            </>
          </Collapse>

          <Box className={classes.actions} pt={4} pb={r({ xs: 2, md: 0 })}>
            <Grid
              container
              rowSpacing={r({ xs: 3, md: 0 })}
              columnSpacing={r({ xs: 0, md: 4 })}
              alignItems="center"
              className={clsx(classes.row, classes.rowActions)}
            >
              <Grid
                item
                xs={12}
                md={'auto'}
                mr={'auto'}
                className={clsx(classes.col)}
              >
                <Button
                  onClick={prevStep}
                  variant="outlined"
                  className={classes.btn}
                  fullWidth={r({ xs: true, lg: false })}
                >
                  {t('action.back', 'Назад')}
                </Button>
              </Grid>
              <Grid item xs={12} md={'auto'} className={clsx(classes.col)}>
                <LoadingButton
                  loading={isLoading}
                  disabled={!noErrors}
                  variant="contained"
                  type="submit"
                  className={classes.btn}
                  fullWidth={r({ xs: true, lg: false })}
                >
                  {t('action.signUp', 'Зарегистрироваться')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </TabContext>
    </form>
  );
};
