import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'e-sign-list',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    title: {
      marginBottom: theme.spacing(2),
    },
    item: {
      marginBottom: theme.spacing(2),
      '&:last-child, &$last': {
        marginBottom: 0,
      },
    },
    last: {},
  }),
  options
);

export default useStyles;
