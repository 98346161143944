import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'a11y',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      cursor: 'pointer',
      userSelect: 'none',
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      '&:hover, &:focus:hover': {
        '& $text': {
          textDecoration: 'underline',
        },
      },
    },
    icon: {
      flex: '0 0 24px',
      marginRight: theme.spacing(1.5),
      height: 24,
      width: 24,
    },
    text: {
      flex: `0 0 auto`,
      maxWidth: `calc(100% - 36px)`,
    },
  }),
  options
);

export default useStyles;
