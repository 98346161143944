import { Tooltip } from '@material-ui/core';
import { FormikValues, useFormik } from 'formik';
import { get } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../common/store';
import {
  clearRecoverPasswordSmsStates,
  clearRecoverPasswordStates,
  recoverPassword,
  recoverPasswordSms,
} from '../../../thunks/profile-thunks';
import {
  FIELD_PHONE,
  FIELD_PINFL,
  FIELD_SMS_CODE,
  PasswordRecoveryValidationForm,
} from './password-recovery-schema';

export const usePasswordRecovery = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [codeSms, setCodeSms] = useState<String>();

  const { passwordRecovery: sendSms } = useSelector(
    (state: State) => state.profile
  );
  const { passwordRecoverySMS: checkSms } = useSelector(
    (state: State) => state.profile
  );

  const handleSendSms = () => {
    handleSubmit(formik.values);
  };

  const handleResendSms = () => {
    handleSubmit(formik.values);
  };

  const handleSmsChange = (code: string) => {
    setCodeSms(code);
  };

  const handleSubmit = (values: FormikValues) => {
    if (!Boolean(codeSms)) {
      const data = {
        [FIELD_PINFL]: values[FIELD_PINFL],
        [FIELD_PHONE]: values[FIELD_PHONE],
      };

      dispatch(recoverPassword(data));
    }

    if (Boolean(codeSms)) {
      const data = {
        [FIELD_PINFL]: values[FIELD_PINFL],
        [FIELD_SMS_CODE]: codeSms,
        phoneNumber: values[FIELD_PHONE].replace(/ /g, ''),
      };

      dispatch(recoverPasswordSms(data));
    }
  };

  const handleRetry = () => {
    // action.toRetry
    dispatch(clearRecoverPasswordStates());
    dispatch(clearRecoverPasswordSmsStates());
  };

  const handleFieldChange = (field: string, e: any) => {
    // console.log(field);
    // console.log(e);
    let value = e.target.value;
    // if (field === FIELD_PHONE) {
    //   value = value.toUpperCase();
    // }
    formik.setFieldValue(field, value);
    formik.setFieldError(field, null);
    dispatch(clearRecoverPasswordStates());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_PINFL]: '',
      [FIELD_PHONE]: '',
    },
    validationSchema: PasswordRecoveryValidationForm,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return {
    codeSms,
    sendSms,
    checkSms,
    formik,
    handleSubmit,
    handleRetry,
    handleSendSms,
    handleResendSms,
    handleSmsChange,
    handleFieldChange,
    hasError,
    getErrorMessage,
  };
};
