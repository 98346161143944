import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';

export const SignUpMedia: ComponentType<BoxProps> = ({ ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.media} component="picture" {...props}>
      <source srcSet="/assets/img/photo5.jpg" type="image/webp" />
      <source
        srcSet="/assets/img/photo5.jpg 1x, /assets/img/photo5.jpg 2x,  /assets/img/photo5.jpg 3x"
        type="image/jpeg"
      />
      <img
        className={classes.img}
        src="/assets/img/photo5.jpg"
        srcSet="/assets/img/photo5.jpg 2x,  /assets/img/photo5.jpg 3x"
        alt={t('signUp.passport.exmaple', 'Пример паспорта')}
      />
    </Box>
  );
};
