import {
  Alert,
  Collapse,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import clsx from 'clsx';
import { get, isInteger } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import useCountDown from 'react-countdown-hook';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { displayTime } from '../../../common/utils/format-utils';
import {
  checkSms,
  clearAskSmsSates,
  clearSmsStates,
  requestSms,
} from '../../../thunks/auth-thunks';
import useStyles from './styles';

interface PhoneSmsProps {
  className?: any;
  variant?: any;
  phone?: string;
  data: {
    email: string;
    passport: string;
    phone: string;
    pinfl: string;
    birthDate: Date | null;
  };
  disabled?: boolean;
}

export const PhoneSms: ComponentType<PhoneSmsProps> = ({
  className,
  // variant, // phone,
  data,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const resendTime = 120 * 1000;
  const [timeLeft, { start: startTimer }] = useCountDown(resendTime, 1000);
  const auth = useSelector((state: State) => state.auth);

  const pinflData = get(auth, 'pinflData', {
    isLoading: false,
    isValid: false,
    code: null,
    message: null,
  });
  const phone = get(auth, 'phone', {
    isLoading: false,
    isValid: false,
    code: null,
    message: null,
  });
  const askSms = get(auth, 'askSms', {
    isLoading: false,
    code: null,
    message: null,
    data: null,
  });
  const sms = get(auth, 'sms', {
    isLoading: false,
    isValid: false,
    code: null,
    message: null,
  });

  const phoneConfirmed = get(phone, 'isValid', false);
  const pinflConfirmed = get(pinflData, 'isValid', false);
  const birthDateConfirmed = true;

  const askSmsHasError = isInteger(askSms.code) && Number(askSms.code) !== 0;
  const smsHasError = isInteger(sms.code) && Number(sms.code) !== 0;

  useEffect(() => {
    if (askSmsHasError) {
      setOpen(false);
    }
  }, [askSmsHasError]);

  const countDown = displayTime(timeLeft / 1000);

  const handleOpen = () => {
    dispatch(requestSms(data));
    !askSmsHasError && setOpen(!open);
    startTimer();
  };

  const _handleSmsCodeCheck = (event: any) => {
    const code = event.target.value;
    if (code.length === 6) {
      dispatch(checkSms(code));
    } else {
      dispatch(clearSmsStates());
      dispatch(clearAskSmsSates());
    }
  };

  const _resendSms = () => {
    dispatch(requestSms(data));
    dispatch(clearAskSmsSates());
    startTimer(resendTime);
  };

  return (
    <div className={clsx(classes.root, className)}>
      {
        <LoadingButton
          onClick={handleOpen}
          variant="contained"
          size="small"
          disabled={
            disabled ||
            !Boolean(phoneConfirmed) ||
            (!Boolean(pinflConfirmed) && !Boolean(birthDateConfirmed)) ||
            askSmsHasError
          }
          loading={askSms.isLoading}
        >
          {t('phone.sms.get', 'Получить SMS с кодом')}
        </LoadingButton>
      }

      {open && !askSmsHasError && !askSms.isLoading && (
        <>
          <Typography
            component="div"
            variant="body2"
            color="textSecondary"
            mb={0.5}
          >
            {t(
              'phone.sms.title',
              'На указанный вами телефона мы отправили SMS с кодом'
            )}
          </Typography>

          <Grid container className={clsx(classes.row)}>
            <Grid
              xs={'auto'}
              item
              className={clsx(classes.col, classes.colCode)}
            >
              <TextField
                id="code"
                className={classes.code}
                label={t('phone.sms.code', 'Код из SMS')}
                size="small"
                required
                onChange={_handleSmsCodeCheck}
                helperText={' '}
                error={smsHasError}
                fullWidth={false}
                // maxLength={6}
                InputProps={{
                  'aria-label': t('phone.sms.code', 'Код из SMS'),
                }}
              />
            </Grid>
            <Grid
              className={clsx(classes.col, classes.colText)}
              xs
              item
              alignSelf="flex-start"
            >
              <Typography
                className={classes.text}
                variant="caption"
                color="textSecondary"
                component="div"
              >
                {/* Новое SMS можно будетотправить через 00:59 */}
                {t('phone.sms.countDown', { countDown })}
              </Typography>
            </Grid>
            <Grid
              xs={'auto'}
              item
              className={clsx(classes.col, classes.colActions)}
            >
              <LoadingButton
                disabled={!Boolean(timeLeft === 0)}
                onClick={_resendSms}
                loading={sms.isLoading}
                className={classes.btn}
                variant="outlined"
                size="small"
              >
                {t('phone.sms.again', 'Отправить еще')}
              </LoadingButton>
            </Grid>
          </Grid>
        </>
      )}

      <Collapse in={askSmsHasError}>
        <Alert
          severity="error"
          sx={{
            mt: 3,
          }}
        >
          {t(`error.api.${askSms.code}.full`, `Ошибка с кодом ${askSms.code}`)}
        </Alert>
      </Collapse>

      <Collapse in={smsHasError}>
        <Alert
          severity="error"
          sx={{
            mt: 3,
          }}
        >
          {t(`error.api.${askSms.code}.full`, `Ошибка с кодом ${sms.code}`)}
        </Alert>
      </Collapse>
    </div>
  );
};
