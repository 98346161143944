import * as yup from 'yup';

export const FIELD_TEXT = 'text';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_TEXT]: {
    label: 'Описание ошибки',
    labelKey: 'error.report.description',
    arialLabel: 'Your description',
  },
};

export const SendReportValidationForm = yup.object().shape({
  [FIELD_TEXT]: yup
    .string()
    .min(8, 'error.minLength')
    .max(160, 'error.maxLength')
    .required('error.required')
    .trim(),
});
