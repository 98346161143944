import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'help-questions',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  branch: {
    boxShadow: 'none',
    transition: 'background 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    background: 'transparent',
  },
  branchExpanded: {
    margin: 0,
    borderRadius: 4,
    background: theme.palette.grey[400],
    '& $branchHeaderIcon': {
      transform: 'rotate(-90deg)',
    },
    '& + $branch': {
      '&:before': {
        opacity: 0,
      },
    },
  },
  branchHeader: {
    minHeight: 50,
    margin: 0,
    padding: theme.spacing(0, 2.5),
    '&.Mui-expanded': {
      minHeight: 50,
    },
  },
  branchDetails: {
    padding: 0,
  },
  branchHeaderIcon: {
    '& svg': {
      fontSize: 16,
    },
  },
  question: {
    position: 'relative',
    boxShadow: 'none',
    background: 'transparent',
    '&:before': {
      top: 0,

      background: theme.palette.common.white,
    },
    '&:first-of-type': {
      '&:before': {
        display: 'block',
      },
    },
  },
  questionExpanded: {
    margin: 0,
    '&:before': {
      opacity: '1 !important',
    },
  },
  questionHeader: {
    minHeight: 50,
    padding: theme.spacing(0, 2.5, 0, 3.5),
    '&.Mui-expanded': {
      minHeight: 50,
    },
  },
  questionHeaderIcon: {
    opacity: 0,
    transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  questionHeaderIconExpanded: {
    opacity: 1,
  },
  questionHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:before': {
      content: '""',
      flexShrink: 0,
      flexBasis: 8,
      display: 'block',
      width: 8,
      height: 8,
      marginRight: theme.spacing(1.75),
      borderRadius: '50%',
      background: theme.palette.primary.main,
    },
  },
  questionDetails: {
    padding: theme.spacing(1.25, 6.25, 2.5),
    fontSize: 14,
    '& p': {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1.25),
      },
    },
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    '& ol': {
      margin: 0,
      padding: theme.spacing(0, 0, 0, 2),
      listStyle: 'none',
      counterReset: 'item',
      '& li': {
        display: 'flex',
        alignItems: 'flex-start',
        counterIncrement: 'item',
        '&:before': {
          content: 'counter(item)',
          display: 'block',
          position: 'relative',
          marginRight: theme.spacing(1.25),
          color: theme.palette.grey[900],
        },
      },
    },
    '& li': {
      color: theme.palette.grey[800],
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  },
  options,
}));

export default useStyles;
