import { SvgIcon } from '@material-ui/core';
import { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon, IconLabels } from '../../../common/icons';

interface IconListItemProps {
  href?: string;
  icon: string;
  label: string;
}

interface IconListProps {
  data: IconListItemProps[];
}

export const IconList: ComponentType<IconListProps> = ({ data }) => {
  return (
    <ul>
      {(data || []).map(
        (
          { label, icon: iconLabel, iconColor, href, ...rest }: any,
          idx: number
        ) => {
          const icon = (
            <SvgIcon
              component={IconLabels[iconLabel] || CheckIcon}
              color={iconColor ? iconColor : 'primary'}
              sx={{ mr: 0.5 }}
            />
          );
          return (
            <li key={idx}>
              {href ? (
                <Link to={href} {...rest}>
                  {icon}
                  {label}
                </Link>
              ) : (
                <>
                  {icon}
                  {label}
                </>
              )}
            </li>
          );
        }
      )}
    </ul>
  );
};
