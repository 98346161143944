import { Grid, TextField, Typography } from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import clsx from 'clsx';
import { get, isInteger } from 'lodash';
import { ComponentType, useEffect } from 'react';
import useCountDown from 'react-countdown-hook';
import { useTranslation } from 'react-i18next';
import { displayTime } from '../../../common/utils/format-utils';
import useStyles from './styles';

interface PhoneConfirmProps {
  className?: string;
  delay?: number;
  storeState: Record<string, any> | any;
  onRetry: () => void;
  onChange: (e: any) => void;
  onSubmit: (code: string) => void;
}

export const PhoneConfirm: ComponentType<PhoneConfirmProps> = ({
  className,
  delay = 120 * 1000,
  storeState = {},
  onRetry,
  onChange,
  onSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [timeLeft, { start: startTimer }] = useCountDown(delay, 1000);

  const isLoading = get(storeState, 'isLoading', false);
  const code = get(storeState, 'code', false);

  const hasError = isInteger(code) && Number(code) !== 0;

  const countDown = displayTime(timeLeft / 1000);

  const handleChangeCode = (e: any) => {
    const code = e.target.value;
    if (code.length === 6 && onSubmit) {
      onSubmit(code);
    } else {
      if (onChange) onChange(e);
    }
  };

  const handleRetry = () => {
    startTimer();
    if (onRetry) onRetry();
  };

  useEffect(() => {
    startTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={clsx(classes.root, className)}>
      <Typography
        component="div"
        variant="body2"
        color="textSecondary"
        mb={0.5}
      >
        {t(
          'phone.sms.title',
          'На указанный вами телефона мы отправили SMS с кодом'
        )}
      </Typography>
      <Grid container className={clsx(classes.row)}>
        <Grid xs={'auto'} item className={clsx(classes.col, classes.colCode)}>
          <TextField
            id="code"
            className={classes.code}
            label={t('phone.sms.code', 'Код из SMS')}
            size="small"
            required
            onChange={handleChangeCode}
            helperText={' '}
            error={hasError}
            fullWidth={false}
            InputProps={{
              'aria-label': t('phone.sms.code', 'Код из SMS'),
            }}
          />
        </Grid>
        <Grid
          className={clsx(classes.col, classes.colText)}
          xs
          item
          alignSelf="flex-start"
        >
          <Typography
            className={classes.text}
            variant="caption"
            color="textSecondary"
            component="div"
          >
            {/* Новое SMS можно будетотправить через 00:59 */}
            {t('phone.sms.countDown', { countDown })}
          </Typography>
        </Grid>
        <Grid
          xs={'auto'}
          item
          className={clsx(classes.col, classes.colActions)}
        >
          <LoadingButton
            disabled={!Boolean(timeLeft === 0)}
            onClick={handleRetry}
            loading={isLoading}
            className={classes.btn}
            variant="outlined"
            size="small"
          >
            {t('phone.sms.again', 'Отправить еще')}
          </LoadingButton>
        </Grid>
      </Grid>
    </div>
  );
};
