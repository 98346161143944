import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'e-sign-browse',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    text: {
      '& > p': {
        marginBottom: theme.spacing(1),
        '&:last-child, &$last': {
          marginBottom: 0,
        },
      },
    },
    last: {},
  }),
  options
);

export default useStyles;
