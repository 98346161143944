import { Dispatch } from 'redux';
import i18n from '../common/i18next';
import { getISOLocale } from '../common/locales';
import { api, HEADERS } from '../common/utils/fetch-utils';
import { rplSpace } from '../common/utils/format-utils';
import { profileActions } from '../slices/profile-slice';
import { openSuccessSnackbar } from '../slices/toaster-slice';
import { getUserInfo, restoreAuthToken, userLogOut } from './auth-thunks';
const {
  getRefreshDataRequest,
  getRefreshDataSuccess,
  getRefreshDataFailure,

  setPhoneRequest,
  setPhoneSuccess,
  setPhoneFailure,
  setPhoneDefault,

  setAutificationPhoneRequest,
  setAutificationPhoneSuccess,
  setAutificationPhoneFailure,
  setAutificationPhoneDefault,

  setEmailRequest,
  setEmailSuccess,
  setEmailFailure,
  setEmailDefault,

  setPasswordRequest,
  setPasswordSuccess,
  setPasswordFailure,
  setPasswordDefault,

  setRecoverPasswordRequest,
  setRecoverPasswordSuccess,
  setRecoverPasswordFailure,
  setRecoverPasswordDefault,

  setRecoverPasswordSmsRequest,
  setRecoverPasswordSmsSuccess,
  setRecoverPasswordSmsFailure,
  setRecoverPasswordSmsDefault,

  setLoginRequest,
  setLoginSuccess,
  setLoginFailure,
  setLoginDefault,

  setPassportSerialNumberRequest,
  setPassportSerialNumberSuccess,
  setPassportSerialNumberFailure,
  setPassportSerialNumberDefault,

  setPhotoRequest,
  setPhotoSuccess,
  setPhotoFailure,
  setPhotoDefault,

  setProfileRemoveRequest,
  setProfileRemoveSuccess,
  setProfileRemoveFailure,
  setProfileRemoveDefault,

  setProfileSmsRequest,
  setProfileSmsSuccess,
  setProfileSmsFailure,
  setProfileSmsDefault,

  setProfileDefault,
} = profileActions;

export const getRefreshData = () => async (dispatch: any) => {
  dispatch(getRefreshDataRequest());
  try {
    api.addHeader(HEADERS.AUTHORIZATION, `Bearer ${restoreAuthToken()}`);
    api.addHeader(HEADERS.ACCEPT_LANGUAGE, getISOLocale());
    const { data, status } = await api.get(`profile/refresh/data`);
    if (status.code === 0) {
      openSuccessSnackbar(
        i18n.t('success.updated', 'Данные успешно обновлены')
      );
      dispatch(getRefreshDataSuccess(data));
    }
  } catch (error: any) {
    console.error(error);
    dispatch(getRefreshDataFailure(error));

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};

//User authentication width Phone
export const checkAuthenticationPhone = () => async (dispatch: any) => {
  dispatch(setAutificationPhoneRequest());

  try {
    const { status, data: responseData } = await api.get(
      'profile/phoneVerified'
    );
    if (status.code === 0) {
      dispatch(
        setAutificationPhoneSuccess({
          ...status,
          data: rplSpace(responseData?.newPhoneNumber),
        })
      );
    } else {
      dispatch(setAutificationPhoneFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(setAutificationPhoneFailure({ code: -1, message: 'error' }));
  }
};
export const clearAuthenticationPhoneStates = () => async (dispatch: any) => {
  dispatch(setAutificationPhoneDefault());
};

//User profile phone change request
export const changePhone = (data: any) => async (dispatch: any) => {
  dispatch(setPhoneRequest());
  try {
    const { status, data: responseData } = await api.post(
      'profile/changePhone',
      {
        newPhoneNumber: rplSpace(data.newPhoneNumber),
      }
    );
    if (status.code === 0) {
      dispatch(
        setPhoneSuccess({
          ...status,
          data: rplSpace(responseData?.newPhoneNumber),
        })
      );
    } else {
      dispatch(setPhoneFailure(status));
    }
  } catch (error: any) {
    console.error(error);
    dispatch(setPhoneFailure({ code: -1, message: 'error' }));

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};
export const clearPhoneStates = () => async (dispatch: any) => {
  dispatch(setPhoneDefault());
};

//User profile confirm phone sms
export const confirmProfilePhone = (data: any) => async (dispatch: any) => {
  dispatch(setProfileSmsRequest());
  try {
    const { status } = await api.post('profile/phone/smsCheck', {
      ...data,
      newPhoneNumber: rplSpace(data.newPhoneNumber),
    });
    if (status.code === 0) {
      dispatch(setProfileSmsSuccess(status));
    } else {
      dispatch(setProfileSmsFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(setProfileSmsFailure({ code: -1, message: 'error' }));
  }
};
export const clearProfileSmsStates = () => async (dispatch: any) => {
  dispatch(setProfileSmsDefault());
};

//User profile email change request
export const changeEmail = (data: any) => async (dispatch: any) => {
  dispatch(setEmailRequest());
  try {
    const { status, data: responseData } = await api.post(
      'profile/changeEmail',
      data
    );
    if (status.code === 0) {
      dispatch(setEmailSuccess({ ...status, email: responseData }));
    } else {
      dispatch(setEmailFailure(status));
    }
  } catch (error: any) {
    console.error(error);
    dispatch(setEmailFailure({ code: -1, message: 'error' }));

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};
export const clearEmailStates = () => async (dispatch: any) => {
  dispatch(setEmailDefault());
};

//User profile password change request
export const changePassword = (data: any) => async (dispatch: any) => {
  dispatch(setPasswordRequest());
  try {
    const { status } = await api.post('profile/change/password', data);
    if (status.code === 0) {
      dispatch(setPasswordSuccess(status));
      openSuccessSnackbar(
        i18n.t('success.updated', 'Данные успешно обновлены')
      );
      dispatch(getUserInfo());
    } else {
      dispatch(setPasswordFailure(status));
    }
  } catch (error: any) {
    console.log(error);
    dispatch(setPasswordFailure({ code: -1, message: 'error' }));

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};
export const clearPasswordStates = () => async (dispatch: any) => {
  dispatch(setPasswordDefault());
};

//User profile recover password request

export const recoverPassword = (data: any) => async (dispatch: any) => {
  console.log(data);
  const { phone, pinfl } = data;
  dispatch(setRecoverPasswordRequest());
  try {
    const { status, data: responseData } = await api.post(
      'profile/getCodeToRecoveryPass',
      // 'https://id-.egov.uz/api/v1/profile/getCodeToRecoveryPass',
      {
        phoneNumber: rplSpace(phone),
        pinfl: rplSpace(pinfl),
      }
    );
    if (status.code === 0) {
      dispatch(setRecoverPasswordSuccess({ ...status, data: responseData }));
    } else {
      dispatch(setRecoverPasswordFailure({ ...status, data: responseData }));
    }
  } catch (error) {
    console.error(error);
    dispatch(setRecoverPasswordFailure({ code: -1, message: 'error' }));
  }
};
export const clearRecoverPasswordStates = () => async (dispatch: any) => {
  dispatch(setRecoverPasswordDefault());
};

export const recoverPasswordSms = (data: any) => async (dispatch: any) => {
  dispatch(setRecoverPasswordSmsRequest());
  try {
    const { status } = await api.post('profile/recoveryPasswordByCode', data);
    if (status.code === 0) {
      dispatch(setRecoverPasswordSmsSuccess(status));
    } else {
      dispatch(setRecoverPasswordSmsFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(setRecoverPasswordSmsFailure({ code: -1, message: 'error' }));
  }
};
export const clearRecoverPasswordSmsStates = () => async (dispatch: any) => {
  dispatch(setRecoverPasswordSmsDefault());
};

//User profile change login request
export const changeLogin = (data: any) => async (dispatch: any) => {
  dispatch(setLoginRequest());
  try {
    const { status } = await api.post('profile/changeLogin', data);
    if (status.code === 0) {
      dispatch(setLoginSuccess(status));
      openSuccessSnackbar(
        i18n.t('success.updated', 'Данные успешно обновлены')
      );
      dispatch(getUserInfo());
    } else {
      dispatch(setLoginFailure(status));
    }
  } catch (error: any) {
    console.error(error);
    dispatch(setLoginFailure({ code: -1, message: 'error' }));
    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};
export const clearLoginStates = () => async (dispatch: any) => {
  dispatch(setLoginDefault());
};

//User profile change passport serial number request
export const changePasswordSerialNumber =
  (data: any) => async (dispatch: any) => {
    dispatch(setPassportSerialNumberRequest());
    try {
      const { status } = await api.post('profile/changeDataWithDocument', data);
      if (status.code === 0) {
        dispatch(setPassportSerialNumberSuccess(status));
        dispatch(getUserInfo());
      } else {
        dispatch(setPassportSerialNumberFailure(status));
      }
    } catch (error) {
      console.error(error);
      dispatch(setPassportSerialNumberFailure({ code: -1, message: 'error' }));
    }
  };
export const clearPasswordSerialNumberStates = () => async (dispatch: any) => {
  dispatch(setPassportSerialNumberDefault());
};

//Fetch user profile photo
export const getPhotoForce = (pinfl: string) => async (dispatch: any) => {
  dispatch(setPhotoRequest());
  try {
    api.addHeader(HEADERS.AUTHORIZATION, `Bearer ${restoreAuthToken()}`);
    const { data, status } = await api.post(`profile/getPhoto`, { pinfl });
    if (status.code === 0) {
      dispatch(setPhotoSuccess(data?.photo));
    } else {
      dispatch(setPhotoFailure());
    }
  } catch (error) {
    console.error(error);
    dispatch(setPhotoFailure());
  }
};
export const getPhoto =
  (pinfl: string) => async (dispatch: any, getState: any) => {
    const storeStates = getState();
    const { photo } = storeStates.profile;
    if (!photo.data && !photo.isLoading) {
      dispatch(getPhotoForce(pinfl));
    }
  };
export const clearPhotoStates = () => async (dispatch: Dispatch) => {
  dispatch(setPhotoDefault());
};

//User profile remove request
export const removeProfile = (data: any) => async (dispatch: any) => {
  dispatch(setProfileRemoveRequest());
  try {
    const { status } = await api.post('profile/account/delete', data);
    if (status.code === 0) {
      try {
        dispatch(setProfileRemoveSuccess(status.message));
        dispatch(userLogOut());
      } catch (error) {
        console.error(error);
      }
    } else {
      dispatch(setProfileRemoveFailure(status.message));
    }
  } catch (error) {
    console.error(error);
    dispatch(setProfileRemoveFailure(error));
  }
};
export const clearRemoveProfileStates = () => async (dispatch: any) => {
  dispatch(setProfileRemoveDefault());
};

export const clearProfileCredentials = () => async (dispatch: any) => {
  dispatch(setProfileDefault());
};

//Clear all states in profile
export const clearProfile = () => async (dispatch: any) => {
  dispatch(clearPhoneStates() as any);
  dispatch(clearEmailStates() as any);
  dispatch(clearPasswordStates() as any);
  dispatch(clearRecoverPasswordStates() as any);
  dispatch(clearLoginStates() as any);
  dispatch(clearPasswordSerialNumberStates() as any);
  dispatch(clearPhotoStates() as any);
  dispatch(clearRemoveProfileStates() as any);
  dispatch(clearProfileCredentials() as any);
};

//
// curl 'https://id.egov.uz/api/v1/profile/resetPasswordByPinfl' \
//   -H 'Connection: keep-alive' \
//   -H 'Pragma: no-cache' \
//   -H 'Cache-Control: no-cache' \
//   -H 'sec-ch-ua: "Chromium";v="92", " Not A;Brand";v="99", "Google Chrome";v="92"' \
//   -H 'Accept: application/json, text/plain, */*' \
//   -H 'Accept-Language: ru' \
//   -H 'sec-ch-ua-mobile: ?0' \
//   -H 'User-Agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/92.0.4515.107 Safari/537.36' \
//   -H 'Content-Type: application/json;charset=UTF-8' \
//   -H 'Origin: https://id.egov.uz' \
//   -H 'Sec-Fetch-Site: same-origin' \
//   -H 'Sec-Fetch-Mode: cors' \
//   -H 'Sec-Fetch-Dest: empty' \
//   -H 'Referer: https://id.egov.uz/' \
//   --data-raw '{"pinfl":"12345678901212","passSeriaNumber":"AA5477777"}' \
//   --compressed
