import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import useEventListener from '@use-it/event-listener';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CloseIcon } from '../../../common/icons';
import { clearSendReport } from '../../../thunks/error-thunks';
import { useResponsive } from '../../theme/useResponsive';
import { ErrorForm } from '../index';
import useStyles from './styles';

interface ErrorDialogProps {
  open?: boolean;
}

export const ErrorDialog: ComponentType<ErrorDialogProps> = ({
  open: dialogOpen = false,
}) => {
  const r = useResponsive();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(dialogOpen);
  const handleClose = () => {
    setOpen(false);
    dispatch(clearSendReport());
  };

  const handleOpen = (value: any) => {
    if (value.ctrlKey && value.keyCode === 13) {
      setOpen(true);
    }
  };
  useEventListener('keydown', handleOpen);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={r({ xs: true, lg: false })}
      scroll={'body'}
      maxWidth={'md'}
      aria-labelledby="error-title"
      aria-describedby="error-dialog"
    >
      <DialogContent id="error-dialog">
        <Box
          sx={{
            position: r({ xs: 'static', lg: 'absolute' }),
            top: 4,
            right: 4,
            display: 'flex',
            justifyContent: 'flex-end',
            pt: r({ xs: 0, lg: 0 }),
            m: r({ xs: '-12px -12px 0 0', lg: '0' }),
          }}
        >
          <IconButton
            onClick={handleClose}
            color="primary"
            aria-label={t('action.dialog.close', 'Закрыть диалоговое окно')}
          >
            <SvgIcon component={CloseIcon} />
          </IconButton>
        </Box>
        <Typography component="div" variant="h4" mb={2.5} id="error-title">
          {t('error.report.title', 'Сообщить об ошибке')}
        </Typography>
        <ErrorForm className={classes.form} onSuccess={handleClose} />
      </DialogContent>
    </Dialog>
  );
};
