import * as yup from 'yup';
import 'yup-phone';

export const FIELD_CITIZENSHIP = 'citizenship';
export const FIELD_BIRTH_DATE = 'birthDate';
export const FIELD_PINFL = 'pinfl';
export const FIELD_PASSPORT = 'passport';
export const FIELD_EMAIL = 'email';
export const FIELD_PHONE = 'phone';
export const FIELD_LOGIN = 'login';
export const FIELD_PASSWORD = 'password';
export const FIELD_PASSWORD_REPEAT = 'password_repeat';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_CITIZENSHIP]: {
    label: 'Гражданство',
    labelKey: 'signUp.citizenship',
    ariaLabel: 'Your citizenship',
  },
  [FIELD_BIRTH_DATE]: {
    label: 'Дата рождения',
    labelKey: 'signUp.dateBirth',
    ariaLabel: 'Your date of birth ',
    mask: '__.__.____',
  },
  [FIELD_PINFL]: {
    label: 'ПИНФЛ',
    labelKey: 'signUp.id',
    ariaLabel: 'Your id',
  },
  [FIELD_PASSPORT]: {
    label: 'Серия и Номер паспорта',
    labelKey: 'signUp.passportId',
    ariaLabel: 'Your series and passport number',
  },
  [FIELD_EMAIL]: {
    label: 'Электронная почта',
    labelKey: 'signUp.email',
    ariaLabel: 'Your email',
  },
  [FIELD_PHONE]: {
    label: 'Телефон',
    labelKey: 'signUp.phone',
    ariaLabel: 'Your phone number',
  },
  [FIELD_LOGIN]: {
    label: 'Логин',
    labelKey: 'signUp.login',
    ariaLabel: 'Your login',
  },
  [FIELD_PASSWORD]: {
    label: 'Пароль',
    labelKey: 'signUp.password',
    ariaLabel: 'Your password',
  },
  [FIELD_PASSWORD_REPEAT]: {
    label: 'Повторите пароль',
    labelKey: 'signUp.password.repeat',
    ariaLabel: 'Password repeat',
  },
};

export const SignUpValidationSchema = yup.object().shape({
  [FIELD_CITIZENSHIP]: yup.number(),
  [FIELD_BIRTH_DATE]: yup.string().when([FIELD_CITIZENSHIP], {
    is: 1,
    then: yup.string().required('error.required').trim(),
  }),
  [FIELD_PINFL]: yup.string().when([FIELD_CITIZENSHIP], {
    is: 0,
    then: yup
      .string()
      .transform((value) => value.replace(/\s/g, ''))
      .length(14, 'error.passport.pin.length')
      .required('error.required')
      .trim(),
  }),
  [FIELD_PASSPORT]: yup
    .string()
    .transform((value) => value.replace(/\s/g, ''))
    .required('error.required')
    .trim(),
  [FIELD_EMAIL]: yup
    .string()
    .email('error.email.notValid')
    .required('error.required')
    .trim(),
  [FIELD_PHONE]: yup
    .string()
    .transform((value) => value.replace(/\s/g, ''))
    .min(13, 'error.phone.notValid')
    .max(13, 'error.phone.notValid')
    // .phone('UZ', false, `error.phone.notValid`) 33 55 не работают
    .required('error.required')
    .trim(),
  [FIELD_LOGIN]: yup
    .string()
    .min(3, 'error.minLength3')
    .matches(/^[a-zA-Z0-9_.@#-]+$/, 'error.login.rules')
    .required('error.required')
    .trim(),
  [FIELD_PASSWORD]: yup
    .string()
    .required('error.required')
    .min(8, 'error.minLength.8')
    .matches(/^.*((?=.*[A-Z]){1}).*$/, 'error.password.rule.uppercase')
    .matches(/^.*((?=.*[a-z]){1}).*$/, 'error.password.rule.lowercase')
    .matches(/^.*(?=.*\d).*$/, 'error.password.rule.number')
    .trim(),
  [FIELD_PASSWORD_REPEAT]: yup.string().required('error.required').trim(),
});
