import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import clsx from 'clsx';
import { map } from 'lodash';
import React, { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon, PlusIcon, SearchIcon } from '../../../common/icons';
import { useResponsive } from '../../theme/useResponsive';
import { LegalEntityEmployeesAdd } from '../legal-entity-employees-add/legal-entity-employees-add';
import useStyles from './styles';

interface LegalEntityEmployeesListProps {
  persons: Record<string, any>;
  onDeletePerson: (document: string, pinfl: string) => void;
  leTin: string;
}

export const LegalEntityEmployeesList: ComponentType<LegalEntityEmployeesListProps> = ({
  persons,
  onDeletePerson,
  leTin,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();

  const [employees, setEmployees] = useState(persons);

  const [openAddEmployee, setOpenAddEmployee] = useState(false);

  // const handleSearchEmployee = (e: any) => {
  //   const value = e.target.value.toLowerCase();
  //   setFilteredRows(
  //     employees.filter(
  //       (item:any) => item.fullName.toLowerCase().indexOf(value) >= 0
  //     )
  //   );
  // };

  const handleAddEmployee = (newEmployee: any) => {
    // setEmployees([...employees, newEmployee]);
    setOpenAddEmployee(false);
  };

  const deleteEmployee = (person: { document: string; pinfl: string }) => {
    const { document, pinfl } = person;
    onDeletePerson(document, pinfl);
    setEmployees(employees.filter((e: any) => e.pinfl !== pinfl));
  };

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.panel}>
        <TextField
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ color: 'primary.main' }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          fullWidth
          size="medium"
          placeholder={t('action.search', 'Поиск')}
          // onChange={handleSearchEmployee}
        />

        <div>
          <Button
            variant="outlined"
            size="medium"
            endIcon={<PlusIcon />}
            className={classes.addBtn}
            onClick={() => setOpenAddEmployee(true)}
          >
            {t('legalEntity.addEmployee', 'Добавить сотрудника')}
          </Button>
        </div>
      </div>
      {persons.length > 0 && (
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell width="264">{t('eSign.fullName', 'ФИО')}</TableCell>
                <TableCell width="264">
                  {t('legalEntity.position', 'Должность')}
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(employees, (row: any) => (
                <TableRow key={row.pinfl} className={classes.tableRow}>
                  <TableCell>{`${row.surname_latin} ${row.name_latin} ${row.patronym_latin}`}</TableCell>
                  <TableCell>{row.position}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => deleteEmployee(row)}
                    >
                      {t('action.disable', 'Отключить')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        open={openAddEmployee}
        onClose={() => setOpenAddEmployee(false)}
        fullScreen={r({ xs: true, lg: false })}
        scroll="body"
        maxWidth="sm"
        aria-labelledby="agreement-title"
        aria-describedby="agreement-description"
      >
        <DialogContent id="agreement-description">
          <Box pt={r({ xs: 3, lg: 0 })}>
            <IconButton
              onClick={() => setOpenAddEmployee(false)}
              color="primary"
              aria-label={t('action.dialog.close', 'Закрыть диалоговое окно')}
              sx={{
                position: 'absolute',
                right: 4,
                top: 4,
              }}
            >
              <SvgIcon component={CloseIcon} />
            </IconButton>
          </Box>
          <LegalEntityEmployeesAdd
            leTin={leTin}
            onAddEmployee={handleAddEmployee}
            onSuccess={() => {
              setOpenAddEmployee(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
