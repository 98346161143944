import { Box, Typography } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { locales, localesMap } from '../../../common/locales';
import { useLangSwitcher } from '../index';
import useStyles from './styles';

interface LangSwitcherInlineProps {}

export const LangSwitcherInline: ComponentType<
  LangSwitcherInlineProps & BoxProps
> = ({ className, ...props }) => {
  const classes = useStyles();
  const { changeLang, curentLang } = useLangSwitcher();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      {locales.map((lang) => {
        return (
          <Typography
            component="span"
            variant="body2"
            key={`lang__${lang}`}
            className={clsx(classes.item, curentLang(lang) && classes.active)}
            title={localesMap[lang].name}
            onClick={() => changeLang(lang)}
          >
            {localesMap[lang].shortName}
          </Typography>
        );
      })}
    </Box>
  );
};
