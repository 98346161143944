import { ComponentType } from 'react';
import { PasswordEdit } from './password-edit/password-edit';

interface PasswordProps {
  className?: any;
  variant?: 'edit';
}

export const Password: ComponentType<PasswordProps> = ({
  className,
  variant = 'edit',
}) => {
  return (variant === 'edit' && <PasswordEdit />) || null;
};
