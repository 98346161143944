import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { themeBreakpoints } from '../theme/breakpoints';
import { shadows } from '../theme/shadows';

const options = {
  name: 'auth',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
    },
    content: {
      margin: '0 auto',
      padding: `0 0 ${theme.spacing(3)}`,
      maxWidth: 420,
      width: '100%',
      [themeBreakpoints.up('lg')]: {
        maxWidth: 640,
        '&:hover': {
          '& $cardPaper': {
            boxShadow: 'none',
            '&:hover': {
              boxShadow: shadows[1],
            },
          },
        },
      },
    },
    container: {},
    header: {
      background: 'transparent',
    },
    body: {
      textAlign: 'center',
    },
    media: {
      display: 'block',
      margin: '0 auto',
      width: 250,
      [themeBreakpoints.up('lg')]: {
        position: 'absolute',
        right: -157,
        bottom: -251,
        zIndex: 100,
        pointerEvents: 'none',
        height: 393,
        width: 330,
        margin: 0,
      },
    },
    img: {
      display: 'block',
      height: 'auto',
      width: '100%',
    },
    title: {
      marginBottom: theme.spacing(1.5),
    },
    text: {},
    link: {},
    action: {
      marginTop: theme.spacing(4.5),
      overflow: 'hidden',
    },
    btn: {},
    wrap: {
      position: 'relative',
    },
    signIn: {},
    signUp: {
      marginTop: theme.spacing(1),
      '&:first-child': {
        marginTop: 0,
      },
      [themeBreakpoints.up('lg')]: {
        boxShadow: 'none',
      },
    },
    // for cards
    cardPaper: {
      [themeBreakpoints.up('lg')]: {
        borderRadius: theme.spacing(1),
      },
    },
    cardBody: {},
    cardRoot: {},
    // variants
    full: {
      '& $cardBody.login': {
        maxWidth: 456,
      },
      '& $cardBody.e-sign': {
        maxWidth: 536,
      },
      '& $cardBody.id-card': {
        maxWidth: 456,
      },
    },
    short: {},
  }),
  options
);

export default useStyles;
