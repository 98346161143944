import { Box, SvgIcon } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useVaccination } from '..';
import { VaccinatedIcon } from '../../../common/icons';
import useStyles from './styles';

interface VaccinationBadgeProps {}

export const VaccinationBadge: ComponentType<
  VaccinationBadgeProps & BoxProps
> = ({ className, ...props }) => {
  const classes = useStyles();
  const { status } = useVaccination();

  if (!status) return null;

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <SvgIcon
        className={classes.icon}
        component={VaccinatedIcon}
        color="success"
        viewBox="0 0 110 40"
      />
    </Box>
  );
};
