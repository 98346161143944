import { ComponentType } from 'react';
import { AddressAuto, AddressCustom } from './index';
import useStyles from './styles';

interface AddressProps {
  className?: string;
  variant?: 'auto' | 'custom';
  addressData?: Record<string, any> | null;
}

export const Address: ComponentType<AddressProps> = ({
  className,
  variant = 'auto',
  addressData,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {variant === 'auto' && <AddressAuto />}
      {variant === 'custom' && <AddressCustom addressData={addressData} />}
    </div>
  );
};
