import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CloseIcon } from '../../../common/icons';
import { clearPasswordSerialNumberStates } from '../../../thunks/profile-thunks';
import { useResponsive } from '../../theme/useResponsive';
import { PassportSerialNumberForm } from './passport-serrial-number-form';

interface PasswordRecoveryDialogProps {
  open?: boolean;
  onClose?: () => void;
}

export const PassportSerialNumberDialog: ComponentType<PasswordRecoveryDialogProps> = ({
  open = false,
  onClose,
}) => {
  const dispatch = useDispatch();
  const r = useResponsive();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(clearPasswordSerialNumberStates());
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={r({ xs: true, lg: false })}
      scroll={'body'}
      maxWidth={'sm'}
      aria-labelledby="psn-title"
      aria-describedby="psn-description"
    >
      <DialogContent id="psn-description">
        <Box
          sx={{
            position: r({ xs: 'static', lg: 'absolute' }),
            top: 4,
            right: 4,
            display: 'flex',
            justifyContent: 'flex-end',
            pt: r({ xs: 0, lg: 0 }),
            m: r({ xs: '-12px -12px 0 0', lg: '0' }),
          }}
        >
          <IconButton
            onClick={handleClose}
            color="primary"
            aria-label={t('action.dialog.close', 'Закрыть диалоговое окно')}
          >
            <SvgIcon component={CloseIcon} />
          </IconButton>
        </Box>
        <Typography component="div" variant="h1" mb={2.5}>
          {t('passport.change.serialNumber', 'Изменить Серию и Номер паспорта')}
        </Typography>
        <PassportSerialNumberForm />
      </DialogContent>
    </Dialog>
  );
};
