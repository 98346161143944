import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState = {
  isRequiresPreviousSignInCheck: false,
  isSigningIn: false,
  isSignedIn: false,
  isLoading: false,
  accessToken: null as string | null,
  refreshToken: null as string | null,
  tokenType: null as string | null,
  expiryDate: null as string | null,
  pinfl: null as string | null,
  error: null as string | null,
  passportData: null as Record<string, any> | null,
  data: null as Record<string, any> | null,
  uuid: null as string | null,

  pinflData: {
    isLoading: false,
    isValid: false,
    code: null as number | null,
    message: null as string | null,
  },
  phone: {
    isLoading: false,
    isValid: false,
    code: null as number | null,
    message: null as string | null,
  },
  askSms: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
    data: null as Record<string, any> | null,
    token: null as string | null,
  },
  sms: {
    isLoading: false,
    isValid: false,
    code: null as number | null,
    message: null as string | null,
    token: null as string | null,
  },
  signInSms: {
    isLoading: false,
    isValid: false,
    code: null as number | null,
    message: null as string | null,
  },
  signInPhone: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
    controlCode: null as number | null,
    actionId: null as string | null,
  },
  signIn: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
  signUp: {
    isLoading: false,
    code: null as number | null,
    message: null as string | null,
  },
};

export const { actions: authActions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRequiresPreviousSignInCheck: (
      state,
      { payload }: PayloadAction<boolean>
    ) => ({ ...state, isRequiresPreviousSignInCheck: payload }),
    restorePreviousSignInRequest: (state) => ({ ...state, isSigningIn: true }),
    restorePreviousSignInResponse: (state) => ({
      ...state,
      isSigningIn: false,
    }),

    setSignInRequest: (state) => {
      state.signIn = {
        isLoading: true,
        code: null,
        message: null,
      };
    },
    setSignInSuccess: (state, { payload }: PayloadAction<any>) => {
      state.isSignedIn = true;
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.expiryDate = payload.expiryDate;
      state.pinfl = payload.pinfl;
      state.tokenType = payload.tokenType;
      state.signIn = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setSignInFailure: (state, { payload }: PayloadAction<any>) => {
      state.signIn = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setSignInDefault: (state) => {
      state.signIn = {
        isLoading: false,
        code: null,
        message: null,
      };
    },

    //Sign In SMS password
    setSignInSmsRequest: (state) => {
      state.signInSms = {
        isLoading: true,
        isValid: false,
        code: null,
        message: null,
      };
    },
    setSignInSmsSuccess: (state, { payload }: PayloadAction<any>) => {
      state.signInSms = {
        isLoading: false,
        isValid: true,
        code: payload.code,
        message: payload.message,
      };
    },
    setSignInSmsFailure: (state, { payload }: PayloadAction<any>) => {
      state.signInSms = {
        isLoading: false,
        isValid: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setSignInSmsDefault: (state) => {
      state.signInSms = {
        isLoading: false,
        isValid: false,
        code: null,
        message: null,
      };
    },

    //Sign In Phone
    setSignInPhoneRequest: (state) => {
      state.signInPhone = {
        isLoading: true,
        controlCode: null,
        actionId: null,
        code: null,
        message: null,
      };
    },
    setSignInPhoneSuccess: (state, { payload }: PayloadAction<any>) => {
      state.signInPhone = {
        isLoading: false,
        controlCode: payload.controlCode,
        actionId: payload.actionId,
        code: payload.status.code,
        message: payload.status.message,
      };
    },
    setSignInPhoneFailure: (state, { payload }: PayloadAction<any>) => {
      state.signInPhone = {
        isLoading: false,
        controlCode: null,
        actionId: null,
        code: payload.code,
        message: payload.message,
      };
    },
    setSignInPhoneDefault: (state) => {
      state.signInPhone = {
        isLoading: false,
        controlCode: null,
        actionId: null,
        code: null,
        message: null,
      };
    },

    setSignUpRequest: (state) => {
      state.signUp = {
        isLoading: true,
        code: null,
        message: null,
      };
    },
    setSignUpSuccess: (state, { payload }: PayloadAction<any>) => {
      state.signUp = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setSignUpFailure: (state, { payload }: PayloadAction<any>) => {
      state.signUp = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setSignUpDefault: (state) => {
      state.signUp = {
        isLoading: false,
        code: null,
        message: null,
      };
    },

    logOut: (state) => ({
      ...state,
      isSignedIn: false,
      accessToken: null, //TODO
      refreshToken: null, //TODO
      pinfl: null, //TODO
    }),
    getInfoRequest: (state) => ({ ...state, isLoading: true }),
    getInfoSuccess: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      isLoading: false,
      isSignedIn: true,
      ...payload,
    }),
    getInfoFailure: (state) => ({
      ...state,
      isLoading: false,
    }),

    setAskSmsRequest: (state) => {
      state.askSms = {
        isLoading: true,
        code: null,
        message: null,
        data: null,
        token: null,
      };
    },
    setAskSmsSuccess: (state, { payload }: PayloadAction<any>) => {
      state.askSms = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
        data: payload.data,
        token: payload.token,
      };
    },
    setAskSmsFailure: (state, { payload }: PayloadAction<any>) => {
      state.askSms = {
        isLoading: false,
        code: payload.code,
        message: payload.message,
        data: null,
        token: null,
      };
    },
    setAskSmsDefault: (state) => {
      state.askSms = {
        ...state.askSms,
        isLoading: false,
        code: null,
        message: null,
        data: null,
      };
    },

    setPinflRequest: (state) => {
      state.pinflData = {
        isLoading: true,
        isValid: false,
        code: null,
        message: null,
      };
    },
    setPinflSuccess: (state, { payload }: PayloadAction<any>) => {
      state.pinflData = {
        isLoading: false,
        isValid: true,
        code: payload.code,
        message: payload.message,
      };
    },
    setPinflFailure: (state, { payload }: PayloadAction<any>) => {
      state.pinflData = {
        isLoading: false,
        isValid: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setPinflDefault: (state) => {
      state.pinflData = {
        isLoading: false,
        isValid: false,
        code: null,
        message: null,
      };
    },

    setPhoneRequest: (state) => {
      state.phone = {
        isLoading: true,
        isValid: false,
        code: null,
        message: null,
      };
    },
    setPhoneSuccess: (state, { payload }: PayloadAction<any>) => {
      state.phone = {
        isLoading: false,
        isValid: true,
        code: payload.code,
        message: payload.message,
      };
    },
    setPhoneFailure: (state, { payload }: PayloadAction<any>) => {
      state.phone = {
        isLoading: false,
        isValid: false,
        code: payload.code,
        message: payload.message,
      };
    },
    setPhoneDefault: (state) => {
      state.phone = {
        isLoading: false,
        isValid: false,
        code: null,
        message: null,
      };
    },

    setSmsRequest: (state) => {
      state.sms = {
        isLoading: true,
        isValid: false,
        code: null,
        message: null,
        token: null,
      };
    },
    setSmsSuccess: (state, { payload }: PayloadAction<any>) => {
      state.passportData = payload.passportData;
      state.pinfl = payload.pinfl;
      state.sms = {
        isLoading: false,
        isValid: true,
        code: payload.code,
        message: payload.message,
        token: payload.token,
      };
    },
    setSmsFailure: (state, { payload }: PayloadAction<any>) => {
      state.sms = {
        isLoading: false,
        isValid: false,
        code: payload.code,
        message: payload.message,
        token: null,
      };
    },
    setSmsDefault: (state) => {
      state.sms = {
        ...state.sms,
        isLoading: false,
        isValid: false,
        code: null,
        message: null,
      };
    },

    getUuidRequest: (state) => ({ ...state, isLoading: true }),
    getUuidSuccess: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      isLoading: false,
      uuid: payload.uuid,
    }),
    getUuidFailure: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      isLoading: false,
    }),
  },
});
