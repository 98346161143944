import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'legal-entity-esign',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(3.75),
    },
    header: {},
    container: {},
    content: {},
    btn: {},
    icon: {},
  }),
  options
);

export default useStyles;
