import { FormikValues, useFormik } from 'formik';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { clearSendReport, sendReport } from '../../../thunks/error-thunks';
import {
  FIELD_TEXT,
  SendReportValidationForm,
} from './error-validation-scheme';
import { Tooltip } from '@material-ui/core';

export const useSendReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { report } = useSelector((state: State) => state.error);

  const handleSubmit = (values: FormikValues) => {
    dispatch(sendReport(values));
  };

  const handleFieldChange = (field: string, e: any) => {
    formik.setFieldValue(field, e.target.value);
    formik.setFieldError(field, null);
    dispatch(clearSendReport());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_TEXT]: '',
    },
    validationSchema: SendReportValidationForm,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return {
    ...report,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  };
};
