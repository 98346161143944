import { ComponentType } from 'react';
import { Layout } from '../layout';
import { Logo } from '../logo';
import { UserSSO } from '../user/user-sso/user-sso';
import { useUser } from '../user/useUser';
interface PageSelectRoleProps {}

export const PageSelectRole: ComponentType<PageSelectRoleProps> = () => {
  const { listRole } = useUser();

  // if (listRole.length === 1) return null;

  return (
    <>
      <Layout
        variant="simple"
        bgcolor="primary.main"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Logo
          variant="xLarge"
          sx={{
            display: 'flex',
            mt: 2,
            mx: 'auto',
            mb: 5,
          }}
        />
        {listRole.length !== 1 && <UserSSO />}
      </Layout>
    </>
  );
};
