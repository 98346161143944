import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { LegalEntityEmployeesAddForm } from '..';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

interface LegalEntityEmployeesAddProps {
  onAddEmployee: (item: any) => void;
  onSuccess?: () => any;
  leTin: string;
}

export const LegalEntityEmployeesAdd: ComponentType<LegalEntityEmployeesAddProps> = ({
  onAddEmployee,
  onSuccess,
  leTin,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  return (
    <div className={clsx(classes.root)}>
      <Typography
        variant={r({ xs: 'h3', sm: 'h2', md: 'h1' })}
        component="h2"
        className={classes.title}
      >
        {t('legalEntity.employeesAdd', 'Добавить сотрудника')}
      </Typography>

      <LegalEntityEmployeesAddForm
        leTin={leTin}
        className={classes.form}
        onSuccess={onSuccess}
      />
    </div>
  );
};
