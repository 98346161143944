import { alpha, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'user-settings',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    count: {
      display: 'inline-flex',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
      flexDirection: 'row',
      flexWrap: 'nowrap',
      borderRadius: 4,
      padding: '4px 8px',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      color: theme.palette.primary.main,
      background: alpha(theme.palette.primary.contrastText, 0.4),
    },
    iconWrap: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(-0.5),
      flex: '0 0 20px',
      width: 20,
    },

    icon: {
      display: 'block',
      height: 20,
      margin: 0,
      width: 20,
    },
    text: {},
  }),
  options
);

export default useStyles;
