import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useStyles from './styles';

interface ESignNotFoundProps {
  className?: string;
  variant?: 'notIndividual' | 'noLegalEntity' | 'notFound';
}

export const ESignNotFound: ComponentType<ESignNotFoundProps> = ({
  className,
  variant = 'notFound',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography
        className={classes.title}
        color="textPrimary"
        variant="body1"
        component="div"
        sx={{
          fontWeight: 700,
        }}
      >
        {t(`eSign.${variant}`, `Нет перевода: eSign.${variant}`)}
      </Typography>
      <Typography
        className={classes.text}
        color="textSecondary"
        variant="body1"
        component="div"
      >
        <p>
          {t(
            'eSign.fixError',
            'Для устранения ошибки, выполните следующие действия:'
          )}
        </p>

        <ol>
          <li>
            {t(
              'eSign.fixError.step1',
              'Проверьте, что ключи ЭЦП располагаются в папке C:\\DSKEYS или D:\\DSKEYS.'
            )}
          </li>
          <li>
            {t(
              'eSign.fixError.step2',
              'Проверьте, что антивирусная программа не блокирует доступ к ключам.'
            )}
          </li>
          <li>
            {t(
              'eSign.fixError.step3',
              'В случае использования корпоративного компьютера, удостоверьтесь, что политики организации разрешают вам использование ключей ЭЦП.'
            )}
          </li>
          {variant === 'noLegalEntity' && (
            <li>
              {t(
                'eSign.fixError.step4',
                'Проверьте, что имеется ключ ЭЦП с ролью юридического лица, зарегистрированный на ваш ПИНФЛ'
              )}
            </li>
          )}
        </ol>

        <p>
          <Trans i18nKey="eSign.instructions">
            Ознакомиться с инструкцией по установке Модуля Е-IMZO можно
            <a
              href="https://e-imzo.uz/#instructions"
              rel="noopener noreferrer"
              target="_blank"
              title={t(
                'eSign.action.instructions',
                'Инструкция по установке Модуля Е-IMZO'
              )}
            >
              здесь
            </a>
          </Trans>
        </p>
      </Typography>
    </div>
  );
};
