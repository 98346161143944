import { Link, SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { DownloadFileIcon } from '../../../common/icons';
import useStyles from './styles';

interface FileListItemProps {
  href: string;
  label: string;
}

interface FileListProps {
  className?: string;
  data: FileListItemProps[];
}

export const FileList: ComponentType<FileListProps> = ({ className, data }) => {
  const classes = useStyles();

  return (
    <ul className={clsx(classes.root, className)}>
      {(data || []).map(({ href, label }: any, idx: number) => (
        <li className={clsx(classes.item)} key={idx}>
          <Link href={href} download target="_blank">
            <SvgIcon
              component={DownloadFileIcon}
              viewBox="0 0 14 16"
              fontSize="small"
            />
            {label}
          </Link>
        </li>
      ))}
    </ul>
  );
};
