import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState = {
  keys: [],
  certs: [],
  tokens: [],
  keyId: null,
  pkcs7: null,
};

export const { actions: eSignActions, reducer: eSignReducer } = createSlice({
  name: 'eSign',
  initialState,
  reducers: {
    setKeys: (state, { payload }: PayloadAction<any>) => {
      state.keys = payload;
    },
    setCerts: (state, { payload }: PayloadAction<any>) => {
      state.certs = payload;
    },
    setTokenReader: (state, { payload }: PayloadAction<any>) => {
      state.tokens = payload.readers;
    },
    setKeyId: (state, { payload }: PayloadAction<any>) => {
      state.keyId = payload;
    },
    setPkcs7: (state, { payload }: PayloadAction<any>) => {
      state.keyId = payload;
    },
  },
});
