import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'sign-up',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    fullWidth: {},
    shifted: {
      '& $body': {
        maxWidth: theme.spacing(60),
      },
    },
    content: {
      padding: theme.spacing(3.75, 3.75),
      borderRadius: theme.spacing(1),
    },
    body: {},
    row: {},
    col: {},
    title: {
      marginBottom: theme.spacing(2.5),
    },
    subtitle: {
      marginBottom: theme.spacing(1),
    },
    text: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(1),
      },
      '& + $text': {
        marginTop: theme.spacing(3.75),
      },
    },
    agreement: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'flex-start',
      '& $checkbox': {
        flex: '0 0 24px',
      },
      '& $label': {
        marginLeft: theme.spacing(2),
        flex: `0 0 calc(100% - ${theme.spacing(5)})`,
        width: `calc(100% - ${theme.spacing(5)})`,
      },
    },
    instruction: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      textAlign: 'right',
    },
    control: {},
    controlIcon: {},
    checkbox: {
      padding: 0,
    },
    label: {
      lineHeight: 1.5,
    },
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover, &:hover:focus': {
        color: theme.palette.primary.light,
      },
    },
    linkWidthIcon: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    linkIcon: {
      marginLeft: theme.spacing(1),
    },
    actions: {
      paddingTop: theme.spacing(3.75),
    },
    btn: {},
    disabled: {
      backgroundColor: 'rgba(34, 34, 34, 0.4)',
    },
  }),
  options
);

export default useStyles;
