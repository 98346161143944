import { Box, Button, Divider } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { LegalEntityDatagrid } from '../legal-entity-datagrid/legal-entity-datagrid';
import { LegalEntityEmployeesList } from '../legal-entity-employees-list';
import useStyles from './styles';
import {
  deleteLAPerson,
  getLAPersons,
  updateLEData,
} from '../../../thunks/legal-entity-thunks';

export const LegalEntityDirector: ComponentType = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { entity, persons } = useSelector((state: State) => state.legalEntity);

  useEffect(() => {
    if (entity.tin) {
      dispatch(getLAPersons(entity.tin));
    }
  }, [dispatch, entity.tin]);

  const onDeletePerson = (document: string, pinfl: string) =>
    dispatch(deleteLAPerson(pinfl, document, entity.tin));

  const _updateLEData = () => dispatch(updateLEData(entity.tin));

  return (
    <div className={clsx(classes.root)}>
      <LegalEntityDatagrid data={entity} head />

      <Box>
        <Button variant="outlined" size="small" onClick={_updateLEData}>
          {t('legalEntity.refreshDataHead', 'Обновить данные о руководителе')}
        </Button>
      </Box>

      <Divider sx={{ mb: 4.5, mt: 4.5 }} />

      <LegalEntityEmployeesList
        leTin={entity.tin}
        onDeletePerson={onDeletePerson}
        persons={persons}
      />
    </div>
  );
};
