import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, CloseIcon } from '../../../common/icons';
import { Locale } from '../../../common/locales';
import useStyles from './styles';

interface HelpQuestionsProps {
  className?: string;
  data: any[Locale];
}

export const HelpQuestions: ComponentType<HelpQuestionsProps> = ({
  className,
  data,
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [expandedBranch, setExpandedBranch] = useState<any>(null);
  const [expandedQuestion, setExpandedQuestion] = useState<any>(null);

  const tree = data[i18n.language as Locale]['tree'];

  const handleBranchChange = (idx: number) => {
    setExpandedQuestion(null);
    if (expandedBranch === idx) {
      setExpandedBranch(null);
    } else {
      setExpandedBranch(idx);
    }
  };

  const handleQuestionChange = (idx: number) => {
    if (expandedQuestion === idx) {
      setExpandedQuestion(null);
    } else {
      setExpandedQuestion(idx);
    }
  };

  const renderAnswer = ({
    text,
    steps,
    hints,
    textEnd,
  }: Record<string, any>) => (
    <div>
      {text && <Typography variant="body2">{text}</Typography>}
      {hints && hints.length && (
        <ul>
          {hints.map((hint: string, idx: number) => (
            <li key={idx}>{hint}</li>
          ))}
        </ul>
      )}
      {steps && steps.length && (
        <ol>
          {steps.map((step: string, idx: number) => (
            <li key={idx}>{step}</li>
          ))}
        </ol>
      )}
      {textEnd && (
        <Typography variant="body2" mt={1}>
          {textEnd}
        </Typography>
      )}
    </div>
  );

  const renderQuestions = (questions: any[]) =>
    (questions || []).map(
      ({ question, answer }: Record<string, any>, idx: number) => (
        <Accordion
          onChange={() => handleQuestionChange(idx)}
          expanded={expandedQuestion === idx}
          classes={{
            root: classes.question,
            expanded: classes.questionExpanded,
          }}
          square
          key={idx}
        >
          <AccordionSummary
            classes={{
              root: classes.questionHeader,
              content: classes.questionHeaderContent,
              expandIconWrapper: clsx(classes.questionHeaderIcon, {
                [classes.questionHeaderIconExpanded]: expandedQuestion === idx,
              }),
            }}
            expandIcon={
              <SvgIcon
                component={CloseIcon}
                viewBox="0 0 24 24"
                fontSize="small"
                color="primary"
              />
            }
          >
            <Typography
              component="div"
              variant="body2"
              color={expandedQuestion === idx ? 'textPrimary' : 'primary'}
            >
              {question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.questionDetails,
            }}
          >
            {renderAnswer(answer)}
          </AccordionDetails>
        </Accordion>
      )
    );

  const branches = (tree || []).map(
    ({ header, questions }: Record<string, any>, idx: number) => (
      <Accordion
        onChange={() => handleBranchChange(idx)}
        expanded={expandedBranch === idx}
        classes={{ root: classes.branch, expanded: classes.branchExpanded }}
        square
        key={idx}
      >
        <AccordionSummary
          classes={{
            root: classes.branchHeader,
            expandIconWrapper: classes.branchHeaderIcon,
          }}
          expandIcon={
            <SvgIcon
              component={ChevronLeftIcon}
              viewBox="0 0 9 16"
              fontSize="small"
              color="primary"
            />
          }
        >
          <Typography color="primary">{header}</Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.branchDetails }}>
          {renderQuestions(questions)}
        </AccordionDetails>
      </Accordion>
    )
  );

  return (
    <Grid
      container
      className={clsx(classes.root, className)}
      direction="column"
      rowSpacing={4.5}
    >
      {/* <Grid item>
        <Alert
          color="primary"
          icon={<SvgIcon component={PlayIcon} viewBox="0 0 31 31" />}
        >
          <Link
            href="https://www.youtube.com/watch?v=NnIOnwodeIs"
            target="_blank"
          >
            {t(
              'help.questions.video',
              'Мы подготовили для вас видео-тур по функционалу Единой системы идентификации с ответами на часто-задаваемые вопросы.'
            )}
          </Link>
        </Alert>
      </Grid> */}
      <Grid item>{branches}</Grid>
    </Grid>
  );
};
