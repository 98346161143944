import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import { createTheme, StyledEngineProvider } from '@material-ui/core/styles';
import React, { ComponentType, createContext, useState } from 'react';
import { breakpoints } from './breakpoints';
import { themeComponents } from './components';
import { a11yPalette } from './palettes/a11y';
import { darkPalette } from './palettes/dark';
import { lightPalette } from './palettes/light';
import { shadows } from './shadows';
import { typography } from './typography';

// #readmore https://techinscribed.com/building-react-app-using-material-ui-with-support-for-multiple-switchable-themes/

const lightTheme = createTheme({
  palette: lightPalette,
  typography,
  components: themeComponents(lightPalette),
  shadows,
  breakpoints,
});

const darkTheme = createTheme({
  palette: darkPalette,
  typography,
  components: themeComponents(darkPalette),
  shadows,
  breakpoints,
});

const a11yTheme = createTheme({
  palette: a11yPalette,
  typography,
  components: themeComponents(a11yPalette),
  shadows,
  breakpoints,
});

// const themeMap: { [key: string]: Theme } = {

const themeMap = {
  light: lightTheme,
  dark: darkTheme,
  a11y: a11yTheme,
};

export type ThemeType = keyof typeof themeMap;

export const themes = Object.keys(themeMap) as ThemeType[];
export const defaultTheme: ThemeType = 'light';

export interface ThemeContextInterface {
  theme: ThemeType;
  handleTheme: (theme: ThemeType) => void;
}

export const ThemeContext = createContext<ThemeContextInterface>({
  theme: defaultTheme,
  handleTheme: () => {},
});

export const ThemeProvider: ComponentType = (props) => {
  const curThemeName = localStorage.getItem('theme') || defaultTheme;
  const [theme, setTheme] = useState<ThemeType>(curThemeName as ThemeType);
  const currentTheme = themeMap[theme];
  const handleTheme = (theme: ThemeType) => {
    localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        handleTheme,
      }}
    >
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={currentTheme}>
          <CssBaseline />
          {props.children}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};
