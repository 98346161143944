import { addMonths, intervalToDuration, isAfter } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../common/store';
import { checkVaccination } from '../../thunks/vaccination-thunks';

interface DatePros {
  valid: boolean;
  start: Date;
  end: Date;
  duration: Duration | null;
}

export const useVaccination = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<boolean | null | undefined>();
  const [date, setDate] = useState<DatePros | null>(null);

  const { vaccinated, vaccineIssueDate } = useSelector(
    (state: State) => state.profile
  );
  const { detail } = useSelector((state: State) => state.vaccination);
  const { isLoading, code, message } = detail;

  const getDate = (date?: any): DatePros | null => {
    // check issue date
    if (!date) return null;
    const start = new Date(date);
    // add 6 month to issue date
    const end = addMonths(start, 6);
    // compare date width current date
    const valid = isAfter(end, new Date());
    // get duration date
    const duration = intervalToDuration({
      start: new Date(),
      end,
    });

    return {
      valid,
      start,
      end,
      duration: valid ? duration : null,
    };
  };

  const count = 0;

  // update
  useEffect(() => {
    if (vaccinated && vaccineIssueDate) {
      setStatus(Boolean(vaccinated));
      setDate(getDate(vaccineIssueDate));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vaccinated, vaccineIssueDate]);

  const handleSubmit = () => {
    dispatch(checkVaccination());
  };

  return {
    status,
    count,
    date,
    isLoading,
    code,
    message,
    setStatus,
    handleSubmit,
  };
};
