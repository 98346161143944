import { useSelector } from 'react-redux';
import { State } from '../../common/store';
import { AUTH_TOKEN_KEY, PINFL_KEY } from '../../thunks/auth-thunks';

const useAuth = () => {
  const { isSignedIn } = useSelector((state: State) => state.auth);
  const pinfl = localStorage.getItem(PINFL_KEY);
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY);

  return { authorized: isSignedIn && authToken, pinfl, authToken };
};

export default useAuth;
