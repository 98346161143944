import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'layout-sidebar',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'normal',
      flex: '0 0 100%',
      padding: theme.spacing(5, 6.5, 4.5),
      '& $a11y': {
        transform: `translate(${theme.spacing(-4.5)}, 0)`,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(10),
      },
    },
    default: {},
    short: {
      padding: theme.spacing(5, 1, 4.5),
    },

    content: {},
    header: {},
    body: {
      flex: '1 1 auto',
    },
    footer: {
      width: 284,
    },
    toggle: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 100,
      '&, &:hover, &:focus:hover': {
        color: theme.palette.primary.main,
        background: theme.palette.primary.contrastText,
      },
      [theme.breakpoints.up('lg')]: {
        top: theme.spacing(3.75),
        right: theme.spacing(2.5),
        '&, &:hover, &:focus:hover': {
          color: theme.palette.primary.contrastText,
          background: 'transparent',
        },
      },
      '$short &': {
        right: 32,
      },
    },
    icon: {},
    a11y: {
      '& ~ $lang': {
        marginTop: theme.spacing(2.5),
      },
    },
    lang: {
      marginTop: theme.spacing(5.5),
    },
    logo: {
      marginTop: theme.spacing(4.5),
      flex: '0 0 40px',
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(9),
      },
      '$short &': {
        marginTop: theme.spacing(17.125),
        alignSelf: 'center',
      },
    },
    nav: {
      margin: theme.spacing(10, -6.5, 0),
      [theme.breakpoints.up('lg')]: {
        marginTop: theme.spacing(9),
        marginLeft: theme.spacing(-10),
      },
      '$short &': {
        margin: theme.spacing(9, -1, 0),
      },
    },
    help: {
      margin: theme.spacing(9, -2, 'auto'),
    },
    title: {
      width: 284,
    },
    text: {
      width: 284,
      marginBottom: 'auto',
    },
    subtitle: {},
    developer: {
      marginTop: theme.spacing(4.5),
      width: 284,
    },
    statistics: {
      marginTop: theme.spacing(4.5),
      width: 160,
    },
    copyright: {
      marginTop: theme.spacing(2.5),
      width: 284,
    },
  }),
  options
);

export default useStyles;
