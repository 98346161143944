import {
  Alert,
  Box,
  Collapse,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isInteger } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { EyeHideIcon, EyeIcon } from '../../../common/icons';
import { useToggle } from '../../../hooks/useToggle';
import { useResponsive } from '../../theme/useResponsive';
import {
  FIELD_LABELS,
  FIELD_NEW_LOGIN,
  FIELD_PASSWORD,
} from './passport-serial-number-schema';
import useStyles from './styles';
import { useLoginForm } from './useLoginForm';

interface LoginFormProps {
  className?: string;
}

export const LoginForm: ComponentType<LoginFormProps> = ({ className }) => {
  const classes = useStyles();
  const r = useResponsive();
  const { t } = useTranslation();
  const [visibleChars, toggleVisibleChars] = useToggle(false);
  const {
    isTouched,
    isLoading,
    code,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  } = useLoginForm();

  const renderField = (field: string) => {
    const typeField =
      field === FIELD_PASSWORD && !visibleChars ? 'password' : 'text';
    return (
      <TextField
        {...formik.getFieldProps(field)}
        onChange={(e: any) => handleFieldChange(field, e)}
        label={t(FIELD_LABELS[field]['labelKey'], FIELD_LABELS[field]['label'])}
        id={`login-${field}`}
        error={hasError(field)}
        type={typeField}
        helperText={getErrorMessage(field)}
        disabled={isLoading}
        InputProps={{
          'aria-label': FIELD_LABELS[field]['arialLabel'],
          autoComplete: 'off',
          disabled: isLoading,

          endAdornment: field === FIELD_PASSWORD && (
            <Tooltip
              title={
                t(
                  visibleChars ? `action.password.hide` : `action.password.show`
                ) || ''
              }
              arrow
            >
              <IconButton
                onClick={toggleVisibleChars}
                aria-label={FIELD_LABELS[FIELD_PASSWORD]['arialLabel']}
                color="primary"
                size="medium"
              >
                <SvgIcon
                  component={visibleChars ? EyeHideIcon : EyeIcon}
                  viewBox="0 0 24 24"
                />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={clsx(classes.root, className)}
    >
      <Grid
        container
        columnSpacing={r({ lg: 4.5 })}
        alignItems="flex-end"
        mb={isInteger(code) ? 1 : 0}
      >
        <Grid item xs={12} lg={6}>
          {renderField(FIELD_NEW_LOGIN)}
          <Collapse in={isTouched}>{renderField(FIELD_PASSWORD)}</Collapse>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box className={classes.actions} pt={3} pb={3.25}>
            <LoadingButton
              className={classes.btn}
              loading={isLoading}
              disabled={!isTouched}
              fullWidth={r({ xs: true, lg: false })}
              variant="outlined"
              type="submit"
            >
              {t('action.edit', 'Изменить')}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>

      <Collapse in={isInteger(code) && Number(code) !== 0}>
        <Alert severity="error">
          {t(`error.api.${code}`, `Ошибка с кодом ${code}`)}
        </Alert>
      </Collapse>

      <Collapse in={isInteger(code) && Number(code) === 0}>
        <Alert severity="success">
          {t('success.updated', 'Данные успешно обновлены')}
        </Alert>
      </Collapse>
    </form>
  );
};
