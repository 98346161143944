import { ComponentType } from 'react';
import { PhoneAdd } from './phone-add/phone-add';
import { PhoneEdit } from './phone-edit/phone-edit';
interface PhoneProps {
  className?: any;
  variant?: 'add' | 'edit';
}

export const Phone: ComponentType<PhoneProps> = ({
  className,
  variant = 'add',
}) => {
  return (
    <>
      {variant === 'add' && <PhoneAdd />}
      {variant === 'edit' && <PhoneEdit />}
    </>
  );
};
