import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getISOLocale } from '../../common/locales';
import { HEADERS, api } from '../../common/utils/fetch-utils';
import { authActions } from '../../slices/auth-slice';
import { restoreAuthToken, userLogOut } from '../../thunks/auth-thunks';
import { DataTable } from '../data-table';
import { SESSION_COLUMNS_RESOURCE } from './session-columns-resource';
import useStyles from './styles';

interface SessionProps {
  className?: string;
}

export const Session: ComponentType<SessionProps & BoxProps> = ({
  className,
}) => {
  const classes = useStyles();

  const [hits, setHits] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const handleFetch = () => {
    const page = 1;
    const size = 10;
    setIsLoading(true);
    api.addHeader(HEADERS.AUTHORIZATION, `Bearer ${restoreAuthToken()}`);
    api.addHeader(HEADERS.ACCEPT_LANGUAGE, getISOLocale());
    api
      .get('/profile/getCurrentSessions', { page, size })
      .then(({ data }) => setHits(data.userActions))
      .catch((error: any) => {
        if (error.code === 401) {
          dispatch(authActions.getInfoFailure());
          userLogOut()(dispatch);
        }
        dispatch(authActions.getInfoFailure());
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <DataTable
      className={clsx(classes.root, className)}
      data={hits}
      columns={SESSION_COLUMNS_RESOURCE}
      loading={isLoading}
    />
  );
};
