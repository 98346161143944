import { Divider } from '@material-ui/core';
import { Box, BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { AuthenticationESign, AuthenticationPhone } from '.';
import useStyles from './styles';

export interface AuthenticationProps {
  className?: string;
}

export const Authentication: ComponentType<AuthenticationProps & BoxProps> = ({
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <AuthenticationESign />

      {/* for dev*/}
      {process.env.NODE_ENV === 'development' && (
        <>
          <Divider
            sx={{
              mt: 4.5,
              mb: 4.5,
            }}
          />
          <AuthenticationPhone />
        </>
      )}
    </Box>
  );
};
