import { FormikValues, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { forEach, get } from 'lodash';
import {
  changePasswordSerialNumber,
  clearPasswordSerialNumberStates,
} from '../../../thunks/profile-thunks';
import { State } from '../../../common/store';
import {
  FIELD_DOCUMENT,
  PassportSerialNumberValidationForm,
} from './passport-serial-number-schema';
import { useEffect } from 'react';
import { Tooltip } from '@material-ui/core';

export const usePassportSerialNumber = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { passportSerialNumber } = useSelector((state: State) => state.profile);

  const handleSubmit = (values: FormikValues) => {
    let hasError = [];

    forEach(values, (value, field) => {
      if (value.includes('_')) {
        formik.setFieldError(field, 'error.required');
        hasError.push(field);
      }
    });

    if (hasError.length) return;

    dispatch(changePasswordSerialNumber(values));
  };

  const handleFieldChange = (field: string, e: any) => {
    let value = e.target.value;
    if (field === FIELD_DOCUMENT) {
      value = value.toUpperCase();
    }
    formik.setFieldValue(field, value);
    formik.setFieldError(field, null);
    dispatch(clearPasswordSerialNumberStates());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_DOCUMENT]: '',
    },
    validationSchema: PassportSerialNumberValidationForm,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    return () => {
      dispatch(clearPasswordSerialNumberStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...passportSerialNumber,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  };
};
