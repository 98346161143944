import { get, isInteger } from 'lodash';
import { ComponentType } from 'react';
import { PasswordRecoveryError } from './password-recovery-error';
import { PasswordRecoveryForm } from './password-recovery-form';
import { PasswordRecoverySuccess } from './password-recovery-success';
import { usePasswordRecovery } from './usePasswordRecovery';

interface PasswordRecoveryProps {
  onClose?: () => void;
}

export const PasswordRecovery: ComponentType<PasswordRecoveryProps> = ({
  onClose,
}) => {
  const { sendSms, checkSms } = usePasswordRecovery();

  const sendSmsError =
    isInteger(sendSms.code) && Number(sendSms.code) !== 0
      ? sendSms.code
      : false;
  const checkSmsError =
    isInteger(checkSms.code) && Number(checkSms.code) !== 0
      ? checkSms.code
      : false;
  const errorCode = sendSmsError || checkSmsError;
  // 35 no valid sms code
  // 58 please wait for new sms

  const showForm =
    !isInteger(sendSms.code) ||
    !isInteger(checkSms.code) ||
    errorCode === 35 ||
    errorCode === 58;

  const showError = errorCode && errorCode !== 35 && errorCode !== 58;

  const showSuccess = isInteger(checkSms.code) && Number(checkSms.code) === 0;

  const expiredDate = get(sendSms, ['data', 'expiredDate']);

  return (
    <>
      {showForm && <PasswordRecoveryForm />}

      {showError && (
        <PasswordRecoveryError
          code={Number(errorCode)}
          expiredDate={expiredDate}
        />
      )}

      {showSuccess && <PasswordRecoverySuccess onClose={onClose && onClose} />}
    </>
  );
};
