import {
  Alert,
  Collapse,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isInteger } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EyeHideIcon, EyeIcon } from '../../../common/icons';
import { clearPasswordStates } from '../../../thunks/profile-thunks';
import { useResponsive } from '../../theme/useResponsive';
import {
  FIELD_CONFIRM_PASSWORD,
  FIELD_LABELS,
  FIELD_NEW_PASSWORD,
  FIELD_OLD_PASSWORD,
} from './password-form-schema';
import useStyles from './styles';
import { usePasswordForm } from './usePasswordForm';

interface PasswordFormProps {
  className?: string;
}

export const PasswordForm: ComponentType<PasswordFormProps> = ({
  className,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const r = useResponsive();
  const { t } = useTranslation();
  const {
    isLoading,
    code,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  } = usePasswordForm();
  const [visibleChars, setVisibleChars] = useState<Record<string, boolean>>({
    [FIELD_OLD_PASSWORD]: false,
    [FIELD_NEW_PASSWORD]: false,
    [FIELD_CONFIRM_PASSWORD]: false,
  });

  useEffect(() => {
    return () => {
      dispatch(clearPasswordStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVisibility = (field: string) => {
    setVisibleChars((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const renderField = (field: string) => {
    return (
      <TextField
        id={`password-${field}`}
        label={t(FIELD_LABELS[field]['labelKey'], FIELD_LABELS[field]['label'])}
        type={visibleChars[field] ? 'text' : 'password'}
        error={hasError(field)}
        helperText={getErrorMessage(field)}
        disabled={isLoading}
        InputProps={{
          endAdornment: (
            <Tooltip
              title={
                t(
                  visibleChars[field]
                    ? `action.password.hide`
                    : `action.password.show`
                ) || ''
              }
              arrow
            >
              <IconButton
                onClick={() => toggleVisibility(field)}
                aria-label={FIELD_LABELS[field]['arialLabel']}
                color="primary"
                size="medium"
              >
                <SvgIcon
                  component={visibleChars[field] ? EyeHideIcon : EyeIcon}
                  viewBox="0 0 24 24"
                />
              </IconButton>
            </Tooltip>
          ),
          'aria-label': FIELD_LABELS[field]['arialLabel'],
          autoComplete: 'off',
          disabled: isLoading,
        }}
        {...formik.getFieldProps(field)}
        onChange={(e: any) => handleFieldChange(field, e)}
      />
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={clsx(classes.root, className)}
    >
      <Grid
        container
        columnSpacing={r({ lg: 4.5 })}
        mb={isInteger(code) ? 2 : 0}
      >
        <Grid item xs={12} lg={6}>
          {renderField(FIELD_OLD_PASSWORD)}
        </Grid>
        <Grid item xs={12} lg={6} />
        <Grid item xs={12} lg={6}>
          {renderField(FIELD_NEW_PASSWORD)}
        </Grid>
        <Grid item xs={12} lg={6}>
          {renderField(FIELD_CONFIRM_PASSWORD)}
        </Grid>
        <Grid item xs={12} pt={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            className={classes.btn}
            fullWidth={r({ xs: true, lg: false })}
          >
            {t('action.saveChanges', 'Сохранить изменения')}
          </LoadingButton>
        </Grid>
      </Grid>
      <Collapse in={isInteger(code) && Number(code) !== 0}>
        <Alert severity="error">
          {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`)}
        </Alert>
      </Collapse>
      <Collapse in={isInteger(code) && Number(code) === 0}>
        <Alert severity="success">
          {t('success.updated', 'Данные успешно обновлены')}
        </Alert>
      </Collapse>
    </form>
  );
};
