import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'user-profile',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    row: {
      margin: theme.spacing(0, -1.5),
    },
    col: {
      padding: theme.spacing(0, 1.5),
    },
    // photo: {
    //   flex: `0 0 calc(197px + ${theme.spacing(3)})`,
    //   maxWidth: `calc(197px + ${theme.spacing(3)})`,
    //   '& ~ $data': {
    //     flex: `0 0 calc(100% - 197px - ${theme.spacing(3)})`,
    //     maxWidth: `calc(100% - 197px - ${theme.spacing(3)})`,
    //   },
    // },
    // data: {
    //   flex: '0 0 100%',
    //   maxWidth: '100%',
    // },
    media: {
      display: 'block',
      overflow: 'hidden',
      borderRadius: theme.spacing(1),
      maxWidth: 175,
      height: 'auto',
      width: '100%',
    },
    img: {
      display: 'block',
      height: 'auto',
      width: '100%',
    },
    actions: {},
    edit: {},
    view: {},
  }),
  options
);

export default useStyles;
