import { Paper, Typography } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SignInLogin, SignInPhone } from '.';
import { clearSignInStates } from '../../../thunks/auth-thunks';
import { useResponsive } from '../../theme/useResponsive';
import { SignInESign } from './sign-in-esign/sign-in-esign';
import { SignInIdCard } from './sign-in-id-card/sign-in-id-card';
import useStyles from './styles';

interface SignInProps {
  className?: any;
  variant?: 'fullWidth' | 'shifted';
  classes?: {
    root: string;
    paper: string;
    body: string;
  };
}

// type classStateType = 'prev' | 'next' | 'selected';

// const classState = (index: number, value: number): classStateType => {
//   const vals = new Map()
//     .set('-1', 'prev')
//     .set('1', 'next')
//     .set('0', 'selected');
//   console.log(
//     `classState  index : ${index}  value: ${value} ${vals.get(
//       String(value - index)
//     )}`
//   );
//   return vals.get(String(value - index));
// };

const signInTabs = [
  {
    label: 'Логин',
    labelKey: 'signIn.login',
    value: '1',
    mobile: true,
  },
  {
    label: 'Mobile-ID',
    labelKey: 'signIn.mobileId',
    value: '2',
    mobile: true,
  },
  {
    label: 'ЭЦП',
    labelKey: 'signIn.eSign',
    value: '3',
    mobile: true,
  },
  {
    label: 'ID-карта',
    labelKey: 'signIn.idCard',
    value: '4',
    mobile: true,
  },
];

export const SignIn: ComponentType<SignInProps> = ({
  className,
  classes: extClasses = {
    root: '',
    paper: '',
    body: '',
  },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const r = useResponsive();
  const { t } = useTranslation();

  const [value, setValue] = React.useState(1);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(clearSignInStates);
  };

  return (
    <div className={clsx(classes.root, className, extClasses.root)}>
      <Typography
        className={classes.title}
        component="div"
        variant={r({ xs: 'h2', lg: 'h1' })}
      >
        {t('signIn.title', 'Вход в систему')}
      </Typography>
      <TabContext value={String(value)}>
        <TabList
          className={classes.list}
          onChange={handleChange}
          variant={r({ xs: 'fullWidth', lg: 'scrollable' })}
        >
          {(signInTabs || []).map((item: any, index: any) => {
            return r({ xs: item.mobile, xl: true }) ? (
              <Tab
                key={index}
                classes={{
                  root: clsx(classes.tab),
                  selected: classes.selected,
                  disabled: classes.disabled,
                }}
                label={
                  <>
                    <span className={classes.tabTextFake}>
                      {t(item.labelKey, item.label)}
                    </span>
                    <span className={classes.tabText}>
                      {t(item.labelKey, item.label)}
                    </span>
                  </>
                }
                value={item.value}
              />
            ) : null;
          })}
        </TabList>

        <Paper className={clsx(classes.content, extClasses.paper)}>
          <TabPanel className={clsx(classes.panel)} value="1">
            <div className={clsx(classes.body, extClasses.body, 'login')}>
              <SignInLogin className={classes.login} />
            </div>
          </TabPanel>

          <TabPanel className={classes.panel} value="2">
            <div className={clsx(classes.body, extClasses.body, 'phone')}>
              <SignInPhone className={classes.phone} />
            </div>
          </TabPanel>

          <TabPanel className={classes.panel} value="3">
            <div className={clsx(classes.body, extClasses.body, 'e-sign')}>
              <SignInESign className={classes.eSign} />
            </div>
          </TabPanel>

          <TabPanel className={clsx(classes.panel)} value="4">
            <div className={clsx(classes.body, extClasses.body, 'id-card')}>
              <SignInIdCard className={classes.idCard} />
            </div>
          </TabPanel>
        </Paper>
      </TabContext>
    </div>
  );
};
