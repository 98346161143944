import * as yup from 'yup';

export const FIELD_DOCUMENT = 'document';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_DOCUMENT]: {
    label: 'Новая серия и номер паспорта',
    labelKey: 'passport.new.serialNumber',
    arialLabel: 'Your passport serial number',
  },
};

export const PassportSerialNumberValidationForm = yup.object().shape({
  [FIELD_DOCUMENT]: yup.string().required('error.required').trim(),
});
