import {
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { EyeHideIcon, EyeIcon } from '../../../../common/icons';
import { useToggle } from '../../../../hooks/useToggle';
import { PasswordRecoveryDialog } from '../../../password';
import { useResponsive } from '../../../theme/useResponsive';
import { FIELD_LABELS, FIELD_LOGIN, FIELD_PASSWORD } from './sign-in-schema';
import useStyles from './styles';
import { useSignIn } from './useSignIn';

interface SignInLoginFormProps {
  className?: any;
  handleSubmit: (data: any) => void;
}

export const SignInLoginForm: ComponentType<SignInLoginFormProps> = ({
  className,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const [visibleChars, toggleVisibleChars] = useToggle(false);
  const [isRecoveryVisible, toggleRecoveryVisible] = useToggle(false);
  const { isLoading, formik, handleFieldChange, hasError, getErrorMessage } =
    useSignIn({
      handleSubmit,
    });

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);

  const checkRoute = () => {
    const currentUrl = window.location.search;
    const urlParams = new URLSearchParams(currentUrl);
    const link = urlParams.get('link');
    link && toggleRecoveryVisible(link === 'popup');
  };

  useEffect(() => {
    checkRoute();
  }, [window.location.search]);

  return (
    <div className={clsx(classes.root, className)}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={r({ lg: 2 })}>
          <Grid item xs={12} lg={6}>
            <TextField
              {...formik.getFieldProps(FIELD_LOGIN)}
              label={t(
                FIELD_LABELS[FIELD_LOGIN]['labelKey'],
                FIELD_LABELS[FIELD_LOGIN]['label']
              )}
              onChange={(e: any) => handleFieldChange(FIELD_LOGIN, e)}
              id={FIELD_LOGIN}
              error={hasError(FIELD_LOGIN)}
              helperText={getErrorMessage(FIELD_LOGIN)}
              disabled={isLoading}
              InputProps={{
                'aria-label': FIELD_LABELS[FIELD_LOGIN]['ariaLabel'],
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              {...formik.getFieldProps(FIELD_PASSWORD)}
              label={t(
                FIELD_LABELS[FIELD_PASSWORD]['labelKey'],
                FIELD_LABELS[FIELD_PASSWORD]['label']
              )}
              onChange={(e: any) => handleFieldChange(FIELD_PASSWORD, e)}
              id={FIELD_PASSWORD}
              type={visibleChars ? 'text' : 'password'}
              error={hasError(FIELD_PASSWORD)}
              helperText={getErrorMessage(FIELD_PASSWORD)}
              disabled={isLoading}
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={
                      t(
                        visibleChars
                          ? `action.password.hide`
                          : `action.password.show`
                      ) || ''
                    }
                    arrow
                  >
                    <IconButton
                      onClick={toggleVisibleChars}
                      aria-label={FIELD_LABELS[FIELD_PASSWORD]['arialLabel']}
                      color="primary"
                      size="medium"
                    >
                      <SvgIcon
                        component={visibleChars ? EyeHideIcon : EyeIcon}
                        viewBox="0 0 24 24"
                      />
                    </IconButton>
                  </Tooltip>
                ),
                'aria-label': FIELD_LABELS[FIELD_PASSWORD]['ariaLabel'],
              }}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={r({ lg: 2 })} mt={0.5}>
          <Grid
            item
            xs={12}
            lg={6}
            alignSelf="center"
            order={r({ xs: 1, lg: 2 })}
          >
            <Typography
              className={clsx(classes.text)}
              variant="body1"
              color="textSecondary"
              component="div"
              mb={r({ xs: 3.75, lg: 0 })}
              textAlign={r({ lg: 'right' })}
            >
              {/* prettier-ignore-start */}
              <Trans i18nKey="signIn.forgot">
                Забыли&npbsp;
                <span
                  onClick={toggleRecoveryVisible}
                  role="button"
                  className={classes.link}
                  title={t(
                    'signIn.forgot.link',
                    'Востановить логин или пароль'
                  )}
                >
                  логин или пароль
                </span>
                &npbsp;?
              </Trans>
              {/* prettier-ignore-end */}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} order={r({ xs: 2, lg: 1 })}>
            <LoadingButton
              loading={isLoading}
              disabled={!noErrors}
              variant="contained"
              type="submit"
              className={classes.btn}
              fullWidth={r({ xs: true, lg: false })}
            >
              {t('action.signIn', 'Войти')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>

      {/* PasswordRecoveryDialog */}
      <PasswordRecoveryDialog
        open={isRecoveryVisible}
        onClose={toggleRecoveryVisible}
      />
    </div>
  );
};
