import {
  Collapse,
  Divider,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../common/store';
import {
  addLegalEntity,
  getLegalEntities,
} from '../../thunks/legal-entity-thunks';
import { useResponsive } from '../theme/useResponsive';
import { UiRadio } from '../ui';
import { LegalEntityESign, LegalEntityTin } from './';
import { LegalEntityOrganizations } from './legal-entity-organizations';
import useStyles from './styles';

export const LegalEntity: ComponentType = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLegalEntities());
  }, [dispatch]);

  const [organizations, setOrganizations] = useState<Record<string, any>[]>([]);
  const entities = useSelector((state: State) => state.legalEntity.items);

  useEffect(() => {
    setOrganizations([...organizations, entities]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleTinSubmit = (tin: string) => {
    dispatch(addLegalEntity(tin));
  };

  return (
    <div className={clsx(classes.root)}>
      {organizations.length > 0 && <LegalEntityOrganizations data={entities} />}

      <Typography variant="h5" className={classes.title} mb={4} mt={1.75}>
        {t('legalEntityAdd.title', 'Добавить юридическое лицо')}:
      </Typography>

      <RadioGroup
        aria-label={'add legal entity'}
        defaultValue="esign"
        name="legal-entity-add"
        value={value}
        onChange={handleRadioChange}
      >
        {r({ xs: false, lg: true }) && (
          <>
            <FormControlLabel
              value="esign"
              control={<UiRadio />}
              label={
                <Typography variant="body1" component="div">
                  {t(
                    'legalEntityAdd.esign',
                    'У меня есть ЭЦП представителя организации'
                  )}
                </Typography>
              }
            />
            <Collapse in={value === 'esign'}>
              <LegalEntityESign className={classes.esign} />
            </Collapse>

            <Divider className={classes.divider} />
          </>
        )}
        <FormControlLabel
          value="tin"
          control={<UiRadio />}
          label={
            <Typography variant="body1" component="div">
              {t('legalEntityAdd.tin', 'Я являюсь руководителем организации')}
            </Typography>
          }
        />
        <Collapse in={value === 'tin'}>
          <LegalEntityTin
            className={classes.tin}
            onTinSubmit={handleTinSubmit}
          />
        </Collapse>
      </RadioGroup>
    </div>
  );
};
