import { ComponentType } from 'react';
import { LoginEdit } from './login-edit/login-edit';

interface LoginProps {
  className?: string;
  variant?: 'edit';
}

export const Login: ComponentType<LoginProps> = ({
  variant = 'edit',
  ...props
}) => {
  switch (variant) {
    case 'edit':
      return <LoginEdit {...props} />;
    default:
      return null;
  }
};
