import { Box, SvgIcon, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import i18n from '../../../common/i18next';
import { UserIcon } from '../../../common/icons';
import { State } from '../../../common/store';
import { getPhoto } from '../../../thunks/profile-thunks';
import useAuth from '../../auth/useAuth';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

interface UserHeaderProps {
  className?: any;
}

export const UserHeader: ComponentType<UserHeaderProps> = ({ className }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const r = useResponsive();
  const { authorized } = useAuth();
  const { pinfl, login, passportData, photo } = useSelector(
    (state: State) => state.profile
  );

  useEffect(() => {
    if (pinfl) {
      dispatch(getPhoto(pinfl));
      // dispatch(getUserInfo());
    }
  }, [dispatch, pinfl]);

  const userPhoto = get(photo, 'data', false);

  const userName = [
    get(passportData, 'name_latin') || get(passportData, 'name_latin'),
    get(passportData, 'surname_latin') || get(passportData, 'surname_latin'),
  ]
    .filter(Boolean)
    .join(' ');

  if (!pinfl && !authorized) {
    return null;
  }

  return (
    <Link
      className={clsx(classes.root, className)}
      to={`/${i18n.language}/personal-data`}
    >
      {r({ xs: false, lg: true }) && (
        <div className={classes.body}>
          <Typography
            variant="body2"
            component="div"
            align="right"
            className={classes.title}
          >
            {login}
          </Typography>
          <Typography
            variant="h6"
            component="div"
            align="right"
            className={classes.text}
          >
            {userName}
          </Typography>
        </div>
      )}

      <Box className={classes.media} component={photo ? 'picture' : 'div'}>
        {photo ? (
          <>
            <source
              srcSet={`data:image/jpeg;base64,${userPhoto}`}
              type="image/webp"
            />
            <source
              srcSet={`data:image/jpeg;base64,${userPhoto} 1x,data:image/jpeg;base64,${userPhoto} 2x,data:image/jpeg;base64,${userPhoto} 3x`}
              type="image/jpeg"
            />
            <img
              className={classes.img}
              src={`data:image/jpeg;base64,${userPhoto}`}
              srcSet={`data:image/jpeg;base64,${userPhoto} 1x,data:image/jpeg;base64,${userPhoto} 2x,data:image/jpeg;base64,${userPhoto} 3x`}
              height="38"
              width="38"
              alt={userName}
            />
          </>
        ) : (
          <SvgIcon className={classes.icon} component={UserIcon} />
        )}
      </Box>
    </Link>
  );
};
