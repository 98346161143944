import { alpha, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'layout-default',
};

const drawerWidth = 380;
const drawerWidthShort = 112;

const openedMixin = (theme: Theme): any => ({
  maxWidth: drawerWidth,
  width: '100%',
  transition: theme.transitions.create('max-width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  '& + $content': {
    width: `100%`,
    maxWidth: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create('max-width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const closedMixin = (theme: Theme): any => ({
  maxWidth: drawerWidthShort,
  transition: theme.transitions.create('max-width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      '& .MuiContainer-root': {
        transition: theme.transitions.create('max-width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      [theme.breakpoints.up('xl')]: {
        '&$closed .MuiContainer-root': {
          maxWidth: 1052,
          transition: theme.transitions.create('max-width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        },
      },
    },
    drawer: {
      overflowX: 'hidden',
      maxWidth: drawerWidth,
      width: '100%',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      overflow: 'hidden',
      overflowY: 'auto',
      // '&:hover > .MuiDrawer-paper': {
      //   paddingRight: 0,
      //   overflow: 'hidden',
      //   overflowY: 'auto',
      //   '& $sidebar': {
      //     marginRight: 0,
      //   },
      // },
      '&$opened, &$opened > .MuiDrawer-paper': openedMixin(theme),
      '&$closed, &$closed > .MuiDrawer-paper': closedMixin(theme),
      '& > .MuiDrawer-paper': {
        color: alpha(theme.palette.primary.contrastText, 0.8),
        backgroundColor: theme.palette.primary.main,
        width: '100%',
      },
      // [theme.breakpoints.up('lg')]: {
      //   '& > .MuiDrawer-paper': {
      //     paddingRight: 17,
      //     overflowY: 'hidden',
      //     '& $sidebar': {
      //       marginRight: -17,
      //     },
      //   },
      // },
    },
    content: {
      paddingBottom: theme.spacing(6),
      width: '100%',
      maxWidth: '100%',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    sidebar: {},
    main: {},

    opened: {},
    closed: {},
    short: {},
  }),
  options
);

export default useStyles;
