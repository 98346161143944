import { Radio, RadioProps, SvgIcon } from '@material-ui/core';
import { ComponentType } from 'react';
import { ReactComponent as RadioButtonCheckedIcon } from './assets/radio-button-checked.svg';
import { ReactComponent as RadioButtonUncheckedIcon } from './assets/radio-button-unchecked.svg';
import useStyles from './styles';

interface UiRadioProps {
  className?: string;
}

export const UiRadio: ComponentType<UiRadioProps & RadioProps> = ({
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color={'primary'}
      {...props}
      checkedIcon={
        <SvgIcon component={RadioButtonCheckedIcon} className={classes.icon} />
      }
      icon={
        <SvgIcon
          component={RadioButtonUncheckedIcon}
          className={classes.icon}
        />
      }
    />
  );
};
