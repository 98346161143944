import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'navigation',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      // margin: theme.spacing(1, -2, 1, -10),
      padding: 0,
    },
    default: {},
    short: {
      '& $item': {
        paddingLeft: theme.spacing(5.5),
      },
    },
    item: {
      padding: theme.spacing(1, 2, 1, 2),
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
      '&.Mui-selected, &:hover, &:focus:hover': {
        color: theme.palette.primary.contrastText,
      },
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(5.5),
      },
    },
    divider: {
      margin: theme.spacing(1, 0),
      borderColor: 'inherit',
      opacity: 0.16,
      maxWidth: 200,
      marginLeft: theme.spacing(5),
    },
    iconWrap: {
      color: 'inherit',
      marginRight: theme.spacing(1.5),
      flex: '0 0 24px',
      width: 24,
      minWidth: '1%',
    },
    active: {},
    icon: {},
    body: {
      marginTop: 0,
      marginBottom: 0,
      color: 'inherit',
      flex: `0 0 calc(100% - 24px - 12px)`,
      width: `calc(100% - 24px - 12px)`,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '& > .MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    text: {
      '.Mui-selected &': {
        flex: '0 0 auto',
        maxWidth: '0 0 calc(100% - 32px)',
      },
      '& + $icon': {
        marginLeft: theme.spacing(1),
        flex: '0 0 24px',
        width: 24,
      },
    },
    dot: {
      border: '4px solid',
      borderRadius: 4,
    },
  }),
  options
);

export default useStyles;
