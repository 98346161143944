import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorized } from '../authorized';
import { Header } from '../header';
import { Layout } from '../layout';
import { LegalEntity } from '../legal-entity';

export const PageLegalEntity: ComponentType = Authorized()(() => {
  const { t } = useTranslation();

  return (
    <Layout variant="default" bgcolor="background.paper">
      <Header title={t('legalEntity.list.title', 'Юридическое лицо')} />
      <Box component={'main'} role="main">
        <Container>
          <LegalEntity />
        </Container>
      </Box>
    </Layout>
  );
});
