import { Alert, Box, Collapse, Grid, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty, isInteger } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { MASK_PHONE } from '../../../constants/masks-constants';
import { useResponsive } from '../../theme/useResponsive';
import { PhoneConfirm } from '../phone-confirm';
import { FIELD_LABELS, FIELD_PHONE } from './change-phone-schema';
import useStyles from './styles';
import { useChangePhone } from './useChangePhone';

interface PhoneFormProps {
  className?: string;
}

export const PhoneForm: ComponentType<PhoneFormProps> = ({ className }) => {
  const classes = useStyles();
  const r = useResponsive();
  const { t } = useTranslation();
  const {
    isTouched,
    isLoading,
    code,
    formik,
    profileSms,
    handleFieldChange,
    hasError,
    getErrorMessage,
    handleConfirm,
    handleCodeChange,
    handleRetry,
  } = useChangePhone();

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);

  return (
    <div className={clsx(classes.root, className)}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={r({ lg: 4.5 })}>
          <Grid item xs={12} lg={6}>
            <InputMask
              mask={MASK_PHONE}
              maskPlaceholder={null}
              {...formik.getFieldProps(FIELD_PHONE)}
              onChange={(e: any) => handleFieldChange(FIELD_PHONE, e)}
              disabled={
                isLoading ||
                (isInteger(code) &&
                  Number(code) === 0 &&
                  !isInteger(profileSms?.code))
              }
            >
              <TextField
                label={t(
                  FIELD_LABELS[FIELD_PHONE]['labelKey'],
                  FIELD_LABELS[FIELD_PHONE]['label']
                )}
                id={FIELD_PHONE}
                error={hasError(FIELD_PHONE)}
                helperText={getErrorMessage(FIELD_PHONE)}
                InputProps={{
                  'aria-label': FIELD_LABELS[FIELD_PHONE]['arialLabel'],
                }}
              />
            </InputMask>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box className={classes.actions} pt={r({ lg: 3 })}>
              <LoadingButton
                className={classes.btn}
                loading={isLoading}
                disabled={!noErrors || !isTouched}
                fullWidth={r({ xs: true, lg: false })}
                variant="outlined"
                type="submit"
              >
                {t('action.edit', 'Изменить')}
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>

      {isInteger(code) && Number(code) === 0 && profileSms.code !== 0 && (
        <PhoneConfirm
          onRetry={handleRetry}
          onSubmit={handleConfirm}
          onChange={handleCodeChange}
          storeState={profileSms}
        />
      )}

      <Collapse in={isInteger(code) && Number(code) !== 0}>
        <Alert severity="error">
          {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`)}
        </Alert>
      </Collapse>

      <Collapse
        in={isInteger(profileSms?.code) && Number(profileSms?.code) !== 0}
      >
        <Alert severity="error">
          {t(`error.${profileSms?.code}`, `Ошибка с кодом ${profileSms?.code}`)}
        </Alert>
      </Collapse>

      <Collapse
        in={isInteger(profileSms?.code) && Number(profileSms?.code) === 0}
      >
        <Alert severity="success">
          {t('success.updated', 'Данные успешно обновлены')}
        </Alert>
      </Collapse>
    </div>
  );
};
