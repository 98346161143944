import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = 'uz' as string;

export const {
  actions: currentLocaleActions,
  reducer: currentLocaleReducer,
} = createSlice({
  name: 'currentLocale',
  initialState,
  reducers: {
    setCurrentLocale: (_state, { payload }: PayloadAction<string>) => payload,
  },
});
