import { FormikValues, useFormik } from 'formik';
import { State } from '../../../common/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { forEach, get, isInteger } from 'lodash';
import { changeEmail, clearEmailStates } from '../../../thunks/profile-thunks';
import { useEffect, useState } from 'react';
import { FIELD_EMAIL, ChangeEmailValidationForm } from './change-email-schema';
import { Tooltip } from '@material-ui/core';

export const useChangeEmail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { email, emailForm } = useSelector((state: State) => state.profile);

  const handleSubmit = (values: FormikValues) => {
    let hasError = [];

    forEach(values, (value, field) => {
      if (value.includes('_')) {
        formik.setFieldError(field, 'error.required');
        hasError.push(field);
      }
    });

    if (!hasError.length) {
      dispatch(changeEmail(values));
    }
  };

  const handleFieldChange = (field: string, e: any) => {
    const value = e.target.value;
    if (field === FIELD_EMAIL) {
      setIsTouched(value !== email);
    }

    formik.setFieldValue(field, value);
    formik.setFieldError(field, null);
    dispatch(clearEmailStates());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_EMAIL]: email,
    },
    validationSchema: ChangeEmailValidationForm,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isInteger(emailForm.code) && Number(emailForm.code) === 0) {
      setIsTouched(false);
    }
  }, [emailForm.code]);

  useEffect(() => {
    return () => {
      dispatch(clearEmailStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...emailForm,
    isTouched,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  };
};
