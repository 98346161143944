// readmore
// #TODO
// https://github.com/mui-org/material-ui/blob/next/docs/src/pages.ts
export interface MainRouteProps {
  pathname: string;
  pathAlias?: string;
  children?: MainRouteProps[];

  icon?: string;
  /**
   * Props spread to the Link component
   */
  linkProps?: Record<string, unknown>;
  subheader?: string;
  /**
   * Overrides the default page title.
   */
  title: string;
  i18n: string;
  authorized?: boolean;
}

const MainRoute: readonly MainRouteProps[] = [
  {
    pathname: '',
    icon: 'HomeIcon',
    title: 'Главная',
    i18n: 'nav.home',
    authorized: true,
  },
  {
    pathname: '/personal-data',
    icon: 'UserIcon',
    title: 'Персональные данные',
    i18n: 'nav.personalData',
    authorized: true,
    children: [
      {
        pathname: '/personal-data',
        title: 'Профиль пользователя',
        i18n: 'nav.personalData.profile',
        authorized: true,
      },
      {
        pathname: '/personal-data/contacts',
        title: 'Контактные данные',
        i18n: 'nav.personalData.contacts',
        authorized: true,
      },
      {
        pathname: '/personal-data/settings',
        title: 'Настройки',
        i18n: 'nav.personalData.settings',
        authorized: true,
      },
    ],
  },
  {
    pathname: '/authentication',
    icon: 'CheckCircleOutlineIcon',
    title: 'Подлинность',
    i18n: 'nav.authenticity',
    authorized: true,
  },
  // {
  //   pathname: '/children',
  //   icon: 'ChildIcon',
  //   title: 'Регистрация детей',
  //   i18n: 'register-children',
  //   authorized: true,
  // },
  {
    pathname: '/legal-entity',
    icon: 'CaseIcon',
    title: 'Юридическое лицо',
    i18n: 'nav.entity',
    authorized: true,
  },
  {
    pathname: '/history',
    icon: 'TimeIcon',
    title: 'История',
    i18n: 'nav.history',
    authorized: true,
  },
  {
    pathname: '/sessions',
    icon: 'CheckListIcon',
    title: 'Сеансы',
    i18n: 'nav.sessions',
    authorized: true,
  },
  {
    pathname: '/user-agreement',
    icon: 'DocumentIcon',
    title: 'Пользовательское соглашение',
    i18n: 'nav.userAgreement',
  },
  // {
  //   pathname: '/connected-resources',
  //   icon: 'LaunchIcon',
  //   title: 'Подключенные ресурсы',
  //   i18n: 'nav.connectedResources',
  //   authorized: true,
  // },
  {
    pathname: '/help',
    icon: 'HelpIcon',
    title: 'Помощь',
    i18n: 'nav.help',
  },
  {
    pathname: '/about',
    icon: 'InfoIcon',
    title: 'О проекте',
    i18n: 'nav.about',
  },
];

export default MainRoute;
