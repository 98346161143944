// import i18n from 'i18next';
import { ComponentType, createElement } from 'react';
// import { Redirect } from 'react-router-dom';
// import { AskRedirectToSignIn } from './AskRedirectToSignIn';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import i18n from '../common/i18next';
import { State } from '../common/store';
import { AskRedirectToSignIn } from './AskRedirectToSignIn';

export const Authorized = (ask = false) => <T>(Component: ComponentType<T>) => {
  const EnhancedComponent: ComponentType<T> = (props) => {
    const auth = useSelector((state: State) => state.auth);
    const to = `/${i18n.language}`; //BUG redirecting to home after force reload (f5) /sessions -> /ru

    if (auth.isRequiresPreviousSignInCheck || auth.isSigningIn) {
      return null;
    }

    if (!auth.isSignedIn) {
      if (ask) {
        return createElement(AskRedirectToSignIn);
      }
      return createElement(Redirect, { to });
    }

    return createElement(Component, props);
  };

  return EnhancedComponent;
};
