import { Box, Divider, Fab, Menu, MenuItem, SvgIcon } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType, useState } from 'react';
import { useLangSwitcher } from '..';
import { ArrowBottomIcon } from '../../../common/icons';
import { locales, localesMap } from '../../../common/locales';
import useStyles from './styles';

interface LangSwitcherSelectProps {}

export const LangSwitcherSelect: ComponentType<
  LangSwitcherSelectProps & BoxProps
> = ({ className, ...props }) => {
  const classes = useStyles();
  const { changeLang, curentLang, curentLangName } = useLangSwitcher();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <Fab
        color="primary"
        variant="extended"
        className={classes.btn}
        aria-label="changle language"
        onClick={handleClick}
      >
        <div className={classes.text}>{curentLangName}</div>
        <SvgIcon component={ArrowBottomIcon} className={classes.icon} />
      </Fab>
      <Menu
        className={classes.nav}
        elevation={0}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
        keepMounted
      >
        {locales.map((lang, index) => [
          <MenuItem
            className={classes.item}
            key={`lang_${index}`}
            selected={Boolean(curentLang(lang))}
            onClick={() => changeLang(lang)}
          >
            {localesMap[lang].name}
          </MenuItem>,
          <Divider className={classes.divider} />,
        ])}
      </Menu>
    </Box>
  );
};
