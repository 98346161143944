import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'user-settings',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    row: {},
    col: {},
    item: {},
    divider: {
      margin: theme.spacing(3.75, 0),
    },
    login: {},
    password: {},
    remove: {},
  }),
  options
);

export default useStyles;
