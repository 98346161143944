import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'legal-entity-employees-add',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    title: {
      marginBottom: theme.spacing(5.875),
    },
    search: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 670,
      },
    },
    form: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: 306,
      },
    },
    results: {},
    resultsTable: {
      display: 'inline-grid',
      gridTemplateColumns: '1fr',
      gridColumnGap: theme.spacing(2),
      gridRowGap: theme.spacing(2),
      width: 'auto',
      margin: theme.spacing(4, 0),
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: theme.spacing(4.5),
        gridRowGap: theme.spacing(3.625),
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns:
          'fit-content(10%) fit-content(10%) fit-content(10%)',
        margin: theme.spacing(6.25, 0, 3.625),
      },
    },
    resultsTableCell: {},
    resultsLabel: {
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'nowrap',
      },
    },
    resultsValue: {},
    selectPosition: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(9),
      },
    },
    selectRoot: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 307,
      },
    },
    select: {
      padding: theme.spacing(1.75),
      lineHeight: '20px',
    },
    selectIcon: {
      top: 'calc(50% - 12px)',
    },
    actions: {},
  }),
  options
);

export default useStyles;
