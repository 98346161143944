import * as yup from 'yup';
import 'yup-phone';

export const FIELD_PINFL = 'pinfl';
export const FIELD_PASSPORT = 'document';
export const FIELD_LE_TIN = 'leTin';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_PINFL]: {
    label: 'ПИНФЛ',
    labelKey: 'signUp.id',
    ariaLabel: 'Your id',
  },
  [FIELD_PASSPORT]: {
    label: 'Серия и Номер паспорта',
    labelKey: 'signUp.passportId',
    ariaLabel: 'Your series and passport number',
  },
};

export const EmployeesAddSchema = yup.object().shape({
  [FIELD_PINFL]: yup
    .string()
    .transform((value) => value.replace(/\s/g, ''))
    .length(14, 'error.passport.pin.length')
    .required('error.required')
    .trim(),
  [FIELD_PASSPORT]: yup
    .string()
    .transform((value) => value.replace(/\s/g, ''))
    .required('error.required')
    .trim(),
});
