import * as yup from 'yup';

const PasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .min(3, 'error.minLength')
    .required('error.required')
    .trim(),
});

export default PasswordValidationSchema;
