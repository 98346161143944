import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SignInPhoneForm } from '../index';
import useStyles from './styles';

interface SignInPhoneProps {
  className?: any;
}

export const SignInPhone: ComponentType<SignInPhoneProps> = ({ className }) => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <Typography color="textSecondary" variant="body1" component="div">
        <p>
          {t(
            'signIn.mobileID.text',
            'Для входа в систему, на Ваш номер телефона должна быть подключенауслуга Mobile-ID.'
          )}{' '}
          <Link to={`/${i18n.language}/help`}>
            {t('action.readMore', 'Подробнее')}
          </Link>
        </p>
      </Typography>
      <SignInPhoneForm className={classes.form} />
    </div>
  );
};
