import { IconButton, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeftIcon, ArrowRightIcon } from '../../../common/icons';
import { dateParse } from '../../../common/utils/format-utils';
import { A11y } from '../../a11y';
import useAuth from '../../auth/useAuth';
import { Developer } from '../../developer';
import { HelpWidget } from '../../help';
import { LangSwitcher } from '../../lang-switcher';
import { Logo } from '../../logo';
import { Navigation } from '../../navigation';
import { useResponsive } from '../../theme/useResponsive';
import { UserStatistics } from '../../user';
import { LayoutContext } from '../LayoutProvider';
import useStyles from './styles';

export type VariantSidebar = 'default' | 'short';

export interface LayoutSidebarProps {
  className?: string;
  variant?: VariantSidebar;
  open?: boolean;
}

export const LayoutSidebar: ComponentType<LayoutSidebarProps> = ({
  className,
  variant = 'default',
}) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const r = useResponsive();
  const { sidebar, toggleSidebar } = useContext(LayoutContext);
  const open = sidebar.open;
  const { authorized, pinfl } = useAuth();

  return (
    <div className={clsx(classes.root, className, classes[variant])}>
      {r({ xs: true, xxl: false }) && (
        <Tooltip
          title={t(`action.nav.${!open}`) || ''}
          placement={'right'}
          arrow
        >
          <IconButton
            className={classes.toggle}
            size={r({ xs: 'small', lg: 'medium' })}
            color="inherit"
            onClick={toggleSidebar}
            edge="start"
          >
            <SvgIcon
              component={open ? ArrowLeftIcon : ArrowRightIcon}
              className={clsx(classes.icon)}
            />
          </IconButton>
        </Tooltip>
      )}

      {!open ||
        (r({ xs: false, lg: true }) && <A11y className={classes.a11y} />)}

      {!open ||
        (r({ xs: authorized ? true : false, lg: true }) && (
          <LangSwitcher className={clsx(classes.lang)} variant="inline" />
        ))}

      <Logo className={classes.logo} variant={open ? 'large' : 'small'} />

      <Navigation className={classes.nav} />

      {open && !authorized && !pinfl && r({ lg: true }) && (
        <>
          <Typography
            className={clsx(classes.title)}
            variant="h4"
            component="h2"
            mt={9}
          >
            {t('app.title', 'Единая система идентификации')}
          </Typography>
          <Typography
            className={clsx(classes.text)}
            variant="body2"
            mt={2}
            mb={3}
          >
            {t(
              'app.text',
              'Получите доступ ко&nbsp;всем системам электронного правительства через одну учетную запись'
            )}
          </Typography>
        </>
      )}

      {/* {open && r({ xs: true, lg: false }) && ( */}
      {open && <HelpWidget className={classes.help} />}

      {r({ xs: true, lg: open }) && (
        <>
          <UserStatistics className={clsx(classes.statistics)} />
          <Developer className={clsx(classes.developer)} />
          <Typography
            className={clsx(classes.copyright)}
            variant="caption"
            component="div"
          >
            &copy; 2016&mdash;{dateParse(new Date(), `yyyy`)} <br />
            {t(
              'copyright',
              'Единая система идентификации пользователей электронного правительства Республики Узбекистан'
            )}
          </Typography>
          {/* <App className={clsx(classes.developer)} /> */}
        </>
      )}
    </div>
  );
};
