import { Box, Grid, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { get } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { getRefreshData } from '../../../thunks/profile-thunks';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

interface AddressAutoProps {
  className?: string;
}

const formatAddress = (value: Record<string, any> | null) => {
  if (!value) return null;
  return [get(value, 'pCountry'), get(value, 'pRegion'), get(value, 'pAddress')]
    .filter(Boolean)
    .join(', ')
    .replace(/ {2}|:/g, ' ')
    .replace('uy', 'уй')
    .replace('xonadon', 'хонадон')
    .toUpperCase();
};

export const AddressAuto: ComponentType<AddressAutoProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const dispatch = useDispatch();

  const { addressData, isLoading } = useSelector(
    (state: State) => state.profile
  );

  const [fullAddress, setFullAddress] = useState<string | null>('');

  const _updateUserData = () => {
    dispatch(getRefreshData());
  };

  useEffect(() => {
    setFullAddress(formatAddress(addressData));
  }, [addressData]);

  return (
    <div className={clsx(classes.root, className)}>
      <Box className={classes.body}>
        <Typography
          className={clsx(classes.title)}
          variant="body2"
          color="textSecondary"
          component="div"
        >
          {t('user.address.registration.', 'Адрес по прописке')}
        </Typography>
        <Typography className={clsx(classes.text)} variant="h6" component="div">
          {fullAddress ? fullAddress : t('notSpecified', 'Не указано')}
        </Typography>
      </Box>
      <Box className={classes.actions} pt={3}>
        <Grid container columnSpacing={r({ lg: 4.5 })}>
          <Grid item xs="auto">
            <LoadingButton
              onClick={_updateUserData}
              loading={isLoading}
              variant="outlined"
              className={classes.btn}
              fullWidth={r({ xs: true, lg: false })}
            >
              {t('action.update', 'Обновить')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
