import { Autocomplete, Box, Grid, TextField } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowBottomIcon } from '../../../common/icons';
import { useResponsive } from '../../theme/useResponsive';
import useStyles from './styles';

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
];

interface AddressFormProps {
  className?: string;
}

export const AddressForm: ComponentType<AddressFormProps> = ({ className }) => {
  const classes = useStyles();
  const r = useResponsive();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container columnSpacing={r({ lg: 4.5 })}>
        <Grid item xs={12} lg={6}>
          <Autocomplete
            disablePortal
            id="region"
            options={top100Films}
            popupIcon={<ArrowBottomIcon />}
            renderInput={(params) => (
              <TextField {...params} label={`${t('user.region', 'Область')}`} />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Autocomplete
            disablePortal
            id="city"
            options={top100Films}
            popupIcon={<ArrowBottomIcon />}
            renderInput={(params) => (
              <TextField {...params} label={`${t('user.city', 'Город')}`} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t(
              'user.detail',
              'Индекс, Улица, Квартал, Махаля,  Дом, Квартира'
            )}
          />
        </Grid>
      </Grid>
      <Box className={classes.actions} pt={2}>
        <Grid container columnSpacing={r({ lg: 4.5 })}>
          <Grid item xs="auto">
            <LoadingButton
              loading={false}
              disabled={true}
              variant="outlined"
              className={classes.btn}
              fullWidth={r({ xs: true, lg: false })}
            >
              {t('action.saveChanges', 'Сохранить изменения')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
