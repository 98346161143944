import { Typography } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordForm } from '../index';
import useStyles from './styles';
interface PasswordEditProps {
  className?: any;
}

export const PasswordEdit: ComponentType<PasswordEditProps> = ({
  className,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h5"
        component="h2"
        className={classes.title}
        mb={2.25}
      >
        {t('password.edit', 'Изменить пароль')}
      </Typography>
      <PasswordForm className={classes.form} />
    </>
  );
};
