import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorized } from '../authorized';
import { ConnectedResources } from '../connected-resources';
import { ErrorDialog } from '../error';
import { Header } from '../header';
import { Layout } from '../layout';
import { Main } from '../main/main';

interface PageMainProps {}

export const PageMain: ComponentType<PageMainProps> = Authorized()(() => {
  const { t } = useTranslation();

  return (
    <Layout variant="default" bgcolor="background.paper">
      <Header
        title={t('connectedResources', 'Подключенные ресурсы')}
        component={'h2'}
      />
      <Box component={'main'} role="main">
        <Container>
          <ConnectedResources mb={9} />
          <Main />
          <ErrorDialog />
        </Container>
      </Box>
    </Layout>
  );
});
