import { AlertColor, SnackbarOrigin } from '@material-ui/core';
import { ComponentType, createContext, ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../common/store';
import { toasterActions } from '../../slices/toaster-slice';
import { Snackbar } from './index';

interface SnackbarOpenOptions {
  position?: SnackbarOrigin;
  duration?: number;
  severity?: AlertColor;
}

export const snackbarOpenDefaultOptions: SnackbarOpenOptions = {
  position: {
    vertical: 'top',
    horizontal: 'center',
  },
  duration: 6000,
  severity: 'error',
};

export interface SnackbarContextInterface {
  open: boolean;
  content: ReactElement | string | null;
  options: SnackbarOpenOptions;
  severity?: AlertColor;
  snackbarClose: () => void;
  snackbarOpen: (
    content: ReactElement | string,
    options?: SnackbarOpenOptions
  ) => void;
}

export const SnackbarContext = createContext<SnackbarContextInterface>({
  open: false,
  content: '',
  options: snackbarOpenDefaultOptions,
  snackbarClose: () => {},
  snackbarOpen: () => {},
});

export const SnackbarProvider: ComponentType = (props) => {
  const { open, content, severity } = useSelector(
    (state: State) => state.toaster
  );
  const dispatch = useDispatch();

  const [options, setOptions] = useState<SnackbarOpenOptions>(
    snackbarOpenDefaultOptions
  );

  const snackbarOpen = (
    content: ReactElement | string,
    options = snackbarOpenDefaultOptions
  ) => {
    dispatch(toasterActions.setOpen(options.severity));
    dispatch(toasterActions.setContent(content));
    setOptions({ ...snackbarOpenDefaultOptions, ...options });
  };

  const snackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(toasterActions.setClose());
  };

  return (
    <SnackbarContext.Provider
      value={{
        open,
        options,
        content,
        severity,
        snackbarClose,
        snackbarOpen,
      }}
    >
      {props.children}
      <Snackbar />
    </SnackbarContext.Provider>
  );
};
