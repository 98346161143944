import { ComponentType } from 'react';
import { Typography } from '@material-ui/core';
import { BulletList, FileList } from '../../list';
import { Locale } from '../../../common/locales';
import { useTranslation } from 'react-i18next';
import dataSource from '../../../data-sources/help-connecting-systems-resources.json';
import clsx from 'clsx';
import useStyles from './styles';

interface HelpSystemsProps {
  className?: string;
}

export const HelpSystems: ComponentType<HelpSystemsProps> = ({ className }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const content = dataSource[i18n.language as Locale]['content'];

  const body = (content || []).map((item: Record<string, any>, idx: number) => {
    const { view, list } = item;
    switch (view) {
      case 'files':
        return <FileList data={list} key={idx} />;
      case 'list':
        return <BulletList data={list} key={idx} />;
      default:
        return <Typography {...item} key={idx} />;
    }
  });

  return <div className={clsx(classes.root, className)}>{body}</div>;
};
