import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'connected-resources-list',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      '& .MuiDivider-root:last-child': {
        display: 'none',
      },
    },
    content: {},
    container: {},
    header: {},
    main: {},
    title: {},
    text: {},
    list: {},
    item: {
      '&:last-child': {
        '& > .MuiDivider-root': {
          display: 'none',
        },
      },
    },
  }),
  options
);

export default useStyles;
