import { REACT_APP_API_BASE_URL } from '../constants';
import { getISOLocale } from '../locales';
export const HEADERS = {
  ACCEPT: 'Accept',
  CONTENT_TYPE: 'Content-Type',
  AUTHORIZATION: 'Authorization',
  ACCEPT_LANGUAGE: 'Accept-Language',
};

export const CONTENT_TYPES = {
  JSON: 'application/json',
  MULTIPART_FORMDATA: 'multipart/form-data',
};

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const defaultJsonHeaders = {
  [HEADERS.ACCEPT]: CONTENT_TYPES.JSON,
  [HEADERS.CONTENT_TYPE]: CONTENT_TYPES.JSON,
  [HEADERS.ACCEPT_LANGUAGE]: getISOLocale(),
};

export const fetchJson = async (
  method: HttpMethod,
  url: string,
  extraHeaders: Record<string, string> = {},
  data?: Record<string, any>
) => {
  const headers = { ...defaultJsonHeaders, ...extraHeaders };
  const body = data ? JSON.stringify(data) : undefined;
  const response = await fetch(url, { method, headers, body });
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  }
  if (response.status === 401) {
    throw Object.assign({
      code: response.status,
      text: await response.text(),
    });
  }
  const message = await response.text();
  throw new Error(message);
};

const apiFetchJson = (
  method: HttpMethod,
  path: string,
  params?: Record<string, any>,
  extraHeaders?: Record<string, string>,
  data?: Record<string, any>
) => {
  const rootPath =
    path.includes('https://') || path.includes('http://')
      ? path
      : `${REACT_APP_API_BASE_URL}/${path}`;
  const url = params ? toUrlWithSearchParams(rootPath, params) : rootPath;
  return fetchJson(method, url, extraHeaders, data);
};

export const apiFactory = () => {
  const extraHeaders: Record<string, string> = {};
  return {
    get: (path: string, params?: Record<string, any>) =>
      apiFetchJson('GET', path, params, extraHeaders),
    post: (
      path: string,
      data?: Record<string, any>,
      params?: Record<string, any>
    ) => apiFetchJson('POST', path, params, extraHeaders, data),
    put: (
      path: string,
      data?: Record<string, any>,
      params?: Record<string, any>
    ) => apiFetchJson('PUT', path, params, extraHeaders, data),
    delete: (
      path: string,
      data?: Record<string, any>,
      params?: Record<string, any>
    ) => apiFetchJson('DELETE', path, params, extraHeaders, data),
    addHeader: (name: string, value: string) => (extraHeaders[name] = value),
    removeHeader: (name: string) => delete extraHeaders[name],
  };
};

export const api = apiFactory();

export const fetchMultipartFormData = async (
  url: string,
  values: Record<string, any>
) => {
  const body = new FormData();
  Object.keys(values).forEach((key) => {
    body.append(key, values[key]);
  });
  const response = await fetch(url, { method: 'POST', body });
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    throw await response.text();
  }
};

export const toUrlWithSearchParams = (
  url: string,
  params: Record<string, any>
) => {
  const searchParams = Object.keys(params)
    .reduce((urlSearchParams, key) => {
      const param = params[key];
      if (Array.isArray(param)) {
        param.forEach((item) => {
          urlSearchParams.append(key, item);
        });
      } else {
        urlSearchParams.append(key, param);
      }
      return urlSearchParams;
    }, new URLSearchParams())
    .toString();
  return searchParams ? [url, searchParams].join('?') : url;
};

// const refreshToken = () => {
// https://dev.id.egov.uz/api/v1/secure/refresh/token
//send just post request with refresh toke
//attach bearer token to header
// }
