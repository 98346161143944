import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { themeBreakpoints } from '../theme/breakpoints';

const options = {
  name: 'about',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    container: {},
    header: {
      paddingBottom: theme.spacing(2.5),
      marginBottom: theme.spacing(1),
    },
    figure: {
      display: 'block',
      overflowX: 'scroll',
      margin: theme.spacing(5, -2),
      padding: theme.spacing(0, 2),

      [themeBreakpoints.up('md')]: {
        margin: theme.spacing(7, -2),
      },

      [themeBreakpoints.up('lg')]: {
        margin: theme.spacing(9, 0),
        padding: 0,
        maxWidth: '100%',
      },
    },
    img: {
      display: 'block',
    },
  }),
  options
);

export default useStyles;
