import { Tooltip } from '@material-ui/core';
import { FormikValues, useFormik } from 'formik';
import { get, isInteger } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../common/store';
import { generateRandomPassword } from '../../../../common/utils/random-utils';
import {
  checkPhone,
  checkPinfl,
  clearSignUpStates,
} from '../../../../thunks/auth-thunks';
import {
  FIELD_BIRTH_DATE,
  FIELD_EMAIL,
  FIELD_LOGIN,
  FIELD_PASSPORT,
  FIELD_PASSWORD,
  FIELD_PASSWORD_REPEAT,
  FIELD_PHONE,
  FIELD_PINFL,
  SignUpValidationSchema,
} from './sign-up-schema';

interface useSignUpProps {
  handleSubmit: (values: FormikValues) => void;
}

export const useSignUp = ({ handleSubmit }: useSignUpProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(1);
  const authStore = useSelector((state: State) => state.auth);

  const { signUp } = authStore;
  const pinflData = get(authStore, 'pinflData', {
    isLoading: false,
    code: null,
    message: null,
  });
  const phone = get(authStore, 'phone', {
    isLoading: false,
    code: null,
    message: null,
  });

  const formik: any = useFormik({
    initialValues: {
      [FIELD_BIRTH_DATE]: '',
      [FIELD_PINFL]: '',
      [FIELD_PASSPORT]: '',
      [FIELD_EMAIL]: '',
      [FIELD_PHONE]: '',
      [FIELD_LOGIN]: '',
      [FIELD_PASSWORD]: '',
      [FIELD_PASSWORD_REPEAT]: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: SignUpValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleFieldChange = (field: string, e: any) => {
    if (e !== null) {
      let value;
      e.hasOwnProperty('target') ? (value = e.target.value) : (value = e);
      if (field === FIELD_PASSPORT) {
        value = value.toUpperCase();
      }
      formik.setFieldValue(field, value);
      formik.setFieldError(field, null);
      dispatch(clearSignUpStates());
    }
  };

  const hasError = (field: string) => {
    if (field === FIELD_PINFL && !!pinflData.code) {
      return !!pinflData.code;
    }
    if (field === FIELD_PHONE && !!phone.code) {
      return !!phone.code;
    }
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (field === FIELD_PINFL && !!pinflData.code) {
      fullMessage = t(
        `error.api.${pinflData?.code}.full`,
        `Ошибка с кодом ${pinflData?.code}`
      );
      shortMessage = t(
        `error.api.${pinflData?.code}`,
        `Ошибка с кодом ${pinflData?.code}`
      );
    }

    if (field === FIELD_PHONE && !!phone.code) {
      fullMessage = t(
        `error.api.${phone?.code}.full`,
        `Ошибка с кодом ${phone?.code}`
      );
      shortMessage = t(
        `error.api.${phone?.code}`,
        `Ошибка с кодом ${phone?.code}`
      );
    }

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const handleStep = (newStep: number) => setStep(newStep);

  const nextStep = () => handleStep(step + 1);

  const prevStep = () => handleStep(step - 1);

  const generatePassword = async () => {
    const randomPassword = generateRandomPassword(12);
    await formik.setFieldValue(FIELD_PASSWORD, randomPassword);
    formik.setFieldTouched(FIELD_PASSWORD, true);

    await formik.setFieldValue(FIELD_PASSWORD_REPEAT, randomPassword);
    formik.setFieldTouched(FIELD_PASSWORD_REPEAT, true);
  };

  useEffect(() => {
    if (isInteger(pinflData.code) && Number(pinflData.code) !== 0) {
      formik.setFieldError(FIELD_PINFL, `error.${pinflData.code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinflData.code]);

  useEffect(() => {
    if (isInteger(phone.code) && Number(phone.code) !== 0) {
      formik.setFieldError(FIELD_PHONE, `error.${phone.code}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone.code]);

  useEffect(() => {
    if (!pinflData.isLoading) {
      dispatch(checkPinfl(formik.values.pinfl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.pinfl, dispatch]);

  useEffect(() => {
    if (!phone.isLoading) {
      dispatch(checkPhone(formik.values.phone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.phone, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearSignUpStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...signUp,
    authStore,
    formik,
    step,
    handleFieldChange,
    hasError,
    getErrorMessage,
    handleStep,
    nextStep,
    prevStep,
    generatePassword,
  };
};
