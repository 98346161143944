import { FormikValues, useFormik } from 'formik';
import { State } from '../../../common/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { forEach, get, isInteger } from 'lodash';
import {
  changePhone,
  clearPhoneStates,
  clearProfileSmsStates,
  confirmProfilePhone,
} from '../../../thunks/profile-thunks';
import { useEffect, useState } from 'react';
import { FIELD_PHONE, ChangePhoneValidationForm } from './change-phone-schema';
import { Tooltip } from '@material-ui/core';

export const useChangePhone = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { phoneNumber, phoneForm, profileSms } = useSelector(
    (state: State) => state.profile
  );

  const handleSubmit = (values: FormikValues) => {
    let hasError = [];

    forEach(values, (value, field) => {
      if (value.includes('_')) {
        formik.setFieldError(field, 'error.required');
        hasError.push(field);
      }
    });

    if (!hasError.length) {
      dispatch(changePhone(values));
    }
  };

  const handleFieldChange = (field: string, e: any) => {
    const value = e.target.value;
    if (field === FIELD_PHONE) {
      setIsTouched(value !== phoneNumber);
    }

    formik.setFieldValue(field, value);
    formik.setFieldError(field, null);
    dispatch(clearPhoneStates());
    dispatch(clearProfileSmsStates());
  };

  const handleConfirm = (smsCode: string) => {
    const data = {
      smsCode,
      [FIELD_PHONE]: get(formik, ['values', FIELD_PHONE], ''),
    };
    dispatch(confirmProfilePhone(data));
  };

  const handleCodeChange = () => {
    dispatch(clearProfileSmsStates());
  };

  const handleRetry = () => {
    dispatch(changePhone(formik.values));
    dispatch(clearProfileSmsStates());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_PHONE]: phoneNumber,
    },
    validationSchema: ChangePhoneValidationForm,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isInteger(phoneForm.code) && Number(phoneForm.code) === 0) {
      setIsTouched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneForm.code]);

  useEffect(() => {
    return () => {
      dispatch(clearPhoneStates());
      dispatch(clearProfileSmsStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return {
    ...phoneForm,
    isTouched,
    formik,
    profileSms,
    handleFieldChange,
    hasError,
    getErrorMessage,
    handleConfirm,
    handleCodeChange,
    handleRetry,
  };
};
