import {
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { EyeHideIcon, EyeIcon } from '../../../../common/icons';
import { MASK_PINFL } from '../../../../constants/masks-constants';
import { useToggle } from '../../../../hooks/useToggle';
import { useResponsive } from '../../../theme/useResponsive';
import {
  FIELD_LABELS,
  FIELD_PASSPORT_ID,
  FIELD_PASSWORD,
} from './sign-in-id-card-schema';
import useStyles from './styles';
import { useSignIn } from './useSignInIdCard';

interface SignInIdCardFormProps {
  className?: any;
  handleSubmit: (data: any) => void;
}

export const SignInIdCardForm: ComponentType<SignInIdCardFormProps> = ({
  className,
  handleSubmit,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const [visibleChars, toggleVisibleChars] = useToggle(false);
  const { isLoading, formik, handleFieldChange, hasError, getErrorMessage } =
    useSignIn({
      handleSubmit,
    });

  const filterErrors = Object.values(formik.errors).filter((x) => !!x);
  const noErrors = isEmpty(filterErrors);

  return (
    <div className={clsx(classes.root, className)}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid container columnSpacing={r({ lg: 2 })}>
          <Grid item xs={12} lg={6}>
            <InputMask
              mask={MASK_PINFL}
              maskPlaceholder={null}
              {...formik.getFieldProps(FIELD_PASSPORT_ID)}
              onChange={(e: any) => handleFieldChange(FIELD_PASSPORT_ID, e)}
              disabled={isLoading}
            >
              <TextField
                label={t(
                  FIELD_LABELS[FIELD_PASSPORT_ID]['labelKey'],
                  FIELD_LABELS[FIELD_PASSPORT_ID]['label']
                )}
                id={FIELD_PASSPORT_ID}
                error={hasError(FIELD_PASSPORT_ID)}
                helperText={getErrorMessage(FIELD_PASSPORT_ID)}
                InputProps={{
                  'aria-label': FIELD_LABELS[FIELD_PASSPORT_ID]['ariaLabel'],
                }}
              />
            </InputMask>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              {...formik.getFieldProps(FIELD_PASSWORD)}
              label={t(
                FIELD_LABELS[FIELD_PASSWORD]['labelKey'],
                FIELD_LABELS[FIELD_PASSWORD]['label']
              )}
              onChange={(e: any) => handleFieldChange(FIELD_PASSWORD, e)}
              id={FIELD_PASSWORD}
              type={visibleChars ? 'text' : 'password'}
              error={hasError(FIELD_PASSWORD)}
              disabled={isLoading}
              helperText={getErrorMessage(FIELD_PASSWORD)}
              InputProps={{
                endAdornment: (
                  <Tooltip
                    title={
                      t(
                        visibleChars
                          ? `action.password.hide`
                          : `action.password.show`
                      ) || ''
                    }
                    arrow
                  >
                    <IconButton
                      onClick={toggleVisibleChars}
                      aria-label={FIELD_LABELS[FIELD_PASSWORD]['arialLabel']}
                      color="primary"
                      size="medium"
                    >
                      <SvgIcon
                        component={visibleChars ? EyeHideIcon : EyeIcon}
                        viewBox="0 0 24 24"
                      />
                    </IconButton>
                  </Tooltip>
                ),
                'aria-label': FIELD_LABELS[FIELD_PASSWORD]['ariaLabel'],
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="div">
              {t(
                'signIn.idCard.helpText',
                'Пароль совпадает с паролем от ЭЦП и был выслан через СМС. Если пароль не был получен, можно заново зарегистрироваться в системе.'
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container columnSpacing={r({ lg: 2 })} mt={3.75}>
          <Grid
            item
            xs={12}
            lg={6}
            alignSelf="center"
            order={r({ xs: 1, lg: 2 })}
          />
          <Grid item xs={12} lg={6} order={r({ xs: 2, lg: 1 })}>
            <LoadingButton
              loading={isLoading}
              disabled={!noErrors}
              variant="contained"
              type="submit"
              className={classes.btn}
              fullWidth={r({ xs: true, lg: false })}
            >
              {t('action.signIn', 'Войти')}
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3}>
          <Typography variant="body2" color="textSecondary" component="div">
            {t(
              'signIn.idCard.helpText2',
              '* Данный способ входа можно использовать если вы не зарегистрированы в системе OneID.'
            )}
          </Typography>
        </Grid>
      </form>
    </div>
  );
};
