import { Box, BoxProps, Drawer } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType, useContext } from 'react';

import { useResponsive } from '../../theme/useResponsive';
import { LayoutContext } from '../LayoutProvider';
import {
  LayoutSidebar,
  VariantSidebar,
} from '../layout-sidebar/layout-sidebar';
import useStyles from './styles';

interface LayoutDefaultProps {
  className?: any;
  bgColor?: 'default' | 'paper';
  children?: any;
}

export const LayoutDefault: ComponentType<LayoutDefaultProps & BoxProps> = ({
  className,
  children,
  ...props
}) => {
  const classes = useStyles();
  const r = useResponsive();
  const { sidebar } = useContext(LayoutContext);
  const open = sidebar.open;

  const variantSidebar = r({
    xs: 'defualt',
    xl: open ? 'default' : 'short',
  }) as VariantSidebar;

  return (
    <Box
      className={clsx(classes.root, className, {
        [classes.short]: !open && r({ xl: true }),
        [classes.opened]: open,
        [classes.closed]: !open,
      })}
      {...props}
    >
      <Drawer
        className={clsx(classes.drawer, {
          [classes.short]: !open && r({ xl: true }),
          [classes.opened]: open,
          [classes.closed]: !open,
        })}
        variant={r({ xs: 'temporary', xl: 'permanent' })}
        open={open}
        elevation={0}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <LayoutSidebar className={classes.sidebar} variant={variantSidebar} />
      </Drawer>
      <Box className={classes.content} component="div" pb={6}>
        {children}
      </Box>
    </Box>
  );
};
