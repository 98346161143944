import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Locale } from '../../common/locales';
import dataSource from '../../data-sources/main.json';
import { useResponsive } from '../theme/useResponsive';
import useStyles from './styles';

interface MainProps {
  className?: any;
}

export const Main: ComponentType<MainProps> = ({ className }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const r = useResponsive();
  const title =
    dataSource[i18n.language as Locale].title || dataSource.uz.title; // #TODO
  const content: any =
    dataSource[i18n.language as Locale].content || dataSource.uz.content; // #TODO

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.body}>
        <Typography
          mb={3}
          variant={r({ xs: 'h2', md: 'h1' })}
          component="h1"
          className={classes.title}
        >
          {title}
        </Typography>

        {content &&
          content.map((item: any, index: number) => {
            return (
              <Typography
                color={item?.color}
                variant={item?.variant}
                component="p"
                key={`content_${index}`}
              >
                {item.content}
              </Typography>
            );
          })}
      </div>
      <Box className={classes.actions}>
        <Button
          variant="outlined"
          className={classes.btn}
          fullWidth={r({ xs: true, lg: false })}
          size={r({ xs: 'small', lg: 'medium' })}
          component={Link}
          to={`/${i18n.language}/about`}
        >
          {t('action.readMore', 'Узнать больше')}
        </Button>
      </Box>
    </div>
  );
};
