import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'legal-entity-employees-list',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(4.625, 0),
    },
    panel: {
      display: 'flex',
      marginBottom: theme.spacing(2.5),
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    addBtn: {
      whiteSpace: 'nowrap',
      margin: theme.spacing(0, 0, 3.25),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 0, 0, 3.25),
        width: 'auto',
      },
    },
    tableContainer: {},
    table: {
      '& th, & td': {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      '& td': {
        color: '#777777',
      },
    },
    tableRow: {
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
      },
    },
  }),
  options
);

export default useStyles;
