import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'help-systems',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(1.75, 2.5, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.75, 2, 0),
    },
    '& hr': {
      margin: theme.spacing(4.5, 0),
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(4.5, -2.5),
      },
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(4.5, -2),
      },
      borderColor: theme.palette.grey[400],
      borderBottomWidth: 0,
    },
  },
  files: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& a': {
      display: 'inline-flex',
      alignItems: 'center',
      textDecoration: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '& svg': {
        marginRight: theme.spacing(1),
        fontSize: 16,
      },
    },
  },
  list: {},
  options,
}));

export default useStyles;
