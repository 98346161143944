import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MainRouteProps } from '../../common/routes';
import { Authorized } from '../authorized';
import { Header } from '../header';
import { Layout } from '../layout';
import { UserContacts, UserProfile, UserSettings } from '../user';

const nav: MainRouteProps[] = [
  {
    pathname: '/personal-data',
    title: 'Профиль пользователя',
    i18n: 'nav.personalData.profile',
    authorized: true,
  },
  {
    pathname: '/personal-data/contacts',
    title: 'Контактные данные',
    i18n: 'nav.personalData.contacts',
    authorized: true,
  },
  {
    pathname: '/personal-data/settings',
    title: 'Настройки',
    i18n: 'nav.personalData.settings',
    authorized: true,
  },
];

interface PagePersonalDataProps {}

export const PagePersonalData: ComponentType<PagePersonalDataProps> =
  Authorized()(() => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    return (
      <Layout variant="default" bgcolor="background.paper">
        <Header
          title={t('user.personalData', 'Персональные данные')}
          variant="sticky"
          bgcolor="background.paper"
          actions={nav}
        />
        <Box component={'main'} role="main">
          <Container>
            <Switch>
              <Route
                path={[`${url}`]}
                exact
                render={(routeProps) => (
                  <>
                    <UserProfile />
                    {/* <Divider
                      sx={{
                        mt: 4.5,
                        mb: 4.5,
                      }}
                    />
                    <Vaccination variant="check" /> */}
                  </>
                )}
              />
              <Route path={`${url}/contacts`} exact component={UserContacts} />
              <Route path={`${url}/settings`} exact component={UserSettings} />
            </Switch>
          </Container>
        </Box>
      </Layout>
    );
  });
