import { Tooltip } from '@material-ui/core';
import { useFormik } from 'formik';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  EmployeesAddSchema,
  FIELD_PASSPORT,
  FIELD_PINFL,
  FIELD_LE_TIN,
} from './legal-entity-employees-add-form-schema';
import { addLAPerson } from '../../../thunks/legal-entity-thunks';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../common/store';

export const useEmployeesAdd = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { code, entity } = useSelector((state: State) => state.legalEntity);
  const handleSubmit = (data: any) => {
    const { document, pinfl } = data;
    dispatch(addLAPerson(pinfl, document, entity.tin));
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_PINFL]: '',
      [FIELD_PASSPORT]: '',
      [FIELD_LE_TIN]: '',
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: EmployeesAddSchema,
    onSubmit: handleSubmit,
  });

  const updateLeTin = (leTin: string) =>
    formik.setFieldValue([FIELD_LE_TIN], leTin);

  const handleFieldChange = (field: string, e: any) => {
    if (e !== null) {
      let value;
      e.hasOwnProperty('target') ? (value = e.target.value) : (value = e);
      if (field === FIELD_PASSPORT) {
        value = value.toUpperCase();
      }
      formik.setFieldValue(field, value);
      formik.setFieldError(field, null);
    }
  };

  const hasError = (field: string) => {
    // if (field === FIELD_PINFL && !!pinflData.code) {
    //   return !!pinflData.code;
    // }
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    // if (field === FIELD_PINFL && !!pinflData.code) {
    //   fullMessage = t(
    //     `error.api.${pinflData?.code}.full`,
    //     `Ошибка с кодом ${pinflData?.code}`
    //   );
    //   shortMessage = t(
    //     `error.api.${pinflData?.code}`,
    //     `Ошибка с кодом ${pinflData?.code}`
    //   );
    // }
    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  return {
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
    updateLeTin,
    code,
  };
};
