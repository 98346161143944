import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { typography } from '../../theme/typography';

const options = {
  name: 'help-for-foreigners',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.secondary,
    ...typography.body2,
    '& h2': {
      marginBottom: theme.spacing(2.25),
    },
    '& p': {
      margin: theme.spacing(0, 0, 2),
    },
    '& a': {
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.palette.primary.main,
      textDecoration: 'none',
      transition: '.25s',
      '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
      },
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.25),
      },
    },
    '& ul': {
      position: 'relative',
      listStyle: 'none',
      padding: theme.spacing(0, 0, 0, 4),
      margin: 0,
    },
    '& li': {
      ...typography.body1,
      marginBottom: theme.spacing(1),
      lineHeight: '24px',
      '&:last-of-type': {
        marginBottom: 0,
      },
      '& .MuiSvgIcon-root': {
        position: 'absolute',
        left: 0,
      },
    },
    '& .MuiDivider-root, & hr': {
      margin: theme.spacing(4, 0),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.75),
    },
  },
  options,
}));

export default useStyles;
