import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Authorized } from '../authorized';
import { Header } from '../header';
import { Layout } from '../layout';
import { Session } from '../session';

interface PageSessionsProps {}

export const PageSessions: ComponentType<PageSessionsProps> = Authorized()(
  () => {
    const { t } = useTranslation();

    return (
      <Layout variant="default" bgcolor="background.paper">
        <Header title={t('sessions.active', 'Активные сессии')} />
        <Box component={'main'} role="main">
          <Container>
            <Session />
          </Container>
        </Box>
      </Layout>
    );
  }
);
