import { Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../common/icons';
import { useToggle } from '../../../hooks/useToggle';
import { PassportSerialNumberDialog } from '../../passport/passport-serrial-number/passport-serial-number-dialog';
import useStyles from './styles';

interface UserPersonalDataProps {
  className?: string;
  column?: 2 | 3 | number;
  variant?: 'view' | 'edit';
  profile?: Record<string, any>;
}

export const UserPersonalData: ComponentType<UserPersonalDataProps> = ({
  className,
  column = 3,
  variant = 'view',
  profile,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSerialNumberVisible, toggleSerialNumberVisible] = useToggle(false);

  const edit = variant === 'edit' || false;
  const notSpecified = t('notSpecified', 'Не указано');

  const _handleData = (property: string) =>
    get(profile, ['passportData', property], notSpecified);

  const _handleDataT = (property: string, propertyAlt: string) =>
    get(profile, ['passportData', property]) ||
    get(profile, ['passportData', propertyAlt, notSpecified]);

  const _gender = () => {
    const gender = _handleData('sex');

    if (gender === '1') {
      return t('gender.male', 'Мужской').toUpperCase();
    } else if (gender === '2') {
      return t('gender.female', 'Женский').toUpperCase();
    }

    return notSpecified;
  };

  return (
    <div className={clsx(classes.root, className, classes[variant])}>
      <Grid container className={clsx(classes.row)}>
        <Grid item xs={12} md={12} container>
          <Grid
            item
            xs={12}
            md={column > 2 ? 4 : 6}
            className={clsx(classes.col)}
          >
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.id', 'ПИНФЛ')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {get(profile, 'pinfl', notSpecified)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={column > 2 ? 4 : 6}
            className={clsx(classes.col)}
          >
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.passportId', 'Серия и Номер паспорта')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {_handleData('document')}
              {edit && (
                <Button
                  onClick={toggleSerialNumberVisible}
                  startIcon={<EditIcon className={clsx(classes.btnIcon)} />}
                  className={clsx(classes.btn, classes.btnLink)}
                  color="primary"
                  size="small"
                  variant="text"
                >
                  {t('action.edit', 'Изменить')}
                </Button>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={column > 2 ? 8 : 12} container>
          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.name', 'Имя')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {_handleDataT('name_latin', 'name_engl')}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.dob', 'Дата рождения')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {_handleData('birth_date')}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.surname', 'Фамилия')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {_handleDataT('surname_latin', 'surname_engl')}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.sex', 'Пол')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {_gender()}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.middleName', 'Отчество')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.patronym_latin*/}
              {/*  ? userData?.patronym_latin*/}
              {/*  : 'Не указан'}*/}
              {/*{profile.passportData.patronym_latin ? profile.passportData.patronym_latin : (userData?.patronym_latin ? userData?.patronym_latin : t('notSpecified'))}*/}
              {_handleData('patronym_latin')}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.nationality', 'Национальность')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.nationality ? userData?.nationality : 'Не указан'}*/}
              {/*{profile.passportData.nationality ? profile.passportData.nationality : (userData?.nationality ? userData?.nationality : t('notSpecified'))}*/}
              {_handleData('nationality')}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.cob', 'Страна рождения')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.birth_country ? userData?.birth_country : 'Не указан'}*/}
              {/*{profile.passportData.birth_country ? profile.passportData.birth_country : (userData?.birth_country ? userData?.birth_country : t('notSpecified'))}*/}
              {_handleData('birth_country')}
            </Typography>
          </Grid>

          <Grid item xs={6} md={6} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.pob', 'Место рождения')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.birth_place ? userData?.birth_place : 'Не указан'}*/}
              {/*{profile.passportData.birth_place ? profile.passportData.birth_place : (userData?.birth_place ? userData?.birth_place : t('notSpecified'))}*/}
              {_handleData('birth_place')}
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} className={clsx(classes.col)}>
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.issuedBy', 'Кем выдан')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.doc_give_place*/}
              {/*  ? userData?.doc_give_place*/}
              {/*  : 'Не указан'}*/}
              {/*{profile.passportData.doc_give_place ? profile.passportData.doc_give_place : (userData?.doc_give_place ? userData?.doc_give_place : t('notSpecified'))}*/}
              {_handleData('doc_give_place')}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={column > 2 ? 4 : 12}
          container
          alignSelf="flex-start"
        >
          <Grid
            item
            xs={12}
            md={column > 2 ? 12 : 6}
            className={clsx(classes.col)}
          >
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.citizenship', 'Гражданство')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.citizenship ? userData?.citizenship : 'Не указан'}*/}
              {/*{profile.passportData.citizenship ? profile.passportData.citizenship : (userData?.citizenship ? userData?.citizenship : t('notSpecified'))}*/}
              {_handleData('citizenship')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={column > 2 ? 12 : 6}
            className={clsx(classes.col)}
          >
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.dateIssue', 'Дата выдачи')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.date_begin_document*/}
              {/*  ? userData?.date_begin_document*/}
              {/*  : 'Не указан'}*/}
              {/*{profile.passportData.date_begin_document ? profile.passportData.date_begin_document : (userData?.date_begin_document ? userData?.date_begin_document : t('notSpecified'))}*/}
              {_handleData('date_begin_document')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={6}
            md={column > 2 ? 12 : 6}
            className={clsx(classes.col)}
          >
            <Typography
              className={clsx(classes.title)}
              variant="body2"
              color="textSecondary"
              component="div"
            >
              {t('user.data.dachaExpiration', 'Дача истечения срока')}
            </Typography>
            <Typography
              className={clsx(classes.text)}
              variant="h6"
              component="div"
            >
              {/*{userData?.date_end_document*/}
              {/*  ? userData?.date_end_document*/}
              {/*  : 'Не указан'}*/}
              {/*{profile.passportData.date_end_document ? profile.passportData.date_end_document : (userData?.date_end_document ? userData?.date_end_document : t('notSpecified'))}*/}
              {_handleData('date_end_document')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <PassportSerialNumberDialog
        open={isSerialNumberVisible}
        onClose={toggleSerialNumberVisible}
      />
    </div>
  );
};
