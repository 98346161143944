import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { State } from '../common/store';
import { oAuthGenerated, ssoCheckRedirect } from '../thunks/sso-thunks';

export const useSSO = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    scope: ssoScope,
    uuid: ssoUuid,
    role: ssoRole,
  } = useSelector((state: State) => state.sso);
  const query = new URLSearchParams(
    history.location.search.replace('&amp;', '&')
  );
  const token_id = query.get('token_id');
  const client_id = query.get('client_id');

  useEffect(() => {
    if (token_id) {
      dispatch(ssoCheckRedirect({ uuid: token_id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token_id]);

  useEffect(() => {
    console.log('oAuthGenerated');
    console.log(token_id);
    console.log(ssoRole);
    console.log(ssoUuid);
    console.log(client_id);
    if (token_id && client_id && ssoRole !== null) {
      dispatch(oAuthGenerated({ uuid: token_id, scope: client_id || '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token_id, ssoScope, ssoUuid, ssoRole]);

  return { token_id, ssoScope, ssoUuid, ssoRole };
};
