import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'help-contacts',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.75),
    },
  },
  map: {
    margin: theme.spacing(0, -2, -6),
    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
    '& iframe': {
      display: 'block',
    },
  },
  options,
}));

export default useStyles;
