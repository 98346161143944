import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'user',
};
const useStyles = makeStyles(
  (theme) => ({
    root: {},
    header: {},
    container: {},
    content: {},
  }),
  options
);

export default useStyles;
