import * as yup from 'yup';

export const FIELD_LOGIN = 'login';
export const FIELD_PASSWORD = 'password';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_LOGIN]: {
    label: 'Логин',
    labelKey: 'signIn.login',
    ariaLabel: 'Your login',
  },
  [FIELD_PASSWORD]: {
    label: 'Пароль',
    labelKey: 'signIn.password',
    ariaLabel: 'Your password',
  },
};

export const SignInValidationSchema = yup.object().shape({
  [FIELD_LOGIN]: yup
    .string()
    .min(3, 'error.minLength3')
    .matches(/^[a-zA-Z0-9_.@#-]+$/, 'error.login.rules')
    .required('error.required')
    .trim(),
  [FIELD_PASSWORD]: yup
    .string()
    .min(3, 'error.minLength3')
    .required('error.required')
    .trim(),
});
