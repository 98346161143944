import { FormikValues, useFormik } from 'formik';
import { forEach, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changePassword,
  clearPasswordStates,
} from '../../../thunks/profile-thunks';
import { State } from '../../../common/store';
import {
  FIELD_CONFIRM_PASSWORD,
  FIELD_NEW_PASSWORD,
  FIELD_OLD_PASSWORD,
  PasswordFormValidationForm,
} from './password-form-schema';
import { useEffect } from 'react';
import { Tooltip } from '@material-ui/core';

export const usePasswordForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { passwordForm } = useSelector((state: State) => state.profile);

  const handleSubmit = (values: FormikValues) => {
    let hasError = [];

    forEach(values, (value, field) => {
      if (value.includes('_')) {
        formik.setFieldError(field, 'error.required');
        hasError.push(field);
      }
    });

    if (!hasError.length) {
      dispatch(changePassword(values));
    }
  };

  const handleFieldChange = (field: string, e: any) => {
    formik.setFieldValue(field, e.target.value);
    formik.setFieldError(field, null);
    dispatch(clearPasswordStates());
  };

  const hasError = (field: string) => {
    return (
      get(formik, ['touched', field], false) &&
      Boolean(get(formik, ['errors', field], false))
    );
  };

  const getErrorMessage = (field: string, short: boolean = false) => {
    if (!hasError(field)) return ' ';
    const key = get(formik, ['errors', field], 'error');
    let fullMessage = t(key, 'Ошибка');
    let shortMessage = fullMessage;

    if (short) {
      return shortMessage;
    }

    return (
      <Tooltip title={fullMessage} arrow>
        <span>{shortMessage}</span>
      </Tooltip>
    );
  };

  const formik: any = useFormik({
    initialValues: {
      [FIELD_OLD_PASSWORD]: '',
      [FIELD_NEW_PASSWORD]: '',
      [FIELD_CONFIRM_PASSWORD]: '',
    },
    validationSchema: PasswordFormValidationForm,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    return () => {
      dispatch(clearPasswordStates());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...passwordForm,
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
  };
};
