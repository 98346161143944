import i18n from '../common/i18next';
import { api } from '../common/utils/fetch-utils';
import { legalEntityActions } from '../slices/legal-entity-slice';
import { ssoActions } from '../slices/sso-slice';
import { openSuccessSnackbar } from '../slices/toaster-slice';
import { getUserInfo } from './auth-thunks';

export const getLegalEntities = () => async (dispatch: any) => {
  dispatch(legalEntityActions.getLegalEntitiesRequest());
  try {
    const { data, status } = await api.get(`legal/get`);
    if (status.code === 0) {
      dispatch(
        legalEntityActions.getLegalEntitiesSuccess({
          items: data.juridicalUsers,
          ...status,
        })
      );
    } else {
      dispatch(legalEntityActions.getLegalEntitiesFailure(status));
    }
  } catch (error) {
    console.error(error);
    dispatch(
      legalEntityActions.getLegalEntitiesFailure({ code: -1, message: 'error' })
    );
  }
};

export const addLegalEntity = (tin: string) => async (dispatch: any) => {
  dispatch(legalEntityActions.addLegalEntityRequest());
  try {
    const { status, data } = await api.post(`legal/add`, { tin });
    if (status.code === 0) {
      dispatch(
        legalEntityActions.addLegalEntitySuccess({
          entity: data.juridicalUser,
          ...status,
        })
      );
    } else {
      dispatch(legalEntityActions.addLegalEntityFailure(status));
    }
  } catch (error: any) {
    console.error(error);
    dispatch(
      legalEntityActions.addLegalEntityFailure({ code: -1, message: 'error' })
    );

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};

export const deleteLegalEntity = (tin: string) => async (dispatch: any) => {
  dispatch(legalEntityActions.deleteLegalEntityRequest());
  try {
    const { status } = await api.post(`legal/delete`, { tin });
    if (status.code === 0) {
      dispatch(legalEntityActions.deleteLegalEntitySuccess({ tin, ...status }));
    } else {
      dispatch(legalEntityActions.deleteLegalEntityFailure(status));
    }
  } catch (error: any) {
    console.error(error);
    dispatch(
      legalEntityActions.deleteLegalEntityFailure({
        code: -1,
        message: 'error',
      })
    );

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};

export const addByESP = (data: string) => async (dispatch: any) => {
  dispatch(legalEntityActions.addLegalEntityByESPRequest());
  try {
    const { data: responseData, status } = await api.post('legal/addByESP', {
      data,
    });
    if (status.code === 0) {
      dispatch(
        legalEntityActions.addLegalEntityByESPSuccess({
          entity: responseData.juridicalUser,
          ...status,
        })
      );
    }
  } catch (error: any) {
    dispatch(
      legalEntityActions.addLegalEntityByESPFailure({
        code: -1,
        message: 'error',
      })
    );
    console.error(error);

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};

export const getLAPersons = (tin: number | string) => async (dispatch: any) => {
  dispatch(legalEntityActions.getLEPersonsRequest());
  try {
    const { data, status } = await api.post('legal/getPersons', {
      leTin: tin,
    });
    if (status.code === 0) {
      dispatch(legalEntityActions.getLEPersonsSuccess(data));
    }
  } catch (error) {
    dispatch(
      legalEntityActions.getLEPersonsFailure({
        code: -1,
        message: 'error',
      })
    );
  }
};

export const addLAPerson =
  (pin: string, document: string, leTin: string) => async (dispatch: any) => {
    dispatch(legalEntityActions.addLAPersonRequest());
    try {
      const { status } = await api.post('legal/addPerson', {
        pin,
        document,
        leTin,
      });
      dispatch(legalEntityActions.addLAPersonSuccess(status.code));
    } catch (error: any) {
      dispatch(
        legalEntityActions.addLAPersonFailure({
          code: -1,
          message: 'error',
        })
      );

      if (error.code === 401) {
        openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
        dispatch(getUserInfo());
      }
    }
  };

export const deleteLAPerson =
  (pin: string, document: string, leTin: string) => async (dispatch: any) => {
    dispatch(legalEntityActions.deleteLAPersonRequest());
    try {
      const { data, status } = await api.post('legal/deletePerson', {
        pin,
        document,
        leTin,
      });
      if (status.code === 0) {
        dispatch(legalEntityActions.deleteLAPersonSuccess(data));
      }
    } catch (error: any) {
      dispatch(
        legalEntityActions.deleteLAPersonFailure({
          code: -1,
          message: 'error',
        })
      );

      if (error.code === 401) {
        openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
        dispatch(getUserInfo());
      }
    }
  };

export const updateLEData = (leTin: string) => async (dispatch: any) => {
  dispatch(legalEntityActions.updateLADataRequest());
  try {
    const { data, status } = await api.post('legal/updateLEData', { leTin });
    if (status.code === 0) {
      dispatch(legalEntityActions.updateLADataSuccess(data));
    }
  } catch (error: any) {
    dispatch(
      legalEntityActions.updateLADataFailure({
        code: -1,
        message: 'error',
      })
    );

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};

// roles
export const getLERoles = () => async (dispatch: any) => {
  dispatch(legalEntityActions.getLERolesRequest());
  try {
    const { data, status } = await api.get(`legal/getLE`);

    if (status.code === 0) {
      dispatch(
        legalEntityActions.getLERolesSuccess({
          roles: data.legal || [],
          ...status,
        })
      );
    } else {
      if (status.code === 308) {
        dispatch(
          legalEntityActions.getLERolesSuccess({
            roles: [],
            ...status,
          })
        );
      } else {
        dispatch(legalEntityActions.getLERolesFailure(status));
      }
    }
  } catch (error: any) {
    console.error(error);
    dispatch(
      legalEntityActions.getLERolesFailure({ code: -1, message: 'error' })
    );

    if (error.code === 401) {
      openSuccessSnackbar(i18n.t('error.api.401.full', 'Ошибка с кодом 401'));
      dispatch(getUserInfo());
    }
  }
};

export const updateLERoles = (leTin: string) => async (dispatch: any) => {
  dispatch(legalEntityActions.updateLERolesRequest());
  try {
    const { data } = await api.post('legal/updateLE', {
      le_tin: leTin,
    });

    dispatch(legalEntityActions.updateLERolesSuccess(data));
    dispatch(ssoActions.setRole(leTin));
  } catch (error) {
    dispatch(
      legalEntityActions.updateLERolesFailure({
        code: -1,
        message: 'error',
      })
    );
  }
};
