import { BoxProps } from '@material-ui/system';
import { ComponentType } from 'react';
import { LayoutDefault } from './layout-default/layout-default';
import { LayoutSimple } from './layout-simple/layout-simple';
import { LayoutProvider } from './LayoutProvider';
import useStyles from './styles';

interface LayoutProps {
  className?: any;
  variant?: 'default' | 'simple';
  bgColor?: 'default' | 'paper';
  children?: any;
}

export const Layout: ComponentType<LayoutProps & BoxProps> = ({
  className,
  variant = 'default',
  bgColor = 'paper',
  ...props
}) => {
  const classes = useStyles();

  return (
    <LayoutProvider>
      {variant === 'default' && (
        <LayoutDefault className={classes.default} {...props} />
      )}
      {variant === 'simple' && (
        <LayoutSimple className={classes.simple} {...props} />
      )}
    </LayoutProvider>
  );
};
