import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { typography } from '../../theme/typography';

const options = {
  name: 'ueer-header',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      textDecoration: 'none',
      alignItems: 'center',
      color: theme.palette.primary.main,
      maxWidth: '100%',
      '&:hover, &:focus:hover, &:active': {
        color: theme.palette.primary.dark,
      },
    },
    body: {
      paddingRight: theme.spacing(1.25),
      flex: '0 0 calc(100% - 38px)',
      width: 'calc(100% - 38px)',
    },
    media: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: '50%',
      color: theme.palette.primary.contrastText,
      textTransform: 'uppercase',
      textAlign: 'center',
      ...typography.h5,
      flex: '0 0 38px',
      height: 38,
      width: 38,
      background: theme.palette.primary.light,
    },
    img: {
      display: 'block',
      height: '100%',
      width: '100%',
      borderRadius: '50%',
      border: `1px solid ${theme.palette.primary.light}`,
      objectFit: 'cover',
      objectPosition: '50% 50%',
    },
    icon: {
      height: 24,
      width: 24,
    },
    title: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
    text: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '100%',
    },
  }),
  options
);

export default useStyles;
