import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'logo',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      height: 40,
      minWidth: 51,
      '&, & $img': {
        transition: theme.transitions.create('max-width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    small: {
      width: 51,
      '& $img': {
        transform: 'translate(-78px, 0)',
      },
      '&, & $img': {
        transition: theme.transitions.create('max-width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
    large: {
      width: 129,
    },
    xLarge: {
      height: 50,
      width: 162,
    },
    beta: {
      width: 169,
    },
    img: {
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'block',
      height: '100%',
      width: 'auto',
      color: 'inherit',
      fill: 'inherit',
      transform: 'translate(0, 0)',
      transition: theme.transitions.create('max-width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }),
  options
);

export default useStyles;
