import { Box } from '@material-ui/core';
import { BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { ComponentType } from 'react';
import useStyles from './styles';

interface UserAgreementPageProps {
  className?: any;
  data: any;
}

export const UserAgreementPage: ComponentType<
  UserAgreementPageProps & BoxProps
> = ({ className, data, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      <div
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </Box>
  );
};
