import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'connected-resources-item',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',

      padding: theme.spacing(1, 0),
      userSelect: 'none',
      minHeight: theme.spacing(9),
      '&:hover, &:focus:hover': {
        color: theme.palette.primary.dark,
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1, 5, 1, 0),
        minHeight: theme.spacing(10),
      },
    },
    body: {},
    media: {
      flex: '0 0 100px',
      width: 100,
      height: 40,
      order: 2,
      '& ~ $body': {
        order: 1,
        paddingRight: theme.spacing(3),
        flex: `0 0 calc(100% - 100px)`,
        width: 'calc(100% - 100px)',
      },
    },
    img: {
      display: 'block',
      height: '100%',
      width: '100%',
      objectFit: 'contain',
      objectPosition: '50% 50%',
    },
    icon: {
      verticalAlign: 'top',
      height: 20,
      width: 20,
      opacity: '0.5',
    },
    title: {},
    divider: {},
  }),
  options
);

export default useStyles;
