import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from '../../../common/locales';
import dataSource from '../../../data-sources/help-for-developers.json';
import { IconList } from '../../list/Icon-list';
import useStyles from './styles';

interface HelpForDevelopersProps {
  className?: string;
}

export const HelpForDevelopers: ComponentType<HelpForDevelopersProps> = ({
  className,
}) => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  const content = dataSource[i18n.language as Locale]['content'];

  const body = (content || []).map((item: Record<string, any>, idx: number) => {
    const { view, list } = item;
    switch (view) {
      case 'list':
        return <IconList data={list} key={idx} />;
      case 'divider':
        return (
          <Divider
            key={idx}
            sx={{
              my: 4,
            }}
          />
        );
      default:
        return <Typography {...item} key={idx} />;
    }
  });

  return <div className={clsx(classes.root, className)}>{body}</div>;
};
