import { alpha, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'language',
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
    },
    item: {
      display: 'block',
      position: 'relative',
      cursor: 'pointer',
      marginRight: theme.spacing(4),
      '&:hover, &:focus:hover': {
        textDecoration: 'underline',
      },
      '&:after': {
        position: 'absolute',
        top: '50%',
        right: theme.spacing(-2.375),
        transform: 'translate(0, -50%)',
        content: "''",
        pointerEvents: 'none',
        display: 'block',
        borderRadius: '50%',
        height: theme.spacing(0.75),
        width: theme.spacing(0.75),
        background: alpha(theme.palette.primary.contrastText, 0.2),
      },
      '&:last-child': {
        '&:after': {
          content: 'none',
        },
      },
    },
    active: {
      pointerEvents: 'none',
      color: alpha(theme.palette.primary.contrastText, 0.4),
      textDecoration: 'none !important',
    },
  }),
  options
);

export default useStyles;
