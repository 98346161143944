import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useESign } from '../useESign';
import useStyles from './styles';

interface ESignTokenProps {
  className?: string;
}

export const ESignToken: ComponentType<ESignTokenProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sign } = useESign();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.text}>
        <Button variant="contained" onClick={() => sign('idcard')}>
          {t('eSign.action.sign', 'Войти с ID-картой / USB-ключем')}
        </Button>
      </div>
    </div>
  );
};
