import clsx from 'clsx';
import i18n from 'i18next';
import { ComponentType, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SignInLoginForm } from '..';
import { useSSO } from '../../../../hooks/useSSO';
import { signIn } from '../../../../thunks/auth-thunks';
import useAuth from '../../useAuth';
import useStyles from './styles';

interface SignInLoginProps {
  className?: any;
}

export const SignInLogin: ComponentType<SignInLoginProps> = ({ className }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { authorized } = useAuth();
  const { token_id } = useSSO();

  useEffect(() => {
    if (authorized && !token_id) {
      history.push(`/${i18n.language}/personal-data`);
    }
  }, [authorized, history, token_id]);

  const handleSubmit = async (credentials: {
    login: string;
    password: string;
  }) => {
    const { login, password } = credentials;
    dispatch(signIn({ login, password }));
  };

  return (
    <div className={clsx(classes.root, className)}>
      <SignInLoginForm handleSubmit={handleSubmit} className={classes.form} />
    </div>
  );
};
