import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'password-recovery',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    captchaHolder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 50,
      borderRadius: 4,
      background: '#F3F3F3',
      '& canvas': {
        display: 'block',
        width: 96,
      },
      [theme.breakpoints.up('sm')]: {
        width: 152,
        '& canvas': {
          width: 'auto',
        },
      },
    },
    control: {
      maxWidth: 300,
    },
    actions: {},
  }),
  options
);

export default useStyles;
