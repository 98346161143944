import { Button, TextField } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
interface PhoneAddProps {
  className?: any;
}

export const PhoneAdd: ComponentType<PhoneAddProps> = ({ className }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TextField
        className={classes.root}
        size="small"
        label={t('phone', 'Телефон')}
        id="phone"
        defaultValue={'+998901681169'}
        error={false}
        InputProps={{ 'aria-label': t('phone.areia', 'Ваш номер телефона') }}
      />
      <Button variant="contained" size="small" disabled>
        {t('phone.sms.get', 'Получить SMS с кодом')}
      </Button>
    </>
  );
};
