import { DataTableColumnsProps } from '../../data-table';
import { Typography } from '@material-ui/core';

const defaultTypography = (text: string) => (
  <Typography component="div" variant="body1" color="#777777">
    {text}
  </Typography>
);

export const HISTORY_TRANSFERRED_COLUMNS_RESOURCE: DataTableColumnsProps[] = [
  {
    label: 'Ресурс',
    labelKey: 'resource',
    dataKey: 'resource',
    renderItem: ({ resource }) => defaultTypography(resource),
  },
  {
    label: 'Дата и время',
    labelKey: 'history.dateTime',
    dataKey: 'loginTime',
    renderItem: ({ loginTime }: any) =>
      defaultTypography(
        `${new Date(loginTime).toLocaleDateString()} ${new Date(
          loginTime
        ).toLocaleTimeString()}`
      ),
  },
];
