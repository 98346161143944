import { addDays, isAfter, isBefore } from 'date-fns';
import { replace } from 'lodash';
import { api } from './utils/fetch-utils';
import { dateParse } from './utils/format-utils';

const esign = (window as any).CAPIWS;

const PFX_PLUGIN = 'pfx';
const PKCS7_PLUGIN = 'pkcs7';
const IDCARD_PLUGIN = 'idcard';

const PFX_METHODS = {
  LIST_DISKS: 'list_disks',
  LIST_ALL_CERTIFICATES: 'list_all_certificates',
  LOAD_KEY: 'load_key',
  VERIFY_PASSWORD: 'verify_password',
};
const IDCARD_METHODS = {
  LIST_READERS: 'list_readers',
  PERSONALIZE: 'personalize',
};

const PKCS7_METHODS = {
  CREATE_PKCS7: 'create_pkcs7',
  ATTACH_TIMESTAMP_TOKEN_PKCS7: 'attach_timestamp_token_pkcs7',
};

const API_KEYS = [
  'id.egov.uz',
  '7E4A6963E1AC4F902A0E16064CD965D8E63B74BB4479582D1E94ABDFBC6609321ACE882AB7AA0109114E967BB5FA8D5EBF520FDBEB286422038E81364CA36CDA',
  'localhost',
  '96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B',
  '127.0.0.1',
  'A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F',
];

esign.apikey(
  API_KEYS,
  function (event: any, data: any) {
    if (data.success) {
      //console.log(data);
    } else {
      // fail(null, data.reason)
    }
  },
  function (e: any) {
    // fail(e, null)
  }
);

interface ListDiscsResult {
  disks: string[];
  success: boolean;
}

export const listDiscs = () => {
  return new Promise<ListDiscsResult>((resolve, reject) => {
    esign.callFunction(
      {
        plugin: PFX_PLUGIN,
        name: PFX_METHODS.LIST_DISKS,
      },
      (event: any, data: any) => resolve(data),
      (error: any) => reject(error)
    );
  });
};

export const listCertificates = () => {
  return new Promise<{
    certificates: { alias: string; disk: string; name: string; path: string }[];
    success: boolean;
  }>((resolve, reject) => {
    esign.callFunction(
      {
        plugin: PFX_PLUGIN,
        name: PFX_METHODS.LIST_ALL_CERTIFICATES,
      },
      (event: any, data: any) => {
        resolve(data);
      },
      (error: any) => {
        console.log(error);
        reject(error);
      }
    );
  });
};
export const listTokenReaders = () => {
  return new Promise<{
    readers: string[];
    success: boolean;
  }>((resolve, reject) => {
    esign.callFunction(
      {
        plugin: IDCARD_PLUGIN,
        name: IDCARD_METHODS.LIST_READERS,
      },
      (event: any, data: any) => {
        resolve(data);
      },
      (error: any) => {
        reject(error);
      }
    );
  });
};

export const loadKey = (
  disk: string,
  path: string,
  name: string,
  alias: string
) => {
  return new Promise<{ keyId: string; success: boolean; type: string }>(
    (resolve, reject) => {
      esign.callFunction(
        {
          plugin: PFX_PLUGIN,
          name: PFX_METHODS.LOAD_KEY,
          arguments: [disk, path, name, alias],
        },
        (event: any, data: any) => resolve(data),
        (error: any) => reject(error)
      );
    }
  );
};

export const verifyPassword = (id: string) => {
  return new Promise<{ success: boolean }>((resolve, reject) => {
    esign.callFunction(
      {
        plugin: PFX_PLUGIN,
        name: PFX_METHODS.VERIFY_PASSWORD,
        arguments: [id],
      },
      (event: any, data: any) => resolve(data),
      (error: any) => reject(error)
    );
  });
};

export const create_pkcs7 = (
  data_64: any,
  cert_id: string,
  detached: 'yes' | 'no' = 'no'
) => {
  return new Promise<{
    pkcs7_64: string;
    signature_hex: string;
    signer_serial_number: string;
    success: boolean;
  }>((resolve, reject) => {
    esign.callFunction(
      {
        plugin: PKCS7_PLUGIN,
        name: PKCS7_METHODS.CREATE_PKCS7,
        arguments: [btoa(data_64), cert_id, detached],
      },
      (event: any, data: any) => resolve(data),
      (error: any) => reject(error)
    );
  });
};

export const generateSignTimeStamp = (signatureHex: string) => {
  return new Promise<{ success: boolean; timeStampTokenB64: string }>(
    (resolve, reject) => {
      try {
        const result = api.get('apiProject/eimzo/gettimestamptoken', {
          signatureHex,
        });
        resolve(result);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    }
  ).finally(() => {});
};

export const attachTimestampTokenPkcs7 = (
  pkcs7_64: any,
  signer_serial_number: string,
  timestamp_token_64: string
) => {
  return new Promise<{
    pkcs7_64: string;
    signer_serial_number: string;
    success: boolean;
  }>((resolve, reject) => {
    esign.callFunction(
      {
        plugin: PKCS7_PLUGIN,
        name: PKCS7_METHODS.ATTACH_TIMESTAMP_TOKEN_PKCS7,
        arguments: [pkcs7_64, signer_serial_number, timestamp_token_64],
      },
      (event: any, data: any) => resolve(data),
      (error: any) => reject(error)
    );
  }).finally(() => {});
};

export const getCertParams = (cert: string) => {
  const params = cert.split(',');
  return params.reduce((result: any, param) => {
    const [key, value] = param.split('=');
    result[key] = value;
    return result;
  }, {});
};

export const getCertParams2 = (cert: string) =>
  cert.split(',').reduce((result: any, param) => {
    const [key, value] = param.split('=');
    result[key] = value;
    return result;
  }, {});

export const getCertParams3 = (cert: string) =>
  cert
    .split(',')
    .map((el: any) => el.split('='))
    .reduce((acc, cur) => {
      acc[cur[0]] = cur[1];
      return acc;
    }, {});

export const getCertParams4 = (cert: string) =>
  Object.fromEntries(cert.split(',').map((el) => el.split('=')));

export const makeItFast = (cert: any) => {
  let myData: any = [];
  Object.keys(cert).forEach(function eachKey(key) {
    myData.push({
      key,
      text: cert[key],
      size: cert[key].length,
    });
  });
  return myData;
};

export const reformatCert = (
  certData: any
  // ignoreTitleFields: string[] = ['fullName']
) => {
  //TODO REFACTOR
  const reformat: Record<string, any> = {
    fullName: {
      value: (certData.cn && certData.cn.toUpperCase()) || '',
      error: !certData.cn,
    },
    passportID: {
      value: certData['1.2.860.3.16.1.2'] || '',
      error: false, // !certData['1.2.860.3.16.1.2'],
    },
    taxID: {
      value: certData.businesscategory
        ? certData['1.2.860.3.16.1.1']
        : certData.uid,
      error: false, //!certData.uid,
    },
    typeOwnership: {
      value: certData.businesscategory ? 'le' : 'in',
      error: false,
    },
    organization: {
      value:
        (certData.businesscategory &&
          ((certData.o && certData.o.toUpperCase()) || '')) ||
        null,
      error: certData.businesscategory && !certData.o,
    },
    serialNumber: {
      value: certData.serialnumber || '',
      error: !certData.serialnumber,
    },
    certificatePeriod: {
      value: `${
        (certData.validfrom &&
          dateParse(replace(certData.validfrom, /\./g, '-'))) ||
        ''
      } - ${
        (certData.validto &&
          dateParse(replace(certData.validto, /\./g, '-'))) ||
        ''
      }`,
      error:
        !Boolean(
          isBefore(
            new Date(replace(certData.validfrom, /\./g, '-')),
            addDays(new Date(), +1)
          )
        ) ||
        !Boolean(
          isAfter(new Date(replace(certData.validto, /\./g, '-')), new Date())
        ),
    },
  };

  let dataValues: any = [];
  let dataError: any = [];

  Object.keys(reformat).forEach(function eachKey(key) {
    const item: Record<string, any> = {
      key,
      title: key,
      text: reformat[key].value,
      size: _fieldSizes().get(key),
      error: reformat[key].error && 'noAvailable',
      // ...(!ignoreTitleFields?.includes(key) && { title: key }),
    };

    if (reformat[key].value !== null) {
      dataValues.push(item);
    }

    if (reformat[key].error === true) {
      dataError.push(item);
    }
  });
  return {
    variant: 'select',
    values: dataValues,
    formatedCert: reformat,
    error: dataError,
    status: dataError.length && 'error',
  };
};

const _fieldSizes = (): Map<string, number> => {
  return new Map()
    .set('fullName', 12)
    .set('passportID', 4)
    .set('taxID', 3)
    .set('typeOwnership', 5)
    .set('organization', 12)
    .set('serialNumber', 4)
    .set('certificatePeriod', 8);
};

export const formattedListCertificates: any = async () => {
  const certParams = await _formatCertParam();
  return await _reformatCerts(certParams);
};

const _formatCertParam = async () => {
  return listCertificates().then(({ certificates, success }) => {
    if (success) {
      return certificates.map((certificate) => {
        return {
          certificate: certificate,
          parsedCert: getCertParams(certificate.alias),
        };
      });
    }
  });
};

const _reformatCerts = async (
  data: any
): Promise<
  {
    cert: {
      certificate: Record<string, string>;
      parsedCert: Record<string, string>;
    };
    values: Record<string, string>;
    variant: string;
  }[]
> => {
  return data.map((cert: any) => {
    return { ...reformatCert(cert.parsedCert), cert };
  });
};
