import {
  Alert,
  Snackbar as MuiSnackbar,
  SnackbarProps,
} from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from './index';

export const Snackbar: ComponentType<SnackbarProps> = ({ className }) => {
  const { open, content, options, snackbarClose, severity } = useSnackbar();
  const { t } = useTranslation();
  const message = content || t('error');

  return (
    <MuiSnackbar
      anchorOrigin={options.position && { ...options.position }}
      open={open}
      autoHideDuration={options.duration}
      onClose={snackbarClose}
    >
      <Alert onClose={snackbarClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
