import { AlertColor } from '@material-ui/core';
import { createSlice } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { store } from '../common/store';
import { snackbarOpenDefaultOptions } from '../components/snackbar';

interface initialStateProps {
  open: boolean;
  content: ReactElement | string | null;
  severity: AlertColor;
}
const defaultSeverity: AlertColor = 'error';

const initialState: initialStateProps = {
  open: false,
  content: null,
  severity: defaultSeverity,
};

export const { actions: toasterActions, reducer: toasterReducer } = createSlice(
  {
    name: 'toaster',
    initialState,
    reducers: {
      setOpen: (state, { payload }) => {
        state.open = true;
        state.severity = payload;
      },
      setClose: (state) => {
        state.open = false;
        state.severity = defaultSeverity;
      },
      setContent: (state, { payload }) => {
        state.content = payload;
      },
      snackOpen: (state, { payload }) => {
        state.open = true;
        state.severity = payload.severity;
        state.content = payload.content;
      },
    },
  }
);

export const openSnackbar = (
  content: ReactElement | string,
  options = snackbarOpenDefaultOptions
) => {
  if (store?.dispatch) {
    store.dispatch(toasterActions.setOpen(options.severity));
    store.dispatch(toasterActions.setContent(content));
  }
};

export const openSuccessSnackbar = (content: ReactElement | string) => {
  openSnackbar(content, { severity: 'success' });
};

export const openErrorSnackbar = (content: ReactElement | string) => {
  openSnackbar(content, { severity: 'error' });
};
