import {
  Alert,
  Collapse,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';
import clsx from 'clsx';
import { isInteger } from 'lodash';
import { ComponentType, useEffect } from 'react';
import useCountDown from 'react-countdown-hook';
import { useTranslation } from 'react-i18next';
import { displayTime } from '../../../common/utils/format-utils';
import useStyles from './styles';

interface PhoneConfirmNewProps {
  className?: string;
  delay?: number;
  isLoading?: boolean;
  error?: string | null;
  onRetry: () => void;
  onChange: (code: string) => void;
}

export const PhoneConfirmNew: ComponentType<PhoneConfirmNewProps> = ({
  className,
  delay = 180,
  isLoading,
  error,
  onRetry,
  onChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // const { snackbarOpen } = useSnackbar();

  const [timeLeft, { start: startTimer }] = useCountDown(
    Math.ceil(delay * 1000),
    1000
  );
  const hasError = isInteger(error) && Number(error) !== 0;

  const countDown = displayTime(timeLeft / 1000);

  const handleChangeCode = (e: any) => {
    const code = e.target.value;
    onChange(code);
  };

  const handleRetry = () => {
    startTimer();
    if (onRetry) onRetry();
  };

  useEffect(() => {
    startTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (error) {
  //     snackbarOpen(error, {
  //       severity: 'error',
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [error]);

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container className={clsx(classes.row)}>
        <Grid xs={'auto'} item className={clsx(classes.col, classes.colCode)}>
          <TextField
            id="code"
            className={classes.code}
            label={t('phone.sms.code', 'Код из SMS')}
            size="small"
            required
            onChange={handleChangeCode}
            helperText={' '}
            error={hasError}
            fullWidth={false}
            InputProps={{
              'aria-label': t('phone.sms.code', 'Код из SMS'),
            }}
          />
        </Grid>
        <Grid
          className={clsx(classes.col, classes.colText)}
          xs
          item
          alignSelf="flex-start"
        >
          <Typography
            className={classes.text}
            variant="caption"
            color="textSecondary"
            component="div"
          >
            {/* Новое SMS можно будетотправить через 00:59 */}
            {t('phone.sms.countDown', { countDown })}
          </Typography>
        </Grid>
        <Grid
          xs={'auto'}
          item
          className={clsx(classes.col, classes.colActions)}
        >
          <LoadingButton
            disabled={!Boolean(timeLeft === 0)}
            onClick={handleRetry}
            loading={isLoading}
            className={classes.btn}
            variant="outlined"
            size="small"
          >
            {t('phone.sms.again', 'Отправить еще')}
          </LoadingButton>
        </Grid>
      </Grid>

      <Collapse in={Boolean(error)}>
        <Alert
          severity="error"
          sx={{
            marginBottom: 2,
          }}
        >
          {error}
        </Alert>
      </Collapse>
    </div>
  );
};
