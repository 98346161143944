import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'masonry-list',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    [theme.breakpoints.up('md')]: {
      columnCount: 2,
      columnGap: theme.spacing(6),
    },
  },
  item: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
    breakInside: 'avoid',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8.75),
    },
  },
  figure: {
    margin: theme.spacing(0, 0, 2.5),
  },
  title: {},
  text: {},
  options,
}));

export default useStyles;
