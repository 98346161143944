import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { useResponsive } from '../../theme/useResponsive';
import { LegalEntityDatagrid } from '../legal-entity-datagrid/legal-entity-datagrid';
import useStyles from './styles';

export const LegalEntityCabinet: ComponentType = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();

  const data = useSelector((state: State) => state.legalEntity.entity);

  // TODO: translates

  return (
    <div className={clsx(classes.root)}>
      <LegalEntityDatagrid data={data} general head founders contacts />

      <Box hidden>
        <Button
          variant="outlined"
          // className={classes.btn}
          fullWidth={r({ xs: true, md: false })}
          size="small"
        >
          {t('legalEntity.refreshData', 'Обновить данные')}
        </Button>
      </Box>
    </div>
  );
};
