import { Box, Container } from '@material-ui/core';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { MainRouteProps } from '../../common/routes';
import dataSourceHelpQuestions from '../../data-sources/help-questions-resourses.json';
import { Header } from '../header';
import {
  HelpContacts,
  HelpForForeigners,
  HelpQuestions,
  HelpSystems,
} from '../help';
import { HelpForDevelopers } from '../help/help-for-developers';
import { Layout } from '../layout';

export const nav: MainRouteProps[] = [
  {
    pathname: '/help',
    title: 'Вопросы',
    i18n: 'nav.help.questions',
    authorized: false,
  },
  // {
  //   pathname: '/help/systems',
  //   title: 'Подключение сторонних систем',
  //   i18n: 'nav.help.connectingSystems',
  //   authorized: false,
  // },
  {
    pathname: '/help/contacts',
    title: 'Связаться с нами',
    i18n: 'nav.help.contacts',
    authorized: false,
  },
  {
    pathname: '/help/for-foreigners',
    title: 'Иностранцам',
    i18n: 'nav.help.foreigners',
    authorized: false,
  },
  {
    pathname: '/help/for-developers',
    title: 'Разработчикам',
    i18n: 'nav.help.developers',
    authorized: false,
  },
];

interface PageHelpProps {}

export const PageHelp: ComponentType<PageHelpProps> = () => {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  return (
    <Layout variant="default" bgcolor="background.paper">
      <Header
        title={t('help', 'Помощь')}
        actions={nav}
        variant="sticky"
        bgcolor="background.paper"
      />
      <Box component={'main'} role="main">
        <Container>
          <Switch>
            <Route path={`${url}/systems`} component={HelpSystems} exact />
            <Route path={`${url}/contacts`} component={HelpContacts} exact />
            <Route
              path={`${url}/for-foreigners`}
              component={HelpForForeigners}
              exact
            />
            <Route
              path={`${url}/for-developers`}
              component={HelpForDevelopers}
              exact
            />
            <Route
              render={() => {
                return <HelpQuestions data={dataSourceHelpQuestions} />;
              }}
              exact
            />
          </Switch>
        </Container>
      </Box>
    </Layout>
  );
};
