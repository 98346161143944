import * as yup from 'yup';

export const FIELD_OLD_PASSWORD = 'oldPassword';
export const FIELD_NEW_PASSWORD = 'newPassword';
export const FIELD_CONFIRM_PASSWORD = 'confirmPassword';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_OLD_PASSWORD]: {
    label: 'Текущий пароль',
    labelKey: 'password.current',
    arialLabel: 'Your current passport',
  },
  [FIELD_NEW_PASSWORD]: {
    label: 'Новый пароль',
    labelKey: 'password.new',
    arialLabel: 'Your new passport',
  },
  [FIELD_CONFIRM_PASSWORD]: {
    label: 'Новый пароль еще раз',
    labelKey: 'password.newAgain',
    arialLabel: 'Your new passport again',
  },
};

export const PasswordFormValidationForm = yup.object().shape({
  [FIELD_OLD_PASSWORD]: yup.string().required('error.required').trim(),
  [FIELD_NEW_PASSWORD]: yup
    .string()
    .required('error.required')
    .min(8, 'error.minLength.8')
    .matches(/^.*((?=.*[A-Z]){1}).*$/, 'error.password.rule.uppercase')
    .matches(/^.*((?=.*[a-z]){1}).*$/, 'error.password.rule.lowercase')
    .matches(/^.*(?=.*\d).*$/, 'error.password.rule.number')
    .trim(),
  [FIELD_CONFIRM_PASSWORD]: yup
    .string()
    .required('error.required')
    .min(8, 'error.minLength.8')
    .trim(),
});
