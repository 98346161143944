import { Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Box, BoxProps } from '@material-ui/system';
import clsx from 'clsx';
import { get } from 'lodash';
import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from '../../../common/store';
import { ESign, ESignItem } from '../../e-sign';
import { useESign } from '../../e-sign/useESign';
import useStyles from './styles';

export interface AuthenticationESignProps {
  className?: string;
}

export const AuthenticationESign: ComponentType<
  AuthenticationESignProps & BoxProps
> = ({ className, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { passportData, innData, pinfl, authenticESP } = useSelector(
    (state: State) => state.profile
  );

  const { sign, refreshKeys } = useESign(pinfl, false);

  const userFullName = [
    get(passportData, 'surname_latin') || get(passportData, 'surname_engl'),
    get(passportData, 'name_latin') || get(passportData, 'name_engl'),
    get(passportData, 'patronym_latin' || get(passportData, 'patronym_engl')),
  ]
    .filter(Boolean)
    .join(' ');

  const confirmInfo = {
    variant: 'confirm',
    values: [
      {
        key: 'fullName',
        text: userFullName,
        size: 12,
      },
      {
        key: 'taxID',
        title: 'taxID',
        text: get(innData, 'tin'),
        size: 3,
      },
      {
        key: 'passportId',
        title: 'passportId',
        text: pinfl,
        size: 4,
      },
    ],
  };

  const handleAuthentication = (value: any) => {
    sign(value);
  };

  return (
    <Box className={clsx(classes.root, className)} {...props}>
      {authenticESP ? (
        <>
          <Typography variant="body2" color="textSecondary" mb={2}>
            {t(
              'authentication.title',
              'Ваша учетная запись подтверждена при помощи ЭЦП'
            )}
          </Typography>
          <ESignItem data={confirmInfo} variant="confirm" status="success" />
        </>
      ) : (
        <ESign
          pinfl={pinfl}
          exists={true}
          actionsItem={(item: any) => [
            <LoadingButton
              onClick={() => handleAuthentication(item)}
              size="small"
              variant="contained"
              className={classes.btn}
              key={'refresh_btn'}
            >
              {t('eSign.action.confirm', 'Подтвердить')}
            </LoadingButton>,
          ]}
          onClickRefresh={() => {
            refreshKeys();
          }}
          onClickSignItem={(item) => handleAuthentication(item)}
        />
      )}
    </Box>
  );
};
