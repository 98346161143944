import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import i18n from './common/i18next';
import useAuth from './components/auth/useAuth';
import { ErrorDialog } from './components/error';
import {
  PageAbout,
  PageAuthentication,
  PageChildren,
  PageConnectedResources,
  PageDemoUI,
  PageHelp,
  PageHistory,
  PageMain,
  PagePersonalData,
  PageSelectRole,
  PageSessions,
  PageUserAgreement,
} from './components/pages';
import { PageAuth } from './components/pages/page-auth';
import { PageLegalEntity } from './components/pages/page-legal-entity';
import { PageLegalEntityCabinet } from './components/pages/page-legal-entity-cabinet';
import { ScrollToTop } from './components/scroll-to-top';
import { useSSO } from './hooks/useSSO';
import { currentLocaleActions } from './slices/currentLocaleSlice';
import { getUserInfo } from './thunks/auth-thunks';

const Routes = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const url = match.url === '/' ? '' : match.url;
  const currentLocale = i18n.language;
  const { authorized, authToken } = useAuth();
  const { token_id } = useSSO();

  useEffect(() => {
    dispatch(currentLocaleActions.setCurrentLocale(currentLocale));
  }, [currentLocale, dispatch]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch, currentLocale]);

  const renderInit = () => {
    if (authorized || authToken) {
      if (token_id) {
        return <PageSelectRole />;
      }
      return <PageMain />;
    }
    return <PageAuth />;
  };

  return (
    <Switch>
      <Route path={[`${url}/`, '/']} exact render={() => renderInit()} />

      {/* authorized false */}
      <Route
        path={`${url}/user-agreement`}
        exact
        component={PageUserAgreement}
      />
      <Route path={`${url}/about`} exact component={PageAbout} />
      <Route path={`${url}/help`} component={PageHelp} />

      {/* authorized true */}
      <Route
        path={`${url}/connected-resources`}
        exact
        component={PageConnectedResources}
      />
      <Route path={`${url}/personal-data`} component={PagePersonalData} />
      <Route path={`${url}/history`} component={PageHistory} />
      <Route path={`${url}/children`} component={PageChildren} />
      <Route path={`${url}/sessions`} exact component={PageSessions} />
      <Route path={`${url}/legal-entity`} exact component={PageLegalEntity} />

      <Route
        path={`${url}/authentication`}
        exact
        component={PageAuthentication}
      />

      <Redirect to={`${url}`} />

      {/* for dev*/}
      {process.env.NODE_ENV === 'development' && (
        <>
          <Route path={`${url}/demo-ui`} exact component={PageDemoUI} />
          <Route
            path={`${url}/legal-entity/cabinet`}
            component={PageLegalEntityCabinet}
          />
        </>
      )}
    </Switch>
  );
};

export const App = ({ hideSplashScreen, showSplashScreen }: any) => {
  const { i18n } = useTranslation();
  useEffect(
    () => hideSplashScreen(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <ScrollToTop />
      <ErrorDialog />
      <Switch>
        <Route path={`/${i18n.language}`}>
          <Routes />
        </Route>
        <Route>
          <Routes />
        </Route>
      </Switch>
    </>
  );
};
