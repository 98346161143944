import { alpha } from '@material-ui/core/styles';

export const a11yPalette = {
  type: 'a11y',
  primary: {
    light: '#222222',
    main: '#222222',
    dark: '#111111',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#222222',
    main: '#222222',
    dark: '#111111',
    contrastText: '#FFFFFF',
  },
  info: {
    light: '#222222',
    main: '#222222',
    dark: '#111111',
    contrastText: '#FFFFFF',
  },
  success: {
    light: '#222222',
    main: '#222222',
    dark: '#111111',
    contrastText: '#FFFFFF',
  },
  error: {
    light: '#222222',
    main: '#222222',
    dark: '#111111',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#222222',
    main: '#222222',
    dark: '#111111',
    contrastText: '#FFFFFF',
  },
  grey: {
    100: alpha('#FFFFFF', 0.4),
    200: alpha('#FFFFFF', 0.2),
    300: alpha('#FFFFFF', 0.05),
    400: alpha('#222222', 0.05),
    500: alpha('#222222', 0.2),
    600: alpha('#222222', 0.4),
    700: alpha('#222222', 0.6),
    800: alpha('#222222', 0.8),
    900: '#222222',
  },
  text: {
    primary: alpha('#222222', 1),
    secondary: alpha('#222222', 0.8),
    tertiary: alpha('#222222', 0.6),
    disabled: alpha('#222222', 0.2),
    hint: alpha('#222222', 0.4),
  },
  action: {
    disabled: alpha('#222222', 0.4),
    disabledBackground: alpha('#222222', 0.4),
    disabledOpacity: 0.4,
  },
  background: {
    paper: '#FFFFFF',
    default: '#EAEAEA',
  },
};
