import * as yup from 'yup';

export const FIELD_EMAIL = 'newMail';

export const FIELD_LABELS: Record<string, any> = {
  [FIELD_EMAIL]: {
    label: 'Электронная почта',
    labelKey: 'email',
    arialLabel: 'Your email',
  },
};

export const ChangeEmailValidationForm = yup.object().shape({
  [FIELD_EMAIL]: yup
    .string()
    .email('error.email.notValid')
    .required('error.required')
    .trim(),
});
