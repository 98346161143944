import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useESign } from '../e-sign/useESign';

export const Children: ComponentType = () => {
  const { t } = useTranslation();

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const { certs, signChildren, refreshKeys } = useESign();

  React.useEffect(() => {
    refreshKeys();
  }, []);

  const handleSignIn = (value: any) => {
    signChildren(value);
  };

  console.log(certs);

  return (
    <Box>
      <Typography variant="body1">{t('children-text-1')}</Typography>
      <br />
      <Typography variant="body1">{t('children-text-2')}</Typography>
      <br />
      <Box display={'flex'} gap={3}>
        <TextField
          id="outlined-basic"
          label={t('passport.pin')}
          variant="outlined"
          sx={{ maxWidth: 320 }}
        />
        <TextField
          id="outlined-basic"
          label={t('children-text-3')}
          variant="outlined"
          sx={{ maxWidth: 320 }}
        />
      </Box>
      <br />
      <Box display={'flex'} alignItems={'center'} gap={3}>
        <TextField
          id="outlined-basic"
          label={t('phone')}
          variant="outlined"
          sx={{ maxWidth: 320 }}
        />
        <Button variant="outlined">{t('action.sendSms')}</Button>
      </Box>
      <br />
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={t('children-offer')}
        />
      </Box>
      <Box display={'flex'} gap={3}>
        {certs.length &&
          certs.map((c: any) => (
            <Button
              variant="outlined"
              sx={{ px: 3, py: 2 }}
              onClick={() => handleSignIn(c)}
            >
              {c.formatedCert.fullName.value}
            </Button>
          ))}
      </Box>

      <br />
      <Button variant="contained">{t('action.signUp')}</Button>
    </Box>
  );
};
