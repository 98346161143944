import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

const options = {
  name: 'e-sign-not-found',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    title: {},
    text: {
      '& p': {
        marginBottom: theme.spacing(1),
        '&:last-child, &$last': {
          marginBottom: 0,
        },
      },
      '& ol, & ul': {
        counterReset: 'step',
        listStyle: 'none',
        padding: 0,
        margin: 0,
      },
      '& li': {
        position: 'relative',
        paddingLeft: theme.spacing(2.25),
        marginBottom: theme.spacing(0.5),
        '&:last-child, &$last': {
          marginBottom: '0,',
        },
        '&::before': {
          counterIncrement: 'step',
          content: 'counter(step)',
          position: 'absolute',
          top: 0,
          left: 0,
          fontWeight: 700,
        },
      },
    },
    last: {},
  }),
  options
);

export default useStyles;
