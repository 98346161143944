import {
  Alert,
  Collapse,
  Hidden,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import clsx from 'clsx';
import { isInteger } from 'lodash';
import React, { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { HelpIcon } from '../../../common/icons';
import { MASK_PINFL } from '../../../constants/masks-constants';
import { SignUpMedia } from '../../auth/sign-up/sign-up-form/sign-up-form-media';
import { Hint } from '../../hint';
import { useResponsive } from '../../theme/useResponsive';
import {
  FIELD_LABELS,
  FIELD_PASSPORT,
  FIELD_PINFL,
} from './legal-entity-employees-add-form-schema';
import useStyles from './styles';
import { useEmployeesAdd } from './useEmployeesAdd';

interface LegalEntityEmployeesAddFormProps {
  className?: any;
  onSuccess?: () => any;
  leTin: string;
}

export const LegalEntityEmployeesAddForm: ComponentType<LegalEntityEmployeesAddFormProps> = ({
  className,
  onSuccess,
  leTin,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const r = useResponsive();
  const {
    formik,
    handleFieldChange,
    hasError,
    getErrorMessage,
    code,
  } = useEmployeesAdd();

  const PREFIX = 'employees-add';

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}
      className={clsx(classes.root, className)}
    >
      <InputMask
        mask={MASK_PINFL}
        maskPlaceholder={null}
        {...formik.getFieldProps(FIELD_PINFL)}
      >
        <TextField
          label={t(
            FIELD_LABELS[FIELD_PINFL]['labelKey'],
            FIELD_LABELS[FIELD_PINFL]['label']
          )}
          id={`${PREFIX}-${FIELD_PINFL}`}
          error={hasError(FIELD_PINFL)}
          helperText={getErrorMessage(FIELD_PINFL)}
          required
          InputProps={{
            endAdornment: r({ xs: true, md: false }) && (
              <InputAdornment position="end">
                <Hidden mdUp>
                  <Hint
                    fullScreen
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'center',
                    }}
                    content={<SignUpMedia my={'auto'} />}
                  >
                    <IconButton
                      aria-label={t('singUp.help', 'Помощь')}
                      color="primary"
                      size="medium"
                    >
                      <SvgIcon
                        className=""
                        component={HelpIcon}
                        viewBox="0 0 24 24"
                      />
                    </IconButton>
                  </Hint>
                </Hidden>
              </InputAdornment>
            ),
            'aria-label': FIELD_LABELS[FIELD_PINFL]['ariaLabel'],
          }}
        />
      </InputMask>

      <TextField
        {...formik.getFieldProps(FIELD_PASSPORT)}
        onChange={(e: any) => handleFieldChange(FIELD_PASSPORT, e)}
        label={t(
          FIELD_LABELS[FIELD_PASSPORT]['labelKey'],
          FIELD_LABELS[FIELD_PASSPORT]['label']
        )}
        id={`${PREFIX}-${FIELD_PASSPORT}`}
        error={hasError(FIELD_PASSPORT)}
        helperText={getErrorMessage(FIELD_PASSPORT)}
        required
        InputProps={{
          'aria-label': FIELD_LABELS[FIELD_PASSPORT]['ariaLabel'],
        }}
      />

      <LoadingButton
        // loading={isLoading}
        // disabled={!noErrors}
        variant="contained"
        type="submit"
        className={classes.btn}
        fullWidth={r({ xs: true, lg: false })}
      >
        {t('action.add', 'Добавить')}
      </LoadingButton>
      <Collapse in={isInteger(code) && Number(code) !== 0}>
        <Alert severity="error">
          {t(`error.api.${code}.full`, `Ошибка с кодом ${code}`)}
        </Alert>
      </Collapse>
    </form>
  );
};
