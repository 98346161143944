import { alpha, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { typography } from '../../theme/typography';

const options = {
  name: 'e-sign-item',
};
const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      cursor: 'pointer',
      overflow: 'hidden',
      userSelect: 'none',
      position: 'relative',
      borderRadius: 4,

      '&:hover, &:focus:hover': {
        '& $actions > *': {
          opacity: 1,
          transform: 'translate(0, 0)',
          transition: theme.transitions.create(['opacity', 'transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      },
    },

    select: {
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
      background: theme.palette.background.paper,
      transition: theme.transitions.create('box-shadow', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '&:hover, &:focus:hover': {
        boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
      },
      '&$error': {
        boxShadow: `inset 0 0 0 1px ${theme.palette.error.dark}`,
      },
    },

    confirm: {
      background: theme.palette.grey[400],
    },
    manage: {},

    success: {
      cursor: 'default',
      pointerEvents: 'none',
      color: theme.palette.success.contrastText,
      background: theme.palette.success.main,
      '& $title, & $text': {
        color: 'inherit',
      },
      '& $itemAction': {
        display: 'none',
      },
    },
    itemAction: {},
    error: {},
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2.5),
        flexWrap: 'nowrap',
      },
    },
    body: {
      flex: '0 0 100%',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
        flex: '2 1',
      },
    },
    actions: {
      position: 'relative',
      marginTop: theme.spacing(4),
      flex: '0 0 100%',
      width: '100%',
      '& > *': {
        position: 'relative',
        zIndex: 100,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: 'auto',
        flex: '0 0 auto',
        width: 'auto',
        maxWidth: '100%',
      },
    },

    list: {},
    item: {
      minWidth: 0,
    },

    title: {
      '.fullName &': {
        display: 'none',
      },
    },
    text: {},
    btn: {},
    btnClose: {},
    icon: {},

    alert: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      inset: 0,
      '&[class*=Error]': {
        color: theme.palette.error.contrastText,
        background: alpha(theme.palette.error.main, 0.9),
        boxShadow: `inset 0 0 0 1px ${theme.palette.error.dark}`,
        '& .MuiAlert-icon': {},
        '& .MuiAlert-message': {},
      },
      '& .MuiAlert-message': {
        ...typography.h5,
      },

      '& .MuiAlert-action': {
        position: 'absolute',
        top: 4,
        right: 4,
        margin: 0,
        padding: 0,
        color: 'inherit',
      },
    },
  }),
  options
);

export default useStyles;
