import { Button, Divider } from '@material-ui/core';
import { find, map } from 'lodash';
import { ComponentType, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { legalEntityActions } from '../../../slices/legal-entity-slice';
import { deleteLegalEntity } from '../../../thunks/legal-entity-thunks';
import { ESignList } from '../../e-sign';
import useStyles from './styles';

interface LegalEntityOrganizationsProps {
  data: Record<string, any>[] | null;
}
export const LegalEntityOrganizations: ComponentType<LegalEntityOrganizationsProps> = ({
  data = null,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  let history = useHistory();
  const dispatch = useDispatch();

  const [items, setItems] = useState<any>([]);
  const [convertedData, setConvertedData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    setConvertedData(
      map(items, (item) => {
        return {
          tin: item.tin,
          variant: 'confirm',
          values: [
            {
              key: 'organization',
              text: item.acron_UZ,
              size: 12,
            },
            {
              key: 'taxID',
              title: 'taxID',
              text: item.tin,
              size: 4,
            },
            {
              key: 'serialNumber',
              title: 'serialNumber',
              text: item.le_ID,
              size: 8,
            },
            {
              key: 'status',
              title: 'status',
              text:
                item.valid_FLAG === 'Y'
                  ? t('legalEntity.status.active', 'Действующий')
                  : t('legalEntity.status.inactive', 'Не действующий'),
              size: 4,
            },
            {
              key: 'regDate',
              title: 'regDate',
              text: item.reg_DATE, // dateParse(item.reg_DATE, 'dd.MM.yyyy', 'ddMMyyyy'),
              size: 8,
            },
            {
              key: 'fullName',
              title: 'fullName',
              text: item.head_NM,
              size: 12,
            },
          ],
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleDisableLegal = (tin: string) => {
    dispatch(deleteLegalEntity(tin));
    setItems(items.filter((item: Record<string, any>) => tin !== item.tin));
  };

  const handleSelectLegal = (tin: string) => {
    // get current legal
    const currentLegal = find(items, (item) => item.tin === tin);
    // set current legal to redux
    dispatch(legalEntityActions.setActiveLegalEntity(currentLegal));
    // redirect
    history.push(`${url}/cabinet`);
  };

  if (!items || items.length === 0) return null;

  return (
    <div className={classes.root}>
      <ESignList
        className={classes.list}
        data={convertedData}
        actionsItem={(item) => [
          <Button
            size="small"
            variant="contained"
            className={classes.btn}
            key="action.manage"
            onClick={() =>
              process.env.NODE_ENV === 'development'
                ? handleSelectLegal(item.tin)
                : undefined
            }
          >
            {t('action.manage', 'Управление')}
          </Button>,
          <Button
            size="small"
            variant="outlined"
            color="error"
            className={classes.btn}
            onClick={() => handleDisableLegal(item.tin)}
            key="action.disable"
          >
            {t('action.disable', 'Отключить')}
          </Button>,
        ]}
      />
      <Divider className={classes.divider} />
    </div>
  );
};
